<div class="container-principal">
  <div class="row col-md-12">
    <fieldset class="border p-3 my-3">
      <legend class="legendFormulario" *ngIf="aprovados===false">Solicitações de supervisão recebidas</legend>
      <legend class="legendFormulario" *ngIf="aprovados===true">Supervisionados</legend>
      <table class="table table-bordered table-responsive">
        <thead>
          <tr>
            <th *ngIf="aprovados===false">Data de Recebimento</th>
            <th *ngIf="aprovados===true">Data de Aprovação</th>
            <th>Nome do Supervisionado</th>
            <th>Prazo para análise</th>
            <th *ngIf="!aprovados">Status</th>
            <th *ngIf="aprovados"></th>
            <th *ngIf="aprovados"></th>
            <th *ngIf="!aprovados">Motivo</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let solicitacao of solicitacoes; let i = index">
            <td *ngIf="aprovados===false">{{solicitacao.dataCadastro | date: 'dd/MM/yyyy'}}</td>
            <td *ngIf="aprovados===true">{{solicitacao.dataAlteracao | date: 'dd/MM/yyyy'}}</td>
            <td>{{solicitacao.nome}}</td>
            <td>{{solicitacao.dataPrazoAnalise | date: 'dd/MM/yyyy'}}</td>
            <td *ngIf="!aprovados && solicitacao.idSituacao===1"><a href="#/solicitacao-supervisor/{{solicitacao.idPessoa}}" routerLinkActive="router-link-active">{{solicitacao.situacao}}</a></td>
            <td *ngIf="!aprovados && solicitacao.idSituacao!==1">{{solicitacao.situacao}}</td>
            <td *ngIf="aprovados"><a href="#/home/false/true/relatorios/{{solicitacao.idPessoa}}" routerLinkActive="router-link-active" routerLinkActive="router-link-active">Acompanhar</a></td>
            <td *ngIf="aprovados"><a *ngIf="solicitacao.idSituacao<=4" (click)="confirmarCancelamentoSupervisor(solicitacao.idSolicitacaoSupervisor);" style="cursor:pointer;">Cancelar</a></td>
            <td *ngIf="!aprovados">{{solicitacao.motivoCancelamento}}</td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-12 text-right">
        <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" onclick="history.go(-1);">
          Voltar
        </button>        
      </div>
    </fieldset>

  </div>


</div>