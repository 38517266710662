<form class="mt-3"
      [formGroup]="adesaoCodigoEticaForm"
      id="adesaoCodigoEticaForm"
      name="adesaoCodigoEticaForm"
      (ngSubmit)="submit(adesaoCodigoEticaForm)">
  <fieldset class="border p-3 my-3">
    <legend class="legendFormulario">Adesão ao Código de Ética, aos Guias de Melhores Práticas e Utilização da Marca CFP®</legend>

    <div class="row">
      <div class="form-group col-md-12 col-sm-12">
        <label>{{itens['assunto']}}</label>
        <div formControlName="codigoEtica"
             id="summer"
             name="summer"
             [ngxSummernote]="config"
             [ngxSummernoteDisabled]="true">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label *ngIf="monstrarLabel != null">
          {{monstrarLabel['complemento']}}
        </label>
      </div>

      <div class="col-md-6 text-right">
        <button class="btn btn-sm btn-primary btn-secondary mr-0"
                id="voltar"
                (click) = "voltar()"
                type="button">
        Voltar
        </button>

        <button class="btn btn-sm btn-primary mr-0 ml-2"
                [disabled]="!aderir"
                id="aderir"
                title="Aderir"
                type="submit">
          Aderir
        </button>
      </div>
    </div>
  </fieldset>
</form>
