import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExameService } from 'src/app/services/exame/exame.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-exame-config',
  templateUrl: './exame-config.component.html',
  styleUrls: ['./exame-config.component.scss']
})
export class ExameConfigComponent implements OnInit {
  files: File[] = [];
  view = false;
  imagem = null;
  usuario = null;
  exames = null;
  inscricaoModel = 1;
  modulos = [];
  mostrarGraficos = false;
  dataGabaritoOficial: any;
  origin = '';

  constructor(private router: Router,
    private route: ActivatedRoute, private exameService : ExameService) { }

  ngOnInit(): void {
    this.usuario = window.localStorage.getItem('userId');
    this.route.paramMap.subscribe(params => {
      if (params.get("filtro")) {
        this.inscricaoModel = Number(params.get("filtro"));
      }

      if (params.get("origin")) {
        this.origin = params.get("origin")
      }
    });
    this.carregaGrid();
  }

  getStyles(modulo: any) {
    const height = (modulo.resultado * 250) / 100;

    if (modulo.resultado == 0 ) {
      return {
        height: `18px`,
        textAlign: 'center'
      }
    }

    if (modulo.descricaoSituacao == 'Aprovado') {
      return {
        backgroundColor: '#a5cd39',
        height: `${height}px`,
        textAlign: 'center'
      }
    }

    if (modulo.descricaoSituacao == 'Reprovado') {
      return {
        backgroundColor: '#ad3030',
        height: `${height}px`,
        textAlign: 'center'
      }
    }

    return {
      backgroundColor: '#949292',
      height: `${height}px`,
      textAlign: 'center'
    }
  }

  carregaGrid(){
    this.exameService.obterExame(this.usuario, 2).subscribe((result) => {
        this.exames = result.resultado;
        if (this.inscricaoModel == 1) {
          this.dataGabaritoOficial = moment(this.exames[0]).add(25, 'days').format('DD/MM/yyyy');
        }

        this.carregarModulos();
      });
  }

  voltar() {
    if (this.origin == 'progress')
      this.router.navigate(['home']);
    else
      this.router.navigate(['exame']);
  }

  setMostrarGraficos(mostrar: boolean) {
    this.mostrarGraficos = mostrar;
  }

  carregarModulos() {
    const modulosNome = [
      'Novo Mód. I - Planejamento Financeiro e Ética',
      'Novo Mód. II - Gestão de Ativos e  Investimentos',
      'Novo Mód. III - Planejamento de Aposentadoria',
      'Novo Mód. IV - Gestão de Riscos e Seguros',
      'Novo Mód. V - Planejamento Fiscal',
      'Novo Mód. VI - Planejamento Sucessório'
    ];

    for (let nome of modulosNome) {
      const examesPorModulo = this.exames.filter(e => e.descricaoModulo.includes(nome));
      if (examesPorModulo.length > 0) {
        this.modulos.push(examesPorModulo);
      }
    }
  }

  excluir(exame){
    Swal.fire({
      title: 'Resultado Exame',
      text: "Confirma o descarte do resultado aprovado desse módulo? Essa ação não pode ser desfeita!",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.exameService.excluirExame(exame.idInscricaoModulo).subscribe((resp)=>{
          Swal.fire(
            'Resultado Exame',
            'Exame descartado com sucesso!',
            'success'
          ).then(() => {
            this.carregaGrid();
          });
        },(erro)=>{
          Swal.fire(
            'Resultado Exame',
            erro.error.excecao[0].mensagem,
            'error'
          );
        });
      }
    });
  }

}
