import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { TutorialService } from '../../services/tutorial/tutorial.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-tutoriais',
  templateUrl: './tutoriais.component.html',
  styleUrls: ['./tutoriais.component.scss'],
})
export class TutoriaisComponent implements OnInit {

  tutoriais: [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private tutorialService: TutorialService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.obterTutoriais();
  }


  obterTutoriais() {
    this.tutorialService.obterTutoriais().subscribe((res) => {
      this.tutoriais = res.resultado;
    });
  }

  exbirTexto(idTexto) {
    if ($(`#${idTexto}`).is(':visible')) {
      $(`#${idTexto}`).hide('slow');
    } 
    else {
      $(`#${idTexto}`).show('slow');
    } 
  }

  voltar() {
    this.router.navigate(['/login']);
  }

}
