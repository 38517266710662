import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExameService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }



  obterExame(codigoPessoa: any, filtro: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-resultados-exames/obter-exames`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, filtro },
      }
    );
  }

  excluirExame(codigoInscricaoModulo: any) {
    return this.http.delete(
      `${environment.backofficeUrl}/area-logada-resultados-exames/recusar-resultado`,
      {
        headers: this.httpHeaders,
        params: { codigoInscricaoModulo }
      }
    );
  }
  
}
