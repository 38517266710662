import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SolicitacaoAssociacaoNaoCertificadoService } from 'src/app/services/solicitacao-associacao-nao-certificado/solicitacao-associacao-nao-certificado.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-solicitacao-associacao-nao-certidicado',
  templateUrl: './solicitacao-associacao-nao-certidicado.component.html',
  styleUrls: ['./solicitacao-associacao-nao-certidicado.component.scss']
})
export class SolicitacaoAssociacaoNaoCertidicadoComponent implements OnInit {

  files: File[] = [];
  view = false;
  imagem = null;
  obj = [];
  id: any;

  constructor(
    private route : ActivatedRoute,
    private router: Router,
    public serviceAssociacaoNaoCertificada: SolicitacaoAssociacaoNaoCertificadoService
  ) {
    this.id =  JSON.parse(localStorage.getItem('userId'));
   }

  ngOnInit(): void {
  }

  onSelect(event) {
    this.obj = [];
    this.onRemove();
    this.obj =  event.source._previewChildren;
    this.files.push(...event.addedFiles);
  }

  onRemove() {
    this.obj = [];
    this.files.splice(0, 1);
    if (this.imagem !== '') {
      this.imagem = '';
    }
  }
  enviar(){
        if(this.files.length > 0){
        let obj =  {
            "idPessoa": this.id,
            "nomeArquivo": this.obj['first'].file.name,
            "arquivo": this.obj['first'].imageSrc.replace(/^data:(.*base64,)?/,''),
          };

          this.serviceAssociacaoNaoCertificada.criarInscricaoNaoCertificada(obj)
          .subscribe((resp)=>{
            Swal.fire(
              'Solicitação de Associação Não Certificado',
              `Cadastrada com Sucesso.`,
              'success'
            ).then(() => {
              this.onRemove();
            });
          },(erro)=>{
            Swal.fire(
              'Solicitação de Associação Não Certificado ',
              erro.error.excecao[0].mensagem,
              'error'
            );
          });
        }else{
          Swal.fire(
            'Solicitação de Associação Não Certificado',
            `Insira ao menos um arquivo.`,
            'warning'
          );
        }
  }

}
