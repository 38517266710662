<div class="container">
  <div class="row">
    <p>Selecione uma forma de pagamento.</p>
  </div>

  <div class="row">
    <div class="col-md-6 tipo-pagamento">
      <input (change)="trocaTipo(1);calculaVencimento();" name="tipo" type="radio" />
      <label class="tipo-pagamento-lbl" for="tipo">Cartão</label>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6 tipo-pagamento">
      <input (change)="trocaTipo(2)" name="tipo" type="radio" />

      <label class="tipo-pagamento-lbl_1" for="tipo">Boleto Bancário</label>
      <p class="text-center"><strong>Pagador</strong></p>
      <div class="row mt-3">
        <div class="text-center">
          <div class="col-md-6">
            <input (change)="changePagador(1)" [disabled]="radioTipo" name="pagador" type="radio" value="euMesmo" />
            <label for="pagador">&nbsp; Eu mesmo</label>
          </div>
          <div class="col-md-6">
            <input (change)="changePagador(2)" [disabled]="radioTipo" name="pagador" type="radio"
              value="pessoaJuridica" />
            <label for="pagador">&nbsp; Pessoa Jurídica</label>
          </div>
        </div>

        <form autocomplete="off" class="mt-5" [formGroup]="pagamento" id="pagamento" name="pagamento">

          <div class="inputForm">
            <div class="row">
              <div class="form-group col-md-12 col-sm-12">
                <label for="nomeCompleto">Nome Completo/Razão Social:</label>
                <input class="form-control form-control-sm" [class.is-invalid]="
                  nomeCompleto.invalid && (nomeCompleto.touched || isSubmit)
                  " formControlName="nomeCompleto" id="nomeCompleto" maxlength="100" name="nomeCompleto" type="text"
                  [ngModel]="camposInput ? camposInput.nome : ''" />
                <div *ngIf="nomeCompleto.invalid && (nomeCompleto.touched || isSubmit)">
                  <span class="erro" *ngIf="nomeCompleto.errors.required">Campo obrigatório</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-12 col-sm-12">
                <label for="cpf">CPF/CNPJ:</label>
                <input class="form-control form-control-sm" [class.is-invalid]="
                  cpf.invalid && (cpf.touched || isSubmit)
                  " formControlName="cpf" id="cpf" maxlength="100" name="cpf" type="text"
                  [ngModel]="camposInput ? camposInput.cpf : ''" [mask]="maskCpfCnpj" />
                <div *ngIf="cpf.invalid && (cpf.touched || isSubmit)">
                  <span class="erro" *ngIf="cpf.errors.required">Campo obrigatório</span>
                </div>
                <div *ngIf="cnpjInvalido">
                  <span class="erro">CNPJ inválido.</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6 col-sm-12">
                <label for="cep">CEP:</label>
                <input class="form-control form-control-sm" [class.is-invalid]="
                  cep.invalid && (cep.touched || isSubmit)
                  " formControlName="cep" id="cep" maxlength="8" name="cep" type="text"
                  (blur)="obterLogradouroPorCep()"
                  [ngModel]="camposInput ? camposInput.pessoaEndereco[0].codigoPostal : ''" />
                <div *ngIf="cep.invalid && (cep.touched || isSubmit)">
                  <span class="erro" *ngIf="cep.errors.required">Campo obrigatório</span>
                </div>
              </div>

              <div class="form-group col-md-6 col-sm-12">
                <label for="endereco">Endereço:</label>
                <input class="form-control form-control-sm" [class.is-invalid]="
                  endereco.invalid && (endereco.touched)
                  " formControlName="endereco" id="endereco" maxlength="100" name="endereco" type="text"
                  disabled="true" [ngModel]="camposInput ? camposInput.pessoaEndereco[0].endereco : ''" />
                <div *ngIf="endereco.invalid && (endereco.touched || isSubmit)">
                  <span class="erro" *ngIf="endereco.errors.required">Campo obrigatório</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6 col-sm-12">
                <label for="numero">Número:</label>
                <input class="form-control form-control-sm" [class.is-invalid]="
                  numero.invalid && (numero.touched || isSubmit)
                  " formControlName="numero" id="numero" name="numero" type="text"
                  [ngModel]="camposInput ? camposInput.pessoaEndereco[0].numero : ''" />
                <div *ngIf="numero.invalid && (numero.touched || isSubmit)">
                  <span class="erro" *ngIf="numero.errors.required">Campo obrigatório</span>
                </div>
              </div>

              <div class="form-group col-md-6 col-sm-12">
                <label for="complemento">Complemento:</label>
                <input class="form-control form-control-sm" formControlName="complemento" id="complemento"
                  name="complemento" type="text"
                  [ngModel]="camposInput ? camposInput.pessoaEndereco[0].complemento : ''" />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6 col-sm-12">
                <label for="bairro">Bairro:</label>
                <input class="form-control form-control-sm" [class.is-invalid]="
                  bairro.invalid && (bairro.touched || isSubmit)
                  " formControlName="bairro" id="bairro" maxlength="100" name="bairro" type="text" disabled="true"
                  [ngModel]="camposInput ? camposInput.pessoaEndereco[0].bairro : ''" />
                <div *ngIf="bairro.invalid && (bairro.touched || isSubmit)">
                  <span class="erro" *ngIf="bairro.errors.required">Campo obrigatório</span>
                </div>
              </div>

              <div class="form-group col-md-6 col-sm-12">
                <label for="cidade">Cidade:</label>
                <input class="form-control form-control-sm" [class.is-invalid]="
                  cidade.invalid && (cidade.touched || isSubmit)
                  " formControlName="cidade" id="cidade" maxlength="100" name="cidade" type="text" disabled="true"
                  [ngModel]="camposInput ? camposInput.pessoaEndereco[0].cidade : ''" />
                <div *ngIf="cidade.invalid && (cidade.touched || isSubmit)">
                  <span class="erro" *ngIf="cidade.errors.required">Campo obrigatório</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6 col-sm-12">
                <label class="texto--sm" for="uf">UF:</label>
                <select class="form-control form-control-sm"
                  [class.is-invalid]="uf.invalid && (uf.touched || isSubmitted)" formControlName="uf" id="uf" name="uf">
                  <option value="" [selected]="camposInput ? false : true">Selecione</option>
                  <option value="AC" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'AC' : false">AC
                  </option>
                  <option value="AL" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'AL' : false">AL
                  </option>
                  <option value="AP" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'AP' : false">AP
                  </option>
                  <option value="AM" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'AM' : false">AM
                  </option>
                  <option value="BA" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'BA' : false">BA
                  </option>
                  <option value="CE" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'CE' : false">CE
                  </option>
                  <option value="DF" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'DF' : false">DF
                  </option>
                  <option value="ES" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'ES' : false">ES
                  </option>
                  <option value="GO" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'GO' : false">GO
                  </option>
                  <option value="MA" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'MA' : false">MA
                  </option>
                  <option value="MS" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'MS' : false">MS
                  </option>
                  <option value="MT" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'MT' : false">MT
                  </option>
                  <option value="MG" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'MG' : false">MG
                  </option>
                  <option value="PA" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'PA' : false">PA
                  </option>
                  <option value="PB" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'PB' : false">PB
                  </option>
                  <option value="PR" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'PR' : false">PR
                  </option>
                  <option value="PE" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'PE' : false">PE
                  </option>
                  <option value="PI" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'PI' : false">PI
                  </option>
                  <option value="RJ" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'RJ' : false">RJ
                  </option>
                  <option value="RN" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'RN' : false">RN
                  </option>
                  <option value="RS" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'RS' : false">RS
                  </option>
                  <option value="RO" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'RO' : false">RO
                  </option>
                  <option value="RR" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'RR' : false">RR
                  </option>
                  <option value="SC" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'SC' : false">SC
                  </option>
                  <option value="SP" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'SP' : false">SP
                  </option>
                  <option value="SE" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'SE' : false">SE
                  </option>
                  <option value="TO" [selected]="camposInput ? camposInput.pessoaEndereco[0].estado == 'TO' : false">TO
                  </option>
                </select>
                <div *ngIf="uf.invalid && (uf.touched || isSubmitted)">
                  <span class="erro" *ngIf="uf.errors.required">Campo obrigatório</span>
                </div>
              </div>

              <div class="form-group col-md-6 col-sm-12">

                <label for="vencimento">Vencimento:</label>

                <input id="vencimento" class="form-control form-control-sm"
                [class.is-invalid]="vencimento.invalid && (vencimento.touched || isSubmit)"
                formControlName="vencimento" type="date" value="{{vencimento | date: 'yyyy-MM-dd'}}"
                required (change)="calculaVencimentoValor($event)"/>

                <div *ngIf="
                    vencimento.invalid &&
                    (vencimento.touched || isSubmit)
                  ">
                  <span class="erro" *ngIf="vencimento.errors.required">Campo obrigatório</span>
                </div>
              </div>

            </div>


            <div class="row mt-2">
              <div class="form-group col-md-12 col-sm-12">
                <input formControlName="resp" id="resp" name="resp" type="checkbox" />

                <label class="texto--sm ml-2">
                  Me responsabilizo pelos dados informados acima.
                </label>
                <div *ngIf="isSubmit && pagamento.controls['resp'].value === false">
                  <span class="erro">Campo obrigatório</span>
                </div>
              </div>
            </div>

          </div>
        </form>

      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="debitos">
    <div class="col-md-6 col-sm-12 pagar">
      <div class="row">
        <div class="col-md-10 col-sm-12">
          <p><strong>Valor: R$ {{ debitos.valorCobranca }}</strong></p>
        </div>

        <div class="col-md-2 col-sm-12">
          <button class="btn btn-sm btn-primary" (click)="submit()"
            [disabled]="liberar || (tipoPagamento === 2 && !(tipoPagador === 1 || tipoPagador === 2))" id="btnPagar">
            Pagar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
