import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import Swal from 'sweetalert2';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';
import * as moment from 'moment';

@Component({
  selector: 'app-acordo-supervisao',
  templateUrl: './acordo-supervisao.component.html',
  styleUrls: ['./acordo-supervisao.component.scss']
})
export class AcordoSupervisaoComponent implements OnInit {
  aderir = false;
  monstrarLabel = [];
  config: any;
  acordoSupervisaoForm: FormGroup;
  itens = [];
  id: any;
  isSupervisor : boolean = false;

  codigoExperienciaSupervisionada: number;
  idPessoa: any = null;
  codigoSituacao: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private pessoaService: PessoaService,
    private experienciaSupervisionadaService: ExperienciaSupervisionadaService
    ) {
      this.id = localStorage.getItem('userId');
      this.init();
      this.config = {
        placeholder: '',
        tabsize: 2,
        height: '200px',
        toolbar: [
        ],
        lang: "pt-BR",
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
      }
    }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      if (params.get("idPessoa") !== null)
      {
        this.idPessoa = params.get("idPessoa");        
        this.verificarSupervisionadoEValido(this.idPessoa);    
        this.obterAcordoSupervisao(this.idPessoa);      
      }
      else
      {
        this.idPessoa = this.id;
        this.obterAcordoSupervisao(this.idPessoa);      
      }
    });

    this.verificarSupervisor();    

  }

  obterAcordoSupervisao(idPessoa) {

    this.experienciaSupervisionadaService
          .obterAcordoSupervisaoPorSupervisionado(idPessoa)
            .subscribe((resp) => {
              if(resp.resultado !== null)
              {
                this.codigoExperienciaSupervisionada = resp.resultado.idExperienciaSupervisionada;
                this.codigoSituacao = resp.resultado.idSituacao;
              }
              else
                this.router.navigate(['/home']);    
            });

  }

  enviar()
  {
    this.experienciaSupervisionadaService
      .alterarSituacao(this.codigoExperienciaSupervisionada, (this.isSupervisor === true ? 3 : 2))
      .subscribe((resp)=>{
/*         Swal.fire(
          'Relatório',
          (this.isSupervisor === true ? 'Relatório enviado ao supervisor com sucesso!' : 'Relatório enviado à Planejar com sucesso!'),
          'success'
        ).then(() => {
          history.go(-1);
        }); */        
        this.aderir = false;
        this.obterAcordoSupervisao(this.idPessoa);
        this.textoAcordoSupervisao(this.isSupervisor);  
    }, (erro) => {
      Swal.fire(
        'Relatório',
        erro.error.excecao[0].mensagem,
        'error'
      );
    }); 
  }  

  verificarSupervisionadoEValido(idPessoa)
  {
    this.experienciaSupervisionadaService.obterExperienciasPorSupervisor(this.id).subscribe((resp)=>{
      if(!resp.resultado?.some(d=>d.idPessoa == idPessoa))
        history.go(-1)
    });

  }

  verificarSupervisor()
  {
    this.pessoaService.obterPorId(this.id)
      .subscribe((resp)=>{
        this.isSupervisor = (resp.resultado.perfilSupervisor === null ? false : resp.resultado.perfilSupervisor);
        this.textoAcordoSupervisao(this.isSupervisor);
      });    
  }

  textoAcordoSupervisao(isSupervisor){
    this.experienciaSupervisionadaService
      .obterTextoAcordoSupervisao(this.idPessoa, (isSupervisor === true ? 2: 1))
      .subscribe((resp)=>{
      this.acordoSupervisaoForm.controls.textoAcordo.setValue(resp.resultado.descricao);
       this.itens = resp.resultado;
       this.obterDataAdesao();
    }, (erro) => {
      Swal.fire(
        'Acordo de Supervisão',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  init(){
    this.acordoSupervisaoForm = this.formBuilder.group({
      textoAcordo:[''],
    });
  }

  submit(adesaoCodigoEticaForm){


    Swal.fire({
      title: 'Envio de acordo de supervisão',
      text: "Confirma o aceite do acordo de supervisão?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.experienciaSupervisionadaService
        .aceitarTextoAcordoSupervisao(
        this.idPessoa, (this.isSupervisor === true ? 2: 1)).subscribe((resp)=>{
          Swal.fire(
            'Acordo de Supervisão',
             'Aceite efetuado com sucesso!',
            'success'
          ).then(()=>{
            this.enviar();
          });
      }, (erro) => {
        Swal.fire(
          'Acordo de Supervisão',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });        

      }
    });

  }

  rejeitar(){


    Swal.fire({
      title: 'Rejeitar acordo de supervisão',
      text: "Confirma a rejeição do acordo de supervisão?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.experienciaSupervisionadaService
        .alterarSituacao(this.codigoExperienciaSupervisionada, 4)
        .subscribe((resp)=>{
  /*         Swal.fire(
            'Relatório',
            (this.isSupervisor === true ? 'Relatório enviado ao supervisor com sucesso!' : 'Relatório enviado à Planejar com sucesso!'),
            'success'
          ).then(() => {
            history.go(-1);
          }); */        
/*           this.aderir = false;
          this.obterAcordoSupervisao(this.idPessoa);
          this.textoAcordoSupervisao(this.isSupervisor);  
 */      
          history.go(-1);
        }, (erro) => {
        Swal.fire(
          'Relatório',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });        

      }
    });

  }  

  obterDataAdesao(){
    this.experienciaSupervisionadaService.obterAceiteAcordoSupervisao(
      this.idPessoa, (this.isSupervisor === true ? 2 : 1)).subscribe((resp)=>{
        if(!this.isSupervisor && resp.resultado.dataAcordoSupervisao === null){
          this.aderir = true;
        }
        if(this.isSupervisor && resp.resultado.dataAcordoSupervisaoSupervisor === null){
          this.aderir = true;
        }
        this.monstrarLabel = resp.resultado;
        if(this.isSupervisor === true)
        {
          if(this.monstrarLabel['dataAcordoSupervisaoSupervisor'] !== null)
            this.monstrarLabel['complemento'] = 'Acordo de Supervisão aceito em ' + moment(this.monstrarLabel['dataAcordoSupervisaoSupervisor']).format('DD/MM/YYYY');
          else
            this.monstrarLabel['complemento'] = null;
        }
        else
        {
          if(this.monstrarLabel['dataAcordoSupervisao'] !== null && this.codigoSituacao !== 4)
            this.monstrarLabel['complemento'] = 'Acordo de Supervisão aceito em ' + moment(this.monstrarLabel['dataAcordoSupervisao']).format('DD/MM/YYYY');
          else
            this.monstrarLabel['complemento'] = null;
        }
      },(erro) => {
      Swal.fire(
        'Acordo de Supervisão',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
   }

}
