<div class="container-principal">
  <div class="row col-md-12">
    <fieldset class="border p-3 my-3">
      <legend class="legendFormulario">Histórico de solicitações de supervisão enviadas </legend>
      <table class="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Data de Envio</th>
            <th>Nome do Profissional selecionado</th>
            <th>Prazo para análise</th>
            <th>Status</th>
            <th>Motivo</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let solicitacao of solicitacoes; let i = index">
            <td>{{solicitacao.dataCadastro | date: 'dd/MM/yyyy'}}</td>
            <td>{{solicitacao.nomeSupervisor}}</td>
            <td>{{solicitacao.dataPrazoAnalise | date: 'dd/MM/yyyy'}}</td>
            <td>{{solicitacao.situacao}}</td>
            <td>{{solicitacao.motivoCancelamento}}</td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-12 text-right">
        <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" onclick="history.go(-1);">
          Voltar
        </button>
      </div>
    </fieldset>

  </div>


</div>
