export const dataTabeOptions = {
  lengthMenu: [5, 10, 25, 50],
  language: {
    sEmptyTable: 'Nenhum registro encontrado',
    sInfo: 'Mostrando de _START_ até _END_ de _TOTAL_ registros',
    sInfoEmpty: 'Mostrando 0 até 0 de 0 registros',
    sInfoFiltered: '',
    sInfoPostFix: '',
    sInfoThousands: '.',
    sLengthMenu: 'Exibir _MENU_',
    sLoadingRecords: 'Carregando...',
    sProcessing: 'Processando...',
    sZeroRecords: 'Nenhum registro encontrado',
    sSearch: '',
    searchPlaceholder: 'Pesquisar',
    oPaginate: {
      sNext: 'Próximo',
      sPrevious: 'Anterior',
      sFirst: 'Primeiro',
      sLast: 'Último',
    },
    oAria: {
      sSortAscending: ': Ordenar colunas de forma ascendente',
      sSortDescending: ': Ordenar colunas de forma descendente',
    },
    select: {
      rows: {
        _: 'Selecionado %d linhas',
        0: 'Nenhuma linha selecionada',
        1: 'Selecionado 1 linha',
      },
    },
  },
  aaSorting: [],
};
