

<fieldset class="border p-3 my-3">
  <legend class="legendFormulario">Tutorial</legend>
  <div class="dashboard">
    <div class="row">
      </div>
      <div class="row mt-3 ml-5">
        <div>
          <div *ngFor="let topico of tutoriais" style="margin-bottom: 15px;">
            <div style="font-size: 18px;margin-bottom: 10px;font-weight: bold;color: #a5cd39;">{{ topico.descricao }}</div>
            <div *ngFor="let texto of topico.textos">
              <div>
                <div class="quationItem" (click)="exbirTexto(texto.idTexto)">
                  <p style="font-size: 16px;margin: 0px !important;">{{ texto.assunto }}</p>
                </div>
                <div style="display: none;padding: 0px 5px;margin-top: 5px;" [id]="texto.idTexto" [innerHTML]="texto.descricao">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</fieldset>