import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JustificarAusenciaService } from 'src/app/services/justificar-ausencia/justificar-ausencia.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { SolicitacaoPlanoFinanceiroService } from 'src/app/services/plano-financeiro/solicitacao-plano-financeiro.service';
import * as moment from 'moment';

@Component({
  selector: 'app-justificar-ausencia',
  templateUrl: './justificar-ausencia.component.html',
  styleUrls: ['./justificar-ausencia.component.scss']
})
export class JustificarAusenciaComponent implements OnInit {

  files: File[] = [];
  view = false;
  imagem = null;
  obj = [];
  id: any;
  descricao: any;
  idInscricao: any;
  idExame: any;
  idSolicitacaoPlanoFinanceiro: any;
  justificativaEnviada: any = null;
  exibeObservacao: boolean = false;

  constructor(
    private route : ActivatedRoute,
    private router: Router,
    public justificativaService: JustificarAusenciaService,
    public planoFinanceiroService: SolicitacaoPlanoFinanceiroService
  ) {
    this.id =  JSON.parse(localStorage.getItem('userId'));
    this.idExame =  JSON.parse(localStorage.getItem('exameSelecionado'));
    console.log(this.idExame)
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params.get("idInscricao")) {
        this.idInscricao = params.get("idInscricao")
      }
      if (params.get("idSolicitacaoPlanoFinanceiro")) {
        this.idSolicitacaoPlanoFinanceiro = params.get("idSolicitacaoPlanoFinanceiro")
      }
    });

    let obterTodos = () => this.justificativaService.obterTodos(this.idExame, this.id)

    if (this.idSolicitacaoPlanoFinanceiro) {
      obterTodos = () => this.justificativaService.obterTodosPlanoFinanceiro(this.idSolicitacaoPlanoFinanceiro, this.id)
    }

    obterTodos()
      .subscribe(
        (resp)=>{
          this.justificativaEnviada = resp.resultado;
          console.log(this.justificativaEnviada)

          if (this.idSolicitacaoPlanoFinanceiro && this.justificativaEnviada[0].aprovado) {
            this.planoFinanceiroService.obterSolicitacaoPorId(this.idSolicitacaoPlanoFinanceiro)
              .subscribe(
                (resp) => {
                  if (moment(resp.resultado.dataLimiteCuradoria).isBefore(moment()) || moment(resp.resultado.dataLimiteResultado).isBefore(moment())) {
                    this.exibeObservacao = true;
                  }
                },
                (erro) => {
                  console.log('erro ao buscar solicitacao ' + erro)
                }
              );
          }
        },
        (erro)=>{
          console.log('erro ao buscar justificativas ' + erro)
        }
      );


  }

  onSelect(event) {
    this.obj = [];
    this.onRemove();
    this.obj =  event.source._previewChildren;
    this.files.push(...event.addedFiles);
  }

  onRemove() {
    this.obj = [];
    this.files.splice(0, 1);
    if (this.imagem !== '') {
      this.imagem = '';
    }
  }

  abrirTransferencia(){
    this.router.navigate(['/transferencia-inscricao/'+this.idInscricao+'/true']);
  }

  abrirCancelamento(){
    this.router.navigate(['/cancelamento-inscricao/'+this.idInscricao+'/true']);
  }

  enviar() {
    this.descricao = $('#descricaoJustificativa').val();
    if (this.justificativaEnviada.length > 0) {
      Swal.fire(
        'Solicitação de Justificativa',
        `Já foi enviada uma justificativa, aguarde a análise`,
        'warning'
      );
      return
    }

    if (this.files.length > 0) {
      let obj =  {
        "idPessoa": this.id,
        "descricao": this.descricao,
        "arquivo": this.obj['first'].imageSrc.replace(/^data:(.*base64,)?/,''),
        "idExame": this.idExame,
        "idSolicitacaoPlanoFinanceiro": this.idSolicitacaoPlanoFinanceiro,
        "nomeArquivo": this.obj['first'].file.name,
      };

      let criar = () => this.justificativaService.criar(obj)

      if (this.idSolicitacaoPlanoFinanceiro) {
        criar = () => this.justificativaService.criarPlanoFinanceiro(obj)
      }
      criar()
      .subscribe((resp)=>{
        Swal.fire(
          'Solicitação de Justificativa',
          `Cadastrada com Sucesso. Você pode acompanhar o progresso dela por aqui.`,
          'success'
        ).then(() => {
          this.onRemove();
          if (this.idSolicitacaoPlanoFinanceiro) {
            this.ngOnInit()
          } else {
            this.router.navigate(['/inscricao-config/' + this.idInscricao]);
          }
        });
      },(erro)=>{
        Swal.fire(
          'Solicitação de Justificativa ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    } else {
      Swal.fire(
        'Solicitação de Justificativa',
        `Insira ao menos um arquivo.`,
        'warning'
      );
    }
  }

}
