<fieldset class="border p-3 my-3">
  <legend class="legendFormulario" *ngIf="!this.idSolicitacaoPlanoFinanceiro">Envio de atestado </legend>
  <legend class="legendFormulario" *ngIf="this.idSolicitacaoPlanoFinanceiro">Justificativa de Solicitacao de Plano Financeiro </legend>
<div class="row d-flex justify-content-center">
  <div class="dropMobile text-right align-items-center col-md-4 col-sm-4"
      [ngClass]="{ 'mt-4': view }">
        <div class="text-right align-items-center dropCustom col-sm-12 mt-3">
          <div class="w-100">
            <div [accept]="'image/jpg, image/png, application/pdf, application/vnd.ms-word,.zip, .rar, .doc, .docx, .jpg, .jpeg'"
                 (change)="onSelect($event)"
                 class="custom-dropzone col-sm-12"
                 #drop
                 [disabled]="view"
                 [maxFileSize]="2097152"
                 [multiple]="false"
                 ngx-dropzone>
              <ngx-dropzone-label>
                <div>
                  <img class="img-adicionada"
                       [src]="imagem" />
                  <p *ngIf="(!view && imagem == null) || imagem == ''">
                    Arraste o seu arquivo ou clique aqui para selecionar
                  </p>
                </div>
              </ngx-dropzone-label>
              <ngx-dropzone-image-preview class="previa-img"
                                          *ngFor="let f of files"
                                          [file]="f"
                                          ngProjectAs="ngx-dropzone-preview"
                                          [removable]="true">
              </ngx-dropzone-image-preview>
            </div>
            <div class="text-center">
              <button class="btn btn-sm btn-danger mt-1 btnRemove"
                      (click)="onRemove()"
                      id="remove"
                      [disabled]="view"
                      *ngIf="files.length > 0"
                      title="Remover Imagem">
                Remover Arquivo
              </button>
            </div>
            <p class="mt-1 text-primary text-center col-md-12 col-sm-12"
               *ngIf="
                (files.length === 0 && imagem == '') ||
                (imagem == null && !view)
              ">
              Formatos do arquivo PNG, JPG, JPEG, DOC, DOCX, PDF com tamanho máximo de 2MB.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12 col-sm-12">
        <label for="descricao">Descricao:</label>
        <input class="form-control form-control-sm"
               id="descricaoJustificativa"
               height="300"
               multiple
               maxlength="1000"
               name="descricao"
               type="text"
               [ngModel]="descricao" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right d-flex" style="justify-content: flex-end;">
        <div class="wrapper2">
          <button class="btn btn-sm btn-primary btn-secondary ml-2"
                  type="button"
                  routerLink="{{this.idSolicitacaoPlanoFinanceiro ? '/solicitacao-plano-financeiro':'/inscricao-config/' + this.idInscricao}}">
            Voltar
          </button>
        </div>
        <div class="wrapper2">
          <button class="btn btn-sm btn-primary mr-0 ml-2"
                  (click)="enviar()"
                  id="salvar"
                  type="button">
            Enviar
          </button>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="justificativaEnviada != null && justificativaEnviada.length != []">
      <div>
        <p><b>Data de envio:</b> {{justificativaEnviada[0].dataJustificativa | date: 'dd/MM/yyyy'}}</p>
        <p><b>Descricao:</b> {{justificativaEnviada[0].descricao}}</p>
        <p><b>Status:</b> {{justificativaEnviada[0].dataAprovacao != null && justificativaEnviada[0].dataAprovacao != '0001-01-01T00:00:00' ? justificativaEnviada[0].aprovado ? 'Aprovado' : 'Recusado' : 'Aguardando análise'}}</p>
        <!--<p *ngIf="justificativaEnviada[0].dataAprovacao != null && justificativaEnviada[0].dataAprovacao != '0001-01-01T00:00:00'"><b>Observação da análise:</b> {{justificativaEnviada[0].observacaoAprovacao}}</p>-->
        <p *ngIf="justificativaEnviada[0].dataAprovacao != null && justificativaEnviada[0].dataAprovacao != '0001-01-01T00:00:00'"><b>Data da aprovação:</b> {{justificativaEnviada[0].dataAprovacao | date: 'dd/MM/yyyy'}}</p>
        <p *ngIf="this.exibeObservacao"><b>Observação:</b> Em breve a sua solicitação será prorrogada</p>
        <!--<div>
          <button class="btn btn-sm btn-primary mr-0 ml-2"
                  *ngIf="justificativaEnviada[0].dataAprovacao != null && justificativaEnviada[0].dataAprovacao != '0001-01-01T00:00:00'"
                  (click)="abrirTransferencia()"
                  id="salvar"
                  type="button">
            Transferir Inscrição
          </button>
          <button class="btn btn-sm btn-primary mr-0 ml-2"
                  *ngIf="justificativaEnviada[0].dataAprovacao != null && justificativaEnviada[0].dataAprovacao != '0001-01-01T00:00:00'"
                  (click)="abrirCancelamento()"
                  id="salvar"
                  type="button">
            Cancelar Inscrição
          </button>
        </div>-->
      </div>
    </div>
</fieldset>
