import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { PerfilProfissionalService } from 'src/app/services/perfil-profissional/perfil-profissional.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-perfil-profissional',
  templateUrl: './perfil-profissional.component.html',
  styleUrls: ['./perfil-profissional.component.scss']
})
export class PerfilProfissionalComponent implements OnInit {
  id: any;
  perfilProfissionalForm: FormGroup;
  instrucoes: any;
  idiomaPessoa: any;
  idiomas: any = [];
  niveis: any;
  isSubmit: boolean = false;
  isDisable: boolean = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private perfilProfissionalService: PerfilProfissionalService
  ) {
    this.id =  JSON.parse(localStorage.getItem('userId'));
   }

  ngOnInit(): void {
    this.perfilProfissionalForm = this.formBuilder.group({
      exibirPerfil: [],
      email:[],
      atendimento: [],
      emailRadio: [],
      cel: [],
      celRadio: [],
      tel: [],
      telRadio: [],
      linkedin: [],
      linkedinRadio: [],
      apresentacao: [''],
      empresaAtual: [],
      empresaAtualRadio: [],
      especialidades: [],
      especialidadesRadio: [],
      certificacoes: [],
      certificacoesRadio: [],
      idioma: [null, Validators.required],
      nivel: [null, Validators.required],
      responsabilidade: ['', Validators.required]

    })

    this.init();
  }

  get exibirPerfil() {
    return this.perfilProfissionalForm.get('exibirPerfil');
  }

  get atendimento() {
    return this.perfilProfissionalForm.get('atendimento');
  }

  get emailRadio() {
    return this.perfilProfissionalForm.get('emailRadio');
  }

  get telRadio() {
    return this.perfilProfissionalForm.get('telRadio');
  }

  get linkedinRadio() {
    return this.perfilProfissionalForm.get('linkedinRadio');
  }

  get empresaAtualRadio() {
    return this.perfilProfissionalForm.get('empresaAtualRadio');
  }

  get especialidadesRadio() {
    return this.perfilProfissionalForm.get('especialidadesRadio');
  }

  get certificacoesRadio() {
    return this.perfilProfissionalForm.get('certificacoesRadio');
  }

  get idioma() {
    return this.perfilProfissionalForm.get('idioma');
  }

  get nivel() {
    return this.perfilProfissionalForm.get('nivel');
  }


  init() {
    //obter instruções
    this.perfilProfissionalService
    .obterInstrucao()
    .subscribe( res => {
      this.instrucoes = res.resultado.descricao;
    }, error => {
      console.log(error);
    })

    //obter idiomas combo
    this.perfilProfissionalService
    .obterIdiomas(Number(this.id))
    .subscribe(res => {
      this.idiomas = res.resultado;
    }, error => {
      console.log(error);
    })

    //obter dados de cadastro
    this.perfilProfissionalService
    .obterDadosPessoais(Number(this.id))
    .subscribe( res => {
      this.perfilProfissionalForm.controls.certificacoes.setValue(res.resultado.certificados);
      this.perfilProfissionalForm.controls.especialidades.setValue(res.resultado.especialidades);
      this.perfilProfissionalForm.controls.empresaAtual.setValue(res.resultado.empresaAtual);
      this.perfilProfissionalForm.controls.email.setValue(res.resultado.email);
      this.perfilProfissionalForm.controls.cel.setValue(res.resultado.ddiCelular + ' ' + res.resultado.dddCelular + res.resultado.telefoneCelular);
      this.perfilProfissionalForm.controls.tel.setValue(res.resultado.ddiComercial + ' ' + res.resultado.telefoneComercial);
      this.perfilProfissionalForm.controls.linkedin.setValue(res.resultado.linkedin);
      
      this.perfilProfissionalForm.controls.exibirPerfil.setValue(res.resultado.permiteExibirPerfil === 1 ? true : false);
      this.perfilProfissionalForm.controls.emailRadio.setValue(res.resultado.permiteExibirEmail === 1 ? true : false);
      this.perfilProfissionalForm.controls.celRadio.setValue(res.resultado.permiteExibirCelular === 1 ? true : false);
      this.perfilProfissionalForm.controls.telRadio.setValue(res.resultado.permiteExibirTelefoneComercial === 1 ? true : false);
      this.perfilProfissionalForm.controls.linkedinRadio.setValue(res.resultado.permiteExibirLinkedin === 1 ? true : false);
      this.perfilProfissionalForm.controls.apresentacao.setValue(res.resultado.apresentacaoPessoal);
      this.perfilProfissionalForm.controls.empresaAtualRadio.setValue(res.resultado.permiteExibirEmpresaAtual === 1 ? true : false);
      this.perfilProfissionalForm.controls.especialidadesRadio.setValue(res.resultado.permiteExibirEspecialidades === 1 ? true : false);
      this.perfilProfissionalForm.controls.certificacoesRadio.setValue(res.resultado.permiteExibirCertificacoes === 1 ? true : false);
      this.perfilProfissionalForm.controls.atendimento.setValue(String(res.resultado.formaAtendimento));

    }, error => {
      console.log(error);
    })

    //obter níveis
    this.perfilProfissionalService
    .obterNiveis(Number(this.id))
    .subscribe(res => {
      this.niveis = res.resultado;
    }, error => {
      console.log(error);
    })

    //obter idiomas cadastrados
    this.perfilProfissionalService
    .obterIdiomasPorPessoa(Number(this.id))
    .subscribe( res => {
      this.idiomaPessoa = res.resultado;

    }, error => {
      console.log(error);
    })
  }


  incluirIdioma(){
    this.isSubmit = true;

    if(
      this.perfilProfissionalForm.controls.idioma.value === '' ||
      this.perfilProfissionalForm.controls.idioma.value === null ||
      this.perfilProfissionalForm.controls.nivel.value === '' ||
      this.perfilProfissionalForm.controls.nivel.value === null
      ) {
        return;
    }

    this.perfilProfissionalService
    .criarIdioma(Number(this.id),Number(this.perfilProfissionalForm.value.idioma),Number(this.perfilProfissionalForm.value.nivel))
    .subscribe( res => {
      this.init();
      this.perfilProfissionalForm.controls.idioma.setValue(null);
      this.perfilProfissionalForm.controls.nivel.setValue(null);
      this.perfilProfissionalForm.controls.idioma.setValidators([]);
      this.perfilProfissionalForm.controls.idioma.updateValueAndValidity();
      this.perfilProfissionalForm.controls.nivel.setValidators([]);
      this.perfilProfissionalForm.controls.nivel.updateValueAndValidity();
    }, error => {
      Swal.fire('Perfil profissional', 'Erro ao cadastrar o idioma', 'error');
    })
  }

  excluir(idioma){
    this.perfilProfissionalService
    .excluirIdioma(idioma)
    .subscribe( res => {
      Swal.fire(
        'Perfil profissional',
        'Idioma excluído com sucesso',
        'success'
        ).then(() => {
          this.init();
        });

    }, error => {
      Swal.fire('Perfil profissional', 'Erro ao excluir o idioma', 'error');
    })
  }

  verificaChebox() {
    this.isDisable = !this.perfilProfissionalForm.controls.responsabilidade.value;
  }

  submit(){
    if(!this.idiomaPessoa.length){
      Swal.fire('Perfil profissional', 'É necessário ter ao menos um idioma cadastrado.', 'warning');
      return;
    }

    let obj = {
      "idPessoa": Number(this.id),
      "formaAtendimento": Number(this.perfilProfissionalForm.controls.atendimento.value),
      "apresentacaoPessoal": this.perfilProfissionalForm.controls.apresentacao.value,
      "permiteExibirPerfil": this.perfilProfissionalForm.controls.exibirPerfil.value ? 1 : 0,
      "permiteExibirCelular": this.perfilProfissionalForm.controls.celRadio.value ? 1 : 0,
      "permiteExibirTelefoneComercial": this.perfilProfissionalForm.controls.telRadio.value ? 1 : 0,
      "permiteExibirEmail": this.perfilProfissionalForm.controls.emailRadio.value ? 1 : 0,
      "permiteExibirLinkedin": this.perfilProfissionalForm.controls.linkedinRadio.value ? 1 : 0,
      "permiteExibirEmpresaAtual": this.perfilProfissionalForm.controls.empresaAtualRadio.value ? 1 : 0,
      "permiteExibirEspecialidades": this.perfilProfissionalForm.controls.especialidadesRadio.value ? 1 : 0,
      "permiteExibirCertificacoes": this.perfilProfissionalForm.controls.certificacoesRadio.value ? 1 : 0
    }

    this.perfilProfissionalService
    .salvarPerfilProfissional(obj)
    .subscribe( res => {
      Swal.fire(
        'Perfil profissional',
        'Perfil profissional cadastrado com sucesso',
        'success'
      ).then(() => {
        
      });

    }, erro => {
      Swal.fire(
        'Perfil profissional',
        erro.error.excecao[0].mensagem,
        'error'
      )
    })
  }

}
