import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DatePipe } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ArchwizardModule } from 'angular-archwizard';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from "ng-recaptcha";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ModalModule } from 'ngx-bootstrap/modal';

// ANGULAR MATERIAL
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';

// COMPONENTS
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

// PAGES
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { AutoCadastroComponent } from './pages/auto-cadastro/auto-cadastro.component';
import { ModalProcessoCertificadoComponent } from './pages/modal-processoCertificacao/modalProcessoCertificado/modalProcessoCertificado.component';
import { PreparacaoComponent } from './pages/preparacao/preparacao.component';
import { InscricaoComponent } from './pages/inscricao/inscricao.component';
import { AutorizacaoAceiteComponent } from './pages/inscricao/passos/autorizacao-aceite/autorizacao-aceite.component';
import { SomenteExameComponent } from './pages/inscricao/passos/somente-exame/somente-exame.component';
import { ExpectativaComponent } from './pages/inscricao/passos/expectativa/expectativa.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { AlterarSenhaComponent } from './pages/alterar-senha/alterar-senha.component';
import { FormaPagamentoComponent } from './pages/forma-pagamento/forma-pagamento.component';
import { DebitosComponent } from './pages/debitos/debitos.component';
import { TransferenciaCancelarInscricaoComponent } from './pages/transferencia-cancelar-inscricao/transferencia-cancelar-inscricao.component';

import { LoaderService } from './services/loader/loader.service';
import { LoaderInterceptor } from './services/loader/loader.interceptor';
import { LoaderComponent } from './components/loader/loader.component';
import { AdesaoCodigoEticaComponent } from './pages/adesao-codigo-etica/adesao-codigo-etica.component';
import { ExperienciaSupervisionadaComponent } from './pages/experiencia-supervisionada/experiencia-supervisionada.component';
import { SolicitacaoAssociacaoNaoCertidicadoComponent } from './pages/solicitacao-associacao-nao-certidicado/solicitacao-associacao-nao-certidicado.component';
import { ExperienciaProfissionalComponent } from './pages/experiencia-profissional/experiencia-profissional.component';
import { EducacaoComponent } from './pages/educacao/educacao.component';
import { ExameComponent } from './pages/exame/exame.component';
import { CreditosComponent } from './pages/creditos/creditos.component';
import { LocaisComponent } from './pages/locais/locais.component';
import { LocalComponent } from './pages/locais/local/local.component';
import { AgendasComponent } from './pages/agendas/agendas.component';
import { AgendaComponent } from './pages/agendas/agenda/agenda.component';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { CreditoComponent } from './pages/creditos/credito/credito.component';
import { DeclaracaoPrivacidadeComponent } from './pages/declaracao-privacidade/declaracao-privacidade.component';
import { PerfilProfissionalComponent } from './pages/perfil-profissional/perfil-profissional.component';
import { SiteBuscaPlanejadorComponent } from './pages/site-busca-planejador/site-busca-planejador.component';
import { SemanaEnefComponent } from './pages/semana-enef/semana-enef.component';
import { TransferenciaInscricaoComponent } from './pages/transferencia-inscricao/transferencia-inscricao.component';
import { MaterialEstudosComponent } from './pages/material-estudos/material-estudos.component';
import { DocumentosComponent } from './pages/documentos/documentos.component';
import { JustificarAusenciaComponent } from './pages/justificar-ausencia/justificar-ausencia.component';
import { ProgressComponent } from './pages/progress/progress.component';
import { CancelarInscricaoComponent } from './pages/cancelar-inscricao/cancelar-inscricao.component';
import { ModalEditalComponent } from './pages/modal-processoCertificacao/modalEdital/modalEdital.component';
import { ModalManualComponent } from './pages/modal-processoCertificacao/modalManual/modalManual.component';
import { ModulosComponent } from './pages/modulos/modulos.component';
import { ExameConfigComponent } from './pages/exame-config/exame-config.component';
import { MensagensComponent } from './pages/mensagens/mensagens.component';

import { TutoriaisComponent } from './pages/tutoriais/tutoriais.component';

import { SolicitacaoLicencaComponent } from './pages/solicitacao-licenca/solicitacao-licenca.component';

import { SolicitacaoPlanoFinanceiroComponent } from './pages/solicitacao-plano-financeiro/solicitacao-plano-financeiro.component';

import { RelatorioEvidenciaComponent } from './pages/relatorio-evidencias/relatorio-evidencias.component';

import { AcordoSupervisaoComponent } from './pages/acordo-supervisao/acordo-supervisao.component';

import { ListaSolicitacaoSupervisaoComponent } from './pages/lista-solicitacao-supervisao/lista-solicitacao-supervisao.component';

import { SolicitacaoSupervisorComponent } from './pages/solicitacao-supervisor/solicitacao-supervisor.component';

import { SupervisaoAndamentoComponent } from './pages/supervisao-andamento/supervisao-andamento.component';

import { SupervisorProfissionalComponent } from './pages/supervisor-profissional/supervisor-profissional.component';

import {SelecionarSupervisorComponent} from './pages/selecionar-supervisor/selecionar-supervisor.component';
import { DefinirSupervisorComponent } from './pages/definir-supervisor/definir-supervisor.component';

import{ ParecerFinalSupervisorComponent} from './pages/parecer-final-supervisor-experiencia-profissional-supervisionada/parecer-final-supervisor-experiencia-profissional-supervisionada.component';
import { VotacaoComponent } from './pages/votacao/votacao.component';
import { CriarVotacaoComponent } from './pages/criar-votacao/criar-votacao.component';
import { EditarVotacaoComponent } from './pages/editar-votacao/editar-votacao.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuestionarioComponent } from './pages/votacao/questionario/questionario.component';


export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    AppComponent,
    TopNavComponent,
    MainNavComponent,
    FooterComponent,
    ToolbarComponent,
    BreadcrumbComponent,
    LoginComponent,
    HomeComponent,
    SidebarComponent,
    AutoCadastroComponent,
    ModalProcessoCertificadoComponent,
    ModalEditalComponent,
    ModalManualComponent,
    PreparacaoComponent,
    InscricaoComponent,
    AutorizacaoAceiteComponent,
    SomenteExameComponent,
    ExpectativaComponent,
    AlterarSenhaComponent,
    FormaPagamentoComponent,
    DebitosComponent,
    LoaderComponent,
    AdesaoCodigoEticaComponent,
    ExperienciaSupervisionadaComponent,
    SolicitacaoAssociacaoNaoCertidicadoComponent,
    ExperienciaProfissionalComponent,
    EducacaoComponent,
    TransferenciaCancelarInscricaoComponent,
    ExameComponent,
    CreditosComponent,
    CreditoComponent,
    DeclaracaoPrivacidadeComponent,
    PerfilProfissionalComponent,
    SiteBuscaPlanejadorComponent,
    LocaisComponent,
    LocalComponent,
    AgendasComponent,
    AgendaComponent,
    SemanaEnefComponent,
    TransferenciaInscricaoComponent,
    MaterialEstudosComponent,
    DocumentosComponent,
    JustificarAusenciaComponent,
    ProgressComponent,
    CancelarInscricaoComponent,
    ModulosComponent,
    ExameConfigComponent,
    TutoriaisComponent,
    MensagensComponent,
    SolicitacaoLicencaComponent,
    SolicitacaoPlanoFinanceiroComponent,
    RelatorioEvidenciaComponent,
    AcordoSupervisaoComponent,
    ListaSolicitacaoSupervisaoComponent,
    SupervisaoAndamentoComponent,
    SolicitacaoSupervisorComponent,
    SupervisorProfissionalComponent,
    SelecionarSupervisorComponent,
    DefinirSupervisorComponent,
    ParecerFinalSupervisorComponent,
    VotacaoComponent,
    QuestionarioComponent,
    CriarVotacaoComponent,
    EditarVotacaoComponent
  ],
  imports: [
    MatButtonModule,
    MatStepperModule,
    MatCardModule,
    MatPaginatorModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCheckboxModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxSummernoteModule,
    NgxDropzoneModule,
    AppRoutingModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    ArchwizardModule,
    RecaptchaModule,
    // NgbModule,
    ModalModule.forRoot(),
    NgCircleProgressModule.forRoot({
      // set defaults here
      'radius': 100,
      'outerStrokeWidth': 16,
      'innerStrokeWidth': 8,
      'outerStrokeColor': "#78C000",
      'innerStrokeColor': "#C7E596",
      'animationDuration': 300,
      'title': [
        "working",
        "in",
        ""
      ],

    }),
    BrowserAnimationsModule,
  ],
  providers: [DatePipe,
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'pt',
    },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
