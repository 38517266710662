<div class="container-principal">
  <div class="row col-md-12">
    <fieldset class="border p-3 my-3">
      <legend class="legendFormulario">Semana ENEF - Prospecção </legend>
      <div class="row"
           *ngIf="exibeProspeccao">
        <div class="col-md-5">
          <p class="texto--sm">{{prospeccao.descricao}}</p><br>
          <p class="texto--sm">Prospecção de {{prospeccao.prospeccaoInicio | date: 'dd/MM/yyyy'}} a
            {{prospeccao.prospeccaoFim | date: 'dd/MM/yyyy'}} </p><br>
          <p class="texto--sm">Inscrição de {{prospeccao.inscricaoInicio | date: 'dd/MM/yyyy'}} a
            {{prospeccao.inscricaoFim | date: 'dd/MM/yyyy'}} </p><br>
          <p class="texto--sm">Realização de {{prospeccao.realizacaoInicio | date: 'dd/MM/yyyy'}} a
            {{prospeccao.realizacaoFim | date: 'dd/MM/yyyy'}} </p><br>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right mr-5 mb-3">
          <button class="btn btn-sm btn-primary m-0"
                  id="btnAdicionar"
                  routerLink="adicionar"
                  title="Adicionar">
            Adicionar
          </button>
        </div>
      </div>
      <table class="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Local</th>
            <th width="130px">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let local of locais; let i = index">
            <td>{{local.descricao}}</td>
            <td class="text-center"
                width="120px">
              <div class="row">
                <button class="btn btn-sm"
                        (click)="agenda(local.idEventoLocal)"
                        title="Agenda"
                        type="button">
                  <img class="logo"
                       src="../../../../../assets/img/icons/calendario.svg"
                       style=" height: 15px;
                          position: relative;" />
                </button>
                <button class="btn btn-sm"
                        (click)="visualizar(local.idEventoLocal)"
                        title="Visualizar"
                        type="button">
                  <img class="logo"
                       src="../../../../../assets/img/icons/visualizar.svg"
                       style=" height: 15px;
                          position: relative;" />
                </button>
                <button class="btn btn-sm"
                        (click)="editar(local.idEventoLocal)"
                        title="Editar"
                        type="button">
                  <img class="logo"
                       src="../../../../../assets/img/icons/editar.svg"
                       style=" height: 15px;
                  position: relative;" />
                </button>
                <button class="btn btn-sm"
                        (click)="excluir(local.idEventoLocal)"
                        title="Excluir"
                        type="button">
                  <img class="logo"
                       src="../../../../../assets/img/icons/excluir.svg"
                       style=" height: 15px;
                          position: relative;" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</div>