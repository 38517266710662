import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CreditosService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterVigencia(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-vigencias`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterVigenciaPorPessoa(codigoPessoaCertificadoVigencia: any, pessoaId: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-creditos-por-vigencias`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoaCertificadoVigencia , pessoaId},
      }
    );
  }

  obterCreditos(codigoPessoaCertificadoVigencia: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-totalizadores-creditos-por-vigencias`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoaCertificadoVigencia },
      }
    );
  }

  excluir(id: any) {
    return this.http.delete(
      `${environment.backofficeUrl}/area-logada-credito/desativar-credito?id=${id}`,
      {
        headers: this.httpHeaders,
      }
    );
  }


}
