import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inscricao',
  templateUrl: './inscricao.component.html',
  styleUrls: ['./inscricao.component.scss']
})

export class InscricaoComponent implements OnInit, AfterViewInit, OnDestroy {
  passosAceite: {
    currentStep: number,
    isDigital: boolean,
    passos: string[]
  } = {
      currentStep: 1,
      isDigital: false,
      passos: [
        "aceite-manual-da-certificacao",
        "aceite-ao-edital-do-exame",
        "aceite-processo-certificacao"
      ]
    }
  @ViewChild('childModal') childModal: ModalDirective;
  id: any;
  showModalInscricao = false
  currentIframeInscricao = "aceite-manual-da-certificacao"
  validaItens = [];
  inscricoesModulos = [];
  closeResult = '';
  expectativaSelecionadas = [];
  somenteExamesSelecionadas = null;
  edital: any;
  manual: any;
  certificado: any;
  passoUmSubmitted: boolean;
  passoDoisSubmitted: boolean;
  passoTresSubmitted: boolean;
  passoQuatroSubmitted: boolean;
  passoCincoSubmitted: boolean;
  modalResulte = [];
  currentStep = 1;
  idDebito: any;
  exibeCombos = true;
  pendencias = null;
  habilitaEducacao = false;
  habilitaExperiencia = false;
  isValidForm = false;
  wizard: any;
  hasLoaded: boolean = false;
  perguntaRespostas: any = [];
  eventListener: any = null
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serviceExpectativa: AreaLogadaInscricaoService,
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
  }

  ngOnDestroy() {
    document.removeEventListener("evento-apos-salvar-preparacao", null)
  }

  ngAfterViewInit() {
    this.eventListener = document.addEventListener("evento-apos-salvar-preparacao", (event: any) => {
      console.log("NOVO EVT")
      const data = event.detail
      if (data && data.valid) {
        this.validaPasso4()
      }
    })
  }

  ngOnInit(): void {

    this.verificarProfissionaJaCertificado();

    // setTimeout(() => {
    this.carregaPendencias();
    // }, 100);

    if (localStorage.getItem('passo-atual'))
      this.currentStep = Number(localStorage.getItem('passo-atual'));
    else
      this.currentStep = 1;

  }

  carregaPendencias() {
    this.serviceExpectativa.obterPendencia(this.id).subscribe((result) => {
      this.pendencias = result.resultado;
      this.habilitaEducacao = this.pendencias.habilitaEducacao;
      this.habilitaExperiencia = this.pendencias.habilitaExperiencia;
    });
  }

  verificarProfissionaJaCertificado() {
    this.serviceExpectativa.obterCertificado(this.id).subscribe((result) => {
      if (result.resultado.length > 0)
        this.router.navigate(['/exames-config/0']);
    });
  }
  hideModalInscricaoAceite(currentStep = 1) {
    (document.querySelector('.modal-inscricao-component') as any).style.display = 'none'
    this.passosAceite.currentStep = currentStep
    alert("Para conseguirmos seguir com a inscrição é necessária a adesão dos termos")
  }

  proximoPasso() {
    if (this.passosAceite.passos.length > this.passosAceite.currentStep) {
      this.passosAceite.currentStep += 1
      this.visualizarPassoAceite()
    } else {
      (document.querySelector('.modal-inscricao-component') as any).style.display = 'none';
      this.validaPasso(5, this.wizard)
    }
  }

  visualizarPassoAceite() {
    const numeroExames = (document.querySelector("#numeroExames") as any)
    const tipoExame = numeroExames.options[numeroExames.options.selectedIndex].innerText.split("-").map(a => a.trim().toLowerCase())[2]
    this.passosAceite.isDigital = tipoExame === "digital"
    const bodyElem = (document.querySelector(`.modal-inscricao-component`) as any)
    bodyElem.style.display = "block"

    const currentAceiteIndex = this.passosAceite.passos[this.passosAceite.currentStep - 1]

    if (currentAceiteIndex === "aceite-manual-da-certificacao") {
      (document.querySelector('#tituloModalInscricao') as any).innerText = "Aceite ao Manual da Certificação"
    }

    if (currentAceiteIndex === "aceite-ao-edital-do-exame") {
      (document.querySelector('#tituloModalInscricao') as any).innerText = "Aceite ao Edital do Exame"
    }

    if (currentAceiteIndex === "aceite-processo-certificacao") {
      (document.querySelector('#tituloModalInscricao') as any).innerText = "Aceite ao Processo de Certificação"
    }


    const html = this.serviceExpectativa.listAceiteAoEditalDoExame((this.passosAceite.isDigital ? "digital/" : "") + currentAceiteIndex)

    bodyElem.querySelector("#body-inscricao").innerHTML = html
  }

  validaPasso4() {
    this.currentStep = 5;
    localStorage.setItem('passo-atual', this.currentStep.toString());
    this.wizard.goToNextStep();
  }

  validaPasso(passo: number, wizard: any) {
    this.wizard = wizard;

    if (passo === 1) {
      this.validaPasso1();
      this.passoUmSubmitted = true;
    }
    else if (passo === 2) {
      this.passoDoisSubmitted = true;
      $("#salvarInscricaoProfissao").click();
      this.passoDoisSubmitted = true;
    }
    else if (passo === 3) {
      $("#salvarInscricaoEducacao").click();
      this.passoTresSubmitted = true;
    }
    else if (passo === 4) {
      const event = new CustomEvent("salvarPreparacaoExame")
      document.dispatchEvent(event)
    }
    else if (passo === 5) {

      if (this.passosAceite.passos.length > this.passosAceite.currentStep) {
        this.visualizarPassoAceite()
        return
      }

      var temModulosSelecionados = false;
      if (this.somenteExamesSelecionadas != null && this.somenteExamesSelecionadas.moduloSelecionados != null && this.somenteExamesSelecionadas.moduloSelecionados.length > 0) {
        this.somenteExamesSelecionadas.moduloSelecionados.forEach((item) => {
          if (item.checked) {
            temModulosSelecionados = true;
            return;
          }
        });
      }

      if (this.somenteExamesSelecionadas == null || this.somenteExamesSelecionadas == undefined || this.somenteExamesSelecionadas.length == 0) {
        Swal.fire(
          'Seleção de exame ',
          `É necessário informar os campos para a seleção do exame`,
          'warning'
        );
      }
      else if (this.somenteExamesSelecionadas.todosJaAprovados) {
        Swal.fire(
          'Seleção de exame ',
          `Todos os módulos já foram aprovados. Não é necessário efetuar nova inscrição`,
          'warning'
        );
      }
      else if (this.somenteExamesSelecionadas.todosJaInscritos) {
        Swal.fire(
          'Seleção de exame ',
          `Todos os módulos já foram inscritos. Não é necessário efetuar nova inscrição`,
          'warning'
        );
      }
      else if (this.somenteExamesSelecionadas.modulos == null || this.somenteExamesSelecionadas.modulos == undefined || this.somenteExamesSelecionadas.modulos.length == 0) {
        Swal.fire(
          'Seleção de exame ',
          `Não há módulos disponíveis para a seleção do exame`,
          'warning'
        );
      }
      else if (!temModulosSelecionados) {
        Swal.fire(
          'Seleção de exame ',
          `É necessário informar os módulos para a seleção do exame`,
          'warning'
        );
      }
      //   else if (this.edital === undefined || this.edital == false) {
      //     Swal.fire(
      //       'Termo de Aceite de Edital',
      //       `Termo de aceite de edital deve ser preenchido`,
      //       'warning'
      //     );
      //   }
      //   else if (this.manual === undefined || this.manual == false) {
      //    Swal.fire(
      //      'Termo de Aceite de Manual ',
      //      `Termo de aceite de manual deve ser preenchido`,
      //      'warning'
      //    );
      //  }
      else {

        if (this.somenteExamesSelecionadas.avanca != 'true')
          return;

        this.inscricoesModulos = [];
        this.passoTresSubmitted = true;
        localStorage.setItem('edital', JSON.stringify(this.edital));
        localStorage.setItem('manual', JSON.stringify(this.manual));
        localStorage.setItem('certificado', JSON.stringify(this.manual));
        var i = 0;
        this.somenteExamesSelecionadas.moduloSelecionados.forEach((item) => {
          if (item.checked) {
            this.inscricoesModulos.push({
              'idModulo': item.idModulo
            });
          }
        });

        this.perguntaRespostas = JSON.parse(localStorage.getItem('passo4'));
        var obj = {
          "idPessoa": this.id,
          "idExameCidade": Number(this.somenteExamesSelecionadas.numeroExameSelecionado),
          "inscricaoModulo": this.inscricoesModulos,
          "comboNaoAssociado": this.somenteExamesSelecionadas.comboNaoAssociado,
          "cancelarModulosAnteriores": this.somenteExamesSelecionadas.cancelarModulosAnteriores,
          "perguntasResposta": this.perguntaRespostas
        };

        this.serviceExpectativa.criarNovaInscricao(obj).subscribe((resp) => {
          if (!resp.identificador) {
            Swal.fire(
              'Inscrição ',
              "Não foi possível realizar uma inscrição, tente mais tarde",
              'error'
            )
            return
          }

          this.serviceExpectativa.obterInscricao(resp.identificador).subscribe((result) => {

            this.idDebito = result.resultado.idDebito;
            this.currentStep = 1;
            localStorage.setItem('passo-atual', this.currentStep.toString());
            this.childModal.show();
            this.modalResulte = result.resultado;
            if (result.resultado.comboNaoAssociado === 0) {
              this.exibeCombos = false;
            }
          }, erro => Swal.fire('Inscrição ', erro.error.excecao[0].mensagem, 'error'));
        }, erro => Swal.fire('Inscrição ', erro.error.excecao[0].mensagem, 'error'));
      }
    }
  }

  voltar(wizard, passo: number) {
    if (passo === 0) {
      this.router.navigate(['home']);
      localStorage.removeItem('passo1');
      localStorage.removeItem('modulos');
      localStorage.removeItem('itens');
      localStorage.removeItem('edital');
    }
    if (passo == 4) {
      this.passoQuatroSubmitted = false;
    }
    wizard.goToStep(passo - 1);
    this.currentStep = passo;
    localStorage.setItem('passo-atual', this.currentStep.toString());
  }

  abrirPagamento() {
    this.router.navigateByUrl(`debitos/forma-pagamento/${this.idDebito}`);
  }

  receberSomenteExame(result) {
    this.somenteExamesSelecionadas = result;
  }

  receberValidForm(result) {
    this.isValidForm = result;
    if (!this.isValidForm) {
      Swal.fire(
        'Dados Pessoais',
        `Por favor confirme seus dados`,
        'warning'
      );
    } else {
      localStorage.setItem('passo1', JSON.stringify({ mensagem: 'dados cadastrais ja atualizados' }));
      this.currentStep = 2;
      localStorage.setItem('passo-atual', this.currentStep.toString());
      this.wizard.goToNextStep();
    }
  }

  receberValidFormEducacao(result) {
    console.log(result);
    if (!result.valid) {
      Swal.fire(
        'Dados Educacionais',
        `Por favor informe seus dados`,
        'error'
      );
    } else {
      localStorage.setItem('passo3', JSON.stringify(result.educacao));
      this.currentStep = 4;
      localStorage.setItem('passo-atual', this.currentStep.toString());
      this.wizard.goToNextStep();
    }
  }

  receberValidFormPreparacao(result) {
    console.log(result);
    if (!result.valid || result.respostas === null) {
      Swal.fire(
        'Preparação para exame',
        `Por favor responda as perguntas`,
        'error'
      );
    } else {
      localStorage.setItem('passo4', JSON.stringify(result.respostas));
      this.passoQuatroSubmitted = true;
    }
  }

  receberValidFormProfissao(result) {
    console.log(result);
    if (!result.valid) {
      Swal.fire(
        'Dados Profissionais',
        `Por favor informe seus dados`,
        'error'
      );
    } else {
      localStorage.setItem('passo2', JSON.stringify(result.profissao));
      this.currentStep = 3;
      localStorage.setItem('passo-atual', this.currentStep.toString());
      this.wizard.goToNextStep();
    }
  }

  receberAceiteEdital(result) {
    if (!result.aceito) {
      this.passoQuatroSubmitted = result.aceito;
      this.manual = false;
      this.certificado = false;
    }
    this.edital = result.aceito;

    if (result.aceito == true) {
      this.currentStep = 5;
      localStorage.setItem('passo-atual', this.currentStep.toString());
      this.wizard.goToNextStep();
    }
  }

  receberAceiteManual(result) {
    if (!result.aceito) {
      this.passoQuatroSubmitted = result.aceito;
      this.edital = false;
      this.certificado = false;
    }
    this.manual = result.aceito;
  }

  receberAceiteCertificado(result) {
    if (!result.aceito) {
      this.passoQuatroSubmitted = result.aceito;
      this.edital = false;
      this.manual = false;
    }

    this.certificado = result.aceito;
  }

  hideChildModal(): void {
    this.childModal.hide();
    localStorage.removeItem('passo1');
    localStorage.removeItem('modulos');
    localStorage.removeItem('itens');
    localStorage.removeItem('edital');
    localStorage.removeItem('manual');
    localStorage.removeItem('certificado');
    this.router.navigate(['home']);
  }

  async validaPasso1() {
    $("#salvarAutoCadastro").click();
  }
}

