
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JustificarAusenciaService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  criar(justificativa: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/justificativa/criar`,
      justificativa,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarPlanoFinanceiro(justificativa: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/justificativa/criar/plano-financeiro`,
      justificativa,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterTodos(idExame?: any, idPessoa?: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/justificativa/obter-todos`,
      {
        headers: this.httpHeaders,
        params: { idExame, idPessoa },
      }
    );
  }

  obterTodosPlanoFinanceiro(idSolicitacaoPlanoFinanceiro?: any, idPessoa?: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/justificativa/obter-todos/plano-financeiro`,
      {
        headers: this.httpHeaders,
        params: { idSolicitacaoPlanoFinanceiro, idPessoa },
      }
    );
  }

}
