<!-- <div class="col-12 text-right mr-5">
  <button class="btn btn-sm btn-primary m-0"
          id="btnAdicionar"
          routerLink="adicionar"
          title="Adicionar">
    Adicionar
  </button>
</div> -->
<form name="creditoForm"
      id="creditoForm"
      class="mt-3"
      [formGroup]="creditoForm"
      (ngSubmit)="submit(creditoForm)">

  <div class="container-principal">
    <div class="row col-md-12">
      <fieldset class="border p-3 my-3">
        <legend class="legendFormulario">Créditos </legend>
        <div class="row">
          <div class="form-group col-md-4 col-sm-4">
            <label class="texto--sm"
                   for="periodo">Período</label>
            <select class="form-control form-control-sm custom-select custom-select-sm"
                    formControlName="periodo"
                    [class.is-invalid]="
              periodo.invalid && (periodo.touched || isSubmitted)"
                    id="periodo"
                    name="periodo">
              <option value=""
                      disabled>Selecione</option>
              <option *ngFor="let periodo of periodos"
                      [value]="periodo.idPessoaCertificadoVigencia">
                {{periodo.inicio | date: 'dd/MM/yyyy'}} a {{periodo.fim | date: 'dd/MM/yyyy'}}
              </option>
            </select>
            <div *ngIf="periodo.invalid && (periodo.touched || isSubmitted)">
              <span class="erro"
                    *ngIf="periodo.errors.required">Campo obrigatório</span>
            </div>
          </div>
          <div class="col-md-1 d-flex justify-content-end align-items-center mt-4">
            <button class="btn btn-sm btn-primary mt-3 "
                    id="salvar"
                    type="submit">
              Pesquisar
            </button>
          </div>

          <div class="col-md-5 ml-5"
               *ngIf="habilitaCreditos">
            <p class="texto--sm"
               *ngFor="let credito of creditos; let i = index">{{credito}}</p>
          </div>

        </div>
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Categoria</th>
              <th>Descrição</th>
              <th>Instituição</th>
              <th>Data</th>
              <th>Situação</th>
              <th>Créditos</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let vigencia of vigencias; let i = index">
              <td>{{vigencia.descricaoTipoAtividade}}</td>
              <td>{{vigencia.descricaoCategoria}}</td>
              <td>{{vigencia.tituloAtividade}}</td>
              <td>{{vigencia.nomeEmpresa}}</td>
              <td>{{vigencia.data | date: 'dd/MM/yyyy'}}</td>
              <td>{{vigencia.descricaoSituacaoCredito}}</td>
              <td>{{vigencia.credito}}</td>
              <td class="text-center"
                  width="120px">
                <div class="row">
                  <button class="btn btn-sm"
                          (click)="visualizar(vigencia.idPessoaCertificadoCredito)"
                          title="Visualizar"
                          type="button">
                    <img class="logo"
                         src="../../../../../assets/img/icons/visualizar.svg"
                         style=" height: 15px;
                          position: relative;" />
                  </button>
                  <!-- <button class="btn btn-sm"
                          disabled
                          *ngIf="!vigencia.habilitaExcluir"
                          title="Excluir"
                          type="button">
                    <img class="logo"
                         src="../../../../../assets/img/icons/excluir.svg"
                         style=" height: 15px;
                          position: relative;" />
                  </button>
                  <button class="btn btn-sm"
                          (click)="excluir(vigencia.idPessoaCertificadoCredito)"
                          *ngIf="vigencia.habilitaExcluir"
                          title="Excluir"
                          type="button">
                    <img class="logo"
                         src="../../../../../assets/img/icons/excluir.svg"
                         style=" height: 15px;
                          position: relative;" />
                  </button> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </fieldset>

    </div>


  </div>
</form>