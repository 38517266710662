import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { BuscaPlanejadorService } from 'src/app/services/busca-planejador/busca-planejador.service';
import { dataTabeOptions } from 'src/app/utils/datatable-options';
import * as $ from 'jquery';
import 'datatables.net';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-site-busca-planejador',
  templateUrl: './site-busca-planejador.component.html',
  styleUrls: ['./site-busca-planejador.component.scss']
})
export class SiteBuscaPlanejadorComponent implements OnInit {
  validaCaptcha = false;
  chaveCaptcha = '6Lc8sksaAAAAAKoW-GeO_-mPOMTuu3JLUfCu_B-L';
  porNome: any = false;
  porFiltros: any = false;
  pesquisaModel: any;
  nome: any;
  especialidades: any;
  arrEspecialidades: any;
  atendimento: any = '2';
  uf: any = null;
  municipio: any = null;
  arrUf: any;
  arrMunicipios: any;
  tabela: any;
  arrPlanejadores: any = [];
  perfilProfissional: any = false;
  dadosProfissional: any;
  idiomaPessoa: any;

  constructor(
    private buscaPlanejador: BuscaPlanejadorService
  ) { }

  ngOnInit(): void {
    this.initDatatable();
  }

  private initDatatable(): void {
    const tabela: any = $('#planejadores');
    this.tabela = tabela.DataTable(dataTabeOptions);
  }

  private reInitDatatable(): void {
    if (this.tabela) {
      this.tabela.destroy();
      this.tabela = null;
    }
    setTimeout(() => this.initDatatable(), 0);
  }

  pesquisarFiltro() {
    if (this.pesquisaModel === '1') {
      this.porNome = true;
      this.porFiltros = false;
      this.validaCaptcha = false;
    } else {
      this.porNome = false;
      this.porFiltros = true;
      this.validaCaptcha = false;
      this.obterEspecialidades();
      this.obterUf();
    }
  }

  obterEspecialidades() {
    this.buscaPlanejador
      .obterEspecialidades()
      .subscribe(res => {
        this.arrEspecialidades = res.resultado;
        this.arrEspecialidades.forEach(element => {
          element.checked = false;
        });
      })
  }


  obterUf() {
    this.buscaPlanejador
      .obterUf()
      .subscribe(res => {
        this.arrUf = res.resultado;
      })
  }


  changeUfObterCidade() {
    this.buscaPlanejador
      .obterCidades(this.uf)
      .subscribe(res => {
        this.arrMunicipios = res.resultado;
      })
  }

  pesquisarPorNome() {
    if (!this.nome || this.nome === null || this.nome === '') {
      Swal.fire('Busca Planejador', 'Digite o nome desejado para pesquisa.', 'info');
      return;
    }

    if (this.nome.length < 4) {
      Swal.fire('Busca Planejador', 'O nome inserido deve ter ao menos 4 caracteres.', 'info');
      return;
    }

    this.buscaPlanejador
      .obterPorNome(this.nome)
      .subscribe(res => {
        this.arrPlanejadores = res.resultado;
        this.reInitDatatable();
      }, erro => {
        Swal.fire('Busca planejador', 'Ocorreu um erro na busca, tente novamente mais tarde.', 'error');
      })
  }

  verificaChebox() {
    this.especialidades = this.arrEspecialidades.filter((local) => {
      if (local.checked) {
        return local;
      }
    });

  }

  pesquisaPorFiltros() {

    let arrEspec = [];
    if (this.especialidades) {
      this.especialidades.forEach(el => {
        arrEspec.push(el.idAreaAtuacaoConhecimento);
      })
    }

    if (!arrEspec.length) {
      Swal.fire('Busca planejador', 'Selecione ao menos uma especialidade.', 'info');
      return;
    }

    this.buscaPlanejador
      .obterPorFiltros(arrEspec, Number(this.atendimento), this.uf !== null ? this.uf : 0, this.municipio !== null ? this.municipio : 0)
      .subscribe(res => {
        this.arrPlanejadores = null;
        this.arrPlanejadores = res.resultado;
        this.reInitDatatable();
      })
  }

  resolved(captchaResponse: string) {
    if (captchaResponse) {
      this.validaCaptcha = true;
    } else {
      this.validaCaptcha = false;
    }
  }

  verPerfil(id) {
    this.buscaPlanejador
      .obterProfissional(id)
      .subscribe(res => {
        this.perfilProfissional = true;
        this.dadosProfissional = res.resultado;
        if (res.resultado.formaAtendimento === 0) {
          this.dadosProfissional.formaAtendimento = 'Presencial';
        } else if (res.resultado.formaAtendimento === 1) {
          this.dadosProfissional.formaAtendimento = 'À distância';
        } else {
          this.dadosProfissional.formaAtendimento = 'Híbrido';
        }


        this.buscaPlanejador
          .obterIdiomasPorPessoa(id)
          .subscribe(res => {
            this.idiomaPessoa = res.resultado;

          }, error => {
            console.log(error);
          })
      }, error => {
        console.log(error);
      })
  }

}
