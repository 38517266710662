<form name="tranferenciaForm"
      id="tranferenciaForm"
      class="mt-3"
      [formGroup]="tranferenciaForm"
      (ngSubmit)="submit(tranferenciaForm)">
  <fieldset class="border p-3 my-3">
    <legend class="legendFormulario">Cancelar Inscrição: <b>{{inscricaoSalva.descricaoInscricao}}</b></legend>
    <div class="row">
      <div class="col-md-12">
        <label>Inscrição</label>
        <select class="form-control"
                id="inscricao"
                name="inscricao"
                [class.is-invalid]="inscricao.invalid && (inscricao.touched || isSubmitted)"
                formControlName="inscricao"
                (change)="verificarInscricao()">
          <option value=""
                  disabled>Selecione</option>
          <option *ngFor="let inscricao of inscricoes;"
                  [ngValue]="inscricao">{{ inscricao.descricaoInscricao }}
          </option>
        </select>
        <div *ngIf="inscricao.invalid && (inscricao.touched || isSubmitted)">
          <span class="erro"
                *ngIf="inscricao.errors.required">Campo obrigatório</span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-right mr-4">
        <div>
          <button class="btn btn-sm btn-primary btn-secondary ml-2"
                  type="button"
                  routerLink="/inscricao-config/{{idInscricao}}">
            Cancelar
          </button>
          <!-- <button *ngIf="botaoConfirmar"
                  class="btn btn-sm btn-primary ml-2"
                  type="submit">
            Confirmar
          </button> -->

        </div>
      </div>
    </div>
  </fieldset>
</form>




<!-- ESTRONO  -->
<form name="estornoForm"
      id="estornoForm"
      class="mt-3"
      [formGroup]="estornoForm"
      (ngSubmit)="submitEstorno(estornoForm)">
  <div aria-labelledby="dialog-child-name"
       bsModal
       #estornoModal="bs-modal"
       [config]="{ backdrop: 'static', keyboard: false}"
       class="modal fade"
       tabindex="-1"
       role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-padding">
          <h2 id="dialog-child-name"
              class="modal-title pull-left">Estorno </h2>
          <button aria-label="Close"
                  class="close pull-right"
                  (click)="hideEstorno()"
                  type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <h4 *ngIf="tipoModal == 1">Informe os dados do cartão de sua titularidade, (não aceitará CPF de
                terceiros).</h4>
              <h4 *ngIf="tipoModal == 0">Informe os dados bancários de sua titularidade, (não aceitará conta de
                terceiros).</h4>
            </div>
          </div>
          <!-- TED -->
          <div class="mt-4 mb-2"
               *ngIf="tipoModal == 0">
            <div class="row">
              <div class="col-md-12">
                <label>Banco</label>
                <input type="text"
                       class="form-control form-control-sm grupo-input"
                       id="banco"
                       name="banco"
                       formControlName="banco" />
                <div *ngIf="banco.invalid && (banco.touched || isSubmittedEstorno)">
                  <span class="erro"
                        *ngIf="banco.errors.required">Campo obrigatório</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Agencia</label>
                <input type="text"
                       class="form-control form-control-sm grupo-input"
                       [class.is-invalid]="agencia.invalid && (agencia.touched || isSubmitted)"
                       id="agencia"
                       name="agencia"
                       maxlength="5"
                       formControlName="agencia" />
                <div *ngIf="agencia.invalid && (agencia.touched || isSubmittedEstorno) ">
                  <span class="erro"
                        *ngIf="agencia.errors.required">Campo obrigatório</span>
                </div>
              </div>
              <div class="col-md-4">
                <label>Conta</label>
                <input type="text"
                       class="form-control form-control-sm grupo-input"
                       [class.is-invalid]="conta.invalid && (conta.touched || isSubmitted)"
                       id="conta"
                       name="conta"
                       formControlName="conta"
                       maxlength="7" />
                <div *ngIf="conta.invalid && (conta.touched || isSubmittedEstorno)">
                  <span class="erro"
                        *ngIf="conta.errors.required">Campo obrigatório</span>
                </div>
              </div>
              <div class="col-md-2">
                <label>Digito</label>
                <input type="text"
                       class="form-control form-control-sm grupo-input"
                       [class.is-invalid]="digito.invalid && (digito.touched || isSubmitted)"
                       id="digito"
                       name="digito"
                       formControlName="digito"
                       maxlength="2" />
                <div *ngIf="digito.invalid && (digito.touched || isSubmittedEstorno)">
                  <span class="erro"
                        *ngIf="digito.errors.required">Campo obrigatório</span>
                </div>
              </div>
            </div>
          </div>
          <!-- PIX -->
          <div class="mt-4 mb-2"
               *ngIf="tipoModal == 1">
            <div class="row">
              <div class="col-md-6">
                <label>CPF do titular do cartão</label>
                <input type="text"
                       class="form-control form-control-sm grupo-input"
                       [class.is-invalid]="cpf.invalid && (cpf.touched || isSubmittedEstorno)"
                       id="cpf"
                       name="cpf"
                       mask="000.000.000-00"
                       formControlName="cpf" />
                <div *ngIf=" cpf.invalid && (cpf.touched || isSubmittedEstorno)">
                  <span class="erro"
                        *ngIf="cpf.errors.required">Campo obrigatório</span>
                  <span class="erro"
                        *ngIf="cpf.errors.validCpf">CPF inválido</span>
                </div>
              </div>
            </div>
          </div>

          <!-- TERMO DE ADESÃO  -->
          <div class="row">
            <div class="form-group col-md-12 col-sm-12">
              <label>Condições de Cancelamento</label>
              <div formControlName="codigoEtica"
                   id="summer"
                   name="summer"
                   [ngxSummernote]="config"
                   [ngxSummernoteDisabled]="true">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 check-centralizado">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input"
                       (change)="mudaTermo()"
                       [(ngModel)]="modelCheck"
                       type="checkbox"
                       id="aceiteTermo"
                       name="aceiteTermo"
                       formControlName="aceiteTermo">&nbsp;<label class="">Li e aceito as condições de
                  cancelamento.</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-primary btn-secondary"
                  (click)="hideEstorno()">Cancelar</button>
          <button type="submit"
                  id="confimarEstorno"
                  name="confimarEstorno"
                  *ngIf="modelCheck === true"
                  class="btn btn-sm btn-primary ">Confirmar</button>
          <button type="button"
                  id="confimarEstorno"
                  name="confimarEstorno"
                  disabled
                  *ngIf="modelCheck === false"
                  class="btn btn-sm btn-primary ">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- JUSTIFICATIVA  -->
<!-- <form name="justificativaForm"
      id="justificativaForm"
      class="mt-3"
      [formGroup]="justificativaForm"
      (ngSubmit)="submitJustificativa(justificativaForm)">
  <div aria-labelledby="dialog-child-name"
       bsModal
       #justificativaModal="bs-modal"
       [config]="{ backdrop: 'static', keyboard: false}"
       class="modal fade"
       tabindex="-1"
       role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-padding">
          <h2 id="dialog-child-name"
              class="modal-title pull-left">Justificativa</h2>
          <button aria-label="Close"
                  class="close pull-right"
                  (click)="hideJustificativa()"
                  type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group col-md-12 col-sm-12">
              <label> Descrição da justificativa</label>
              <textarea class="form-control"
                        id="justificativaDescricao"
                        rows="4"
                        name="justificativaDescricao"
                        formControlName="justificativaDescricao"></textarea>
              <div
                   *ngIf="justificativaDescricao.invalid && (justificativaDescricao.touched || isSubmittedJustificativa)">
                <span class="erro"
                      *ngIf="justificativaDescricao.errors.required">Campo obrigatório</span>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="dropMobile text-right align-items-center col-md-4 col-sm-4"
                   [ngClass]="{ 'mt-4': view }">
                <div class="text-right align-items-center dropCustom col-sm-12 mt-3">
                  <div class="w-100">
                    <div [accept]="'image/jpg, image/png, application/pdf, application/vnd.ms-word,.zip, .rar'"
                         (change)="onSelect($event)"
                         class="custom-dropzone col-sm-12"
                         #drop
                         [disabled]="view"
                         [maxFileSize]="2097152"
                         [multiple]="false"
                         ngx-dropzone>
                      <ngx-dropzone-label>
                        <div>
                          <img class="img-adicionada"
                               [src]="imagem" />
                          <p *ngIf="(!view && imagem == null) || imagem == ''">
                            Arraste o seu arquivo ou clique aqui para selecionar
                          </p>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-image-preview class="previa-img"
                                                  *ngFor="let f of files"
                                                  [file]="f"
                                                  ngProjectAs="ngx-dropzone-preview"
                                                  [removable]="true">
                      </ngx-dropzone-image-preview>
                    </div>
                    <div class="text-center">
                      <button class="btn btn-sm btn-danger mt-1 btnRemove"
                              (click)="onRemove()"
                              id="remove"
                              [disabled]="view"
                              *ngIf="files.length > 0 || imagem "
                              title="Remover Imagem">
                        Remover Arquivo
                      </button>
                    </div>
                    <p class="mt-1 text-primary text-center col-md-12 col-sm-12"
                       *ngIf="
                              (files.length === 0 && imagem == '') ||
                              (imagem == null && !view)
                            ">
                      Formatos do arquivo PNG, JPG, DOC e ZIP com tamanho máximo de 2MB.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-primary btn-secondary"
                  (click)="hideJustificativa()">Cancelar</button>
          <button type="submit"
                  class="btn btn-sm btn-primary">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</form> -->