<form class="mt-3"
      [formGroup]="acordoSupervisaoForm"
      id="acordoSupervisaoForm"
      name="acordoSupervisaoForm"
      (ngSubmit)="submit(acordoSupervisaoForm)">
  <fieldset class="border p-3 my-3">
    <legend class="legendFormulario">Acordo de Supervisão</legend>

    <div class="row">
      <div class="form-group col-md-12 col-sm-12">
        <div formControlName="textoAcordo"
             id="summer"
             name="summer"
             [ngxSummernote]="config"
             [ngxSummernoteDisabled]="true">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label *ngIf="monstrarLabel['complemento'] !== null">
          {{monstrarLabel['complemento']}}
        </label>
      </div>

      <div class="col-md-6 text-right">
        <button class="btn btn-sm btn-primary"
                [disabled]="!aderir"
                id="aderir"
                title="Aderir"
                type="submit">
          Aceitar
        </button>&nbsp;
        <button class="btn btn-sm btn-primary"
                [disabled]="!aderir"
                id="aderir"
                title="Aderir"
                type="button"
                (click)="rejeitar()">
          Rejeitar
        </button>        
        <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" onclick="history.go(-1);">
          Voltar
        </button>        
      </div>
    </div>
  </fieldset>
</form>