<div
  class="mt-3"
  id="relatorioForm"
  name="relatorioForm"
  (ngSubmit)="submit(relatorioForm)"
>
  <fieldset class="border p-3 my-3">
    <legend class="legendFormulario" *ngIf="periodoRelatorio !== 'Final'">
      {{ periodoRelatorio }} Relatório + Evidências
    </legend>
    <legend class="legendFormulario" *ngIf="periodoRelatorio === 'Final'">
      Relatório Final + Evidências
    </legend>

    <section class="options-table">
      <div class="terms">
        Os relatórios de supervisão e as evidências, são os principais
        entregáveis que comprovarão todo o processo da Experiência Profissional
        Supervisionada. Assim, apresentamos o modelo de relatório a ser
        praticado. Nele estarão relacionados os pontos a serem observados para
        avaliar o perfil de competências do Supervisionado durante o período de
        supervisão.<br /><br />
        São 6 (seis) relatórios, cujas datas de envios à PLANEJAR serão
        apresentadas a partir da data do Acordo de Supervisão firmado entre
        Supervisor e Supervisionado, serão disponibilizados para o preenchimento
        de forma digital na plataforma PLANEJAR. A cada trimestre, o respectivo
        relatório deverá ser respondido pelo Supervisor e Supervisionado em
        conjunto, anexando as respectivas evidências.<br /><br />
        Para concluir o processo de Experiência Profissional Supervisionada, o
        Supervisor deverá formalizar o seu parecer final sobre as competências
        profissionais apresentadas e o cumprimento das etapas do processo de
        melhores práticas de Planejamento Financeiro, assim confirmando a
        habilitação do Supervisionado para a obtenção do certificado e do
        direito de uso da marca CFP®.<br /><br />
        As respostas e ponderações do Supervisor devem refletir os atendimentos
        realizados pelo Supervisionado dos seus clientes reais, no decorrer das
        reuniões de supervisão e acompanhamento de todo o processo. Veja a
        seguir a composição dos itens a serem avaliados.<br /><br />
        <b>A. COMPETÊNCIAS PROFISSIONAIS DO PLANEJADOR FINANCEIRO</b><br /><br />
        <b> A1. CONHECIMENTO E CAPACIDADE</b><br /><br />
        O Supervisor deve avaliar o desempenho com relação ao conhecimento e
        capacidades aplicados pelo Supervisionado e indicar o engajamento e a
        periodicidade do envolvimento do Supervisionado com cada componente de
        Planejamento Financeiro.<br /><br />
        Na avaliação de conhecimento e capacidade o Supervisor deverá considerar
        3 (três) aspectos: <br /><br />
        1 - Avaliação de aplicabilidade de cada componente;<br /><br />
        2 - Avaliação de engajamento e periodicidade;<br /><br />
        3 - Avaliação das evidências.<br /><br />
        <b>1 - Avaliação de aplicabilidade de cada componente</b><br /><br />Este
        indicador tem como objetivo verificar o desenvolvimento do
        Supervisionado na aplicação dos 6 (seis) componentes de planejamento
        financeiro.<br /><br />
        Para que seja qualificado na
        <b>experiência profissional supervisionada</b> o candidato deve ter
        aplicado, <b>obrigatoriamente</b>, durante a
        <b> maior parte do tempo</b> do período de supervisão, pelo menos
        <b>4 (quatro) dos componentes</b> de Planejamento Financeiro, sendo
        obrigatório o de Planejamento e Gestão Financeira. <br /><br />
        Um “desempenho excelente” em um componente de Planejamento Financeiro
        não compensará um “desempenho ruim” em outro, sendo cada componente
        avaliado individualmente.<br /><br />
        <b>2 – Avaliação de engajamento e periodicidade</b><br /><br />
        Este indicador tem como objetivo verificar o engajamento e periodicidade
        por meio da frequência em que os componentes de planejamento financeiro
        foram aplicados nas atividades do Supervisionado. <br /><br />
        A periodicidade é classificada em três níveis:<br /><br />
        - Às vezes: prática ocasional<br /><br />
        - Quase sempre: prática frequente <br /><br />
        - Sempre: prática contínua<br /><br />
        <b> 3 - Avaliação das evidências</b><br /><br />
        Este indicador tem como objetivo obter comprovações de que o
        Supervisionado aplicou os componentes de Planejamento Financeiro, por
        meio do envio das evidências.<br /><br />
        As sugestões de evidências possíveis estarão elencadas junto com cada
        componente. Avalie cuidadosamente as evidências fornecidas pelo
        Supervisionado, que devem ser de clientes reais,
        <b
          >atentando para que as informações pessoais não sejam
          identificadas.</b
        ><br /><br />
        - Enviar de <b> 2 a 3 evidências por trimestre</b> dos componentes
        relacionados ao atendimento do respectivo cliente. <br /><br />
        Exemplo: No relatório do primeiro trimestre, enviar evidências referente
        ao conteúdo de Planejamento e Gestão Financeira. No trimestre seguinte
        pode ser apresentado evidências de um Planejamento de Aposentadoria, e
        assim por diante até completar os seus componentes abordados com a
        entrega dos 6 relatórios. <br /><br />
        - As evidências fornecidas devem ser claras indicações de quais
        componentes de Planejamento Financeiro o candidato aplicou durante o
        período de experiência. <br /><br />
        <div>
          Lembrete: <br /><br />
          <b>
            O Supervisionado deverá ter o cuidado de ocultar as informações de
            identificação do cliente.</b
          >
        </div>
        <b>2. HABILIDADES PROFISSIONAIS</b><br /><br />
        O Supervisor deve avaliar o desempenho em relação as habilidades
        aplicadas pelo Supervisionado referentes as
        <b>Competências Profissionais do Planejador Financeiro</b>, durante o
        período de supervisão. <b></b>
        <b
          >A3. COMPROMETIMENTO COM AS MELHORES PRÁTICAS DE PLANEJAMENTO
          FINANCEIRO</b
        >
        <br /><br />
        O Supervisor deve avaliar o desempenho em relação a utilização das
        <b>Melhores Práticas do Processo de Planejamento Financeiro</b>.
      </div>

      <div *ngFor="let tabela of perguntasRespostas">
        <h2>{{ tabela.titulo }}</h2>

        <p>{{ tabela.subTitulo }}</p>

        <div
          *ngFor="let opcao of tabela.perguntaRespostaEspecializacaoSelecao[0]"
        >
          <!-- iteração para os items da tabela -->

          <div class="title">
            <h2>{{ opcao.ordem + " " + opcao.descricao }}</h2>
          </div>

          <section
            *ngFor="let subOption of opcao.subEspecializacoes"
            class="column-field"
          >
            <div class="sub-title">
              <h3>
                {{
                  opcao.ordem +
                    "." +
                    subOption.ordem +
                    " " +
                    subOption.descricao
                }}
              </h3>
            </div>
            <ng-container *ngIf="!subOption.comentario; else commentsField">
              <div
                class="d-flex"
                *ngIf="subOption.engajamentoPeriodicidade; else semEngajamento"
              >
                <div
                  *ngFor="let question of subOption.perguntas"
                  class="column-content"
                >
                  <span>{{ question.descricao }}</span>

                  <section class="options-field">
                    <div *ngFor="let options of question.opcoes">
                      <input
                        [name]="options.idOpcao.toString()"
                        type="radio"
                        [(ngModel)]="
                          respostasSimNaoRadioButtons[question.idPergunta]
                        "
                        [value]="
                          options.descricao.toLowerCase() == 'sim'
                            ? true
                            : options.descricao.toLowerCase() == 'não'
                            ? false
                            : options.descricao.toLowerCase() == 'talvez'
                            ? 1
                            : options.descricao.toLowerCase() == 'quase sempre'
                            ? 2
                            : options.descricao.toLowerCase() == 'às vezes'
                            ? 3
                            : options.descricao.toLowerCase() == 'sempre'
                            ? 4
                            : null
                        "
                        [disabled]="isSupervisor"
                      />
                      <label [for]="options.idOpcao.toString()"
                        >&nbsp; {{ options.descricao }}</label
                      >
                    </div>
                  </section>
                </div>
              </div>

              <ng-template #semEngajamento>
                <div
                  *ngFor="let question of subOption.perguntas"
                  class="between-content"
                >
                <span [ngClass]="{'questao-size': question.descricao.length > 106}">{{
                    opcao.ordem +
                      "." +
                      subOption.ordem +
                      "." +
                      question.ordem +
                      " " +
                      question.descricao
                  }}</span>

                  <section class="options-field">
                    <div *ngFor="let options of question.opcoes">
                      <input
                        [name]="options.idOpcao.toString()"
                        type="radio"
                        [(ngModel)]="
                          respostasSimNaoRadioButtons[question.idPergunta]
                        "
                        [value]="
                          options.descricao.toLowerCase() == 'sim'
                            ? true
                            : options.descricao.toLowerCase() == 'não'
                            ? false
                            : options.descricao.toLowerCase() == 'talvez'
                            ? 1
                            : options.descricao.toLowerCase() == 'quase sempre'
                            ? 2
                            : options.descricao.toLowerCase() == 'às vezes'
                            ? 3
                            : options.descricao.toLowerCase() == 'sempre'
                            ? 4
                            : null
                        "
                        [disabled]="isSupervisor"
                      />
                      <label [for]="options.idOpcao.toString()"
                        >&nbsp; {{ options.descricao }}</label
                      >
                    </div>
                  </section>
                </div>
              </ng-template>
            </ng-container>

            <ng-template #commentsField>
              <div class="comments-bordered">
                <div
                  *ngIf="subOption.comentarioSupervisor || isSupervisor"
                  class="comments-field"
                >
                  <label
                    [for]="
                      subOption.idSubEspecializacao + 'comentarioSupervisor'
                    "
                    >Comentário supervisor</label
                  >
                  <textarea
                    [id]="
                      subOption.idSubEspecializacao + 'comentarioSupervisor'
                    "
                    [value]="subOption.comentarioSupervisor"
                    [(ngModel)]="
                      comentariosSupervisor[subOption.idSubEspecializacao]
                    "
                    [disabled]="!isSupervisor"
                  ></textarea>
                </div>

                <div
                  *ngIf="subOption.comentarioPlanejar"
                  class="comments-field"
                >
                  <label
                    [for]="subOption.idSubEspecializacao + 'comentarioPlanejar'"
                    >Comentário planejar</label
                  >
                  <textarea
                    [id]="subOption.idSubEspecializacao + 'comentarioPlanejar'"
                    [value]="subOption.comentarioPlanejar"
                    readonly
                  ></textarea>
                </div>
              </div>
            </ng-template>
          </section>
        </div>
      </div>
    </section>

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label *ngIf="monstrarLabel['complemento'] !== null">
          {{ monstrarLabel["complemento"] }}
        </label>
      </div>

      <div class="col-md-6 text-right">
        <button
          class="btn btn-sm btn-primary"
          id="aderir"
          title="Aceitar"
          (click)="salvarInformacoes()"
          *ngIf="!isSupervisor"
        >
        Gravar
        </button>
        <button
          class="btn btn-sm btn-primary btn-secondary ml-2"
          type="button"
          onclick="history.go(-1);"
        >
          Voltar
        </button>
      </div>
    </div>
  </fieldset>
</div>
<fieldset class="border p-3 my-3" *ngIf="monstrarLabel['complemento'] !== null">
  <div class="row">
    <div class="form-group col-md-12 col-sm-12">
      <label>Evidências</label>
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div
      class="dropMobile text-right align-items-center col-md-4 col-sm-4"
      [ngClass]="{ 'mt-4': view }"
    >
      <div class="text-right align-items-center dropCustom col-sm-12 mt-3">
        <div class="w-100">
          <div
            [accept]="
              'image/jpg, image/png, application/pdf, application/vnd.ms-word, .doc, .docx, .jpg, .jpeg'
            "
            (change)="onSelect($event)"
            class="custom-dropzone col-sm-12"
            #drop
            [disabled]="view"
            [maxFileSize]="20971520"
            [multiple]="true"
            ngx-dropzone
          >
            <ngx-dropzone-label>
              <div>
                <img class="img-adicionada" [src]="imagem" />
                <p *ngIf="(!view && imagem == null) || imagem == ''">
                  Arraste o(s) seu(s) arquivo(s) ou clique aqui para selecionar
                </p>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview
              class="previa-img"
              *ngFor="let f of files"
              [file]="f"
              ngProjectAs="ngx-dropzone-preview"
              [removable]="true"
            >
              <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </div>
          <div class="text-center">
            <button
              class="btn btn-sm btn-danger mt-1 btnRemove"
              (click)="onRemove()"
              id="remove"
              [disabled]="view"
              *ngIf="files.length > 0"
              title="Remover Imagem"
            >
              Remover Arquivo
            </button>
          </div>
          <p
            class="mt-1 text-primary text-center col-md-12 col-sm-12"
            *ngIf="
              (files.length === 0 && imagem == '') || (imagem == null && !view)
            "
          >
            Formatos do arquivo PNG, JPG, JPEG, DOC, DOCX ou PDF.
          </p>
        </div>
      </div>
    </div>
  </div>
  <button
    class="btn btn-sm btn-primary btn-secondary ml-2"
    type="button"
    (click)="importarAnexos()"
    *ngIf="this.files.length > 0"
  >
    Incluir arquivo(s)
  </button>
  <div
    class="row col-md-12"
    *ngIf="anexosGravados !== null && anexosGravados.length > 0"
  >
    <fieldset class="border p-3 my-3">
      <legend class="legendFormulario">Evidências:</legend>
      <table class="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Data Cadastro</th>
            <th>Arquivo</th>
            <th>Abrir</th>
            <th>Excluir</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let anexo of anexosGravados; let i = index">
            <td>{{ anexo.dataCadastro | date : "dd/MM/yyyy" }}</td>
            <td>{{ anexo.nomeArquivo }}</td>
            <td>
              <a
                [routerLink]=""
                (click)="
                  obterArquivo(
                    anexo.idExperienciaSupervisionadaAnexo,
                    anexo.nomeArquivo
                  )
                "
                >Abrir</a
              >
            </td>
            <td>
              <a
                [routerLink]=""
                (click)="
                  excluirArquivo(
                    anexo.idExperienciaSupervisionadaAnexo,
                    anexo.nomeArquivo
                  )
                "
                >Excluir</a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-12 text-right">
        <button
          class="btn btn-sm btn-primary btn-secondary ml-2"
          type="button"
          (click)="enviar()"
          *ngIf="isSupervisor === true"
        >
          Aprovar
        </button>
        <button
          class="btn btn-sm btn-primary btn-secondary ml-2"
          type="button"
          (click)="rejeitar()"
          *ngIf="isSupervisor === true"
        >
          Rejeitar
        </button>
        <button
          class="btn btn-sm btn-primary btn-secondary ml-2"
          type="button"
          (click)="enviar()"
          *ngIf="!isSupervisor && respondeuTodasPerguntas"
        >
          Enviar
        </button>
        <button
          class="btn btn-sm btn-primary btn-secondary ml-2"
          type="button"
          onclick="history.go(-1);"
        >
          Voltar
        </button>
      </div>
    </fieldset>
  </div>
</fieldset>
