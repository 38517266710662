<div class="container-principal">
  <div class="row col-md-12">
    <fieldset class="border p-3 my-3">
      <legend class="legendFormulario">Débitos </legend>
      <table style="height: 600px;" class="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>ID</th>
            <th>Inclusão</th>
            <th>Título</th>
            <th>Valor</th>
            <th>Situação</th>
            <th>Forma de Pagamento</th>
            <th>Vencimento</th>
            <th>Pagamento</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let debitos of debito; let i = index">
            <td>{{debitos.idDebito}}</td>
            <td>{{debitos.dataCriacao | date: 'dd/MM/yyyy'}}</td>
            <td>{{debitos.descricaoOrigem}}-{{debitos.descricaoServico}}</td>
            <td>{{debitos.valorCobranca}}</td>
            <td>{{debitos.descricaoSituacao}}</td>
            <td>{{debitos.descricaoFormaPagamento}}</td>
            <td>{{debitos.dataVencimento | date: 'dd/MM/yyyy'}}</td>
            <td>{{debitos.dataPagamento | date: 'dd/MM/yyyy'}}</td>
            <td class="text-center"
                width="120px">
              <div class="row">

                <button
                    *ngIf="verificaRegraSeDeveExibitBotaoDePagar(debitos)"
                        class="btn btn-sm "
                        routerLink="forma-pagamento/{{debitos.idDebito}}"
                        title="Pagar"
                        type="button">
                  <img class="logo"
                       style=" height: 21px;
                            position: relative;"
                       src="../../../../../assets/img/icons/pagamento.svg" />
                </button>

                <button *ngIf="(verificaSeDataAtualMaiorQueSeteDias(debitos) && (debitos.idDebitoSituacao !== 7 && debitos.idDebitoSituacao !== 4 && debitos.idDebitoSituacao !== 6 && debitos.idDebitoSituacao !== 5)) && certificado == null"
                        class="btn btn-sm "
                        (click)="fazerEstorno(debitos.idDebito)"
                        title="Cancelar"
                        type="button">
                  <img class="logo"
                       style=" height: 21px; position: relative;"
                       src="../../../../../assets/img/icons/excluir.svg" />
                </button>

                <button *ngIf="!debitos.habilitarRecibo && debitos.habilitarReimpressaoBoleto"
                        class="btn btn-sm"
                        (click)="obterBoleto(debitos.idDebito)"
                        title="Imprimir Boleto"
                        type="button">
                  <img class="logo"
                       src="../../../../../assets/img/icons/boleto.svg"
                       style=" height: 21px;
                          position: relative;" />
                </button>
                <button *ngIf="debitos.habilitarRecibo && debitos.idDebitoSituacao !== 7"
                        class="btn btn-sm"
                        (click)="obterRecibo(debitos.idDebito)"
                        title="Emitir Recibo"
                        type="button">
                  <img class="logo"
                       src="../../../../../assets/img/icons/boleto.svg"
                       style="height: 21px;position: relative;" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </fieldset>

  </div>
</div>

<div class="modal-data-boleto" *ngIf="showModalBoletoDataVencimento">
    <label for="">Selecione uma data de vencimento:</label>
    <input (change)="setNovaDataVencimento($event)" type="date" class="form-control">
    <br>
    <button class="btn btn-primary" (click)="gerarBoleto(lastDebitClicked)">Gerar boleto</button>
    <button class="btn btn-danger pull-right" (click)="showModalBoletoDataVencimento = false">Fechar</button>
</div>
