
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SolicitacaoAssociacaoNaoCertificadoService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  criarInscricaoNaoCertificada(inscricao: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/criar-inscricao-nao-certificada`,
      inscricao,
      {
        headers: this.httpHeaders,
      }
    );
  }

}
