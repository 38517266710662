import { finalize } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AdesaoCodigoEticaService } from 'src/app/services/adesao-codigo-etica/adesao-codigo-etica.service';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import Swal from 'sweetalert2';
import {
  GravarComentariosModel,
  GravarRespostasModel,
  OpcaoModel,
  PerguntasRespostasModel,
} from './models/perguntas-respostas.model';

@Component({
  selector: 'app-relatorio-evidencias',
  templateUrl: './relatorio-evidencias.component.html',
  styleUrls: ['./relatorio-evidencias.component.scss'],
})
export class RelatorioEvidenciaComponent implements OnInit {
  @ViewChild('childModal') childModal: ModalDirective;
  id: any;
  files: File[] = [];
  anexos = [];
  view = false;
  imagem = null;
  obj = [];
  observacao: any;
  periodo: any;
  solicitacao: any = null;
  acessoPlanoFinanceiro = true;
  alterar = false;
  dataAtual = moment();
  notas: any;
  periodoRelatorio: string;

  aderir = false;
  monstrarLabel = [];
  config: any;
  relatorioForm: FormGroup;
  itens = [];

  codigoExperienciaSupervisionada: number;
  isSupervisor = false;
  idPessoa: any = null;
  codigoPeriodo: number = null;
  anexosGravados = null;
  perguntasRespostas: PerguntasRespostasModel[];
  respostasSimNaoRadioButtons = {};
  comentariosSupervisor = {};
  totalDePerguntas = 0;
  respondeuTodasPerguntas = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public experienciaSupervisionadaService: ExperienciaSupervisionadaService,
    public pessoaService: PessoaService,
    private formBuilder: FormBuilder,
    private serviceAdesaoCodigoEtica: AdesaoCodigoEticaService
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
    this.init();
    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '200px',
      toolbar: [],
      lang: 'pt-BR',
      fontNames: [
        'Helvetica',
        'Arial',
        'Arial Black',
        'Comic Sans MS',
        'Courier New',
        'Roboto',
        'Times',
      ],
    };
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      if (params.get('idPeriodo') === '1') {
        this.periodoRelatorio = '1º';
        this.codigoPeriodo = 1;
      }
      if (params.get('idPeriodo') === '2') {
        this.periodoRelatorio = '2º';
        this.codigoPeriodo = 2;
      }
      if (params.get('idPeriodo') === '3') {
        this.periodoRelatorio = '3º';
        this.codigoPeriodo = 3;
      }
      if (params.get('idPeriodo') === '4') {
        this.periodoRelatorio = '4º';
        this.codigoPeriodo = 4;
      }
      if (params.get('idPeriodo') === '5') {
        this.periodoRelatorio = '5º';
        this.codigoPeriodo = 5;
      }
      if (params.get('idPeriodo') === '6') {
        this.periodoRelatorio = 'Final';
        this.codigoPeriodo = 6;
      }

      if (params.get('idPessoa') !== null) {
        this.idPessoa = params.get('idPessoa');
        this.verificarSupervisionadoEValido(Number(this.idPessoa));
        this.obterRelatorio(this.idPessoa);
      } else {
        this.idPessoa = this.id;
        this.obterRelatorio(this.idPessoa);
      }
    });

    this.verificarSupervisor();
  }

  verificarSupervisor() {
    this.pessoaService.obterPorId(this.id).subscribe((resp) => {
      this.isSupervisor =
        resp.resultado.perfilSupervisor === null
          ? false
          : resp.resultado.perfilSupervisor;
      this.textoRelatorio(this.isSupervisor);
    });
  }

  textoRelatorio(isSupervisor) {
    this.experienciaSupervisionadaService
      .obterTextoRelatorio(
        this.idPessoa,
        isSupervisor === true ? 2 : 1,
        this.codigoPeriodo
      )
      .subscribe(
        (resp) => {
          this.relatorioForm.controls.textoRelatorio.setValue(
            resp.resultado.descricao
          );
          this.itens = resp.resultado;
          this.obterDataAdesao();
        },
        (erro) => {
          Swal.fire('Relatório', erro.error.excecao[0].mensagem, 'error');
        }
      );
  }

  init() {
    this.relatorioForm = this.formBuilder.group({
      textoRelatorio: [''],
    });
  }

  obterArquivo(codigoExperienciaSupervisionadaAnexo, nomeArquivo) {
    var applicationType = '';
    var extension = nomeArquivo.split('.').pop();

    if (extension === 'pdf') applicationType = 'application/pdf';

    if (extension === 'doc' || extension === 'docx')
      applicationType = 'application/vnd.ms-word';

    if (extension === 'jpg' || extension === 'jpeg')
      applicationType = 'application/jpg';

    if (extension === 'png') applicationType = 'application/png';

    if (applicationType === '') applicationType = 'application/octect-stream';

    this.experienciaSupervisionadaService
      .obterArquivo(codigoExperienciaSupervisionadaAnexo)
      .subscribe(
        (resp) => {
          const blob = new Blob([resp], {
            type: applicationType,
          });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (erro) => {
          Swal.fire(
            'Experiência Supervisionada',
            erro.error.excecao[0].mensagem,
            'error'
          );
        }
      );
  }

  importarAnexos() {
    this.obj.forEach((o) => {
      let arquivo = {
        idExperienciaSupervisionada: this.codigoExperienciaSupervisionada,
        nomeArquivo: o.file.name,
        anexo: o.imageSrc.replace(/^data:(.*base64,)?/, ''),
      };

      this.experienciaSupervisionadaService.importarArquivo(arquivo).subscribe(
        (resp) => {
          this.obterAnexos(this.codigoExperienciaSupervisionada);
          $('#remove').click();
        },
        (erro) => {
          Swal.fire('Relatório', erro.error.excecao[0].mensagem, 'error');
        }
      );
    });
  }

  enviar() {
    Swal.fire({
      title:
        this.isSupervisor === true
          ? 'Aprovação do relatório + evidências'
          : 'Envio do relatório + evidências',
      text:
        this.isSupervisor === true
          ? 'Confirma a aprovação deste relatório + evidências? Os dados serão remetidos à Planejar para análise.'
          : 'Confirma o envio deste relatório + evidências? Os dados serão remetidos ao supervisor para análise.',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.experienciaSupervisionadaService
          .alterarSituacao(
            this.codigoExperienciaSupervisionada,
            this.isSupervisor === true ? 3 : 2
          )
          .subscribe(
            (resp) => {
              Swal.fire(
                'Relatório',
                this.isSupervisor === true
                  ? 'Relatório enviado ao supervisor com sucesso!'
                  : 'Relatório enviado à Planejar com sucesso!',
                'success'
              ).then(() => {
                if(this.isSupervisor){
                  this.registrarAceiteRelatorio();
                }else{
                  history.go(-1);
                }

              });
            },
            (erro) => {
              Swal.fire('Relatório', erro.error.excecao[0].mensagem, 'error');
            }
          );
      }
    });
  }

  rejeitar() {
    Swal.fire({
      title: 'Rejeição do Relatório',
      text: 'Confirma a rejeição deste relatório? Os dados serão remetidos de volta ao supervisionado',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.experienciaSupervisionadaService
          .alterarSituacao(this.codigoExperienciaSupervisionada, 4)
          .subscribe(
            (resp) => {
              Swal.fire(
                'Relatório',
                'Relatório rejeitado com sucesso!',
                'success'
              ).then(() => {
                history.go(-1);
              });
            },
            (erro) => {
              Swal.fire('Relatório', erro.error.excecao[0].mensagem, 'error');
            }
          );
      }
    });
  }

  excluirArquivo(codigoExperienciaSupervisionadaAnexo, nomeArquivo) {
    Swal.fire({
      title: 'Exclusão do arquivo',
      text: "Confirma a exclusão do arquivo '" + nomeArquivo + "'?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.experienciaSupervisionadaService
          .removerAnexo(codigoExperienciaSupervisionadaAnexo)
          .subscribe(
            (resp) => {
              Swal.fire(
                'Relatório',
                'Arquivo excluido com sucesso!',
                'success'
              ).then(() => {
                this.obterAnexos(this.codigoExperienciaSupervisionada);
              });
            },
            (erro) => {
              Swal.fire('Relatório', erro.error.excecao[0].mensagem, 'error');
            }
          );
      }
    });
  }

  submit(relatorioForm) {
    Swal.fire({
      title: 'Envio de relatório',
      text: 'Confirma o aceite do relatório?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.experienciaSupervisionadaService
          .aceitarTextoRelatorio(
            this.idPessoa,
            this.isSupervisor === true ? 2 : 1,
            this.codigoPeriodo
          )
          .subscribe(
            (resp) => {
              Swal.fire(
                'Relatório',
                'Adesão efetuada com sucesso!',
                'success'
              ).then(() => {
                this.aderir = false;
                this.textoRelatorio(this.isSupervisor);
              });
            },
            (erro) => {
              Swal.fire('Relatório', erro.error.excecao[0].mensagem, 'error');
            }
          );
      }
    });
  }

  obterDataAdesao() {
    this.experienciaSupervisionadaService
      .obterAceiteRelatorio(
        this.idPessoa,
        this.isSupervisor === true ? 2 : 1,
        this.codigoPeriodo
      )
      .subscribe(
        (resp) => {
          this.monstrarLabel = resp.resultado;

          if (this.codigoPeriodo < 6) {
            if (
              !this.isSupervisor &&
              this.monstrarLabel[
                'dataAceiteRelatorio' + this.codigoPeriodo.toString()
              ] === null
            ) {
              this.aderir = true;
            }

            if (
              this.isSupervisor &&
              this.monstrarLabel[
                'dataAceiteRelatorio' +
                  this.codigoPeriodo.toString() +
                  'Supervisor'
              ] === null
            ) {
              this.aderir = true;
            }
          } else {
            if (
              !this.isSupervisor &&
              this.monstrarLabel['dataAceiteRelatorioFinal'] !== null
            ) {
              this.aderir = true;
            }

            if (
              this.isSupervisor &&
              this.monstrarLabel['dataAceiteRelatorioFinalSupervisor'] !== null
            ) {
              this.aderir = true;
            }
          }

          this.monstrarLabel = resp.resultado;

          if (this.codigoPeriodo < 6) {
            if (this.isSupervisor === true) {
              if (
                this.monstrarLabel[
                  'dataAceiteRelatorio' +
                    this.codigoPeriodo.toString() +
                    'Supervisor'
                ] !== null
              )
                this.monstrarLabel['complemento'] =
                  'Relatório aceito em ' +
                  moment(
                    this.monstrarLabel[
                      'dataAceiteRelatorio' +
                        this.codigoPeriodo.toString() +
                        'Supervisor'
                    ]
                  ).format('DD/MM/YYYY');
              else this.monstrarLabel['complemento'] = null;
            } else {
              if (
                this.monstrarLabel[
                  'dataAceiteRelatorio' + this.codigoPeriodo.toString()
                ] !== null
              )
                this.monstrarLabel['complemento'] =
                  'Relatório aceito em ' +
                  moment(
                    this.monstrarLabel[
                      'dataAceiteRelatorio' + this.codigoPeriodo.toString()
                    ]
                  ).format('DD/MM/YYYY');
              else this.monstrarLabel['complemento'] = null;
            }
          } else {
            if (this.isSupervisor === true) {
              if (
                this.monstrarLabel['dataAceiteRelatorioFinalSupervisor'] !==
                null
              )
                this.monstrarLabel['complemento'] =
                  'Relatório aceito em ' +
                  moment(
                    this.monstrarLabel['dataAceiteRelatorioFinalSupervisor']
                  ).format('DD/MM/YYYY');
              else this.monstrarLabel['complemento'] = null;
            } else {
              if (this.monstrarLabel['dataAceiteRelatorioFinal'] !== null)
                this.monstrarLabel['complemento'] =
                  'Relatório aceito em ' +
                  moment(this.monstrarLabel['dataAceiteRelatorioFinal']).format(
                    'DD/MM/YYYY'
                  );
              else this.monstrarLabel['complemento'] = null;
            }
          }
        },
        (erro) => {
          Swal.fire('Relatório', erro.error.excecao[0].mensagem, 'error');
        }
      );
  }

  obterRelatorio(idPessoa) {
    this.experienciaSupervisionadaService
      .obterRelatorioPorSupervisionadoPeriodo(idPessoa, this.codigoPeriodo)
      .pipe(finalize(() => this.obterDadosPerguntasERespostas()))
      .subscribe((resp) => {
        if (resp.resultado !== null) {
          this.codigoExperienciaSupervisionada =
            resp.resultado.idExperienciaSupervisionada;
          this.obterAnexos(this.codigoExperienciaSupervisionada);
        } else this.router.navigate(['/home']);
      });
  }

  obterAnexos(codigoExperienciaSupervisionada) {
    this.experienciaSupervisionadaService
      .obterAnexos(codigoExperienciaSupervisionada)
      .subscribe((resp) => {
        this.anexosGravados = resp.resultado;
      });
  }

  verificarSupervisionadoEValido(idPessoa: number) {
    this.experienciaSupervisionadaService
      .obterExperienciasPorSupervisor(this.id)
      .subscribe((resp) => {
        if (!resp.resultado?.some((d) => d.idPessoa === idPessoa))
          this.router.navigate(['/home']);
      });
  }

  onSelect(event) {
    this.obj = [];
    this.onRemove();
    this.obj = event.source._previewChildren;
    this.files.push(...event.addedFiles);
  }

  onRemove() {
    this.obj = [];
    this.files.splice(0, 1);
    if (this.imagem !== '') {
      this.imagem = '';
    }
  }

  salvarInformacoes() {
    this.prepararDadosPerguntaEResposta();
    this.gravarRespostas();
    if (this.isSupervisor) this.gravarComentarios();
  }

  private gravarRespostas() {
    const dadosParaGravar = [] as GravarRespostasModel[];

    for (let tab = 0; tab < this.perguntasRespostas.length; tab++) {
      for (
        let per = 0;
        per <
        this.perguntasRespostas[tab].perguntaRespostaEspecializacaoSelecao[0]
          .length;
        per++
      ) {
        for (
          let sub = 0;
          sub <
          this.perguntasRespostas[tab].perguntaRespostaEspecializacaoSelecao[0][
            per
          ].subEspecializacoes.length;
          sub++
        ) {
          let currentItem =
            this.perguntasRespostas[tab]
              .perguntaRespostaEspecializacaoSelecao[0][per].subEspecializacoes[
              sub
            ].perguntas;
          if (currentItem.length === 0) continue;

          for (let subPer = 0; subPer < currentItem.length; subPer++) {
            let currentItem =
              this.perguntasRespostas[tab]
                .perguntaRespostaEspecializacaoSelecao[0][per]
                .subEspecializacoes[sub].perguntas[subPer];
            if (currentItem.opcoes.length < 1) continue;
            let opcaoSelecionada = currentItem.opcoes.find(
              (option) => option.selecionado
            );
            if (opcaoSelecionada) {
              dadosParaGravar.push({
                idExperienciaSupervisionada:
                  this.codigoExperienciaSupervisionada,
                idPerguntaOpcao: opcaoSelecionada.idOpcao,
                idRespostaEspecializacao: currentItem.idRespostaEspecializacao,
              });
            }
            if (
              this.totalDePerguntas !==
              Object.keys(this.respostasSimNaoRadioButtons).length
            ) {
              Swal.fire(
                'Gravar respostas',
                'Todos os itens são obrigatórios',
                'error'
              );
                this.respondeuTodasPerguntas = true;
              return;
            }
          }
        }
      }
    }

    this.experienciaSupervisionadaService
      .postGravarRespostas(dadosParaGravar)
      .subscribe(
        () => {
          Swal.fire(
            'Gravar respostas',
            'Sucesso ao gravar suas respostas!',
            'success'
          );
          this.respondeuTodasPerguntas = true;
        },
        (erro) => {
          Swal.fire(
            'Gravar respostas',
            erro.error.excecao[0].mensagem,
            'error'
          );
          this.respondeuTodasPerguntas = false;
        }
      );
  }

  private registrarAceiteRelatorio(){
    this.experienciaSupervisionadaService
    .registrarAceiteRelatorio(this.codigoExperienciaSupervisionada, false)
    .subscribe(
      () => {
        // Swal.fire(
        //   'Aceite relatório registrado com sucesso.',
        //   'Sucesso ao gravar aceite.',
        //   'success'
        // );
        history.go(-1);
      },
      (erro) => {
        Swal.fire(
          'Gravar aceite.',
          erro.error.excecao[0].mensagem,
          'error'
        );
      }
    );
  }

  private prepararDadosPerguntaEResposta() {
    this.perguntasRespostas.forEach((item) => {
      item.perguntaRespostaEspecializacaoSelecao[0].forEach((item2) => {
        //itera os items da tabela
        item2.subEspecializacoes.forEach((sub) => {
          sub.perguntas.map((per) => {
            if (this.respostasSimNaoRadioButtons[per.idPergunta] === true) {
              //Caso opção selecionada tenha sido SIM
              let opcaoParaAlterarSelecionado = per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'sim'
              );
              if (opcaoParaAlterarSelecionado)
                opcaoParaAlterarSelecionado.selecionado = true;

              let opcaoParaAlterarSelecionadoParaFalse = per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'não'
              );
              if (opcaoParaAlterarSelecionadoParaFalse)
                opcaoParaAlterarSelecionadoParaFalse.selecionado = false;
            } else if (
              this.respostasSimNaoRadioButtons[per.idPergunta] === false
            ) {
              //Caso opção selecionada tenha sido NÃO
              let opcaoParaAlterarSelecionado = per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'não'
              );
              if (opcaoParaAlterarSelecionado)
                opcaoParaAlterarSelecionado.selecionado = true;

              let opcaoParaAlterarSelecionadoParaFalse = per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'sim'
              );
              if (opcaoParaAlterarSelecionadoParaFalse)
                opcaoParaAlterarSelecionadoParaFalse.selecionado = false;
            } else if (this.respostasSimNaoRadioButtons[per.idPergunta] === 1) {
              //Caso opção selecionada tenha sido Talvez
              let opcaoParaAlterarSelecionado = per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'talvez'
              );
              if (opcaoParaAlterarSelecionado)
                opcaoParaAlterarSelecionado.selecionado = true;

              per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'sim'
              ).selecionado = false;

              per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'não'
              ).selecionado = false;
            } else if (this.respostasSimNaoRadioButtons[per.idPergunta] === 2) {
              //Caso opção selecionada tenha sido Quase sempre
              let opcaoParaAlterarSelecionado = per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'quase sempre'
              );
              if (opcaoParaAlterarSelecionado)
                opcaoParaAlterarSelecionado.selecionado = true;

              per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'às vezes'
              ).selecionado = false;

              per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'sempre'
              ).selecionado = false;
            } else if (this.respostasSimNaoRadioButtons[per.idPergunta] === 3) {
              //Caso opção selecionada tenha sido Às vezes
              let opcaoParaAlterarSelecionado = per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'às vezes'
              );
              if (opcaoParaAlterarSelecionado)
                opcaoParaAlterarSelecionado.selecionado = true;

              per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'quase sempre'
              ).selecionado = false;

              per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'sempre'
              ).selecionado = false;
            } else if (this.respostasSimNaoRadioButtons[per.idPergunta] === 4) {
              //Caso opção selecionada tenha sido Sempre
              let opcaoParaAlterarSelecionado = per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'sempre'
              );
              if (opcaoParaAlterarSelecionado)
                opcaoParaAlterarSelecionado.selecionado = true;

              per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'quase sempre'
              ).selecionado = false;

              per.opcoes.find(
                (option) => option.descricao.toLowerCase() === 'às vezes'
              ).selecionado = false;
            }

            return per;
          });
        });
      });
    });
  }
  private obterDadosPerguntasERespostas() {
    this.experienciaSupervisionadaService
      .obterPerguntasRespostas(this.codigoExperienciaSupervisionada)
      .subscribe((response) => {
        this.perguntasRespostas = response;
        this.totalDePerguntas = 0;
        this.respondeuTodasPerguntas = response[0].respondeuTodas;

        response?.forEach((item) => {
          item.perguntaRespostaEspecializacaoSelecao[0].forEach((item2) => {
            //itera os items da tabela
            item2.subEspecializacoes.forEach((sub) => {
              sub.perguntas.forEach((per) => {
                let selecionado = per.opcoes.filter((op) => op.selecionado)[0];
                if (per.idPergunta && per.opcoes.length > 0)
                  this.totalDePerguntas++;
                this.respostasSimNaoRadioButtons[per.idPergunta] =
                  selecionado && selecionado.descricao.toLowerCase() === 'sim'
                    ? true
                    : selecionado &&
                      selecionado.descricao.toLowerCase() === 'não'
                    ? false
                    : selecionado &&
                      selecionado.descricao.toLowerCase() === 'talvez'
                    ? 1
                    : selecionado &&
                      selecionado.descricao.toLowerCase() === 'quase sempre'
                    ? 2
                    : selecionado &&
                      selecionado.descricao.toLowerCase() === 'às vezes'
                    ? 3
                    : selecionado &&
                      selecionado.descricao.toLowerCase() === 'sempre'
                    ? 4
                    : null;
              });
            });
          });
        });
        //Remove todos os items que são nulos, itens que não foram respondidos
        Object.keys(this.respostasSimNaoRadioButtons).forEach((item) => {
          if (this.respostasSimNaoRadioButtons[item] === null)
            delete this.respostasSimNaoRadioButtons[item];
        });
      });
  }

  private gravarComentarios() {
    const comentarios = [] as GravarComentariosModel[];
    this.perguntasRespostas.forEach((item) => {
      item.perguntaRespostaEspecializacaoSelecao[0].forEach((item2) => {
        //itera os items da tabela
        item2.subEspecializacoes.forEach((sub) => {
          if (this.comentariosSupervisor[sub.idSubEspecializacao]) {
            comentarios.push({
              comentarioPlanejar: sub.comentarioPlanejar || '',
              comentarioSupervisor:
                this.comentariosSupervisor[sub.idSubEspecializacao] || '',
              idComentarioEspecializacao: sub.idComentarioEspecializacao,
              idEspecializacao: item2.idEspecializacao,
              idExperienciaSupervisionada: this.codigoExperienciaSupervisionada,
            });
          }
        });
      });
    });
    if (comentarios.length > 0) {
      this.experienciaSupervisionadaService
        .postGravarComentarios(comentarios)
        .subscribe(
          () => {
            Swal.fire(
              'Gravar comentários',
              'Sucesso ao gravar seus comentários',
              'success'
            );
          },
          (erro) => {
            Swal.fire(
              'Gravar respostas',
              erro.error.excecao[0].mensagem,
              'error'
            );
          }
        );
    }
  }
}
