<div class="container">
  <div class="col-12">

    <h1>Votações</h1>
    <ul>
      <li class="pergunta" *ngFor="let votacao of votacoes">
        {{ votacao.titulo }}

        <div class="button-votar">

          <button (click)="irParaVotacao(votacao)" *ngIf="!votacao.votacaoPerguntas" class="ml-2" mat-raised-button
            color="primary">
            Ir para Votação
          </button>

        </div>
      </li>
    </ul>

    <div class="row col-12">
      <div class="col-md-1"><a *ngIf="currentPage > 0" class="btn btn-link" (click)="paginaAnterior()">Anterior</a>
      </div>
      <div class="col-md-9"></div>
      <div class="col-md-1"><a *ngIf="votacoes.length >= 10" class="btn btn-link" (click)="proximaPagina()">Próxima</a>
      </div>
    </div>
  </div>
</div>