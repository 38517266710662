<div class="container" *ngIf="currentUrl.includes('busca-planejador')">
  <div class="row">
    <div class="col-md-12" id="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div *ngIf="!currentUrl.includes('busca-planejador')">
  <header>
    <app-top-nav></app-top-nav>
    <app-main-nav></app-main-nav>
  </header>
  <ng-container *ngIf="usuarioAutenticado || !currentUrl.includes('login')">
    <div class="container">
      <app-loader></app-loader>
      <div class="content-pad-3x">
        <div *ngIf="currentUrl !== 'auto-cadastro' && !currentUrl.includes('alterar-senha')" class="row">
          <div class="col-md-9" id="content">
            <router-outlet></router-outlet>
          </div>
          <div id="sidebar" class="col-md-3 normal-sidebar">
            <app-sidebar></app-sidebar>
          </div>
        </div>
        <div *ngIf="currentUrl === 'auto-cadastro' || currentUrl.includes('alterar-senha')" class="row">
          <div class="col-md-12" id="content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <app-login
    *ngIf="!usuarioAutenticado && currentUrl.includes('login')"
  ></app-login>

  <app-footer></app-footer>
</div>
