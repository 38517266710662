import { SituacaoService } from './../../services/situacao/situacao.service';
import { ExameService } from 'src/app/services/exame/exame.service';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { environment } from 'src/environments/environment';

interface TrilhaCertificado
{
  idTrilhaItem: number;
  justificativaReprovacao: string | null;
  descricaoTrilhaItem: string;
  percentualConclusao: string;
  quantidadeConcluida: number;
  quantidadeTotal: number;
  habilitaReprovado: boolean;
  habilitaAcessar: boolean;
  idStatus: number | null;
  retornoStatus: string;
  idSituacao: number | null;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  id: any
  certificados = [];
  naoCertificados = [];
  instrucaoModal: ModalDirective;
  itemModal: any;
  exames = null;
  certificado: any = null;
  trilhaCertificado: any = null;
  todosAprovados : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inscricaoService : AreaLogadaInscricaoService,
    private exameService : ExameService,
    private pessoaService : PessoaService,
    private serviceSituacao : SituacaoService,
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
  }

  ngOnInit(): void {
    this.carregaGrid();
    this.pessoaService.obterCertificadoPessoa(this.id).subscribe((result) => {
      this.certificado = result.resultado;
      console.log(result.resultado)
    });

    this.route.paramMap.subscribe(params => {
      //renovacao
      if (params.get("renovacao") == 'false')
      {
        this.serviceSituacao.obterSituacaoCertificado(this.id).subscribe((res)=>{
          this.trilhaCertificado = res.resultado;
          if (this.trilhaCertificado[0].idTrilhaItem === 11 && this.trilhaCertificado[0].quantidadeConcluida === this.trilhaCertificado[0].quantidadeTotal)
            this.todosExamesForamAprovados(this.id, a =>
            {
              this.todosAprovados = a
            })
        });
      }
      else {
        this.serviceSituacao.obterSituacaoRenovacao(this.id).subscribe((res) =>
        {

          this.trilhaCertificado = res.resultado;

          if (this.trilhaCertificado.length == 0) {

            this.serviceSituacao.obterSituacaoCertificado(this.id).subscribe((res) =>
            {
              this.trilhaCertificado = res.resultado;
              if (this.trilhaCertificado.length > 0 && this.trilhaCertificado[0].idTrilhaItem === 11 && this.trilhaCertificado[0].quantidadeConcluida === this.trilhaCertificado[0].quantidadeTotal) {
                this.todosExamesForamAprovados(this.id, a =>
                {
                  this.todosAprovados = a
                })
              }
            });

          } else {
            this.todosExamesForamAprovados(this.id, a =>
            {
              this.todosAprovados = a
            })
          }
        });
      }

      console.log(1, this.todosAprovados)

    });
  }

  todosExamesForamAprovados(pessoaId, fn)
  {
    fetch(environment.backofficeUrl + "/area-logada-resultados-exames/obter-exames?codigoPessoa=" + pessoaId + "&filtro=0").then(res =>
    {
      res.json().then(json =>
      {
        const a = json.resultado.filter(a => a.dataValidade)
        fn(json.resultado.length === a.length)
      })
    })
  }

  carregaGrid()
  {
    this.inscricaoService.obterDebitosPorPessoa(this.id).subscribe((result) =>
    {
      this.exameService.obterExame(this.id, 3).subscribe((resultExam) =>
      {
        result.resultado.forEach((e) =>
        {
          e.modulos = e.inscricaoModulo.map(i => i.codigo).join();
        });

        let newResult = []

        for(const a of result.resultado) {

          for(const b of resultExam.resultado) {
            if(parseInt(a.idExame) === parseInt(b.idExame)) {
              if(b.descricaoExame) {
                a.descricaoExame = b.descricaoExame
              }
            }

            if(!a.descricaoExame) {
              a.descricaoExame = "Exame " + a.numeroExame + "° Presencial"
            }
          }

          newResult.push(a)
        }

        this.exames = newResult
        // this.exames = result.resultado;
        console.log(this.exames, resultExam)
        //   if (resultExam.resultado) {

        //     this.todosAprovados = resultExam.resultado.filter(a => a.todosAprovados).length >= resultExam.resultado.length;
        //   }

        //   const aprovados = resultExam.resultado.filter(
        //     (obj) =>
        //       obj.descricaoSituacao === 'Aprovado' &&
        //       new Date(obj.dataValidade) > new Date()
        //   );

        //   // cria um conjunto de descrições de módulo únicas para os aprovados
        //   const descricoesAprovadas = new Set(
        //     aprovados.map((obj) => obj.descricaoModulo)
        //   );

        //   this.todosAprovados = descricoesAprovadas.size >= 6;

        //   console.log(2, this.todosAprovados, descricoesAprovadas)
      });
    });
  }


  abrirInscricao() {
    this.inscricaoService.obterCancelamentosAtivos(this.id).subscribe((result) => {
      if(result.resultado!=null && result.resultado.length > 0)
      {
        Swal.fire(
          'Inscrição',
          'Por motivos de bloqueio você não poderá seguir com sua inscrição para o Exame CFP®. Para obter informações entre em contato com nosso time de atendimento.',
          'info'
        );
      }
      else
      {
        this.inscricaoService.obterBloqueiosAtivos(this.id).subscribe((result) => {
          if(result.resultado!=null)
          {
            Swal.fire(
              'Inscrição',
              'Por motivos de bloqueio você não poderá seguir com sua inscrição para o Exame CFP®. Para obter informações entre em contato com nosso time de atendimento.',
              'info'
            );
          }
          else
            this.router.navigate(['/inscricao']);
        });
      }
    });
  }

  abrirInscricaoConfig(exame: any) {
    localStorage.setItem('exameSelecionado', exame.idExame.toString());
    this.router.navigate(['/inscricao-config/'+exame.idInscricao]);

    /*if (exame.descricaoPagamento == 'Pendente' || exame.descricaoPagamento == 'Aguardando Confirmação') {
      Swal.fire('Detalhes da inscrição', 'Só será possivel acessar todos os detalhes da inscrição após o pagamento ser confirmado', 'warning');
    }*/
  }
}
