import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeclaracaoPrivacidadeService } from 'src/app/services/declaracao-privacidade/declaracao-privacidade.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-declaracao-privacidade',
  templateUrl: './declaracao-privacidade.component.html',
  styleUrls: ['./declaracao-privacidade.component.scss']
})
export class DeclaracaoPrivacidadeComponent implements OnInit {
  aderir = false;
  monstrarLabel = [];
  config: any;
  declaracaoPrivacidadeForm: FormGroup;
  itens = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private declaracaoPrivacidadeService: DeclaracaoPrivacidadeService
    ) {
      this.init();
      this.config = {
        placeholder: '',
        tabsize: 2,
        height: '200px',
        toolbar: [
        ],
        lang: "pt-BR",
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
      }
    }

  ngOnInit(): void {
    this.textoCodigoEtica();
  }

  textoCodigoEtica(){
    this.declaracaoPrivacidadeService
      .obterEditalPrivacidade()
      .subscribe((resp)=>{
      this.declaracaoPrivacidadeForm.controls.codigoEtica.setValue(resp.resultado.descricao);
       this.itens = resp.resultado;
    }, (erro) => {
      Swal.fire(
        'Adesão ao Código de Ética',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  init(){
    this.declaracaoPrivacidadeForm = this.formBuilder.group({
      codigoEtica:[''],
    });
  }

  submit(teste){

  }

  voltar(){
    this.router.navigate(['/home']);
  }

}
