import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { ViewChild } from '@angular/core';
import { CreditoService } from 'src/app/services/credito/credito.service';

@Component({
  selector: 'app-credito',
  templateUrl: './credito.component.html',
  styleUrls: ['./credito.component.scss']
})
export class CreditoComponent implements OnInit {

  nomeArq = null;
  creditoForm: FormGroup;
  instrucoesForm: FormGroup;
  isSubmitted: Boolean;
  livre: Boolean;
  botaoSalvar: Boolean;
  desabilitarButtons: Boolean;
  desabilitarUpload: Boolean;
  id: any;
  tipos = [];
  categorias = [];
  instituicoes = [];
  atividades = [];
  topicos = [];
  itemModal: any;
  topicosAdicionados = [];
  vigencia = [];
  files: File[] = [];
  somaCreditos: any;
  datas = {};
  dataInicio: any;
  dataFim: any;
  documentoCarregado = [];
  obj = [];
  idPessoaCertificadoCredito: any;
  idAtividadeTipoCategoria: any;
  tipoSelecionado: any;
  atividadeSelecionado: any;
  categoriaSelecionado: any;
  motivo: any;
  justificativaReprovacao: any;
  arquivoEdit: any;

  imagem = null;
  mode = 'add';
  @ViewChild('instrucaoModal') instrucaoModal: ModalDirective;

  config: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private serviceCredito: CreditoService) {
    this.route.paramMap.subscribe(params => {
      this.idPessoaCertificadoCredito = params.get("id");
    })
    this.id = localStorage.getItem('userId');
    this.vigenciaPessoa(this.id);
    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '200px',
      toolbar: [
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    };

    this.creditoForm = this.formBuilder.group({
      tipo: ['', Validators.required],
      categoria: [{ value: '', disabled: true }, Validators.required],
      instituicao: [{ value: '', disabled: true }, Validators.required],
      instituicaoTexte: [{ value: '', disabled: false }, Validators.required],
      atividade: [{ value: '', disabled: true }, Validators.required],
      atividadeComplemento: [{ value: '', disabled: true }, Validators.required],
      data: [{ value: '', disabled: true }, Validators.required],
      limite: [{ value: '', disabled: true }],
      cargaHoraria: [{ value: '', disabled: true }, [Validators.required, Validators.min(1)]],
      creditos: [{ value: '', disabled: true }, Validators.required],
      topico: [{ value: '', disabled: true }, Validators.required],
      observacao: [''],
      responsabilidade: [{ value: '', disabled: true }, Validators.required],
      ciente: [{ value: '', disabled: true }, Validators.required],
      categoriaTexte: [{ value: '', disabled: true }],
      tipoTexte: [{ value: '', disabled: true }],
      atividadeTexte: [{ value: '', disabled: true }],

    });

    this.instrucoesForm = this.formBuilder.group({
      instrucao: [''],

    });
  }

  ngOnInit(): void {
    if (this.router.url.split('/')[2] === 'adicionar') {
      this.idPessoaCertificadoCredito = 0;

    }
    if (this.router.url.split('/')[2] === 'editar') {
      this.mode = 'edit';
      this.botaoSalvar = true;
      this.desabilitarButtons = true;
      this.desabilitarUpload = false;
      this.creditoForm = this.formBuilder.group({
        tipo: ['',],
        categoria: [{ value: '', disabled: true }],
        instituicao: [{ value: '', disabled: true }],
        instituicaoTexte: [{ value: '', disabled: false }],
        atividade: [{ value: '', disabled: true }],
        atividadeComplemento: [{ value: '', disabled: true }],
        data: [{ value: '', disabled: true }],
        limite: [{ value: '', disabled: true }],
        cargaHoraria: [{ value: '', disabled: true }],
        creditos: [{ value: '', disabled: true }],
        topico: [{ value: '', disabled: true }, Validators.required],
        observacao: [''],
        responsabilidade: [{ value: '', disabled: true }, Validators.required],
        ciente: [{ value: '', disabled: true }, Validators.required],
        categoriaTexte: [{ value: '', disabled: true }],
        tipoTexte: [{ value: '', disabled: true }],
        atividadeTexte: [{ value: '', disabled: true }],

      });

      this.instrucoesForm = this.formBuilder.group({
        instrucao: [''],

      });


      this.serviceCredito.obterAtividade(this.idPessoaCertificadoCredito).subscribe((resp) => {
        this.motivo = resp.resultado.descricaoSituacaoCredito;
        this.justificativaReprovacao = resp.resultado.justificativaReprovacao;
        this.arquivoEdit = resp.resultado.arquivo;
        this.idAtividadeTipoCategoria = resp.resultado.idAtividadeTipoCategoria;

        this.creditoForm.controls["categoriaTexte"].setValue(resp.resultado.descricaoCategoria);
        this.creditoForm.controls["tipoTexte"].setValue(resp.resultado.descricaoTipoAtividade);
        this.creditoForm.controls["atividadeTexte"].setValue(resp.resultado.tituloAtividade);
        this.creditoForm.controls["atividadeComplemento"].setValue(resp.resultado.complementoAtividade);
        this.creditoForm.controls["instituicaoTexte"].setValue(resp.resultado.nomeEmpresa);
        this.creditoForm.controls["observacao"].setValue(resp.resultado.observacoes);


        if (resp.resultado.livre == 1 ? true : false) {
          this.livre = resp.resultado.livre == 1 ? true : false;
          setTimeout(() => {
            this.tipoSelecionado = resp.resultado.idAtividadeTipo;
            this.creditoForm.controls["tipo"].setValue(this.tipoSelecionado);
          }, 300);
          setTimeout(() => {
            this.carregaCategoriaEditar();
            this.categoriaSelecionado = resp.resultado.idAtividadeCategoria;

            this.creditoForm.controls["categoria"].setValue(resp.resultado.idAtividadeTipoCategoria);
            this.carregarAtividadesEditar(resp.resultado.idAtividade);
          }, 800);
          setTimeout(() => {
            this.atividadeSelecionado = resp.resultado.idAtividade;
            this.creditoForm.controls["atividade"].setValue(resp.resultado.idAtividade);
            this.nomeArq = resp.resultado.nomeArquivo;

          }, 100);
        } else {
          setTimeout(() => {
            this.tipoSelecionado = resp.resultado.idAtividadeTipo;
            this.creditoForm.controls["tipo"].setValue(this.tipoSelecionado);
            this.carregaCategoriaEditar();
          }, 300);
          setTimeout(() => {
            this.categoriaSelecionado = resp.resultado.idAtividadeCategoria;
            this.creditoForm.controls["categoria"].setValue(resp.resultado.idAtividadeTipoCategoria);
            this.carregarAtividadesEditar(resp.resultado.idAtividade);
          }, 800);
          setTimeout(() => {
            this.creditoForm.controls["atividade"].setValue(resp.resultado.idAtividade);
            this.creditoForm.controls["instituicao"].setValue(resp.resultado.idEmpresa);
          }, 900);
        }

      }, (erro) => {
        Swal.fire(
          'Experiência Não Supervisionada',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    }
    if (this.router.url.split('/')[2] === 'visualizar') {
      this.mode = 'visualizar';
      this.creditoForm.controls['ciente'].disable();
      this.creditoForm.controls['responsabilidade'].disable();
      this.creditoForm.controls['topico'].disable();
      this.desabilitarButtons = true;
      this.desabilitarUpload = true;
      this.creditoForm = this.formBuilder.group({
        tipo: ['',],
        categoria: [{ value: '', disabled: true }],
        instituicao: [{ value: '', disabled: true }],
        instituicaoTexte: [{ value: '', disabled: true }],
        atividade: [{ value: '', disabled: true }],
        atividadeComplemento: [{ value: '', disabled: true }],
        data: [{ value: '', disabled: true }],
        limite: [{ value: '', disabled: true }],
        cargaHoraria: [{ value: '', disabled: true }],
        creditos: [{ value: '', disabled: true }],
        topico: [{ value: '', disabled: true }, Validators.required],
        observacao: [{ value: '', disabled: true }],
        responsabilidade: [{ value: '', disabled: true }, Validators.required],
        ciente: [{ value: '', disabled: true }, Validators.required],
        categoriaTexte: [{ value: '', disabled: true }],
        tipoTexte: [{ value: '', disabled: true }],
        atividadeTexte: [{ value: '', disabled: true }],

      });

      this.instrucoesForm = this.formBuilder.group({
        instrucao: [''],
      });
      this.serviceCredito.obterAtividade(this.idPessoaCertificadoCredito).subscribe((resp) => {
        this.motivo = resp.resultado.descricaoSituacaoCredito;
        this.justificativaReprovacao = resp.resultado.justificativaReprovacao;
        this.arquivoEdit = resp.resultado.arquivo;
        this.idAtividadeTipoCategoria = resp.resultado.idAtividadeTipoCategoria;

        this.creditoForm.controls["categoriaTexte"].setValue(resp.resultado.descricaoCategoria);
        this.creditoForm.controls["tipoTexte"].setValue(resp.resultado.descricaoTipoAtividade);
        this.creditoForm.controls["atividadeTexte"].setValue(resp.resultado.descricaoTipoAtividade);
        this.creditoForm.controls["atividadeTexte"].setValue(resp.resultado.descricaoTipoAtividade);

        this.creditoForm.controls["atividadeComplemento"].setValue(resp.resultado.complementoAtividade);
        this.creditoForm.controls["observacao"].setValue(resp.resultado.observacoes);
        if (resp.resultado.livre == 1 ? true : false) {
          this.livre = resp.resultado.livre == 1 ? true : false;
          setTimeout(() => {
            this.tipoSelecionado = resp.resultado.idAtividadeTipo;
            this.creditoForm.controls["tipo"].setValue(this.tipoSelecionado);
          }, 300);
          setTimeout(() => {
            this.carregaCategoriaVisualizar();
            this.categoriaSelecionado = resp.resultado.idAtividadeCategoria;
            this.creditoForm.controls["categoria"].setValue(resp.resultado.idAtividadeTipoCategoria);
            this.carregarAtividadesEditar(resp.resultado.idAtividade);
          }, 800);
          setTimeout(() => {
            this.creditoForm.controls["atividade"].setValue(resp.resultado.idAtividade);
            this.nomeArq = resp.resultado.nomeArquivo;
          }, 900);
        } else {
          setTimeout(() => {
            this.tipoSelecionado = resp.resultado.idAtividadeTipo;
            this.creditoForm.controls["tipo"].setValue(10);
            this.carregaCategoriaVisualizar();
          }, 300);
          setTimeout(() => {
            this.categoriaSelecionado = resp.resultado.idAtividadeCategoria;
            this.creditoForm.controls["categoria"].setValue(resp.resultado.idAtividadeTipoCategoria);
            this.creditoForm.controls["instituicao"].setValue(resp.resultado.idEmpresa);
            this.carregarAtividadesEditar(resp.resultado.idAtividade);
          }, 800);
          setTimeout(() => {
            this.creditoForm.controls["atividade"].setValue(resp.resultado.idAtividade);
          }, 900);
        }
      }, (erro) => {
        Swal.fire(
          'Experiência Não Supervisionada',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    }
    this.carregarTipo();
  }

  async submit(form) {
    this.isSubmitted = true;
    if (form.status == 'VALID') {
      if (this.mode !== 'edit') {
        if (!this.livre) {
          if (this.topicosAdicionados.length > 0) {
            let topicosSelecionados = [];
            this.topicosAdicionados.map((item) => {
              topicosSelecionados.push(
                {
                  "idAtividadeTopico": item.idAtividadeTopico,
                });
            });
          }
          let obj = {
            "idPessoaCertificadoVigencia": this.vigencia['idPessoaCertificadoVigencia'],
            "idAtividadeTipoCategoria": this.creditoForm.controls['categoria'].value.idAtividadeTipoCategoria,
            "idEmpresa": Number(this.creditoForm.controls['instituicao'].value),
            "nomeEmpresa": null,
            "idAtividade": Number(this.creditoForm.controls['atividade'].value.idAtividade),
            "complementoAtividade": this.creditoForm.controls['atividadeComplemento'].value,
            "observacoes": null,
            "nomeArquivo": null,
            "arquivo": null,
            "data": this.creditoForm.controls['data'].value,
            "cargaHoraria": Number(this.creditoForm.controls['cargaHoraria'].value),
            "credito": this.creditoForm.controls['creditos'].value,
          }
          this.criarSemTopico(obj);
        } else {
          let obj = {
            "idPessoaCertificadoVigencia": this.vigencia['idPessoaCertificadoVigencia'],
            "idAtividadeTipoCategoria": this.creditoForm.controls['categoria'].value.idAtividadeTipoCategoria,
            "nomeEmpresa": this.creditoForm.controls['instituicaoTexte'].value,
            "idAtividade": Number(this.creditoForm.controls['atividade'].value.idAtividade),
            "complementoAtividade": this.creditoForm.controls['atividadeComplemento'].value,
            "observacoes": null,
            "nomeArquivo": null,
            "arquivo": null,
            "data": this.creditoForm.controls['data'].value,
            "cargaHoraria": Number(this.creditoForm.controls['cargaHoraria'].value),
            "credito": this.creditoForm.controls['creditos'].value,
            "pessoaCertificadoCreditoTopico": [
              {
                "idAtividadeTopico": Number(this.creditoForm.controls['topico'].value)
              }
            ]
          }
          this.criarComTopico(obj);
        }
      }
    }
  }

  get tipo() {
    return this.creditoForm.get('tipo');
  }
  get categoria() {
    return this.creditoForm.get('categoria');
  }
  get instituicao() {
    return this.creditoForm.get('instituicao');
  }
  get instituicaoTexte() {
    return this.creditoForm.get('instituicaoTexte');
  }
  get atividade() {
    return this.creditoForm.get('atividade');
  }
  get atividadeComplemento() {
    return this.creditoForm.get('atividadeComplemento');
  }
  get data() {
    return this.creditoForm.get('data');
  }
  get limite() {
    return this.creditoForm.get('limite');
  }
  get cargaHoraria() {
    return this.creditoForm.get('cargaHoraria');
  }
  get creditos() {
    return this.creditoForm.get('creditos');
  }
  get topico() {
    return this.creditoForm.get('topico');
  }
  get observacao() {
    return this.creditoForm.get('observacao');
  }
  get responsabilidade() {
    return this.creditoForm.get('responsabilidade');
  }
  get ciente() {
    return this.creditoForm.get('ciente');
  }
  get instrucao() {
    return this.instrucoesForm.get('instrucao');
  }

  voltar() {
    this.router.navigate(['/creditos'])
  }
  onSelect(event) {
    this.obj = [];
    this.documentoCarregado = [];
    this.onRemove();
    this.obj = event.source._previewChildren;
    this.files.push(...event.addedFiles);
    this.nomeArq = this.files[0].name;
  }
  onRemove() {
    this.obj = [];
    this.files.splice(0, 1);
    this.nomeArq = null;
  }

  hideInstrucoes(): void {
    this.instrucaoModal.hide();
  }

  abrirModal(): void {
    this.instrucaoModal.show();
    this.serviceCredito.obterTextoCreditos().subscribe((resp) => {
      this.instrucoesForm.controls['instrucao'].setValue(resp.resultado.descricao);
      this.itemModal = resp.resultado.descricaoTipo;

    }, (erro) => {
      Swal.fire(
        'Créditos ',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  carregarTipo() {
    this.serviceCredito.obterTipos().subscribe((resp) => {
      this.tipos = resp.resultado;

    }, (erro) => {
      Swal.fire(
        'Créditos ',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }
  carregaCategoriaEditar() {
    this.creditoForm.controls['categoria'].enable();
    this.tipoSelecionado = this.tipoSelecionado;
    if (this.livre) {
      this.creditoForm.controls['instituicao'].disable();
      this.creditoForm.controls['cargaHoraria'].disable();
      this.creditoForm.controls['tipo'].disable();
      this.creditoForm.controls['atividade'].disable();
      if (this.mode == "edit") {
        this.creditoForm.controls['responsabilidade'].enable();
      }
      this.creditoForm.controls['topico'].enable();
      this.creditoForm.controls['instituicaoTexte'].disable();
      this.creditoForm.controls['ciente'].enable();
      this.livre = true;
    } else {
      this.creditoForm.controls['responsabilidade'].enable();
      this.creditoForm.controls['instituicao'].disable();
      this.creditoForm.controls['ciente'].disable();
      this.creditoForm.controls['topico'].disable();
      this.creditoForm.controls['cargaHoraria'].disable();
      this.creditoForm.controls['instituicaoTexte'].disable();
      this.carregarEmpresas(this.idAtividadeTipoCategoria);
      this.livre = false;
    }
    this.serviceCredito.obterCategorias(this.tipoSelecionado).subscribe((resp) => {
      this.categorias = resp.resultado;
    }, (erro) => {
      Swal.fire(
        'Créditos',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  carregaCategoriaVisualizar() {
    this.creditoForm.controls['categoria'].disable();
    this.tipoSelecionado = this.tipoSelecionado;

    if (this.livre) {
      this.creditoForm.controls['instituicao'].disable();
      this.creditoForm.controls['cargaHoraria'].disable();
      this.creditoForm.controls['tipo'].disable();
      this.creditoForm.controls['atividade'].disable();
      if (this.mode == "edit") {
        this.creditoForm.controls['responsabilidade'].disable();
      }
      this.creditoForm.controls['topico'].disable();
      this.creditoForm.controls['instituicaoTexte'].disable();
      this.creditoForm.controls['ciente'].disable();
      this.livre = true;
    } else {
      this.creditoForm.controls['responsabilidade'].disable();
      this.creditoForm.controls['instituicao'].disable();
      this.creditoForm.controls['ciente'].disable();
      this.creditoForm.controls['cargaHoraria'].disable();
      this.creditoForm.controls['instituicaoTexte'].disable();
      this.carregarEmpresas(this.idAtividadeTipoCategoria);
      this.livre = false;
    }
    this.serviceCredito.obterCategorias(this.tipoSelecionado).subscribe((resp) => {
      this.categorias = resp.resultado;
    }, (erro) => {
      Swal.fire(
        'Créditos',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  carregarAtividadesVisualizar() {
    this.creditoForm.controls.limite.setValue(this.creditoForm.controls['categoria'].value.quantidadeMaxima),
      this.serviceCredito.obterPorTipoCategoria(
        this.tipoSelecionado,
        this.categoriaSelecionado, -1
      ).subscribe((resp) => {
        this.creditoForm.controls['atividade'].disable();
        this.atividades = resp.resultado;

      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  carregarAtividadesEditar(idAtividade) {
    this.creditoForm.controls.limite.setValue(this.creditoForm.controls['categoria'].value.quantidadeMaxima),
      this.serviceCredito.obterPorTipoCategoria(
        this.tipoSelecionado,
        this.categoriaSelecionado, -1
      ).subscribe((resp) => {
        this.creditoForm.controls['atividade'].disable();
        this.atividades = resp.resultado;
        this.selecionaAtividadeEditarVisualizar(idAtividade);



      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }



  carregaAtividadesEmpresas() {
    if (this.creditoForm.controls['tipo'].value.livre ? true : false) {
      if (this.creditoForm.controls['tipo'].value.livre ? true : false) {
        this.creditoForm.controls['instituicao'].disable();
        this.creditoForm.controls['instituicaoTexte'].enable();
      } else {
        this.creditoForm.controls['instituicao'].enable();
        this.creditoForm.controls['instituicaoTexte'].disable();
      }

      this.carregarAtividades();
    }
    else {
      this.carregarEmpresas(this.creditoForm.controls['categoria'].value.idAtividadeTipoCategoria);
    }
  }

  carregarAtividades() {
    this.creditoForm.controls['data'].setValue(null);
    this.creditoForm.controls['atividade'].setValue('');
    this.creditoForm.controls['creditos'].setValue(null);
    this.creditoForm.controls['limite'].setValue(null);
    this.creditoForm.controls['cargaHoraria'].setValue(null);

    this.creditoForm.controls.limite.setValue(this.creditoForm.controls['categoria'].value.quantidadeMaxima);

    if (this.creditoForm.controls['tipo'].value.livre ? true : false) {
      this.serviceCredito.obterPorTipoCategoria(
        this.creditoForm.controls['tipo'].value.idAtividadeTipo,
        this.creditoForm.controls['categoria'].value.idAtividadeCategoria, -1
      ).subscribe((resp) => {
        this.creditoForm.controls['atividade'].enable();
        this.atividades = resp.resultado;
      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    }
    else {
      this.serviceCredito.obterPorTipoCategoriaEmpresa(
        this.creditoForm.controls['tipo'].value.idAtividadeTipo,
        this.creditoForm.controls['categoria'].value.idAtividadeCategoria, this.creditoForm.controls['instituicao'].value
      ).subscribe((resp) => {
        this.creditoForm.controls['atividade'].enable();
        this.atividades = resp.resultado;
      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    }



  }

  carregarEmpresas(codigoAtividadeTipoCategoria) {

    if (this.creditoForm.controls['tipo'].value.livre ? true : false) {
      this.creditoForm.controls['instituicao'].disable();
      this.creditoForm.controls['instituicaoTexte'].enable();
    } else {
      this.creditoForm.controls['instituicao'].enable();
      this.creditoForm.controls['instituicaoTexte'].disable();
    }

    this.creditoForm.controls["instituicao"].setValue('');
    this.serviceCredito.obterEmpresas(codigoAtividadeTipoCategoria).subscribe((resp) => {
      this.instituicoes = resp.resultado;
    }, (erro) => {
      Swal.fire(
        'Créditos ',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  carregaCategoria() {
    this.categorias = [];

    this.creditoForm.controls['data'].setValue(null);
    this.creditoForm.controls['atividade'].setValue('');
    this.creditoForm.controls['categoria'].setValue('');
    this.creditoForm.controls['creditos'].setValue(null);
    this.creditoForm.controls['limite'].setValue(null);

    this.creditoForm.controls['categoria'].enable();
    let obj = this.creditoForm.controls['tipo'].value;

    if (this.creditoForm.controls['tipo'].value.livre ? true : false) {
      this.creditoForm.controls['instituicao'].disable();
      this.creditoForm.controls['instituicaoTexte'].disable();
      this.creditoForm.controls['cargaHoraria'].reset();
      if (this.mode == "edit") {
        this.creditoForm.controls['responsabilidade'].enable();
      }
      this.creditoForm.controls['topico'].enable();
      this.livre = this.creditoForm.controls['tipo'].value.livre ? true : false;
    } else {
      this.creditoForm.controls['responsabilidade'].enable();
      this.creditoForm.controls['instituicao'].disable();
      this.creditoForm.controls['instituicaoTexte'].disable();
      this.creditoForm.controls['ciente'].disable();
      this.creditoForm.controls['responsabilidade'].disable();
      this.creditoForm.controls['topico'].disable();
      this.livre = this.creditoForm.controls['tipo'].value.livre ? true : false;
    }

    this.serviceCredito.obterCategorias(obj.idAtividadeTipo).subscribe((resp) => {
      this.categorias = resp.resultado;
    }, (erro) => {
      Swal.fire(
        'Créditos',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }


  selecionarAtividade() {

    if (this.creditoForm.controls['atividade'].value.data !== null) {
      this.creditoForm.controls['data'].setValue(moment(this.creditoForm.controls['atividade'].value.data).format('YYYY-MM-DD'));
    } else {
      this.creditoForm.controls['data'].enable();
    }

    if (this.mode == "edit" || this.mode == "visualizar") {
      this.creditoForm.controls['cargaHoraria'].disable();
      this.creditoForm.controls['atividadeComplemento'].disable();
    } else {
      if (this.livre) {
        this.creditoForm.controls['cargaHoraria'].enable();
      }
      else {
        this.creditoForm.controls['cargaHoraria'].disable();
      }
    }

    if (this.livre) {

      if (this.mode != "edit" && this.mode != "visualizar") {
        this.creditoForm.controls['atividadeComplemento'].enable();
      }

      this.carregarTopicos();
      this.serviceCredito.obterTopicos(this.idPessoaCertificadoCredito).subscribe((resp) => {
        this.topicosAdicionados = resp.resultado;
      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    } else {

      this.creditoForm.controls['atividadeComplemento'].disable();

      this.serviceCredito.obterTopicosAtividade(this.creditoForm.controls['atividade'].value.idAtividade, this.idPessoaCertificadoCredito).subscribe((resp) => {
        this.topicosAdicionados = resp.resultado;
      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    }
    this.creditoForm.controls['cargaHoraria'].setValue(this.creditoForm.controls['atividade'].value.cargaHoraria);
    this.creditoForm.controls['creditos'].setValue(this.creditoForm.controls['atividade'].value.creditos);
  }

  carregarTopicos() {
    this.serviceCredito.obterTopicosAtividade(this.creditoForm.controls['atividade'].value.idAtividade, this.idPessoaCertificadoCredito).subscribe((resp) => {
      this.topicos = resp.resultado;
    }, (erro) => {
      Swal.fire(
        'Créditos ',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  soma() {
    let cargaHoraria = this.creditoForm.controls['cargaHoraria'].value;
    let creditosPorHora = parseFloat(this.atividades[0].creditosHora);
    this.somaCreditos = cargaHoraria * Number(creditosPorHora);
    this.creditoForm.controls['creditos'].setValue(this.somaCreditos);
  }

  criarComTopico(obj) {
    this.serviceCredito.criarComTopicos(obj).subscribe((resp) => {
      this.router.navigate([
        `/creditos/editar/${resp.identificador}`,]);
      this.ngOnInit();
      Swal.fire(
        'Créditos ',
        "Tópico adicionado com sucesso.",
        'success'
      );
    }, (erro) => {
      Swal.fire(
        'Créditos ',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }
  criarSemTopico(obj) {
    this.serviceCredito.criarSemTopicos(obj).subscribe((resp) => {
      this.router.navigate([
        `/creditos/editar/${resp.identificador}`,]);
      this.ngOnInit();
    }, (erro) => {
      Swal.fire(
        'Créditos ',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  vigenciaPessoa(id) {
    this.serviceCredito.obterUltimaVigencia(id).subscribe((resp) => {
      this.vigencia = resp.resultado;
      this.datas = {
        'inicio': moment(resp.resultado.inicio).format('DD/MM/YYYY'),
        'fim': moment(resp.resultado.fim).format('DD/MM/YYYY')
      }

      this.dataInicio = moment(resp.resultado.inicio).format('YYYY-MM-DD')
      this.dataFim = moment(resp.resultado.fim).format('YYYY-MM-DD')
    }, (erro) => {
      Swal.fire(
        'Créditos',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }



  incluirTopico(form) {
    if (form.controls.topico.status == "VALID") {
      let obj = {
        "idPessoaCertificadoCredito": Number(this.idPessoaCertificadoCredito),
        "idAtividadeTopico": Number(this.creditoForm.controls['topico'].value)
      };
      this.serviceCredito.criarTopicosIsolado(obj).subscribe((resp) => {
        Swal.fire(
          'Créditos',
          'Tópico crédito adicionado com sucesso!',
          'success'
        ).then(() => {
          this.ngOnInit();
        });

      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    }

  }
  excluir(topico) {
    this.serviceCredito.excluirTopico(topico).subscribe((resp) => {
      Swal.fire(
        'Créditos',
        'Tópico excluído com sucesso!',
        'success'
      ).then(() => {
        this.ngOnInit();
      });
    }, (erro) => {
      Swal.fire(
        'Créditos',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }
  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        return resolve((e.target as FileReader).result);
      };

      reader.onerror = (e) => {
        return reject(null);
      };

      if (file == null) {
        return resolve(null);
      }

      reader.readAsDataURL(file);
    });
  }

  async salvar() {
    let file;
    if (this.files && this.files.length) {
      file = await this.readFile(this.files[0]);
    }
    if (file || this.nomeArq !== null || this.livre == false) {
      let obj = {
        "idPessoaCertificadoCredito": Number(this.idPessoaCertificadoCredito),
        "observacoes": this.creditoForm.controls['observacao'].value ? this.creditoForm.controls['observacao'].value : null,
        "nomeArquivo": this.nomeArq,
        "arquivo": file ? String(file).replace(/^data:(.*;base64,)?/, '') : this.arquivoEdit
      };

      this.serviceCredito.alterar(obj).subscribe((resp) => {

        Swal.fire(
          'Créditos ',
          'Crédito editado com sucesso!',
          'success'
        ).then(() => {
          this.router.navigate(['/creditos']);
        });

      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    } else {
      Swal.fire(
        'Créditos ',
        'Selecione ao menos um arquivo',
        'info'
      );
    }
  }

  verificaChebox() {
    // tipo livre,
    let ciente = this.creditoForm.controls['ciente'].value;
    let responsabilidade = this.creditoForm.controls['responsabilidade'].value;
    if (this.livre) {
      if (ciente && responsabilidade) {
        this.botaoSalvar = false;
      } else {
        this.botaoSalvar = true;
      }
    } else {
      if (responsabilidade) {
        this.botaoSalvar = false;
      } else {
        this.botaoSalvar = true;
      }
    }
  }

  selecionaAtividadeEditarVisualizar(idAtividade) {
    this.creditoForm.controls['cargaHoraria'].disable();
    this.creditoForm.controls['data'].setValue(moment(this.creditoForm.controls['atividade'].value.data).format('YYYY-MM-DD'));

    if (this.livre) {
      this.carregarTopicosEditar(idAtividade);
      this.serviceCredito.obterTopicos(this.idPessoaCertificadoCredito).subscribe((resp) => {
        this.topicosAdicionados = resp.resultado;
      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    } else {
      this.serviceCredito.obterTopicosAtividade(idAtividade, this.idPessoaCertificadoCredito).subscribe((resp) => {
        this.topicosAdicionados = resp.resultado;
      }, (erro) => {
        Swal.fire(
          'Créditos ',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
    }
    this.creditoForm.controls['cargaHoraria'].setValue(this.creditoForm.controls['atividade'].value.cargaHoraria);
    this.creditoForm.controls['creditos'].setValue(this.creditoForm.controls['atividade'].value.creditos);
  }
  carregarTopicosEditar(idAtividade) {
    this.serviceCredito.obterTopicosAtividade(idAtividade, this.idPessoaCertificadoCredito).subscribe((resp) => {
      this.topicos = resp.resultado;
    }, (erro) => {
      Swal.fire(
        'Créditos ',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }
}

