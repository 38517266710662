import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

type VotacaoPerguntaResposta = {
  resposta: string
  peso: string
  idVotacaoPergunta: number
  idVotacaoPerguntaResposta: number
}

type VotacaoAnexos = {
  idVotacao: number
  idVotacaoAnexos: number
  caminho: string
  tipo: string
}

type VotacaoPerguntas = {
  idVotacaoPergunta: number
  titulo: string
  permitirVotoNulo: boolean
  multiplaEscolha: boolean
  idVotacao: number
  respostas: VotacaoPerguntaResposta[]
}

type Votacao = {
  enviarEmailParaParticipanteComDiasDeAntecedencia: boolean
  enviarEmailParaParticipanteNoFinalDaVotacao: boolean
  filtrarParticipantesDaVotacao: boolean,
  fim: Date
  inicio: Date
  idVotacao: number
  votacaoAnexos: VotacaoAnexos[]
  votacaoPerguntas: VotacaoPerguntas[]
  textoApresentacao: string
  textoFinalizacao: string
}
type VotacaoRespostaSelecionada = {
  idPessoa: number,
  idVotacaoPergunta: number,
}

@Component({
  selector: 'app-votacao',
  templateUrl: './votacao.component.html',
  styleUrls: ['./votacao.component.scss'],
})
export class VotacaoComponent implements OnInit {
  myGroup: FormGroup;
  private userId = parseInt(localStorage.getItem('userId'))

  @ViewChild(MatPaginator) paginator: MatPaginator;
  votacoes: Votacao[];
  currentPage = 0;

  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.carregarVotacoes();
  }

  irParaVotacao(votacao:Votacao) {
    this.router.navigate(['/votacao/questionario/' + votacao.idVotacao]);
  }

  proximaPagina() {
    if (this.votacoes.length > 0) {
      this.currentPage += 1
      this.carregarVotacoes();
    }
  }

  paginaAnterior() {
    if (this.currentPage > 0) {
      this.currentPage -= 1
      this.carregarVotacoes();
    }
  }

  carregarVotacoes(): void {
    fetch(environment.backofficeUrlV2 + `/votacao/listar-somente-nao-respondidas/${this.userId}?page=${this.currentPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {

      if (res.ok) {

        res.json().then(json => {
          console.log(json.resultado)
          this.votacoes = json.resultado
        })
      }
    })
  }

  onPageChange(event): void {
    this.currentPage = event.pageIndex + 1;
    this.carregarVotacoes();
  }
}
