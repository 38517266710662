import { SemanaEnefComponent } from './pages/semana-enef/semana-enef.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/authentication/auth.guard';

import { LoginComponent } from './pages/login/login.component';
import { AutoCadastroComponent } from './pages/auto-cadastro/auto-cadastro.component';
import { PreparacaoComponent } from './pages/preparacao/preparacao.component';
import { InscricaoComponent } from './pages/inscricao/inscricao.component';
import { HomeComponent } from './pages/home/home.component';
import { VotacaoComponent } from './pages/votacao/votacao.component';
import { AlterarSenhaComponent } from './pages/alterar-senha/alterar-senha.component';

import { DebitosComponent } from './pages/debitos/debitos.component';
import { FormaPagamentoComponent } from './pages/forma-pagamento/forma-pagamento.component';
import { AdesaoCodigoEticaComponent } from './pages/adesao-codigo-etica/adesao-codigo-etica.component';
import { ExperienciaSupervisionadaComponent } from './pages/experiencia-supervisionada/experiencia-supervisionada.component';

//SOLICITAÇÃO DE ASSOCIAÇÃO NÃO CERTIFICADO
import { SolicitacaoAssociacaoNaoCertidicadoComponent } from './pages/solicitacao-associacao-nao-certidicado/solicitacao-associacao-nao-certidicado.component';

//EXPERIENCIIA NAO SUPERVISIONADA
import { ExperienciaProfissionalComponent } from './pages/experiencia-profissional/experiencia-profissional.component';

//EDUCAÇÃO
import { EducacaoComponent } from './pages/educacao/educacao.component';

//TRANSFERENCIA CANCELAR INSCRICAO
import { TransferenciaCancelarInscricaoComponent } from './pages/transferencia-cancelar-inscricao/transferencia-cancelar-inscricao.component';

//EXAME
import { ExameComponent } from './pages/exame/exame.component';

//CREDITOS
import { CreditosComponent } from './pages/creditos/creditos.component';
import { CreditoComponent } from './pages/creditos/credito/credito.component';

//DECLARAÇÃO DE PRIVACIDADE
import { DeclaracaoPrivacidadeComponent } from './pages/declaracao-privacidade/declaracao-privacidade.component';

//PERFIL PROFISSIONAL
import { PerfilProfissionalComponent } from './pages/perfil-profissional/perfil-profissional.component';

//SITE BUSCA PLANEJADOR
import { SiteBuscaPlanejadorComponent } from './pages/site-busca-planejador/site-busca-planejador.component';

//LOCAIS
import { LocaisComponent } from './pages/locais/locais.component';
import { LocalComponent } from './pages/locais/local/local.component';

//AGENDAS
import { AgendasComponent } from './pages/agendas/agendas.component';
import { AgendaComponent } from './pages/agendas/agenda/agenda.component';
import { InscricaoConfigComponent } from './pages/inscricao-config/inscricao-config.component';
import { TransferenciaInscricaoComponent } from './pages/transferencia-inscricao/transferencia-inscricao.component';
import { MaterialEstudosComponent } from './pages/material-estudos/material-estudos.component';
import { DocumentosComponent } from './pages/documentos/documentos.component';
import { JustificarAusenciaComponent } from './pages/justificar-ausencia/justificar-ausencia.component';
import { CancelarInscricaoComponent } from './pages/cancelar-inscricao/cancelar-inscricao.component';
import { ModulosComponent } from './pages/modulos/modulos.component';
import { ExameConfigComponent } from './pages/exame-config/exame-config.component';
import { MensagensComponent } from './pages/mensagens/mensagens.component';

import { TutoriaisComponent } from './pages/tutoriais/tutoriais.component';

import { SolicitacaoLicencaComponent } from './pages/solicitacao-licenca/solicitacao-licenca.component';

import { SolicitacaoPlanoFinanceiroComponent } from './pages/solicitacao-plano-financeiro/solicitacao-plano-financeiro.component';

import { RedirecionaComponent} from './pages/redireciona/redireciona.component';

import { RelatorioEvidenciaComponent } from './pages/relatorio-evidencias/relatorio-evidencias.component';

import { AcordoSupervisaoComponent } from './pages/acordo-supervisao/acordo-supervisao.component';

import { ListaSolicitacaoSupervisaoComponent } from './pages/lista-solicitacao-supervisao/lista-solicitacao-supervisao.component';

import { SupervisaoAndamentoComponent } from './pages/supervisao-andamento/supervisao-andamento.component';

import { SolicitacaoSupervisorComponent } from './pages/solicitacao-supervisor/solicitacao-supervisor.component';

import { SupervisorProfissionalComponent } from './pages/supervisor-profissional/supervisor-profissional.component';

import {SelecionarSupervisorComponent} from './pages/selecionar-supervisor/selecionar-supervisor.component';

import {DefinirSupervisorComponent} from './pages/definir-supervisor/definir-supervisor.component';

import { ParecerFinalSupervisorComponent } from './pages/parecer-final-supervisor-experiencia-profissional-supervisionada/parecer-final-supervisor-experiencia-profissional-supervisionada.component';
import { EditarVotacaoComponent } from './pages/editar-votacao/editar-votacao.component';
import { CriarVotacaoComponent } from './pages/criar-votacao/criar-votacao.component';
import { QuestionarioComponent } from './pages/votacao/questionario/questionario.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    data: {
      breadcrumb: 'Login',
    },
    component: LoginComponent,
  },
  {
    path: 'busca-planejador',
    component: SiteBuscaPlanejadorComponent
  },
  {
    //se nao passar parametro a exibicao de trilha de renovacao é implicita
    path: 'home',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Home',
    },
    component: HomeComponent,
  },
  {
    path: 'home/:renovacao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Home',
    },
    component: HomeComponent,
  },
  {
    path: 'home/:renovacao/:sub-trilhas',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Home',
    },
    component: HomeComponent,
  },
  {
    path: 'home/:renovacao/:sub-trilhas/:etapa',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Home',
    },
    component: HomeComponent,
  },
  {
    path: 'home/:renovacao/:sub-trilhas/:etapa/:idPessoaSupervisionada',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Home',
    },
    component: HomeComponent,
  },
  {
    path: 'votacao',
    component: VotacaoComponent,
  },
  {
    path: "votacao/questionario/:votacaoId",
    component: QuestionarioComponent
  },
  {
    path: 'editar-votacao',
    component: EditarVotacaoComponent,
  },
  {
    path: 'criar-votacao',
    component: CriarVotacaoComponent,
  },
  {
    path: 'inscricao-config/:idInscricao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Exame',
    },
    component: InscricaoConfigComponent,
  },
  {
    path: 'material-estudos/:idInscricao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Material de Estudo',
    },
    component: MaterialEstudosComponent,
  },
  {
    path: 'justificar-ausencia/:idInscricao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Envio de atestado',
    },
    component: JustificarAusenciaComponent,
  },
  {
    path: 'justificar-plano-financeiro/:idSolicitacaoPlanoFinanceiro',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Justificativa de Solicitação de Plano Financeiro',
    },
    component: JustificarAusenciaComponent,
  },
  {
    path: 'documentos/:idInscricao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Biblioteca de documentos',
    },
    component: DocumentosComponent,
  },
  {
    path: "CriarVotacao",
    data: {
      breadcrumb: "Criar Votação"
    },
    component: VotacaoComponent
  },
  {
    path: 'auto-cadastro/:editar',
    data: {
      breadcrumb: 'Auto Cadastro',
    },
    component: AutoCadastroComponent,
  },
  {
    path: 'auto-cadastro/:editar/:renovacao',
    data: {
      breadcrumb: 'Auto Cadastro',
    },
    component: AutoCadastroComponent,
  },
  {
    path: 'tutoriais',
    data: {
      breadcrumb: 'Tutoriais',
    },
    component: TutoriaisComponent,
  },
  {
    path: 'preparacao',
    data: {
      breadcrumb: 'Preparação exame',
    },
    component: PreparacaoComponent,
  },
  {
    path: 'inscricao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Inscrição',
    },
    component: InscricaoComponent,
  },
  {
    path: 'alterar-senha',
    component: AlterarSenhaComponent,
  },
  {
    path: 'debitos/forma-pagamento/:id',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Forma de Pagamento',
    },
    component: FormaPagamentoComponent,
  },
  {
    path: 'debitos',
    data: {
      breadcrumb: 'Débitos',
    },
    component: DebitosComponent,
  },
  {
    path: 'declaracao-privacidade',
    data: {
      breadcrumb: 'Declaração de Privacidade',
    },
    component: DeclaracaoPrivacidadeComponent,
  },
  {
    path: 'creditos',
    data: {
      breadcrumb: 'Créditos',
    },
    children: [
      {
        path: '',
        component: CreditosComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'adicionar',
        component: CreditoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Adicionar',
        },
      },
      {
        path: 'visualizar/:id',
        component: CreditoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Visualizar',
        },
      },
      {
        path: 'editar/:id',
        component: CreditoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Editar',
        },
      },

    ],

  },
  {
    path: 'creditos/:renovacao',
    data: {
      breadcrumb: 'Créditos',
    },
    children: [
      {
        path: '',
        component: CreditosComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'adicionar',
        component: CreditoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Adicionar',
        },
      },
      {
        path: 'visualizar/:id',
        component: CreditoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Visualizar',
        },
      },
      {
        path: 'editar/:id',
        component: CreditoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Editar',
        },
      },

    ],

  },
  {
    path: 'locais',
    data: {
      breadcrumb: 'Locais',
    },
    children: [
      {
        path: '',
        component: LocaisComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'adicionar',
        component: LocalComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Adicionar',
        },
      },
      {
        path: 'visualizar/:id',
        component: LocalComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Visualizar',
        },
      },
      {
        path: 'editar/:id',
        component: LocalComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Editar',
        },
      },
    ],
  },
  {
    path: 'agendas/:id',
    data: {
      breadcrumb: 'Agenda',
    },
    children: [
      {
        path: '',
        component: AgendasComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'adicionar',
        component: AgendaComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Adicionar',
        },
      },
      {
        path: 'visualizar/:id',
        component: AgendaComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Visualizar',
        },
      },
      {
        path: 'editar/:id',
        component: AgendaComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Editar',
        },
      },
    ],
  },
  {
    path: 'exame',
    data: {
      breadcrumb: 'Resultado Exame',
    },
    component: ExameComponent,
  },
  {
    path: 'solicitacao-associacao-nao-certificado',
    data: {
      breadcrumb: 'Solicitação de Associação Não Certificado',
    },
    component: SolicitacaoAssociacaoNaoCertidicadoComponent,
  },
  {
        path: 'experiencia-profissional',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Experiência Profissional',
        },
            component: ExperienciaProfissionalComponent,
  },
  {
    path: 'adesao-codigo-etica',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Adesão ao Código de Ética',
    },
    component: AdesaoCodigoEticaComponent,
  },
  {
    path: 'acordo-supervisao/:idPessoa',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Envio de Acordo de Supervisão',
    },
    component: AcordoSupervisaoComponent,
  },
  {
    path: 'acordo-supervisao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Envio de Acordo de Supervisão',
    },
    component: AcordoSupervisaoComponent,
  },
  {
    path: 'transferencia-cancelamento',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Transferir ou Cancelar Inscrição',
    },
    component: TransferenciaCancelarInscricaoComponent,
  },
  {
    path: 'transferencia-inscricao/:idInscricao/:justificativa',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Transferir Inscrição',
    },
    component: TransferenciaInscricaoComponent,
  },
  {
    path: 'transferencia-inscricao/:idInscricao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Transferir Inscrição',
    },
    component: TransferenciaInscricaoComponent,
  },
  {
    path: 'cancelamento-inscricao/:idInscricao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Cancelar Inscrição',
    },
    component: CancelarInscricaoComponent,
  },
  {
    path: 'cancelamento-inscricao/:idInscricao/:justificativa',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Cancelar Inscrição',
    },
    component: CancelarInscricaoComponent,
  },
  {
    path: 'experiencia-supervisionada',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Experiência Supervisionada',
    },
    component: ExperienciaSupervisionadaComponent,
  },
  {
    path: 'modulos-atual',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Módulos do exame atual',
    },
    component: ModulosComponent,
  },
  {
    path: 'exames-config/:filtro/:origin',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Exames',
    },
    component: ExameConfigComponent,
  },
  {
    path: 'educacao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Educação',
    },
    component: EducacaoComponent,
  },
  {
    path: 'semana-enef',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Semana ENEF - inscrição',
    },
    component: SemanaEnefComponent,
  },
  {
    path: 'perfil-profissional',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Perfil Profssional',
    },
    component: PerfilProfissionalComponent,
  },
  {
    path: 'mensagens',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Mensagens',
    },
    component: MensagensComponent,
  },
  {
    path: 'solicitacao-licenca',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Solicitação de Licença',
    },
    component: SolicitacaoLicencaComponent,
  },
  {
    path: 'solicitacao-plano-financeiro',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Solicitação de Plano Financeiro',
    },
    component: SolicitacaoPlanoFinanceiroComponent,
  },
  {
    path: 'acesso-hsol',
    //canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Redireciona',
    },
    component: RedirecionaComponent,
  },
  {

    path: 'relatorio-evidencias/:idPeriodo/:idPessoa',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Relatório + Evidências',
    },
    component: RelatorioEvidenciaComponent,
  },
  {
    path: 'relatorio-evidencias/:idPeriodo',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Relatório + Evidências',
    },
    component: RelatorioEvidenciaComponent,
  },
  {
    path: 'lista-solicitacao-supervisao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Envio de Acordo de Supervisão',
    },
    component: ListaSolicitacaoSupervisaoComponent,
  },
  {
    path: 'supervisao-andamento/:aprovados',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Solicitações em andamento',
    },
    component: SupervisaoAndamentoComponent,
  },
  {
    path: 'solicitacao-supervisor/:idPessoa',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Solicitação de Supervisor',
    },
    component: SolicitacaoSupervisorComponent,
  },
  {
    path: 'solicitacao-supervisor/:habilitacao',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Solicitação de Supervisor',
    },
    component: SolicitacaoSupervisorComponent,
  },
  {
    path: 'supervisor-profissional',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Supervisor Prossional',
    },
    component: SupervisorProfissionalComponent,
  },
  {
    path: 'selecionar-supervisor/:idPessoa',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Selecionar Supervisor',
    },
    component: SelecionarSupervisorComponent,
  },
  {
    path: 'definir-supervisor/:idPessoaSupervisor',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Definir Supervisor',
    },
    component: DefinirSupervisorComponent,
  },
  {
    path: 'acesso-hsol',
    //canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Redireciona',
    },
    component: RedirecionaComponent,
  },
  {
    path: 'parecer-final-supervisor/:idPessoa',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Parecer Final Supervisor',
    },
    component: ParecerFinalSupervisorComponent,
  },
  {
    path: 'parecer-final-supervisor',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Parecer Final Supervisor',
    },
    component: ParecerFinalSupervisorComponent,
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
