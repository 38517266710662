import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TransferenciaCancelamentoService } from 'src/app/services/transferencia-cancelamento/transferencia-cancelamento.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as moment from 'moment';

@Component({
  selector: 'transferencia-inscricao',
  templateUrl: './transferencia-inscricao.component.html',
  styleUrls: ['./transferencia-inscricao.component.scss'],
})
export class TransferenciaInscricaoComponent implements OnInit {
  files: File[] = [];
  view = false;
  imagem = null;
  obj = [];
  chave: any;
  tipoTransferencias = [];
  tranferenciaForm: FormGroup;
  isSubmitted: boolean;
  id: any;
  justificativaObjeto = [];
  idInscricao: any;
  isJustificativa: boolean = false;
  inscricaoSalva: any = null;
  incricaoSelecionada = [];
  public emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  config: any;
  info = [];
  inscricoes = [];
  public datas = [];
  public paras = [];
  isChecked: boolean;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private tranferenciaService: TransferenciaCancelamentoService,
    private route: ActivatedRoute
  ) {
    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '200px',
      toolbar: [],
      lang: 'pt-BR',
      fontNames: [
        'Helvetica',
        'Arial',
        'Arial Black',
        'Comic Sans MS',
        'Courier New',
        'Roboto',
        'Times',
      ],
    };
    this.id = JSON.parse(localStorage.getItem('userId'));
  }

  ngOnInit(): void {
    this.tranferenciaForm = this.formBuilder.group({
      numero: ['', Validators.required],
      para: ['', Validators.required],
    });
    this.isChecked = true;
    this.route.paramMap.subscribe((params) => {
      if (params.get('idInscricao')) {
        this.idInscricao = params.get('idInscricao');
      }
      if (params.get('justificativa')) {
        this.isJustificativa = true;
      }
    });

    this.carregaInscricaoSelecionada();
  }

  init() {
    this.route.paramMap.subscribe((params) => {
      if (params.get('idInscricao')) {
        this.idInscricao = params.get('idInscricao');
      }
    });

    this.carregaInscricaoSelecionada();
  }

  submit(value) {
    this.isSubmitted = true;
    console.log(value);
    if (value.status == 'VALID') {
      let obj = {
        idInscricao: Number(this.idInscricao),
        idExameCidade: Number(this.tranferenciaForm.controls['numero'].value),
        idExameCidadeAnterior: Number(this.inscricaoSalva.idExameCidade),
        justificativa: null,
        nomeArquivo: null,
        arquivo: null,
      };

      //verifica se pode cancelar a inscricao
      this.tranferenciaService.verificarInscricao(obj).subscribe(
        (resp) => {
          if (resp.resultado.limiteTransferenciaExcedido) {
            Swal.fire(
              'Transferir Inscrição',
              'Não é permitido transferir a inscrição selecionada, limite excedido. Conforme Edital do Exame é possível realizar somente uma transferência por inscrição.',
              'success'
            ).then(() => {
              this.router.navigate(['/home']);
              return;
            });
          } else {
            this.tranferenciaService.transferirIncricao(obj).subscribe(
              () => {
                Swal.fire(
                  'Transferir Inscrição',
                  'Inscrição Transferida com sucesso!',
                  'success'
                ).then(() => {
                  this.router.navigate(['/home']);
                });
              },
              (error) => {
                Swal.fire(
                  'Transferir Inscrição',
                  error.error.excecao[0].mensagem,
                  'error'
                );
              }
            );
          }
        },
        (error) => {
          Swal.fire(
            'Transferir Inscrição',
            error.error.excecao[0].mensagem,
            'error'
          );
        }
      );
    } else {
      Swal.fire('Transferir Inscrição', 'Selecione uma opção', 'error');
    }
  }

  carregaParas(inscricaoSalva) {
    if (inscricaoSalva.exameRealizado && !this.isJustificativa) {
      Swal.fire({
        title: 'Transferir ou Cancelar Inscrição',
        text: "É necessário apresentar uma justificativa para transferir está inscrição, para isso vá no menu 'enviar atestado' da tela anterior",
        icon: 'warning',
      });
    } else {
      this.tranferenciaService.obterCidadeExame(this.idInscricao).subscribe(
        (res) => {
          console.log(res.resultado);
          this.paras = res.resultado;
        },
        (error) => {
          Swal.fire(
            'Transferir ou Cancelar Inscrição',
            error.error.excecao[0].mensagem,
            'error'
          );
        }
      );
    }
  }

  carregaInscricaoSelecionada() {
    if (this.isJustificativa) {
      this.tranferenciaService.obterInscricoesJustificativa(this.id).subscribe(
        (res) => {
          const inscricaoSelecionada = res.resultado.find(
            (item) => item.idInscricao == this.idInscricao
          );
          inscricaoSelecionada.descricaoInscricao =
            inscricaoSelecionada.numeroExame +
            '° - ' +
            inscricaoSelecionada.descricaoCidade +
            ' - ' +
            moment(inscricaoSelecionada.dataExame).format('DD/MM/YYYY');
          this.inscricaoSalva = inscricaoSelecionada;
          this.carregaParas(this.inscricaoSalva);
        },
        (error) => {
          Swal.fire(
            'Transferir Inscrição',
            error.error.excecao[0].mensagem,
            'error'
          );
        }
      );
    } else {
      this.tranferenciaService.obterInscricoes(this.id).subscribe(
        (res) => {
          const inscricaoSelecionada = res.resultado.find(
            (item) => item.idInscricao == this.idInscricao
          );
          inscricaoSelecionada.descricaoInscricao =
            inscricaoSelecionada.numeroExame +
            '° - ' +
            inscricaoSelecionada.descricaoCidade +
            ' - ' +
            moment(inscricaoSelecionada.dataExame).format('DD/MM/YYYY');
          this.inscricaoSalva = inscricaoSelecionada;
          this.carregaParas(this.inscricaoSalva);
        },
        (error) => {
          Swal.fire(
            'Transferir Inscrição',
            error.error.excecao[0].mensagem,
            'error'
          );
        }
      );
    }
  }

  selecionePara() {
    this.tranferenciaService
      .obterDataExameCidade(this.tranferenciaForm.controls['para'].value)
      .subscribe(
        (res) => {
          this.datas = res.resultado;
        },
        (error) => {
          Swal.fire(
            'Transferir ou Cancelar Inscrição',
            error.error.excecao[0].mensagem,
            'error'
          );
        }
      );
  }

  get numero() {
    return this.tranferenciaForm.get('numero');
  }
  get para() {
    return this.tranferenciaForm.get('para');
  }

  onRemove() {
    this.obj = [];
    this.files.splice(0, 1);
    if (this.imagem !== '') {
      this.imagem = '';
    }
  }
  onSelect(event) {
    this.obj = [];
    this.onRemove();
    this.obj = event.source._previewChildren;
    this.files.push(...event.addedFiles);
  }
}
