import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  usuarioAutenticado = false;
  title = 'front-arealogada';
  currentUrl = '';
  private unsubscribe = new Subject<void>();

  constructor(private router: Router,
    private authenticationService: AuthenticationService
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url.replace('/', '');
      }
    });
  }

  ngOnInit(): void {
    if(localStorage.getItem('user') && !window.location.href.includes('busca-planejador')){
      this.router.navigate(['/home']);
    }

    this.authenticationService
    .loginAnonimo()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      () => {}
    );
  }
}
