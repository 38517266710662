import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MensagemService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }



  naoLidasCount(idPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-mensagem-interna/total-nao-lidas`,
      {
        headers: this.httpHeaders,
        params: { idPessoa },
      }
    );
  }

  obterPorPessoa(idPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-mensagem-interna/mensagem-pessoa`,
      {
        headers: this.httpHeaders,
        params: { idPessoa }
      }
    );
  }

  lerMensagem(idMensagem: any, mensagemGeral: any) {
    let codUsuario = localStorage.getItem('userId');
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-mensagem-interna/marcar-lida?idMensagem=${idMensagem}&mensagemGeral=${mensagemGeral}&idPessoa=${codUsuario}`, null,
      {
        headers: this.httpHeaders,
      }
    );
  }

}
