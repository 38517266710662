<fieldset class="border p-3 my-3">
  <legend class="legendFormulario">Solicitação de associação não certificado </legend>
<div class="row d-flex justify-content-center">
  <div class="dropMobile text-right align-items-center col-md-4 col-sm-4"
      [ngClass]="{ 'mt-4': view }">
        <div class="text-right align-items-center dropCustom col-sm-12 mt-3">
          <div class="w-100">
            <div [accept]="'image/jpg, image/png, application/pdf, application/vnd.ms-word,.zip, .rar, .doc, .docx, .jpg, .jpeg'"
                 (change)="onSelect($event)"
                 class="custom-dropzone col-sm-12"
                 #drop
                 [disabled]="view"
                 [maxFileSize]="2097152"
                 [multiple]="false"
                 ngx-dropzone>
              <ngx-dropzone-label>
                <div>
                  <img class="img-adicionada"
                       [src]="imagem" />
                  <p *ngIf="(!view && imagem == null) || imagem == ''">
                    Arraste o seu arquivo ou clique aqui para selecionar
                  </p>
                </div>
              </ngx-dropzone-label>
              <ngx-dropzone-image-preview class="previa-img"
                                          *ngFor="let f of files"
                                          [file]="f"
                                          ngProjectAs="ngx-dropzone-preview"
                                          [removable]="true">
              </ngx-dropzone-image-preview>
            </div>
            <div class="text-center">
              <button class="btn btn-sm btn-danger mt-1 btnRemove"
                      (click)="onRemove()"
                      id="remove"
                      [disabled]="view"
                      *ngIf="files.length > 0"
                      title="Remover Imagem">
                Remover Arquivo
              </button>
            </div>
            <p class="mt-1 text-primary text-center col-md-12 col-sm-12"
               *ngIf="
                (files.length === 0 && imagem == '') ||
                (imagem == null && !view)
              ">
              Formatos do arquivo PNG, JPG, JPEG, DOC, DOCX e ZIP com tamanho máximo de 2MB.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <div class="wrapper2">
          <button class="btn btn-sm btn-primary mr-0 ml-2"
                  (click)="enviar()"
                  id="salvar"
                  type="button">
            Enviar
          </button>
        </div>
      </div>
    </div>
</fieldset>
