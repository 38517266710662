<div id="top-nav" class="dark-div nav-style-1">
  <nav class="navbar navbar-inverse main-color-1-bg mb-0" role="navigation">
    <div class="container">
      <div class="top-menu">
        <button type="button" class="mobile-menu-toggle visible-xs">
          <span class="sr-only">Menu</span>
          <i class="fa fa-bars"></i>
        </button>
      </div>
    </div>
  </nav>
</div>
