<form name="experienciaProfissionalForm"
      id="experienciaProfissionalForm"
      class="mt-3"
      [formGroup]="experienciaProfissionalForm"
      (ngSubmit)="submit(experienciaProfissionalForm, isInscricao)">
  <fieldset class="border p-3 my-3">
    <legend class="legendFormulario">Experiência Profissional</legend>
    <div class="row">
      <div class="col-md-12 mb-4 mt-4">
        <label>Incluir apenas experiências que forem relacionadas diretamente com pessoa física.</label>
      </div>
      <div class="form-group col-md-8">
        <label class="texto--sm"
                for="areaSetorAtuaca o">Segmento de atuação atual</label>
        <select class="form-control form-control-sm custom-select custom-select-sm"
                (change)="verificaoOutraSetor()"
                formControlName="areaSetorAtuacao"
                [class.is-invalid]="
              areaSetorAtuacao.invalid && (areaSetorAtuacao.touched || isSubmitted)"
                id="areaSetorAtuacao"
                name="areaSetorAtuacao">
          <option value=""
                  disabled>Selecione</option>
          <option *ngFor="let areaSetorAtuacao of areaSetorAtuacaos"
                  value="{{areaSetorAtuacao.idAreaAtuacao}}">
            {{ areaSetorAtuacao.descricao }}
          </option>
          <option value="outro">Outro</option>
        </select>
        <div *ngIf="areaSetorAtuacao.invalid && (areaSetorAtuacao.touched || isSubmitted)">
          <span class="erro"
                *ngIf="areaSetorAtuacao.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="form-group col-md-4">
        <label class="texto--sm"
                for="outroAreaSetorAtuacao"></label>
        <input class="form-control form-control-sm"
                [class.is-invalid]="
              outroAreaSetorAtuacao.invalid && (outroAreaSetorAtuacao.touched || isSubmitted)"
                formControlName="outroAreaSetorAtuacao"
                id="outroAreaSetorAtuacao"
                placeholder="Outra"
                name="outroAreaSetorAtuacao"
                type="text" />
        <div *ngIf="outroAreaSetorAtuacao.invalid && (outroAreaSetorAtuacao.touched || isSubmitted)">
          <span class="erro"
                *ngIf="outroAreaSetorAtuacao.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="form-group col-md-8">
        <label class="texto--sm"
              for="empresa">Empresa</label>
        <select class="form-control form-control-sm custom-select custom-select-sm"
              formControlName="empresa"
              (change)="verificaoOutraEmpresa()"
              [class.is-invalid]="
            empresa.invalid && (empresa.touched || isSubmitted)"
              id="empresa"
              name="empresa">
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let empresa of empresas"
                value="{{empresa.idEmpresa}}">
          {{ empresa.nome }}
        </option>
        <option value="outro">Outro</option>
      </select>
      <div *ngIf="empresa.invalid && (empresa.touched || isSubmitted)">
        <span class="erro"
              *ngIf="empresa.errors.required">Campo obrigatório</span>
      </div>
      </div>
      <div class="form-group col-md-4">
      <label class="texto--sm"
              for="outroEmpresa"></label>
      <input class="form-control form-control-sm"
              [class.is-invalid]="
            outroEmpresa.invalid && (outroEmpresa.touched || isSubmitted)"
              formControlName="outroEmpresa"
              id="outroEmpresa"
              placeholder="Outra"
              name="outroEmpresa"
              type="text" />
      <div *ngIf="outroEmpresa.invalid && (outroEmpresa.touched || isSubmitted)">
        <span class="erro"
              *ngIf="outroEmpresa.errors.required">Campo obrigatório</span>
      </div>
      </div>
      <div class="form-group col-md-8">
        <label class="texto--sm"
              for="empresaOndeTrabalha">A empresa onde você trabalha é</label><br/>
        <input type="radio" id="outraEmpresaIndependente" name="outraEmpresaOpcao" value="1" checked (click)="desativarPlataforma()"/> Independente<br/>
        <input type="radio" id="outraEmpresaVinculada" name="outraEmpresaOpcao" value="0" (click)="ativarPlataforma()"/> Vinculada à plataforma      
        <select class="form-control form-control-sm custom-select custom-select-sm"
              formControlName="empresaOndeTrabalha"
              (change)="verificaoOutraEmpresaOndeTrabalha()"
              [class.is-invalid]="
              plataformaIndependente==false && empresaOndeTrabalha.invalid && (empresaOndeTrabalha.touched || isSubmitted)"              
              id="empresaOndeTrabalha"
              name="empresaOndeTrabalha"
              disabled>
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let plataforma of plataformas"
                value="{{plataforma.idPlataforma}}">
          {{ plataforma.descricao }}
        </option>
        <option value="outro">Outro</option>
      </select>
      <div *ngIf="plataformaIndependente==false && empresaOndeTrabalha.invalid && (empresaOndeTrabalha.touched || isSubmitted)">
        <span class="erro"
              *ngIf="empresaOndeTrabalha.errors.required">Campo obrigatório</span>
      </div>      
      </div>
      <div class="form-group col-md-4">
      <label class="texto--sm"
              for="outroEmpresaOndeTrabalha"></label><br/><br/><br/>
      <input class="form-control form-control-sm"
              [class.is-invalid]="
              plataformaIndependente==false && outroEmpresaOndeTrabalha.invalid && (outroEmpresaOndeTrabalha.touched || isSubmitted)"
              formControlName="outroEmpresaOndeTrabalha"
              id="outroEmpresaOndeTrabalha"
              placeholder="Outra"
              name="outroEmpresaOndeTrabalha"
              type="text" />
      <div *ngIf="plataformaIndependente==false && outroEmpresaOndeTrabalha.invalid && (outroEmpresaOndeTrabalha.touched || isSubmitted)">
        <span class="erro"
              *ngIf="outroEmpresaOndeTrabalha.errors.required">Campo obrigatório</span>
      </div>
      </div>      
    </div>    
    <div class="row">
      <div class="form-group col-md-8">
        <label class="texto--sm"
               for="cargo">Cargos</label>
        <select class="form-control form-control-sm custom-select custom-select-sm"
                (change)="verificaoOutraCargo()"
                formControlName="cargo"
                [class.is-invalid]="
              cargo.invalid && (cargo.touched || isSubmitted)"
                id="cargo"
                name="cargo">
          <option value=""
                  disabled>Selecione</option>
          <option *ngFor="let cargo of cargos"
                  value="{{cargo.idCargo}}">
            {{ cargo.descricao }}
          </option>
          <option value="outro">Outro</option>
        </select>
        <div *ngIf="cargo.invalid && (cargo.touched || isSubmitted)">
          <span class="erro"
                *ngIf="cargo.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="form-group col-md-4">
        <label class="texto--sm"
               for="outroCargo"></label>
        <input class="form-control form-control-sm"
               [class.is-invalid]="
             outroCargo.invalid && (outroCargo.touched || isSubmitted)"
               formControlName="outroCargo"
               id="outroCargo"
               placeholder="Outra"
               name="outroCargo"
               type="text" />
        <div *ngIf="outroCargo.invalid && (outroCargo.touched || isSubmitted)">
          <span class="erro"
                *ngIf="outroCargo.errors.required">Campo obrigatório</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label> Período </label>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-3">
        <label class="texto--sm"
               for="de">De</label>
        <input class="form-control form-control-sm"
               [class.is-invalid]="
          de.invalid && (de.touched || isSubmitted)
        "
               (change)="setaDataMinima()"
               formControlName="de"
               id="de"
               name="de"
               type="date"
               min="1900-02-01"
               [max]="dataAtual" />
        <div *ngIf="
          de.invalid && (de.touched || isSubmitted)
        ">
          <span class="erro"
                *ngIf="de.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="form-group col-md-3">
        <label class="texto--sm"
               for="ate">Até</label>
        <input class="form-control form-control-sm"
               [class.is-invalid]="ate.invalid && (ate.touched || isSubmitted)"
               formControlName="ate"
               id="ate"
               type="date"
               [min]="dataAtualMinima"
               [max]="dataAtual" />
        <div *ngIf="
          ate.invalid && (ate.touched || isSubmitted)
        ">
          <span class="erro"
                *ngIf="ate.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="col-md-3 check-centralizado">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input"
                 type="checkbox"
                 id="estagio"
                 name="estagio"
                 [(ngModel)]="estagio"
                 [ngModelOptions]="{standalone: true}">
          <label class="ml-2">Estágio</label>
        </div>
      </div>

      <section *ngIf="!isInscricao">
        <div class="row">
          <div class="col-md-12 check-centralizado">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input"
                    type="checkbox"
                    id="declaracao"
                    name="declaracao"
                    [(ngModel)]="declaracao"
                    [ngModelOptions]="{standalone: true}">
              <label class="ckeck-texto-label">Declaro que atuo ou atuei, diretamente, com clientes pessoas físicas, na
                atividade de planejamento financeiro pessoal, além de relatar minha experiência especificadas em uma ou
                mais áreas compreendidas pela Certificação CFP:
                planejamento e gestão financeira; investimentos; seguros; planejamento de aposentadoria; planejamento
                fiscal e planejamento sucessório.
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12 col-sm-12">
            <label>Descreva detalhadamente as responsabilidades e atividades no atendimento ao cliente pessoa física desta experiência profissional, atuando em uma ou 
              mais áreas do escopo da certificação. Essas informações serão extremamente importantes para comprovação desta experiência.</label>
            <div id="summer"
                name="summer"
                [ngxSummernote]="config"
                [ngxSummernoteDisabled]="false"
                formControlName="descricaoResponsabilidades">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>Documentos aceitos para comprovação da experiência </label>
            <div class="tooltipXp">
              <span class="iconXP">?</span>
              <span class="tooltiptext">
                • Carteira de Trabalho (física)- páginas onde constam a foto, dados pessoais e o registro (data de entrada/saída);<br/>
                • Carteira de Trabalho (digital) - dados pessoais e contratos de trabalho (data de entrada/saída);<br/>
                • Carta do empregador em papel timbrado e assinado pelo responsável da mesma;<br/>
                • Contrato Social da Empresa quando proprietário ou sócio (alteração de entrada e saída da sociedade) assinado e registrado em cartório;<br/>
                • Contrato de prestação de serviço com clientes assinado e registrado em cartório. Para este tipo de comprovação será necessário complementar com o envio de 
                notas fiscais emitidas e/ou documentação com evidências das atividades realizadas no período (exemplo: relatórios, declarações, etc.)
              </span>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="dropMobile text-right align-items-center col-md-4 col-sm-4"
              [ngClass]="{ 'mt-4': view }">
            <div class="text-right align-items-center dropCustom col-sm-12 mt-3">
              <div class="w-100">
                <div [accept]="'image/jpg, image/png, application/pdf, application/vnd.ms-word,.zip, .rar, .doc, .docx, .jpg, .jpeg'"
                    (change)="onSelect($event)"
                    class="custom-dropzone col-sm-12"
                    #drop
                    [disabled]="view"
                    [maxFileSize]="4094304"
                    [multiple]="false"
                    ngx-dropzone>
                  <ngx-dropzone-label>
                    <div>
                      <img class="img-adicionada"
                          [src]="imagem" />
                      <p *ngIf="(!view && imagem == null) || imagem == ''">
                        Arraste o seu arquivo ou clique aqui para selecionar
                      </p>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-image-preview class="previa-img"
                                              *ngFor="let f of files"
                                              [file]="f"
                                              ngProjectAs="ngx-dropzone-preview"
                                              [removable]="true">
                  </ngx-dropzone-image-preview>
                </div>
                <div class="text-center">
                  <button class="btn btn-sm btn-danger mt-1 btnRemove"
                          (click)="onRemove()"
                          id="remove"
                          [disabled]="view"
                          *ngIf="files.length > 0 || imagem "
                          title="Remover Imagem">
                    Remover Arquivo
                  </button>
                </div>
                <p class="mt-1 text-primary text-center col-md-12 col-sm-12"
                  *ngIf="
                      (files.length === 0 && imagem == '') ||
                      (imagem == null && !view)
                    ">
                  Formatos do arquivo PNG, JPG, JPEG, DOC, DOCX e ZIP com tamanho máximo de 4MB.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="row mt-4"
           *ngIf="!edit">
        <div align="center">
          <button class="btn btn-sm btn-primary ml-2"
            type="submit"
            [disabled]="(!declaracao && !possuiCertificado) && !isInscricao"
            id="salvarInscricaoProfissao"
            [ngStyle]="{'visibility': isInscricao ? 'hidden' : 'visible'}"
          >
            Incluir
          </button>

          <button class="btn btn-sm btn-primary ml-2"
            type="button"
            *ngIf="isInscricao && experiencias.length > 0"
            (click)="submit(experienciaProfissionalForm, false)"
            [disabled]="(!declaracao && !possuiCertificado) && !isInscricao"
            id="incluirInscricaoProfissao"
          >
            Incluir
          </button>
        </div>
      </div>
      <div class="row mt-4"
           *ngIf="edit">
        <div align="center">
          <button (click)="cancelar()"
                  class="btn btn-sm btn-secondary ml-2"
                  type="button">
            Cancelar
          </button>
          <button [disabled]="!declaracao"
                  class="btn btn-sm btn-primary ml-2"
                  type="submit">
            Editar
          </button>

        </div>
      </div>
    </div>
  </fieldset>
</form>
<span *ngIf ="isInscricao">*Caso já possua dados profissionais cadastrados pode prosseguir</span>
<div class="row mt-4 empresa-inclusa"
     *ngFor="let experiencia of experiencias">
  <div class="col-md-12 mt-4">
    <div class="row">
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-12">
            <label>Empresa: </label><span> {{experiencia.nomeEmpresa}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <Label>Área de atuação: </Label><span> {{experiencia.descricaoAreaAtuacao}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Plataforma: </label><span> {{experiencia.descricaoPlataforma}}</span>
          </div>
        </div>        
        <div class="row">
          <div class="col-md-12">
            <label>Cargo: </label><span> {{experiencia.descricaoCargo}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Situação: </label><span [ngStyle]="{'color': (experiencia.descricaoTrilhaItemSituacao == 'Aprovado') ? '#78C000' : (experiencia.descricaoTrilhaItemSituacao == 'Análise Pendente') ? '#dbcb25' : 'red'}" style="font-weight: bolder;"> {{experiencia.descricaoTrilhaItemSituacao}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Motivo: </label><span> {{experiencia.justificativaReprovacao}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-12">
            <label>Período: </label><span> 
              {{experiencia.inicio | date: 'dd/MM/yyyy'}} - {{experiencia.fim}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>{{experiencia.nomeArquivo}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Responsabilidades, atividades e outras informações: </label>
            <p class="p-caracters"> {{experiencia.complemento}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="experiencia.habilitaEditarExcluir" class="col-md-2">
        <div align="center">
          <button class="btn-sm btn-primary"
                  type="button"
                  *ngIf ="!isInscricao"
                  (click)="editar(experiencia)">
            Editar
          </button>
        </div>
        <div align="center"
             class="mt-1">
          <button class="btn-sm btn-secundary btn-excluir excluir"
                  type="button"
                  *ngIf ="!isInscricao"
                  (click)="excluir(experiencia)">
            Excluir
          </button>
        </div>
      </div>
    </div>
  </div>
</div>