<form class="mt-3"
      [formGroup]="declaracaoPrivacidadeForm"
      id="declaracaoPrivacidadeForm"
      name="declaracaoPrivacidadeForm"
      (ngSubmit)="submit(declaracaoPrivacidadeForm)">

      <fieldset class="border p-3 my-3">
        <legend class="legendFormulario">Declaração de Privacidade </legend>

  <div class="row">
    <div class="form-group col-md-12 col-sm-12">
      <label>{{itens['descricaoTipo']}}</label>
      <div formControlName="codigoEtica"
           id="summer"
           name="summer"
           [ngxSummernote]="config"
           [ngxSummernoteDisabled]="true">
      </div>
    </div>
  </div>
  <!-- BOTÕES -->
  <div class="row">
    <div class="col-md-12 text-right mt-4">
      <button class="btn btn-sm btn-primary ml-2"
              (click)="voltar()"
              id="voltar"
              title="Voltar"
              type="button">
        Voltar
      </button>
    </div>
  </div>
  </fieldset>
</form>