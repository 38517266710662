
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BuscaPlanejadorService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    
  }

  obterEspecialidades() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/site-pesquisa-profissional/obter-especialidades`
    );
  }

  obterUf() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/site-pesquisa-profissional/obter-estados`
    );
  }

  obterCidades(codigoEstado: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/site-pesquisa-profissional/obter-cidades`,
      {
        params: { codigoEstado }
      }
    );
  }
  

  obterPorNome(nome: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/site-pesquisa-profissional/obter-por-nome`,
      {
        params: { nome }
      }
    );
  }

  obterPorFiltros(codigoAreaAtuacaoConhecimento: any, formaAtendimento: any, codigoEstado: any, codigoCidade: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/site-pesquisa-profissional/obter-por-filtros`,
      {
        params: { 
          codigoAreaAtuacaoConhecimento: codigoAreaAtuacaoConhecimento,
          formaAtendimento: formaAtendimento,
          codigoEstado: codigoEstado,
          codigoCidade: codigoCidade
        }
      }
    );
  }

  obterProfissional(codigoPessoa) {
    return this.http.get<any>(
        `${environment.backofficeUrl}/site-pesquisa-profissional/obter-dados-pessoa`,
        {
          params: { codigoPessoa }
        }
    );  
  }

  obterIdiomasPorPessoa(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/site-pesquisa-profissional/obter-idiomas-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
}


}
