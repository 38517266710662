<form name="tranferenciaForm"
      id="tranferenciaForm"
      class="mt-3"
      [formGroup]="tranferenciaForm"
      (ngSubmit)="submit(tranferenciaForm)">
  <fieldset class="border p-3 my-3">
    <legend class="legendFormulario">Transferir inscrição: <b>{{inscricaoSalva.descricaoInscricao}}</b></legend>
    <div class="row mt-3">
      <div>
        <div class="col-md-6">
          <label>Para:</label>
          <select class="form-control"
                  id="para"
                  name="para"
                  (change)="selecionePara()"
                  [class.is-invalid]="para.invalid && (para.touched || isSubmitted)"
                  formControlName="para">
            <option value=""
                    disabled>Selecione</option>
            <option *ngFor="let para of paras;"
                    [value]="para.idCidade">{{ para.descricaoCidade }}
            </option>
          </select>
          <div *ngIf="para.invalid && (para.touched || isSubmitted)">
            <span class="erro"
                  *ngIf="para.errors.required">Campo obrigatório</span>
          </div>
        </div>

        <div class="col-md-6">
          <label>Data - Numero Exame</label>
          <select class="form-control"
                  id="numero"
                  name="numero"
                  [class.is-invalid]="numero.invalid && (numero.touched || isSubmitted)"
                  formControlName="numero">
            <option value=""
                    disabled>Selecione</option>
            <option *ngFor="let numero of datas;"
                    [value]="numero.idExameCidade">{{ numero.dataNumero }}
            </option>
          </select>
          <div *ngIf="numero.invalid &&(numero.touched || isSubmitted)">
            <span class="erro"
                  *ngIf="numero.errors.required">Campo obrigatório</span>
          </div>
        </div>
      </div>

    </div>
    <div class="row mt-3">
      <div class="col-12 text-right mr-4">
        <div>
          <button class="btn btn-sm btn-primary btn-secondary ml-2"
                  type="button"
                  routerLink="/inscricao-config/{{idInscricao}}">
            Cancelar
          </button>
          <button class="btn btn-sm btn-primary ml-2"
                  type="submit">
            Confirmar
          </button>

        </div>
      </div>
    </div>
  </fieldset>
</form>