import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExameService } from 'src/app/services/exame/exame.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.scss']
})
export class ModulosComponent implements OnInit {
  files: File[] = [];
  view = false;
  imagem = null;
  usuario = null;
  exames = null;
  inscricaoModel = 1;

  constructor(private router: Router,
    private route: ActivatedRoute, private exameService: ExameService) { }

  ngOnInit(): void {
    this.usuario = window.localStorage.getItem('userId');
    this.carregaGrid();
  }

  getStyles(situacao: any) {

    if (situacao == 'Aprovado') {
      return {
        backgroundColor: '#a5cd39',
      }
    }

    if (situacao == 'Reprovado') {
      return {
        backgroundColor: '#ad3030',
      }
    }

    return {
      backgroundColor: '#daab00',
    }
  }

  carregaGrid() {
    const modulosNome = [
      'Novo Mód. I - Planejamento Financeiro e Ética',
      'Novo Mód. II - Gestão de Ativos',
      'Novo Mód. III - Planejamento de Aposentadoria',
      'Novo Mód. IV - Gestão de Riscos e Seguros',
      'Novo Mód. V - Planejamento Fiscal',
      'Novo Mód. VI - Planejamento Sucessório'
    ];

    this.exameService.obterExame(this.usuario, 3).subscribe((result) => {
      const examesResultado = result.resultado;
      this.exameService.obterExame(this.usuario, 0).subscribe((result) => {
        //if (result.resultado[0]) examesResultado.push(...result.resultado);
        this.exames = examesResultado.filter(e => e.descricaoSituacao == 'Resultado Pendente' || e.descricaoSituacao == 'Aprovado');

        for (let nome of modulosNome) {
          const examesPorModulo = this.exames.filter(e => e.descricaoModulo.includes(nome));
          if (examesPorModulo.length <= 0) {
            this.exames.push({descricaoModulo: nome, descricaoSituacao: 'Pendente inscrição', numeroExame: null});
          }
        }
      });
    });
  }

  voltar() {
    this.router.navigate(['exame'])
  }

}
