import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-expectativa',
  templateUrl: './expectativa.component.html',
  styleUrls: ['./expectativa.component.scss']
})
export class ExpectativaComponent implements OnInit {
  @Input() isSubmitted: boolean;
  @Output() expectativas = new EventEmitter();

  selecionados = [];
  respostas = [];
  passo1Itens: any;
  constructor(
    private serviceExpectativa: AreaLogadaInscricaoService
  ) {
    this.passo1Itens = JSON.parse(localStorage.getItem('passo1'));
  }

  ngOnInit(): void {
    if (this.passo1Itens) {
      this.respostas = this.passo1Itens;
      this.selecionado();
    }
    else {
      this.serviceExpectativa
        .obterExpectativasComTipo()
        .subscribe((result) => {
          this.respostas = result.resultado;
        }, (erro) => {
          Swal.fire(
            'Espectativas',
            erro.error.excecao[0].mensagem,
            'error'
          );
        });

    }
  }

  selecionado() {
    this.expectativas
      .emit(this.respostas.filter((item) => {
        if (item.checked) {
          return item;
        }
        else {
          return item;
        }
      }));
  }

}

