import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { DebitosService } from 'src/app/services/debitos/debitos.service';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-parecer-final-supervisor-experiencia-profissional-supervisionada',
  templateUrl: './parecer-final-supervisor-experiencia-profissional-supervisionada.component.html',
  styleUrls: ['./parecer-final-supervisor-experiencia-profissional-supervisionada.component.scss']
})
export class ParecerFinalSupervisorComponent implements OnInit {
  //view = false;
  //imagem = null;
  id = null;
  //solicitacoes = null;
  habilitado: boolean = false;
  parecerFinalForm: FormGroup;
  isSubmitted: boolean;
  parecerFinalDescricao: any;
  idPessoa: any;
  isDesabilitarEdicao: boolean = false;
 @Output() validForm = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private experienciaSupervisionadaService: ExperienciaSupervisionadaService) {

      this.parecerFinalForm = this.formBuilder.group({
        parecerFinal: ['', Validators.required],
        habilitadoRadio: [null, Validators.required],

      });

     }

  ngOnInit(): void {
    this.id = window.localStorage.getItem('userId');

    this.route.paramMap.subscribe((params) => {
      if (params.get('idPessoa') !== null){
        this.idPessoa = Number(params.get('idPessoa'));

        this.verificarSupervisionadoEValido(this.idPessoa);

        this.obterParecer(this.idPessoa);
      }
      else{
        this.obterParecer(this.id);
      }
    });
  }

   obterParecer(idPessoa) {
     this.experienciaSupervisionadaService.obterParecerFinalSupervisor(idPessoa).subscribe((result) => {

      this.parecerFinalForm.controls.habilitadoRadio.setValue(result.resultado.statusPareceFinalSupervisor);
      this.parecerFinalForm.controls.parecerFinal.setValue(result.resultado.comentarioPareceFinalSupervisor);
      this.isDesabilitarEdicao = result.resultado.bloquearEdicao;
     });
   }

  get habilitadoRadio() {
    return this.parecerFinalForm.get('habilitadoRadio');
  }
  get parecerFinal() {
    return this.parecerFinalForm.get('parecerFinal');
  }
  mudaHabilitado(value) {
    if (value === false) {
      this.habilitado = false;
    } else {
      this.habilitado = true;
    }
  }
  async submit(formulario: FormGroup) {

    const habilitadoRadioValue = this.parecerFinalForm.get('habilitadoRadio').value;

    if (habilitadoRadioValue === null) {
      Swal.fire('Parecer Final Supervisor', 'Informe se o parecer habilita ou não a experiência prossional supervisionada.', 'info');
      return;
    }

    const descParecerFinal = this.parecerFinalForm.get('parecerFinal').value;
    if (this.parecerFinal === null){
      Swal.fire('Parecer Final Supervisor', 'Informe o comentário do seu parecer final.', 'info');
      return;
    }

    Swal.fire({
      title: 'Parecer final do supervisor',
      text: "Tem certeza que deseja enviar o seu parecer final ? Essa ação não poderá ser desfeita.",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed)
      {
        var comentarioParecerFinal = formulario.value.parecerFinal;
        var habilitado = formulario.value.habilitadoRadio;
        this.registrarParecer(this.idPessoa, comentarioParecerFinal, habilitado);
      }
    });
  }

  registrarParecer(idpessoa, parecerFinalDescricao, parecerFinalStatus) {
    this.experienciaSupervisionadaService.registrarParecerFinalSupervisor(idpessoa, parecerFinalDescricao, parecerFinalStatus).subscribe((result) => {
      Swal.fire(
        'Parecer Final Supervisor',
        `Parecer Final Supervisor registrado com sucesso!`,
        'success'
      ).then(() => {
        this.router.navigate(['/home']);
      });

    },
      (error) => {
        this.validForm.emit(false);
        Swal.fire('Parecer Final Supervisor', error.error.excecao[0].mensagem, 'error');
      }
    );
    };

    verificarSupervisionadoEValido(idPessoa: number)
    {
      this.experienciaSupervisionadaService.obterExperienciasPorSupervisor(this.id).subscribe((resp)=>{
        if(!resp.resultado?.some(d=>d.idPessoa === idPessoa))
          this.router.navigate(['/home']);
      });
    }

  }

