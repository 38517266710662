import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { SenhaValidator } from 'src/app/validator/senha.validator';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { TokenService } from './../../services/token/token.service';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit {
  alterarSenhaForm: FormGroup;
  olho = false;
  olhoNovo = false;
  errorSenhas: boolean;
  token: string;
  
  constructor( 
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private tokenService: TokenService,
  ) { 
    this.alterarSenhaForm = this.formBuilder.group({
      senha: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          SenhaValidator.strong,
          SenhaValidator.number,
          SenhaValidator.upper,
          SenhaValidator.lower,
          SenhaValidator.caracters,
        ],
      ],
      confirmarSenha: ['', Validators.required]
    });

    //OBTER TOKEN POR PARAMS
    this.route.queryParams.subscribe((params) => {
      this.token = String(params['chaveValidacao']); 
    });
 
  }

  ngOnInit(): void {
  }

   //RECONHECER CAMPOS
  get senha() {
    return this.alterarSenhaForm.get('senha');
  }  
  get confirmarSenha() {
    return this.alterarSenhaForm.get('confirmarSenha');
  }

  //OCULTAR E MOSTRAR SENHAS
  mostrarNovaSenha() {
    this.olho = true;
    $('#senha').attr('type', 'text');
  }
  ocultarNovaSenha() {
    this.olho = false;
    $('#senha').attr('type', 'password');
  }

  //CONFIRMAR SENHA
  mostrarConfirmaSenha() {
    this.olhoNovo = true;
    $('#confirmarSenha').attr('type', 'text');
  }
  ocultarConfirmaSenha() {
    this.olhoNovo = false;
    $('#confirmarSenha').attr('type', 'password');
  }

  // VALIDAR SENHAS
  validaSenha() {
    const senha = this.alterarSenhaForm.get('senha').value;
    const confirmarSenha = this.alterarSenhaForm.get('confirmarSenha').value;
    if (senha === confirmarSenha) {
      this.errorSenhas = false;
    } else {
      this.errorSenhas = true;
    }
  }

  cancelar(){
    this.router.navigate(['login']);
  }

  alterarSenha(alterarSenhaForm){
    let dadosSenha: any;

    dadosSenha = {
      chaveValidacao: this.token,
      senha: this.alterarSenhaForm.controls.senha.value,
      senhaConfirmacao: this.alterarSenhaForm.controls.confirmarSenha.value,
      codigoValidacao: '',
    }
    this.tokenService.criarSenha(dadosSenha).subscribe(
      (res) => {
        Swal.fire(
          'Cadastro Senha',
          `Senha Cadastrada com sucesso!`,
          'success'
        ).then(() => {
          this.router.navigate(['/login']);
        });
      },
      (error) => {
        Swal.fire('Cadastro Senha', 
        error.error.excecao[0].mensagem,
        'error'
        );
      }
    );
  }
}
