import { FormaPagamentoService } from './../forma-pagamento/forma-pagamento.service';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AreaLogadaEventoInscricaoService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterEventoInscricao() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-evento-inscricao`,
      {
        headers: this.httpHeaders,
      }
    );
  }
  obterTipoAgenda() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-tipo-agenda`,
      {
        headers: this.httpHeaders,
      }
    );
  }
  obterTemas() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-temas`,
      {
        headers: this.httpHeaders,
      }
    );
  }
  obterPublicos() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-publicos`,
      {
        headers: this.httpHeaders,
      }
    );
  }
  obterEstados() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-estados`,
      {
        headers: this.httpHeaders,
      }
    );
  }
  obterCidades() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-cidades`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterAgendas(codigoPessoa: any, codigoEventoAgendaTipo: any, codigoEventoTema:any  ,codigoEventoPublico:any, estado:any, cidade:any, inicio:any, fim:any ) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-agendas`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, codigoEventoAgendaTipo, codigoEventoTema ,codigoEventoPublico, estado, cidade, inicio, fim},
      }
    );
  }

  criarNovaInscricao(incricao: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/criar-inscricao`,
      incricao,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterAgendaPessoaId(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-agendas-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }
  obterDadosAgenda(codigoEventoAgenda: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-dados-agenda`,
      {
        headers: this.httpHeaders,
        params: { codigoEventoAgenda },
      }
    );
  }
  obterEventoLocal(codigoEventoAgenda: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-dados-local`,
      {
        headers: this.httpHeaders,
        params: { codigoEventoAgenda },
      }
    );
  }
  obterInscricoes(codigoEventoAgenda: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-inscricoes`,
      {
        headers: this.httpHeaders,
        params: { codigoEventoAgenda },
      }
    );
  }
  desativarInscricao(codigoEventoAgendaInscricao: any) {
    return this.http.delete(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/desativar-inscricao?codigoEventoAgendaInscricao=${codigoEventoAgendaInscricao}`,
      {
        headers: this.httpHeaders,
      }
    );
  }


}
