<form name="agendaForm"
      id="agendaForm"
      [formGroup]="agendaForm"
      (ngSubmit)="submit(agendaForm)">
  <div class="row col-md-12">
    <fieldset class="border p-3 my-3">
      <legend class="legendFormulario"
              *ngIf="mode ==='add'">Cadastrar Agenda </legend>
      <legend class="legendFormulario"
              *ngIf="mode ==='visualizar'">Visualizar Agenda </legend>
      <legend class="legendFormulario"
              *ngIf="mode ==='edit'">Editar Agenda </legend>
    </fieldset>
  </div>
  <div class="container-principal mt-3">
    <div class="row">
      <div class="form-group col-md-4 col-sm-4">
        <label class="texto--sm"
               for="tipo">Tipo</label>
        <select class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="tipo"
                [class.is-invalid]="tipo.invalid && (tipo.touched || isSubmitted)"
                id="tipo"
                (change)="carregaTipo()"
                name="tipo">
          <option value=""
                  disabled>Selecione</option>
          <option *ngFor="let tipo of tipos"
                  [value]="tipo.idEventoAgendaTipo">
            {{ tipo.descricao }}
          </option>
        </select>
        <div *ngIf="tipo.invalid && (tipo.touched || isSubmitted)">
          <span class="erro"
                *ngIf="tipo.errors.required">Campo obrigatório</span>
        </div>
      </div>

      <div class="form-group col-md-4 col-sm-4">
        <label class="texto--sm"
               for="tema">Tema</label>
        <select class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="tema"
                [class.is-invalid]="tema.invalid && (tema.touched || isSubmitted)"
                id="tema"
                name="tema">
          <option value=""
                  disabled>Selecione</option>
          <option *ngFor="let tema of temas"
                  [value]="tema.idEventoTema">
            {{ tema.descricao }}
          </option>
        </select>
        <div *ngIf="tema.invalid && (tema.touched || isSubmitted)">
          <span class="erro"
                *ngIf="tema.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="form-group col-md-4 col-sm-4">
        <label class="texto--sm"
               for="publico">Público</label>
        <select class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="publico"
                [class.is-invalid]="publico.invalid && (publico.touched || isSubmitted)"
                id="publico"
                name="publico">
          <option value=""
                  disabled>Selecione</option>
          <option *ngFor="let publico of publicos"
                  [value]="publico.idEventoPublico">
            {{ publico.descricao }}
          </option>
        </select>
        <div *ngIf="publico.invalid && (publico.touched || isSubmitted)">
          <span class="erro"
                *ngIf="publico.errors.required">Campo obrigatório</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-3">
        <label class="texto--sm"
               for="data">Data</label>
        <input class="form-control form-control-sm"
               [class.is-invalid]="data.invalid && (data.touched || isSubmitted)"
               formControlName="data"
               id="data"
               maxlength="100"
               [min]="dataInicio"
               [max]="dataFim"
               name="data"
               type="date" />
        <div *ngIf="
            data.invalid && (data.touched || isSubmitted)
          ">
          <span class="erro"
                *ngIf="data.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="form-group col-md-2 col-sm-12">
        <label class="texto--sm"
               for="horario">Horário</label>
        <input class="form-control form-control-sm"
               [class.is-invalid]="horario.invalid && (horario.touched || isSubmitted)"
               formControlName="horario"
               id="horario"
               name="horario"
               type="time" />
        <div *ngIf="horario.invalid && (horario.touched || isSubmitted)">
          <span class="erro"
                *ngIf="horario.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="form-group col-md-4 col-sm-4">
        <label class="texto--sm"
               for="funcao">Eu serei</label>
        <select class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="funcao"
                [class.is-invalid]="funcao.invalid && (funcao.touched || isSubmitted)"
                id="funcao"
                name="funcao">
          <option value=""
                  disabled>Selecione</option>
          <option *ngFor="let funcao of participantes"
                  [value]="funcao.idEventoParticipanteTipo">
            {{ funcao.descricao }}
          </option>
        </select>
        <div *ngIf="funcao.invalid && (funcao.touched || isSubmitted)">
          <span class="erro"
                *ngIf="funcao.errors.required">Campo obrigatório</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12">
        <label class="texto--sm"
               for="observacao">
          Observação
        </label>
        <textarea class="form-control form-control-sm col-6"
                  id="observacao"
                  name="observacao"
                  formControlName="observacao"
                  rows="5"
                  type="text"></textarea>
      </div>
    </div>

    <div class="row"
         *ngIf="mode ==='edit' || mode ==='visualizar'">
      <div class="col-md-12">
        <p class="texto--sm"
           *ngFor="let inscricao of inscricoes; let i = index">{{inscricao.descricaoTipoParticipante}}: {{inscricao.nome}} - {{inscricao.email}} -
          ({{inscricao.dddCelular}}) {{inscricao.telefoneCelular}}</p><br>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-right">
        <button class="btn btn-sm btn-primary btn-secondary mr-0"
                id="voltar"
                (click)="voltar()"
                type="button">
          Voltar
        </button>
        <button class="btn btn-sm btn-primary mr-0 ml-2"
                id="salvar"
                *ngIf="btnSalvar"
                type="submit">
          Salvar
        </button>
      </div>
    </div>
  </div>
</form>