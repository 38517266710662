import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormaPagamentoService } from '../../services/forma-pagamento/forma-pagamento.service';
import { PessoaService } from '../../services/pessoa/pessoa.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forma-pagamento',
  templateUrl: './forma-pagamento.component.html',
  styleUrls: ['./forma-pagamento.component.scss']
})
export class FormaPagamentoComponent implements OnInit {
  isSubmitted: boolean;
  tipoPagamento: number;
  tipoPagador: number;
  maskCpfCnpj: any;
  isSubmit: boolean = false;
  isButtonDisabled = false;

  pagamento: any = this.formBuilder.group({
    nomeCompleto: ['', Validators.required],
    cpf: ['', Validators.required],
    cep: ['', Validators.required],
    endereco: ['', Validators.required],
    numero: ['', Validators.required],
    bairro: ['', Validators.required],
    cidade: ['', Validators.required],
    uf: ['', Validators.required],
    complemento: [''],
    vencimento: ['', Validators.required],
    vencimentoFixo: ['', Validators.required],
    resp: ['', Validators.required]
  });

  pessoa: any;
  debitos: any;
  camposInput: any;
  radioTipo: boolean = true;
  liberar: boolean = true;
  cnpjInvalido: boolean = false;
  urlRetorno: any;

  constructor(
    private formBuilder: FormBuilder,
    private formaPagamento: FormaPagamentoService,
    private route: ActivatedRoute,
    private router: Router,
    private pessoaService: PessoaService
  ) { }

  ngOnInit(): void {
    moment.locale('pt-br');
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.route.paramMap.subscribe(params => {
      let idDebito = params.get("id");
      this.obterDebito(idDebito);
    })
    this.obterDadosPessoa();
    this.compareTwoDates(new Date());

    this.nomeCompleto.disable();
    this.cpf.disable();
    this.cep.disable();
    this.endereco.disable();
    this.numero.disable();
    this.complemento.disable();
    this.bairro.disable();
    this.cidade.disable();
    // this.vencimento.disable();
    this.pagamento.controls['resp'].disable();
  }

  get nomeCompleto() {
    return this.pagamento.get('nomeCompleto');
  }

  get cpf() {
    return this.pagamento.get('cpf');
  }

  get cep() {
    return this.pagamento.get('cep');
  }

  get endereco() {
    return this.pagamento.get('endereco');
  }

  get numero() {
    return this.pagamento.get('numero');
  }

  get complemento() {
    return this.pagamento.get('complemento');
  }

  get bairro() {
    return this.pagamento.get('bairro');
  }

  get cidade() {
    return this.pagamento.get('cidade');
  }

  get uf() {
    return this.pagamento.get('uf');
  }

  get vencimento() {
    return this.pagamento.get('vencimento');
  }

  get vencimentoFixo() {
    return this.pagamento.get('vencimentoFixo');
  }

  get resp() {
    return this.pagamento.get('resp');
  }

  trocaTipo(tipo) {
    this.tipoPagamento = tipo;
    this.liberar = false;
    if (this.tipoPagamento === 1) {
      this.nomeCompleto.disable();
      this.cpf.disable();
      this.cep.disable();
      this.endereco.disable();
      this.numero.disable();
      this.complemento.disable();
      this.bairro.disable();
      this.cidade.disable();
      // this.vencimento.disable();
      this.pagamento.controls['resp'].disable();

      this.radioTipo = true;

    } else if (this.tipoPagamento === 2 && this.tipoPagador === 2) {
      this.nomeCompleto.enable();
      this.cpf.enable();
      this.cep.enable();
      this.endereco.disable();
      this.numero.enable();
      this.complemento.enable();
      this.bairro.disable();
      this.cidade.disable();
      // this.vencimento.disable();
      this.pagamento.controls['resp'].enable();
      this.radioTipo = false;

    } else if (this.tipoPagamento === 2) {
      this.radioTipo = false;
    }
  }


  obterDadosPessoa() {
    let idPessoa = localStorage.getItem('userId');

    this.formaPagamento.obterDadosPessoa(idPessoa).subscribe(res => {
      this.pessoa = res.resultado;
    })

  }

  obterDebito(idDebito) {

    this.formaPagamento.obterDebito(idDebito).subscribe(res => {
      this.debitos = res.resultado;
      this.debitos.valorCobranca = res.resultado.valorCobranca.toFixed(2);
      var dataVencimento = new Date(res.resultado.dataVencimento);
      this.debitos.vencimentoFixo = moment(dataVencimento).format('YYYY-MM-DD');
    })

  }

  calculaVencimento() {
    const dt = new Date()

    this.compareTwoDates(dt)

    fetch(`${environment.backofficeUrl}/calcula-valor-juros`, {
      method: 'POST',
      body: JSON.stringify({
        novaData: dt.toISOString(),
        idDebito: this.debitos.idDebito
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {

      res.json()
        .then(json => {
          this.debitos.valorCobranca = json.valorCobranca;
        }).catch(err => {
          console.log(err.message);
        })
    })
  }

  calculaVencimentoValor(event) {
    const elem = event.target

    this.compareTwoDates(new Date(elem.value))

    fetch(`${environment.backofficeUrl}/calcula-valor-juros`, {
      method: 'POST',
      body: JSON.stringify({
        novaData: elem.value,
        idDebito: this.debitos.idDebito
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {

      res.json()
        .then(json => {
          this.debitos.valorCobranca = json.valorCobranca;
        }).catch(err => {
          console.log(err.message);
        })
    })
  }

  compareTwoDates(date) {
    let isValidDate = false;
    let validDate = moment(date).add(1, 'days');
    let feriados = this.obterFeriados(moment().year());

    // while (isValidDate == false) {
    //   if (validDate.weekday() === 6 || validDate.weekday() === 7) {
    //     isValidDate = false;
    //     validDate.add(1, 'days');
    //   }
    //   else if (feriados.includes(validDate.format('DD-MM-YYYY'))){
    //     isValidDate = false;
    //     validDate.add(1, 'days');
    //   }
    //   else {
    //     isValidDate = true;
    //   }
    // }

    this.pagamento.controls['vencimento'].setValue(validDate.format('YYYY-MM-DD'));
  }

  obterFeriados(ano) {
    return [
      "01-01-" + ano,
      "02-04-" + ano,
      "21-04-" + ano,
      "01-05-" + ano,
      "07-09-" + ano,
      "12-10-" + ano,
      "02-11-" + ano,
      "15-11-" + ano,
      "25-12-" + ano
    ]
  }


  changePagador(pagador) {
    this.tipoPagador = pagador;
    if (pagador === 1) {
      this.camposInput = this.pessoa;
      this.maskCpfCnpj = "000.000.000-00";
      this.nomeCompleto.disable();
      this.cpf.disable();
      this.cep.disable();
      this.endereco.disable();
      this.numero.disable();
      this.complemento.disable();
      this.bairro.disable();
      this.cidade.disable();
      //this.vencimento.disable();

      if (this.debitos && this.debitos.dataVencimento !== null) {
        this.pagamento.controls['vencimento'].setValue(moment(this.debitos.dataVencimento).format('YYYY-MM-DD'))
      } else {
        this.pagamento.controls['vencimento'].setValue(moment().add(1, 'days').format('YYYY-MM-DD'));
      }

      this.pagamento.controls['resp'].disable();
      this.pagamento.controls['resp'].setValue(true);
    } else {
      this.camposInput = null;
      this.maskCpfCnpj = "00.000.000/0000-00";

      // if (this.debitos.dataVencimento !== null) {
      //   this.pagamento.controls['vencimento'].setValue(moment(this.debitos.dataVencimento).format('YYYY-MM-DD'))
      // } else {
      //   this.pagamento.controls['vencimento'].setValue(moment().add(1, 'days').format('YYYY-MM-DD'));
      // }

      this.nomeCompleto.enable();
      this.cpf.enable();
      this.cep.enable();
      this.endereco.disable();
      this.numero.enable();
      this.complemento.enable();
      this.bairro.disable();
      this.cidade.disable();
      // this.vencimento.disable();
      this.pagamento.controls['resp'].enable();
      this.pagamento.controls['resp'].setValue(false);
    }
  }

  validarCNPJ() {

    let cnpj = this.pagamento.controls['cpf'].value;

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs inválidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;

  }

  obterLogradouroPorCep() {
    this.pessoaService
      .obterPorCep(this.pagamento.value.cep.replace(/\D+/g, ''))
      .subscribe((res) => {
        this.endereco.setValue(res.resultado.logradouro);
        this.bairro.setValue(res.resultado.bairro);
        this.cidade.setValue(res.resultado.localidade);
        this.uf.setValue(res.resultado.uf);
        if (
          res.resultado.logradouro !== '' &&
          this.pagamento.value.cep.length >= 8 &&
          res.resultado.logradouro !== null
        ) {
          this.endereco.disable();
          this.bairro.disable();
          this.cidade.disable();
        } else {
          this.endereco.enable();
          this.bairro.enable();
          this.cidade.enable();
          this.pagamento.patchValue({
            uf: '',
          });
        }
      }, error => {
        console.log(error);
        this.endereco.enable();
        this.bairro.enable();
        this.cidade.enable();
        this.pagamento.patchValue({
          uf: '',
        });
      });
  }

  async submit() {
    this.isButtonDisabled = true;

    if (this.tipoPagamento === 1) {

      this.formaPagamento
        .linkCielo(this.debitos.idDebito)
        .subscribe(resul => {
          let url = resul.resultado;
          let link = document.createElement('a');
          link.href = url;
          link.target = '_self';
          link.click();
        })

    } else if (this.tipoPagamento === 2 && this.tipoPagador === 2) {

      if (!this.nomeCompleto.valid ||
        !this.cpf.valid ||
        !this.endereco.value ||
        !this.cep.valid ||
        !this.numero.valid ||
        !this.bairro.value ||
        !this.cidade.value ||
        !this.vencimento.value ||
        !this.uf.value ||
        this.pagamento.controls['resp'].value === false
      ) {
        this.isSubmit = true;
        Swal.fire(
          'Forma de Pagamento',
          'Informações inválidas.',
          'error'
        )
        return;
      }

      if (
        this.nomeCompleto.valid &&
        this.cpf.valid &&
        this.endereco.value &&
        this.cep.valid &&
        this.numero.valid &&
        this.bairro.value &&
        this.cidade.value &&
        this.vencimento.value &&
        this.uf.value &&
        this.pagamento.controls['resp'].value === true
      ) {

        if (!this.validarCNPJ()) {
          this.cnpjInvalido = true;
          Swal.fire(
            'Forma de Pagamento',
            'CNPJ inválido.',
            'error'
          )
          return;
        } else {
          this.cnpjInvalido = false;
        }

        this.isSubmit = false;
        let envio = {
          idDebito: this.debitos.idDebito,
          nomeRazaoSocial: this.pagamento.controls['nomeCompleto'].value,
          cpfCnpj: this.pagamento.controls['cpf'].value,
          cep: this.pagamento.controls['cep'].value,
          enderecoLogradouro: this.pagamento.controls['endereco'].value,
          enderecoNumero: this.pagamento.controls['numero'].value,
          enderecoComplemento: this.pagamento.controls['complemento'].value,
          enderecoBairro: this.pagamento.controls['bairro'].value,
          enderecoCidade: this.pagamento.controls['cidade'].value,
          enderecoUF: this.pagamento.controls['uf'].value,
          dataVencimento: this.pagamento.controls['vencimento'].value
        }

        this.formaPagamento
          .gerarBoleto(envio)
          .subscribe(res => {

            if (res.sucesso) {
              this.formaPagamento
                .obterBoleto(this.debitos.idDebito)
                .subscribe(resul => {

                  const linkSource = `data:application/octet-stream;base64,${resul.resultado.boleto}`;
                  const downloadLink = document.createElement('a');
                  const fileName = 'boleto.pdf';
                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();

                  this.router.navigate(['/debitos']);

                }, error => {
                  Swal.fire(
                    'Gerar Boleto',
                    error.error.excecao[0].mensagem,
                    'error'
                  );
                }
                )
            }

          }, error => {
            Swal.fire(
              'Gerar Boleto',
              error.error.excecao[0].mensagem,
              'error'
            );
          })

      }
    } else {

      if (this.cep.value.length < 8 && this.pessoa.pessoaEndereco[0].idPais == 32) {
        Swal.fire(
          'CEP Inválido.',
          'O CEP deve possuir 8 caracteres.</br>Atualize seu endereço em dados cadastrais.',
          'error'
        ).then(() => { });

        return;
      }

      if (
        !this.vencimento.value
      ) {
        this.isSubmit = true;
        Swal.fire(
          'Forma de Pagamento',
          'Data de vencimento inválida.',
          'error'
        )
        return;
      }

      if (
        this.vencimento.value
      ) {

        this.isSubmit = false;

        let envio = {
          idDebito: this.debitos.idDebito,
          dataVencimento: (document.querySelector('#vencimento') as any).value
        }

        this.formaPagamento.gerarBoleto(envio).subscribe(res => {

          if (res.sucesso) {
            this.formaPagamento
              .obterBoleto(this.debitos.idDebito)
              .subscribe(resul => {

                const linkSource = `data:application/octet-stream;base64,${resul.resultado.boleto}`;
                const downloadLink = document.createElement('a');
                const fileName = 'boleto.pdf';
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

                this.router.navigate(['/debitos']);

              }, error => {
                Swal.fire(
                  'Forma de Pagamento',
                  error.error.excecao[0].mensagem,
                  'error'
                );
              })
          }

        }, error => {
          Swal.fire(
            'Forma de Pagamento',
            error.error.excecao[0].mensagem,
            'error'
          );
        })
      }
    }

  }

}
