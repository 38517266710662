import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { AgendaComponent } from 'src/app/pages/agendas/agenda/agenda.component';

@Injectable({
  providedIn: 'root',
})
export class AgendaService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    let token = localStorage.getItem('tknCdst');


    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterTodos(codigoEventoLocal: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/obter-por-local`,
      {
        headers: this.httpHeaders,
        params: { codigoEventoLocal}
      }
    );
  }

  obterInscricoes(codigoEventoAgenda: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/obter-inscricoes`,
      {
        headers: this.httpHeaders,
        params: { codigoEventoAgenda}
      }
    );
  }

  obterProspeccao() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/obter-evento-prospeccao`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterPublico() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/obter-publicos`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterTemas() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/obter-temas`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterParticipantes(codigoPessoa: any, codigoEventoAgendaTipo: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/obter-tipo-participante`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, codigoEventoAgendaTipo },
      }
    );
  }

  obterAgendas() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/obter-tipo-agenda`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterPorId(id: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/obter-por-id`,
      {
        headers: this.httpHeaders,
        params: { id },
      }
    );
  }

  atualizarAgenda(agenda: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/alterar`,
      agenda,
      {
        headers: this.httpHeaders,
      }
    );
  }

  excluir(id: any) {
    return this.http.delete(
      `${environment.backofficeUrl}/area-logada-evento-agenda/desativar-id?id=${id}`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarAgenda(agenda: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-evento-agenda/criar`,
      agenda,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterPorCep(cep: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-endereco-cep`,
      {
        headers: this.httpHeaders,
        params: {
          cep: cep,
        },
      }
    );
  }

}
