import { FormControl } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class CpfValidator {

  public static validCpf(control: FormControl): ValidationResult {
    const cpf = control.value;
    const falso = { validCpf: true };
    // Valida CPF
    if (cpf) {
      // tslint:disable-next-line: one-variable-per-declaration
      let numbers, digits, sum, i, result, equalDigits;
      equalDigits = 1;
      if (cpf.length < 11) {
        return falso;
      }
      for (i = 0; i < cpf.length - 1; i++) {
        if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
          equalDigits = 0;
          break;
        }
      }
      if (!equalDigits) {
        numbers = cpf.substring(0, 9);
        digits = cpf.substring(9);
        sum = 0;
        for (i = 10; i > 1; i--) {
          sum += numbers.charAt(10 - i) * i;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        if (result !== Number(digits.charAt(0))) {
          return falso;
        }
        numbers = cpf.substring(0, 10);
        sum = 0;
        for (i = 11; i > 1; i--) {
          sum += numbers.charAt(11 - i) * i;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(1))) {
          return falso;
        }
        return null;
      } else {
        return falso;
      }
    }
    return null;
  }

}
