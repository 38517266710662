<fieldset class="border p-3 my-3">
  <legend class="legendFormulario">Solicitação de Licença</legend>
<div class="row d-flex justify-content-center">
  <div class="dropMobile text-right align-items-center col-md-4 col-sm-4"
      [ngClass]="{ 'mt-4': view }">
        <div class="text-right align-items-center dropCustom col-sm-12 mt-3">
          <div class="w-100">
            <div [accept]="'image/jpg, image/png, application/pdf, application/vnd.ms-word, .doc, .docx, .jpg, .jpeg'"
                 (change)="onSelect($event)"
                 class="custom-dropzone col-sm-12"
                 #drop
                 [disabled]="view"
                 [maxFileSize]="20971520"
                 [multiple]="true"
                 ngx-dropzone>
              <ngx-dropzone-label>
                <div>
                  <img class="img-adicionada"
                       [src]="imagem" />
                  <p *ngIf="(!view && imagem == null) || imagem == ''">
                    Arraste o(s) seu(s) arquivo(s) ou clique aqui para selecionar
                  </p>
                </div>
              </ngx-dropzone-label>
              <ngx-dropzone-image-preview class="previa-img"
                                          *ngFor="let f of files"
                                          [file]="f"
                                          ngProjectAs="ngx-dropzone-preview"
                                          [removable]="true">
              </ngx-dropzone-image-preview>
            </div>
            <div class="text-center">
              <button class="btn btn-sm btn-danger mt-1 btnRemove"
                      (click)="onRemove()"
                      id="remove"
                      [disabled]="view"
                      *ngIf="files.length > 0"
                      title="Remover Imagem">
                Remover Arquivo
              </button>
            </div>
            <p class="mt-1 text-primary text-center col-md-12 col-sm-12"
               *ngIf="
                (files.length === 0 && imagem == '') ||
                (imagem == null && !view)
              ">
              Formatos do arquivo PNG, JPG, JPEG, DOC, DOCX ou PDF.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12 col-sm-12">
        <label for="periodo">Período:</label>
        <select class="form-control form-control-sm custom-select custom-select-sm"
                id="periodo"
                name="periodo"
                [ngModel]="periodo">
          <option value=""
                  disabled>Selecione</option>
          <option value="1">1 ano</option>
          <option value="2">2 anos</option> 
        </select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12 col-sm-12">
        <label for="descricao">Observação:</label>
        <input class="form-control form-control-sm"
               id="observacao"
               height="300"
               multiple
               maxlength="1000"
               name="observacao"
               type="text"
               [ngModel]="observacao" />
      </div>
    </div>
    
    <div class="row">
      <div class="col-12 text-right d-flex" style="justify-content: flex-end;">        
        <div class="wrapper2">
          <button class="btn btn-sm btn-primary btn-secondary ml-2"
                  type="button"
                  routerLink="/home">
            Voltar
          </button> 
        </div>
        <div class="wrapper2">
          <button class="btn btn-sm btn-primary mr-0 ml-2"
                  (click)="enviar()"
                  id="salvar"
                  type="button">
            Enviar
          </button>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="solicitacao != null">
      <div>
        <p><b>Data de Solicitação:</b> {{solicitacao.dataCadastro | date: 'dd/MM/yyyy'}}</p>
        <p><b>Período:</b> {{solicitacao.periodo == 1 ? '1 ano' : '2 anos' }}</p>
        <p><b>Observação:</b> {{solicitacao.observacao}}</p>        
        <p><b>Status:</b> {{solicitacao.aprovado != null ? solicitacao.aprovado ? 'Aprovado' : 'Recusado' : 'Aguardando análise'}}</p>
        <p><b>Motivo:</b> {{solicitacao.motivo}}</p>
      </div>
    </div>
</fieldset>
