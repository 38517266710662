import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ExperienciaProfissionalService } from 'src/app/services/experiencia-profissional/experiencia-profissional.service';

@Component({
  selector: 'app-definir-supervisor',
  templateUrl: './definir-supervisor.component.html',
  styleUrls: ['./definir-supervisor.component.scss'],
})
export class DefinirSupervisorComponent implements OnInit {
  //@ViewChild('childModal') childModal: ModalDirective;
  id: any;
  solicitacaoForm: FormGroup;
  imagem = null;
  conhecimentos = [];
  educacoes = [];
  checkAreas: any[];
  idPessoa: number;
  codigoSolicitacaoSupervisor: number;
  pessoa: [];
  endereco: [];
  experiencias = [];
  cargos = [];
  cidade_uf: string;
  descricaoTipoAtendimentoSupervisao: string;
  dataCertificacao: any;
  idPessoaSupervisor: number;
  checkedTermoCadastro: boolean = false;
  config: any;
  itens = [];

  ngOnInit(): void {
    this.init();

    this.route.paramMap.subscribe((params) => {
      if (params.get('idPessoaSupervisor') !== null)
        this.idPessoaSupervisor = Number(params.get('idPessoaSupervisor'));
    });

    this.route.paramMap.subscribe((params) => {
      this.obterPessoa();
      this.carregaExperiencia();
      this.carregaAreaAtuacaoConhecimento();
    });

    this.textoTermoAceiteCadastro();
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private pessoaService: PessoaService,
    private experienciaSupervisionadaService: ExperienciaSupervisionadaService,
    private serviceExperienciaProfissional: ExperienciaProfissionalService,
    private route: ActivatedRoute
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
    this.solicitacaoForm = this.formBuilder.group({});

    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '200px',
      toolbar: [
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    }
  }

  init() {
    this.solicitacaoForm = this.formBuilder.group({
      nome: [''],
      localizacao: [''],
      email: [''],
      tipoAtendimentoSupervisao: [''],
      telefoneCelular: [''],
      apresentacaoSupervisao: [''],
      dataCertificado: [''],
      especialidades: [''],
      empresa: [''],
      areaAtuacao: [''],
      atribuicaoCargo: [''],
      termoCadastro: [''],
    });
  }

  get nome() {
    return this.solicitacaoForm.get('nome');
  }
  get localizacao() {
    return this.solicitacaoForm.get('localizacao');
  }
  get email() {
    return this.solicitacaoForm.get('email');
  }
  get telefoneCelular() {
    return this.solicitacaoForm.get('telefoneCelular');
  }
  get tipoAtendimentoSupervisao() {
    return this.solicitacaoForm.get('tipoAtendimentoSupervisao');
  }
  get apresentacaoSupervisao() {
    return this.solicitacaoForm.get('apresentacaoSupervisao');
  }
  get dataCertificado() {
    return this.solicitacaoForm.get('dataCertificado');
  }
  get especialidades() {
    return this.solicitacaoForm.get('especialidades');
  }
  get atribuicaoCargo() {
    return this.solicitacaoForm.get('atribuicaoCargo');
  }

  get termoCadastro() {
    return this.solicitacaoForm.get('termoCadastro');
  }

  obterPessoa() {
    this.pessoaService.obterPorId(this.id).subscribe((resp) => {
      this.pessoa = resp.resultado;
      this.endereco = resp.resultado.pessoaEndereco[0];
      this.cidade_uf = this.endereco['cidade'] + '/' + this.endereco['estado'];
      this.descricaoTipoAtendimentoSupervisao =
        resp.resultado.tipoAtendimentoSupervisao === null
          ? 'Não informado'
          : resp.resultado.tipoAtendimentoSupervisao === 1
          ? 'Presencial'
          : resp.resultado.tipoAtendimentoSupervisao === 2
          ? 'À distância'
          : 'Híbrido';
      this.apresentacaoSupervisao.setValue(
        this.pessoa['apresentacaoSupervisao']
      );
      this.dataCertificacao = moment(resp.resultado.dataCertificacao).format(
        'DD/MM/YYYY'
      );
      this.especialidades.setValue(this.pessoa['especialidades']);
      this.atribuicaoCargo.setValue(this.cargos['complemento']);
      this.apresentacaoSupervisao.setValue(this.pessoa['apresentacaoSupervisionado']);
    });
  }

  carregaAreaAtuacaoConhecimento() {
    this.pessoaService.obterAreas().subscribe(
      (resp) => {
        resp.resultado.map((item) => {
          item.checked = false;
        });
        this.conhecimentos = resp.resultado;
        this.preencherAreasSelecionadas();
      },
      (erro) => {
        Swal.fire('Areas Conhecimento', erro.error.excecao[0].mensagem, 'error');
      }
    );
  }

  preencherAreasSelecionadas() {
    let selecionados = [];
    this.experienciaSupervisionadaService
      .obterSolicitacaoSupervisor(this.codigoSolicitacaoSupervisor)
      .subscribe((resp) => {
        resp.resultado.areas.map((itens) => {
          selecionados.push(itens);
        });
      });
    this.conhecimentos.map((item) => {
      item.checked = false;
      selecionados.map((el) => {
        if (item.idAreaSupervisao === el.idAreaSupervisao) {
          item.checked = true;
        }
      });
    });

    this.conhecimentos.forEach(c => {
      c.isDisabled = false;
      if(c.idAreaSupervisao === 1){
        c.checked = true;
        c.isDisabled = true;
        this.checkArea(c.idAreaSupervisao);
      }
    })


  }

  carregaExperiencia() {
    this.serviceExperienciaProfissional
      .obterExperiencia(Number(this.id))
      .subscribe(
        (resp) => {
          this.experiencias = resp.resultado;
          if (this.experiencias.some((e) => e.inicio != undefined))
            this.cargos = this.experiencias[0];
        },
        (erro) => {
          Swal.fire(
            'Experiência Profissional',
            erro.error.excecao[0].mensagem,
            'error'
          );
        }
      );
  }

  checkArea(id) {
    if (this.checkAreas?.some((c) => c == id) ?? false) {
      var index = this.checkAreas.indexOf(id);
      this.checkAreas.splice(index, 1);
      return;
    }

    if (!this.checkAreas) {
      this.checkAreas = [id];
      console.log(this.checkAreas);
      return;
    }

    this.checkAreas.push(id);
  }

  enviarSolicitacao() {
    Swal.fire({
      title: 'Confirmar Solicitação',
      text: 'Ao enviar esta solicitação, você deverá aguardar o prazo de análise de até 15 dias. Durante este período você não poderá enviar novas solicitações. Confirma o envio? ',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
    }).then((result) => {
      if (
        this.checkAreas === undefined ||
        this.checkAreas === null ||
        this.checkAreas.length === 0
      ) {
        Swal.fire(
          'Solicitação de Supervisor',
          'Áreas de conhecimento/atuação não selecionados!',
          'error'
        ).then(() => {});

        return;
      }

      if(!this.checkedTermoCadastro)
      {
        Swal.fire(
          'Termo Solicitação de Experiência Profissional Supervisionada',
          'Necessário aceitar o termo de Solicitação de Experiência Profissional Supervisionada.',
          'error'
        ).then(() => {
        });
        return;
      }

      if (this.checkAreas.length < 4){
        Swal.fire(
          'Solicitação de Supervisor',
          'Selecione ao 3 menos áreas de conhecimento/atuação!',
          'error'
        ).then(() => {});

        return;
      }

      if (
        this.apresentacaoSupervisao.value === '' ||
        this.apresentacaoSupervisao.value === null ||
        this.apresentacaoSupervisao.value === undefined
      ) {
        Swal.fire(
          'Solicitação de Supervisor',
          'Apresentação não preenchida!',
          'error'
        ).then(() => {});

        return;
      }
      if (result.isConfirmed) {
        this.experienciaSupervisionadaService
          .alterarSituacaoSolicitacao(
            this.id,
            this.idPessoaSupervisor,
            1,
            'teste',
            this.checkAreas
          )
          .subscribe(
            (resp) => {
              if (!resp.sucesso) {
                Swal.fire('Solicitação de Supervisor', resp.erro, 'error');
                return;
              }
              Swal.fire(
                'Solicitação de Supervisor',
                'Solicitação de Supervisor aprovado com sucesso!</br> O Supervisor analisará seu pedido e retornará em até 10 dias. </br>Por favor, aguarde este prazo para receber a resposta por e-mail e/ou através do histórico de solicitações de supervisão.',
                'success'
              ).then(() => {
                this.router.navigate(['/home']);
              });
            },
            (erro) => {
              Swal.fire(
                'Solicitação de Supervisor',
                erro.error.excecao[0].mensagem,
                'error'
              );
            }
          );
      }
    });
  }

  selecionado(checked :boolean){
    this.checkedTermoCadastro = checked;
  }

  textoTermoAceiteCadastro() {
    this.pessoaService
      .obterTermoSolicitacaoSupervisor()
      .subscribe((resp) => {
        this.solicitacaoForm.controls.termoCadastro.setValue(resp.resultado.descricao);
        this.itens = resp.resultado;
      }, (erro) => {
        Swal.fire(
          'Auto Cadastro',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }
}
