import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { RedirecionaService } from 'src/app/services/redireciona/redireciona.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-progress',
   templateUrl: './redireciona.component.html',
   styleUrls: ['./redireciona.component.scss']
})
export class RedirecionaComponent implements OnInit {
  token: string;
  url: string;
  tokenValidado: any;
    constructor(
    private route: ActivatedRoute,
    private router: Router,
    private redirecionaService : RedirecionaService,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      console.log('Token:', this.token);
    });

    this.redirecionaService
    .validarAcessoExterno(this.token)
    .subscribe(
      (user: any) => {
        localStorage.setItem(
          'access_token',
          JSON.stringify(user.access_token)
        );

        this.authenticationService
        .loginHsol(user.codigo_usuario)
        .subscribe(
          (user: any) => {
            localStorage.setItem(
              'access_token',
              JSON.stringify(user.access_token)
            );
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('modalInicio', '1');
            this.router.navigate(['home']);
            location.reload();
          },
          (err) => {
            Swal.fire('', 'Não foi possível realizar a autenticação.', 'error');
          }
        );
      },
      (err) => {
        Swal.fire('', 'Não foi possível realizar a autenticação', 'error');
        this.router.navigate(['/login'])
      }
    );
  }
}
