import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();
  loginForm: FormGroup;
  esqueciSenhaForm: FormGroup;
  esqueciSenha: boolean;
  estruturas: any;
  public tabela: any;
  esqueciSenhaMsg = {
    type: '',
  };
  public emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required]],
      manterConectado: [false],
      senha: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.esqueciSenhaForm = this.formBuilder.group({
      emailPrimario: [
        '',
        [Validators.pattern(this.emailPattern), Validators.required],
      ],
      emailSecundario: [
        '',
        [Validators.pattern(this.emailPattern), Validators.required],
      ],
      celular: ['', [Validators.minLength(11), Validators.required]],
    });
  }

  get login() {
    return this.loginForm.get('login');
  }
  get senha() {
    return this.loginForm.get('senha');
  }
  get emailPrimario() {
    return this.esqueciSenhaForm.get('emailPrimario');
  }
  get emailSecundario() {
    return this.esqueciSenhaForm.get('emailSecundario');
  }
  get celular() {
    return this.esqueciSenhaForm.get('celular');
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  mudaCamposRecuperacao() {
    this.emailPrimario.setValidators([
      Validators.required,
      Validators.pattern(this.emailPattern),
    ]);
    this.emailSecundario.setValidators([
      Validators.required,
      Validators.pattern(this.emailPattern),
    ]);
    this.celular.setValidators([Validators.minLength(11), Validators.required]);

    this.emailPrimario.updateValueAndValidity();
    this.emailSecundario.updateValueAndValidity();
    this.celular.updateValueAndValidity();

    if (
      (this.emailPrimario.value && this.emailPrimario.valid) ||
      (this.emailSecundario.value && this.emailSecundario.valid) ||
      (this.celular.value && this.celular.valid)
    ) {
      this.emailPrimario.setValidators(Validators.nullValidator);
      this.emailSecundario.setValidators(Validators.nullValidator);
      this.celular.setValidators(Validators.nullValidator);

      this.emailPrimario.updateValueAndValidity();
      this.emailSecundario.updateValueAndValidity();
      this.celular.updateValueAndValidity();
    }
  }

  recuperarSenha(esqueciSenhaForm) {
    if (this.esqueciSenhaForm.valid) {
      if (this.emailPrimario.value && this.emailPrimario.valid) {
        this.authenticationService
          .recuperarSenha(this.esqueciSenhaForm.value.emailPrimario)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              Swal.fire(
                'Recuperar Senha',
                `Verifique seu e-mail (tempo de envio pode variar de acordo com sua operadora) para dar continuidade ao processo de recuperação de senha!`,
                'success'
              ).then(() => {
                this.esqueciSenha = false;
                this.limpaEsqueciSenha();
              });
            },
            (err) => {
              Swal.fire(
                'Recuperar Senha',
                err.error.excecao[0].mensagem,
                'error'
              );
            }
          );
      } else if (this.emailSecundario.value && this.emailSecundario.valid) {
        this.authenticationService
          .recuperarSenhaEmailSecundario(
            this.esqueciSenhaForm.value.emailSecundario
          )
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              Swal.fire(
                'Recuperar Senha',
                `Verifique seu e-mail (tempo de envio pode variar de acordo com sua operadora) para dar continuidade ao processo de recuperação de senha!`,
                'success'
              ).then(() => {
                this.esqueciSenha = false;
                this.limpaEsqueciSenha();
              });
            },
            (err) => {
              Swal.fire(
                'Recuperar Senha',
                err.error.excecao[0].mensagem,
                'error'
              );
            }
          );
      } else if (this.celular.value && this.celular.valid) {
        this.authenticationService
          .recuperarSenhaCelular(this.esqueciSenhaForm.value.celular)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res) => {
              Swal.fire(
                'Recuperar Senha',
                `Verifique seu celular (tempo de envio pode variar de acordo com sua operadora) e e-mail para dar continuidade ao processo de recuperação de senha!`,
                'success'
              ).then(() => {
                this.esqueciSenha = false;
                this.limpaEsqueciSenha();
              });
            },
            (err) => {
              Swal.fire(
                'Recuperar Senha',
                err.error.excecao[0].mensagem,
                'error'
              );
            }
          );
      }
    } else {
      this.emailPrimario.markAsTouched();
      this.emailSecundario.markAsTouched();
      this.celular.markAsTouched();
    }
  }

  submit(form) {
    if (this.loginForm.valid) {
      this.authenticationService
        .login(
          this.loginForm.value.login,
          this.loginForm.value.senha.replace(/(\.|\/|\-)/g, ''),
          this.loginForm.value.manterConectado
        )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (user: any) => {
            localStorage.setItem(
              'access_token',
              JSON.stringify(user.access_token)
            );
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('modalInicio', '1');
            this.router.navigate(['home']);
            location.reload();
          },
          (err) => {
            Swal.fire('', 'Usuário e/ou senha inválidos.', 'error');
          }
        );
    } else {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  limpaEsqueciSenha() {
    this.esqueciSenhaMsg = {
      type: '',
    };
    this.emailPrimario.reset();
    this.emailSecundario.reset();
    this.celular.reset();
  }
}
