import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-autorizacao-aceite',
  templateUrl: './autorizacao-aceite.component.html',
  styleUrls: ['./autorizacao-aceite.component.scss']
})
export class AutorizacaoAceiteComponent implements OnInit {
  @Input() isSubmitted: boolean;
  @Output() edital = new EventEmitter();
  @Output() manual = new EventEmitter();
  config: any;
  checked = false;
  checkedManual = false;
  editalForm: FormGroup;
  editals: any;
  manuals: any;

  constructor(
    private fb: FormBuilder,
    private serviceExpectativa: AreaLogadaInscricaoService
  ) {

    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '400px',
      toolbar: [
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    }
  }

  ngOnInit(): void {
    this.init();
    //this.carregaEdital();
    //this.carregaManual();
    this.carregarEditalManual();
    let checked = JSON.parse(localStorage.getItem('edital'));
    let checkedManual = JSON.parse(localStorage.getItem('manual'));
    if (!checked.concluir)
    {
      return;
    } else {
      this.checked = checked.concluir;
      this.edital.emit({
        'concluir': this.checked
      });
    }
    if (!checkedManual.concluir)
    {
      return;
    } else {
      this.checkedManual = checkedManual.concluir;
      this.manual.emit({
        'concluir': this.checked
      });
    }
  }

  init() {
    this.editalForm = this.fb.group({
      manual: [''],
      editalExame: [''],
    });
  }

  carregaEdital() {
    this.serviceExpectativa
      .obterEditalManual()
      .subscribe((resp) => {
        this.editals = resp.resultado;
        this.editalForm.controls.manual.setValue(resp.resultado.descricao);
      }, (erro) => {
        Swal.fire(
          'Autorização e Aceite',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  carregarEditalManual()
  {
    this.serviceExpectativa
      .obterEditalManualV2()
      .subscribe((resp) => {

        console.log("autorizacao-aceite.ts")
        resp.resultado.manual.descricao = this.serviceExpectativa.listAceiteAoEditalDoExame("aceite-manual-da-certificacao")
        resp.resultado.edital.descricao = this.serviceExpectativa.listAceiteAoEditalDoExame("aceite-ao-edital-do-exame")

        this.manuals = resp.resultado.manual;
        this.editals = resp.resultado.edital;

        this.editalForm.controls.manual.setValue(resp.resultado.manual.descricao);
        this.editalForm.controls.editalExame.setValue(resp.resultado.edital.descricao);
      }, (erro) => {
        Swal.fire(
          'Autorização e Aceite',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }


  carregaManual() {
    this.serviceExpectativa
      .obterTextoManual()
      .subscribe((resp) => {
        this.manuals = resp.resultado;
        this.editalForm.controls.editalExame.setValue(resp.resultado.descricao);
      }, (erro) => {
        Swal.fire(
          'Autorização e Aceite',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  selecionado(checked) {
    this.checked = checked;
    if(this.checked ) {
      this.edital.emit({
        'concluir': true
      });
    } else {
      this.edital.emit({
        'concluir': false
      });
    }
  }

  selecionadoManual(checkedManual) {
    this.checkedManual = checkedManual;
    if(this.checkedManual) {
      this.manual.emit({
        'concluir': true
      });
    } else {
      this.manual.emit({
        'concluir': false
      });
    }
  }
}
