import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';


@Component({
  selector: 'material-estudos',
  templateUrl: './material-estudos.component.html',
  styleUrls: ['./material-estudos.component.scss']
})
export class MaterialEstudosComponent implements OnInit {
  id: any
  idInscricao: any;
  cupom: string;
  dtExame: any;

  constructor(
    private route: ActivatedRoute,
    private inscricaoService : AreaLogadaInscricaoService
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
  }
  
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params.get("idInscricao")) {
        this.idInscricao = params.get("idInscricao")
      }
    });
    this.carregaInscricao();
  }

  carregaInscricao() {
    this.inscricaoService.obterInscricao(this.idInscricao, true).subscribe((result) => {
      const inscricao = result.resultado;
      this.cupom = inscricao.codigoMaterial;
      this.dtExame = inscricao.exameCidade.dataExame ? inscricao.exameCidade.dataExame : inscricao.exame.dataExame;
    });
  }
}
