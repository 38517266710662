
<form name="solicitacaoForm"
      id="solicitacaoForm"
      class="mt-3"
      [formGroup]="solicitacaoForm">
      <fieldset class="border p-3 my-3">
        <legend class="legendFormulario">Dados Pessoais</legend>

        <div class="row">
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="nome">Nome</label>
                  <input class="form-control form-control-sm"
                  formControlName="nome"
                  id="nome"
                  name="nome"
                  type="text"
                  [(ngModel)]="pessoa['nome']" disabled />
          </div>
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="localizacao">Localização:</label>
            <input class="form-control form-control-sm"
                  formControlName="localizacao"
                  id="localizacao"
                  name="localizacao"
                  type="text"
                  [(ngModel)]="cidade_uf" disabled />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="email">E-mail</label>
                  <input class="form-control form-control-sm"
                  formControlName="email"
                  id="email"
                  name="email"
                  type="text"
                  [(ngModel)]="pessoa['email']" disabled />
          </div>
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="email">Telefone Celular</label>
                  <input class="form-control form-control-sm"
                  formControlName="telefoneCelular"
                  id="telefoneCelular"
                  name="telefoneCelular"
                  type="text"
                  [(ngModel)]="pessoa['telefoneCelular']" disabled />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6" *ngIf="this.habilitarSupervisor">
            <label class="texto--sm"
                  for="dataCertificacao">Data Certificação</label>
                  <input class="form-control form-control-sm"
                  formControlName="dataCertificacao"
                  id="dataCertificacao"
                  name="dataCertificacao"
                  type="text"
                  [(ngModel)]="pessoa['dataCertificacao']" disabled />
          </div>

          <div class="form-group col-md-6">

                  <label class="texto--sm"
                        for="tipoAtendimentoSupervisao">Tipo Atendimento</label>

                  <input class="form-control form-control-sm"
                    formControlName="tipoAtendimentoSupervisao"
                    id="tipoAtendimentoSupervisao"
                    name="tipoAtendimentoSupervisao"
                    type="text"
                    [(ngModel)]="descricaoTipoAtendimentoSupervisao" disabled *ngIf="!this.habilitarSupervisor"/>

                  <select class="form-control form-control-sm"
                    formControlName="tipoAtendimentoSupervisao"
                    id="tipoAtendimentoSupervisao"
                    name="tipoAtendimentoSupervisao"
                    *ngIf="this.habilitarSupervisor">
                    <option value="" [selected]="pessoa['tipoAtendimentoSupervisao']===null">&nbsp;</option>
                    <option value="1" [selected]="pessoa['tipoAtendimentoSupervisao']===1">Presencial</option>
                    <option value="2" [selected]="pessoa['tipoAtendimentoSupervisao']===2">À distância</option>
                    <option value="3" [selected]="pessoa['tipoAtendimentoSupervisao']===3">Híbrido</option>
                  </select>

          </div>
        </div>

        <section>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="texto--sm" for="apresentacaoSupervisionado">Faça uma breve apresentação de sua Experiência Profissional</label>
                <div id="apresentacao">
                    <textarea rows="6" cols="116" class="textArea" id="apresentacaoSupervisionado" name="apresentacaoSupervisionado" formControlName="apresentacaoSupervisionado"></textarea>
                </div>
            </div>
          </div>
        </section>

        <section>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="texto--sm"
                    for="formacao">Área de atuação/Conhecimento</label>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12" >
              <div class="col-md-6" *ngFor="let conhecimento of conhecimentos">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input"
                        type="checkbox"
                        id="conhecimento.idAreaSupervisao"
                        name="conhecimento-{{conhecimento.idAreaSupervisao}}"
                        [(ngModel)]="conhecimento.checked"
                        [ngModelOptions]="{standalone: true}" [disabled]="!this.habilitarSupervisor || conhecimento.isDisabled"
                        (click)="selecionarArea(conhecimento.idAreaSupervisao)"/>
                  <label class="ml-2">{{conhecimento.descricao}}</label>
                </div>
              </div>
            </div>
          </div>
        </section>

     </fieldset>
</form>
<!--
<div *ngIf="!this.habilitarSupervisor">
  <legend class="legendFormulario" *ngIf="!this.habilitarSupervisor">Dados Educacionais</legend>
  <div class="row mt-4 empresa-inclusa" *ngFor="let educacao of educacoes">
    <div class="col-md-12 mt-4" >
      <div class="row">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12">
              <label class="label-weight">Escolaridade</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <Label>Formação: </Label><span> {{educacao.descricaoFormacao}}</span>
            </div>
            <div class="col-md-6">
              <label>Curso: </label><span> {{educacao.formacaoCurso}}</span>
            </div>
            <div class="col-md-6">
              <label>Conclusão: </label><span> {{educacao.fim | date: 'dd/MM/yyyy'}}</span>
            </div>
            <div class="col-md-6">
              <label>Instituição: </label><span> {{educacao.formacaoInstituicao}}</span>
            </div>
          </div>
        </div>
      </div>
        <div class="row" >
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-12">
                <label class="label-weight" *ngIf="habilitarSupervisor===false">Área de Conhecimento / Atuação</label>
                <label class="label-weight" *ngIf="habilitarSupervisor===true">Informe suas principais áreas de conhecimento / atuação:</label>
              </div>
            </div>
            <div class="row" *ngFor="let trilhaPessoaCertificado of educacao.trilhaPessoaItemConhecimento">
              <div class="col-md-12">
                <label>{{trilhaPessoaCertificado.descricaoConhecimento}}</label>
              </div>
            </div>
          </div>

        </div>

    </div>
  </div>
</div>
<div *ngIf="!this.habilitarSupervisor">
  <legend class="legendFormulario" *ngIf="!this.habilitarSupervisor">Dados Profissionais</legend>
  <div class="row mt-4 empresa-inclusa"
      *ngFor="let experiencia of experiencias">
    <div class="col-md-12 mt-4">
      <div class="row">
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12">
              <label>Empresa: </label><span> {{experiencia.nomeEmpresa}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <Label>Área de atuação: </Label><span> {{experiencia.descricaoAreaAtuacao}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Plataforma: </label><span> {{experiencia.descricaoPlataforma}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Cargo: </label><span> {{experiencia.descricaoCargo}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12">
              <label>Período: </label><span>
                {{experiencia.inicio | date: 'dd/MM/yyyy'}} - {{experiencia.fim}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>{{experiencia.nomeArquivo}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Responsabilidades, atividades e outras informações: </label>
              <p class="p-caracters"> {{experiencia.complemento}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
-->
<div class="row" *ngIf="this.habilitarSupervisor">
  <div class="col-md-12">
    <label class="texto--sm"
      for="nome">Salvar minhas informações</label>
      <div class="custom-control custom-checkbox">
        <input class="custom-control-input"
              type="checkbox"
              id="perfilSupervisor"
              name="perfilSupervisor"
              [(ngModel)]="isPerfilSupervisor"
              [ngModelOptions]="{standalone: true}"/>
        <label class="ml-2">Habilitar meu perfil como Supervisor para visualização dos candidatos a supervisão</label>
      </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" (click)="aprovar()" *ngIf="!this.habilitarSupervisor">
      Aprovar
    </button>
    <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" (click)="recusar()" *ngIf="!this.habilitarSupervisor">
      Recusar
    </button>
    <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" (click)="salvar()" *ngIf="this.habilitarSupervisor">
      Salvar
    </button>
    <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" onclick="javascript:history.go(-1);">
      Voltar
    </button>
  </div>
</div>




