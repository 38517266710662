import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AreaLogadaInscricaoService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterModulosAprovados(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-modulos-aprovados`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterExpectativas() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-expectativas`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterExpectativasComTipo() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-expectativas-com-tipo`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterExames() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-cidade-exame`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterDatasPorCidade(codigoCidade: any, userId: any = null) {
    const params = {}
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-data-exame-por-cidade`,
      {
        headers: this.httpHeaders,
        params: { codigoCidade, userId },
      }
    );
  }

  obterValorPorExame(codigoExame: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-dados-exame`,
      {
        headers: this.httpHeaders,
        params: { codigoExame },
      }
    );
  }

  obterModulos(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-modulos`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterTodosModulos() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-todos-modulos`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterValorDaInscricao(codigoExameCidade: any, quantidadeExames: any, idPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-valor-inscricao`,
      {
        headers: this.httpHeaders,
        params: { codigoExameCidade, quantidadeExames, idPessoa },
      }
    );
  }

  obterEditalManual() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-texto-edital-manual`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterTextoProcessoCertificacao() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-texto-processo-certificacao`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterTextoManual() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-texto-manual`,
      {
        headers: this.httpHeaders,
      }
    );
  }


  listAceiteAoEditalDoExame(filename: string) : string {
    return `<iframe
      srcdoc="Carregando..."
      onload="this.removeAttribute('srcdoc')"
      width="100%"
      height="100%"
      src="${environment.backofficeUrlV2}/dropbox/download?key=${filename}.pdf"></iframe>`
  }

  obterEditalManualV2() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-texto-edital-manual-v2`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  verificarCertificadoAceito(codigoPessoa: any, codigoTipoTermoAceite: any) {
    console.log("Pessoa:"+codigoPessoa);
    console.log("idTipo:"+codigoTipoTermoAceite);
    return this.http.get<any>(
      `${environment.backofficeUrl}/termo-aceite-historico/obter-por-pessoa-tipo`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, codigoTipoTermoAceite }
      }
    );
  }

  criarTermoAceite(incricao: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/termo-aceite-historico/criar`,
      incricao,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarNovaInscricao(incricao: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/criar-inscricao-v2`,
      incricao,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterInscricao(id: any, incluirExame: any = false) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-inscricao`,
      {
        headers: this.httpHeaders,
        params: { id, incluirExame },
      }
    );
  }

  obterPendencia(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-pendencia-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterCertificado(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/pessoa/obter-certificado`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterDebitosPorPessoa(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-inscricao-debito`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterCancelamentosAtivos(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/area-logada-auto-cadastro/obter-cancelamentos-ativos`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterBloqueiosAtivos(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/redlist/obter-bloqueios-ativos`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

}
