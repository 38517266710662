<div class="container">
  <h1>Criar Nova Votação</h1>

  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="formTituloDescricao">
      <ng-template matStepLabel>Título e Descrição</ng-template>
      <form class="form-step" [formGroup]="formTituloDescricao">
        <mat-form-field>
          <input
            matInput
            placeholder="Título da Votação"
            formControlName="titulo"
          />
        </mat-form-field>
        <mat-form-field>
          <textarea
            matInput
            placeholder="Descrição da Votação"
            formControlName="descricao"
            rows="4"
          ></textarea>
        </mat-form-field>
        <div class="button-container">
          <button mat-button (click)="cancelar()">Cancelar Votação</button>
          <button mat-button matStepperNext>Próximo</button>

        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="formPerguntaRespostas">
      <ng-template matStepLabel>Pergunta e Respostas</ng-template>
      <form class="form-step" [formGroup]="formPerguntaRespostas">
        <mat-form-field>
          <input
            matInput
            placeholder="Adicione a sua pergunta"
            type="text"
            formControlName="pergunta"
            required
          />
        </mat-form-field>
        <mat-label class="mb-2">Multipla Escolha?</mat-label>

        <mat-radio-group formControlName="tipoVotacao">
          <mat-radio-button value="unico">Não</mat-radio-button>
          <mat-radio-button value="multipla">Sim</mat-radio-button>
        </mat-radio-group>

        <mat-checkbox class="mt-2" [(ngModel)]="temVotoNulo">Adicionar voto nulo</mat-checkbox>
        
        <div formArrayName="respostas" class="mt-3">
          <div
            *ngFor="let resposta of respostas.controls; let i = index"
            [formGroupName]="i"
          >
            <mat-form-field>
              <input
                matInput
                type="text"
                placeholder="Adicionar Respostas"
                formControlName="resposta"
                required
              />
            </mat-form-field>
            <button mat-icon-button (click)="removeResposta(i)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button (click)="addResposta()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>

        <div class="button-container">          
          <button mat-button matStepperPrevious>Voltar</button>
          <button mat-button (click)="cancelar()">Cancelar Votação</button>
          <button mat-button (click)="adicionarPergunta()">
            Enviar Votação
          </button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
