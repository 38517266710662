
<form name="educacaoForm"
      id="educacaoForm"
      class="mt-3"
      [formGroup]="educacaoForm"
      (ngSubmit)="submit(educacaoForm, isInscricao)">
      <fieldset class="border p-3 my-3">
        <legend class="legendFormulario">Educação </legend>

    <div class="row">
      <div class="form-group col-md-6">
        <label class="texto--sm"
              for="formacao">Formação</label>
        <select class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="formacao"
                [class.is-invalid]="
                formacao.invalid && (formacao.touched || isSubmitted)"
                id="formacao"
                name="formacao">
          <option value=""
                  disabled>Selecione</option>
          <option *ngFor="let formacao of formacoes"
                  value="{{formacao.idFormacao}}">
            {{ formacao.descricao }}
          </option>
        </select>
        <div *ngIf="formacao.invalid && (formacao.touched || isSubmitted)">
          <span class="erro"
                *ngIf="formacao.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label class="texto--sm"
              for="curso">Curso</label>
        <input class="form-control form-control-sm"
              [class.is-invalid]="
              curso.invalid && (curso.touched || isSubmitted)"
              formControlName="curso"
              id="curso"
              name="curso"
              type="text" />
              <div *ngIf="curso.invalid && (curso.touched || isSubmitted)">
                <span class="erro"
                      *ngIf="curso.errors.required">Campo obrigatório</span>
              </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-6">
        <label class="texto--sm"
              for="instituicao">Instituição</label>
              <input class="form-control form-control-sm"
              [class.is-invalid]="
              instituicao.invalid && (instituicao.touched || isSubmitted)"
              formControlName="instituicao"
              id="instituicao"
              name="instituicao"
              type="text" />
        <div *ngIf="instituicao.invalid && (instituicao.touched || isSubmitted)">
          <span class="erro"
                *ngIf="instituicao.errors.required">Campo obrigatório</span>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label class="texto--sm"
              for="dataConclusao">Data da conclusão</label>
        <input class="form-control form-control-sm"
              [class.is-invalid]="
              dataConclusao.invalid && (dataConclusao.touched || isSubmitted)"
              formControlName="dataConclusao"
              id="dataConclusao"
              placeholder="Outra"
              name="dataConclusao"
              [max] ="dataAtual"
              type="date" />
              <div *ngIf="dataConclusao.invalid && (dataConclusao.touched || isSubmitted)">
                <span class="erro"
                      *ngIf="dataConclusao.errors.required">Campo obrigatório</span>
              </div>
      </div>
    </div>
    <section *ngIf="!isInscricao">
      <div class="row">
        <div class="form-group col-md-12">
          <label class="texto--sm"
                for="formacao">Área de atuação/Conhecimento</label>
        </div>
      </div>
      <!-- CAMPO VAI SER REPETIDO -->
      <div class="row">
        <div class="form-group col-md-12" >
          <div class="col-md-6" *ngFor="let conhecimento of conhecimentos">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input"
                    type="checkbox"
                    id="conhecimento.idAreaAtuacaoConhecimento"
                    name="conhecimento-{{conhecimento.idAreaAtuacaoConhecimento}}"
                    [(ngModel)]="conhecimento.checked"
                    [ngModelOptions]="{standalone: true}"
                    >
              <label class="ml-2">{{conhecimento.descricao}}</label>
            </div>
        </div>
        <div class="col-md-6">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input"
                  type="checkbox"
                  (change)="verificaoCampoOutraAtuacao()"
                  id="outroAreaAtuacao"
                  name="outroAreaAtuacao"
                  [(ngModel)]="outroAreaAtuacao"
                  [ngModelOptions]="{standalone: true}">

            <label class="ml-2">Outros</label>
            <input class="form-control form-control-sm"
            [class.is-invalid]="
            areaAtuacaoOutro.invalid && (areaAtuacaoOutro.touched || isSubmitted)"
            formControlName="areaAtuacaoOutro"
            id="areaAtuacaoOutro"
            placeholder="se outros, escreva aqui"
            name="areaAtuacaoOutro"
            type="text" />
            <div *ngIf="areaAtuacaoOutro.invalid && (areaAtuacaoOutro.touched || isSubmitted)">
              <span class="erro"
                    *ngIf="areaAtuacaoOutro.errors.required">Campo obrigatório</span>
            </div>
          </div>
      </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label class="texto--sm"
                for="formacao">Certificações / Licença de Trabalho (Apenas certificações válidas)</label>
        </div>
      </div>
      <!-- CAMPO VAI SER REPETIDO  CERTIFICACAO-->
      <div class="row">
        <div class="form-group col-md-12">
          <div class="col-md-6" *ngFor="let certificado of certificados">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input"
                    type="checkbox"
                    id="certificado.idCertificado"
                    name="certificado-{{certificado.idCertificado}}"
                    [(ngModel)]="certificado.checked"
                    [ngModelOptions]="{standalone: true}"
                    >
              <label class="ml-2">{{certificado.descricao}}</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input"
                    type="checkbox"
                    id="outroCertificacao"
                    name="outroCertificacao"
                    (change)="verificaoCampoOutraCertificacao()"
                    [(ngModel)]="outroCertificacao"
                    [ngModelOptions]="{standalone: true}">

              <label class="ml-2">Outros</label>
              <input class="form-control form-control-sm"
              [class.is-invalid]="
              certificacaoOutro.invalid && (certificacaoOutro.touched || isSubmitted)"
              formControlName="certificacaoOutro"
              id="certificacaoOutro"
              placeholder="se outros, escreva aqui"
              name="certificacaoOutro"
              type="text" />
              <div *ngIf="certificacaoOutro.invalid && (certificacaoOutro.touched || isSubmitted)">
                <span class="erro"
                      *ngIf="certificacaoOutro.errors.required">Campo obrigatório</span>
              </div>
            </div>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label> Anexar Arquivo (Diploma ou Certificado).</label>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="input-group mb-3">
                <div [accept]="'image/jpg, image/png, application/pdf, application/vnd.ms-word,.zip, .rar'"
                  (change)="onSelect($event)"
                  class="custom-dropzone-anexos"
                  #drop
                  [disabled]="view"
                  [maxFileSize]="2097152"
                  [multiple]="false"
                  ngx-dropzone>
                  <ngx-dropzone-label class="label-arquivo">
                    Escolher arquivo
                  </ngx-dropzone-label>
                </div>
              </div>
            </div>
            <div class="col-md-9 col-sm-12 mt-2">
              <div class="text-primary mt-1"
                  *ngIf="nomeArq == null">
                Nenhum arquivo selecionado
              </div>
              <div class="text-primary"
                                  *ngIf="nomeArq !==null">
                {{ nomeArq }}

                <button class="ml-2 btn btn-danger"
                        (click)="onRemove()"
                        id="remove"
                        [disabled]="view"
                        *ngIf="files.length > 0 || nomeArq !== null "
                        title="Remover arquivos">
                  Remover Arquivo
                </button>
              </div>
            </div>
          </div>
          <p class="mt-1 text-primary"
                *ngIf="
                  (files.length === 0 && nomeArq == '') ||
                  (nomeArq == null && !view)
                ">
                  Formatos do arquivo PNG, JPG, DOC e ZIP com tamanho máximo de 2MB.
          </p>
        </div>
      </div>
    </section>

      <div class="row mt-4"*ngIf ="!edit" >
        <div align="center">
          <button
          class="btn btn-sm btn-primary ml-2"
          type="submit"
          id="salvarInscricaoEducacao"
          [ngStyle]="{'visibility': isInscricao ? 'hidden' : 'visible'}"
          >
            Incluir
          </button>

          <button
          class="btn btn-sm btn-primary mr-4"
          type="button"
          *ngIf="isInscricao && educacoes.length > 0"
          (click)="submit(educacaoForm, false)"
          id="incluirInscricaoEducacao"
          style="float: right"
          >
            Incluir
          </button>
        </div>
      </div>
      <div class="row mt-4" *ngIf ="edit">
        <div align="center">
          <button
          (click)="cancelar()"
          class="btn btn-sm btn-secondary ml-2"
          type="button"

        >
         Cancelar
        </button>
          <button
          class="btn btn-sm btn-primary ml-2"
          type="submit"

        >
          Salvar
        </button>

        </div>
      </div>
      </fieldset>
</form>
<span *ngIf ="isInscricao">*Caso já possua dados educacionais cadastrados pode prosseguir</span>
<div class="row mt-4 empresa-inclusa" *ngFor="let educacao of educacoes">
  <div class="col-md-12 mt-4" >
    <div class="row">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <label class="label-weight">Escolaridade</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <Label>Formação: </Label><span> {{educacao.descricaoFormacao}}</span>
          </div>
          <div class="col-md-6">
            <label>Curso: </label><span> {{educacao.formacaoCurso}}</span>
          </div>
          <div class="col-md-6">
            <label>Conclusão: </label><span> {{educacao.fim | date: 'dd/MM/yyyy'}}</span>
          </div>
          <div class="col-md-6">
            <label>Instituição: </label><span> {{educacao.formacaoInstituicao}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="educacao.habilitaEditarExcluir">
        <div align="center">
          <button
          class="btn-sm btn-primary"
          type="button"
          *ngIf ="!isInscricao"
          (click)="editar(educacao)"
        >
         Editar
        </button>
        </div>
        <div align="center" class="mt-1">
          <button
          class="btn-sm btn-secundary btn-excluir"
          type="button"
          [disabled]="edit"
          (click)="excluir(educacao)"
          *ngIf ="!isInscricao"
        >
          Excluir
        </button>
        </div>
      </div>
    </div>
      <div class="row" >
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12">
              <label class="label-weight">Área de atuação/Conhecimento</label>
            </div>
          </div>
          <!-- VAI REPETIR -->
          <div class="row" *ngFor="let trilhaPessoaCertificado of educacao.trilhaPessoaItemConhecimento">
            <div class="col-md-12">
              <label>{{trilhaPessoaCertificado.descricaoConhecimento}}</label>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12">
              <label class="label-weight">Certificações/Licença de Trabalho</label>
            </div>
          </div>
            <!-- VAI REPETIR -->
          <div class="row" *ngFor="let trilhaPessoaItemConhecimento of educacao.trilhaPessoaItemCertificado">
            <div class="col-md-12">
              <label>{{trilhaPessoaItemConhecimento.descricaoCertificado}}</label>
            </div>
          </div>
        </div>

      </div>

      <div class="row mt-2">
        <div class="col-md-12 situacaoMotivo">
          <label >Situação: <span [ngStyle]="{'color': (educacao.descricaoTrilhaItemSituacao == 'Aprovado') ? '#78C000' : (educacao.descricaoTrilhaItemSituacao == 'Análise Pendente') ? '#dbcb25' : 'red'}" style="font-weight: bolder;">{{educacao.descricaoTrilhaItemSituacao}}</span></label>
        </div>
        <div class="col-md-12">
          <label class="text-primary">{{educacao.nomeArquivo}}</label>
        </div>
        <div class="col-md-12 mt-2 situacaoMotivo"
        *ngIf="educacao.habilitaJustificativa">
          <label>Motivo: {{educacao.justificativaReprovacao}}</label>
        </div>
      </div>
  </div>
</div>




