import { AfterViewInit, Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router} from '@angular/router';
import { TermoAceite } from './TermoAceiteModel/TermoAceite';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modalEdital',
  templateUrl: './modalEdital.component.html',
  styleUrls: ['./modalEdital.component.scss']
})
export class ModalEditalComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  @Output() finishedLoadingEdital: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() edital = new EventEmitter();

  termo: any;
  textoTermo: any;
  termoProcessoCertificadoForm: FormGroup;
  id: any;
  config: any;
  constructor(
    private route: Router,
    private serviceCertificado: AreaLogadaInscricaoService,
    private formBuilder: FormBuilder,
  ) {
    this.init();
    this.id = localStorage.getItem('userId');
    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '500px',
      toolbar: [
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    }
  }

  ngOnInit() {
    this.carregarTextoCertificado();
  }

  carregarTextoCertificado(){
    this.serviceCertificado
      .obterEditalManualV2()
      .subscribe((resp) => {
        console.log("modalEditalComponent.ts")

        resp.resultado.manual.descricao = this.serviceCertificado.listAceiteAoEditalDoExame("aceite-manual-da-certificacao")
        resp.resultado.edital.descricao = this.serviceCertificado.listAceiteAoEditalDoExame("aceite-ao-edital-do-exame")

        this.termo = resp.resultado.edital;
        this.textoTermo = resp.resultado.edital.descricao;
        this.finishedLoadingEdital.emit(true);
        this.termoProcessoCertificadoForm.controls.edital.setValue(resp.resultado.edital.descricao);
        this.basicModal.show();
      }, (erro) => {
        Swal.fire(
          'Termo de processo de certificação',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  verificarCertificadoAceito() {
    this.serviceCertificado
    .verificarCertificadoAceito(JSON.parse(this.id), JSON.parse("1"))
    .subscribe((resp) => {
      if(resp.resultado.length < 1) {
        this.basicModal.show();
        this.carregarTextoCertificado();
      } else {
        this.finishedLoadingEdital.emit(true);
      }
    }, (erro) => {
      Swal.fire(
        'Termo de processo de certificação',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  htmlToText(html: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  init(){
    this.termoProcessoCertificadoForm = this.formBuilder.group({
      descricaoCertificado:[''],
      edital: ['']
    });
  }

  submit(adesaoCodigoEticaForm){
    this.edital.emit({'aceito': true});
    this.basicModal.hide();
  }

  termoNaoAceito(){
    Swal.fire('Termos', 'Para conseguirmos seguir com a inscrição é necessária a adesão dos termos', 'info');
    this.basicModal.hide();
    this.edital.emit({'aceito': false})
  }
}
