import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { PessoaService } from '../../services/pessoa/pessoa.service';
import { CpfValidator } from 'src/app/validator/cpf.validator';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { SituacaoService } from '../../services/situacao/situacao.service';

@Component({
  selector: 'app-auto-cadastro',
  templateUrl: './auto-cadastro.component.html',
  styleUrls: ['./auto-cadastro.component.scss'],
})
export class AutoCadastroComponent implements OnInit {
  @ViewChild('childModal') childModal: ModalDirective;
  @Input() isInscricao: boolean;
  @Output() validForm = new EventEmitter();
  isSubmitted: boolean;
  pessoaForm: FormGroup;

  generos: any;
  paises: any;
  estadosCivis: any;
  nacionalidades: any;
  orgaosEmissores: any;
  estados: any;
  cepLocalizado: boolean;
  emailPrimarioModel = null;
  filesRg: File[] = [];
  filesCpf: File[] = [];

  files: File[] = [];
  view = false;
  imagem = null;
  nomeImagem = null;
  nomeRg = null;
  nomeCpf = null;
  isEditar: boolean = false;
  btnVoltar = true;
  @Input() isEditarSemAnexo: boolean = false;
  dadosPessoais: any;
  rgInvalido: boolean = false;
  cpfInvalido: boolean = false;
  rgErro: boolean = false;
  semFoto: any;
  config: any;
  aceiteTermoModel = null;
  itens = [];
  desabilitaSalvar = false;
  arquivoRg: any;
  arquivoCpf: any;
  racas: any;
  deficiencias: any;
  possuiDeficiencia: boolean = false;
  pessoaDeficiencias: any;
  dadoDeficientes: any;
  renovacao: boolean = false;
  exibirTextoAtualizacao: boolean = false;
  checkedTermoCadastro: boolean = false;
  recusadoTermoCadastro: boolean = false;

  public showSelect = false;
  public ddiMask = [/\d/, /\d/, /\d/, /\d/];
  public emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  get nomeCompleto() {
    return this.pessoaForm.get('nomeCompleto');
  }

  get sobrenome() {
    return this.pessoaForm.get('sobrenome')
  }

  get tituloEleitor() {
    return this.pessoaForm.get('tituloEleitor')
  }

  get nomeMae() {
    return this.pessoaForm.get('nomeMae')
  }

  get nomePai() {
    return this.pessoaForm.get('nomePai')
  }

  get genero() {
    return this.pessoaForm.get('genero');
  }
  get dataNascimento() {
    return this.pessoaForm.get('dataNascimento');
  }
  get celular() {
    return this.pessoaForm.get('celular');
  }
  get telefoneComercial() {
    return this.pessoaForm.get('telefoneComercial');
  }
  get telefoneResidencial() {
    return this.pessoaForm.get('telefoneResidencial');
  }

  get emailPrimario() {
    return this.pessoaForm.get('emailPrimario');
  }
  get emailSecundario() {
    return this.pessoaForm.get('emailSecundario');
  }
  get linkedin() {
    return this.pessoaForm.get('linkedin');
  }
  get nacionalidade() {
    return this.pessoaForm.get('nacionalidade');
  }
  get numeroPassapote() {
    return this.pessoaForm.get('numeroPassapote');
  }
  get paisEmissor() {
    return this.pessoaForm.get('paisEmissor');
  }
  get tipo() {
    return this.pessoaForm.get('tipo');
  }
  get cpf() {
    return this.pessoaForm.get('cpf');
  }
  get documento() {
    return this.pessoaForm.get('documento');
  }

  get digito() {
    return this.pessoaForm.get('digito');
  }
  get dataEmissao() {
    return this.pessoaForm.get('dataEmissao');
  }
  get ufExpedicao() {
    return this.pessoaForm.get('ufExpedicao');
  }
  get orgaoExpedicao() {
    return this.pessoaForm.get('orgaoExpedicao');
  }
  get dataEmissaoEstrangeiro() {
    return this.pessoaForm.get('dataEmissaoEstrangeiro');
  }
  get dataValidade() {
    return this.pessoaForm.get('dataValidade');
  }
  get pais() {
    return this.pessoaForm.get('pais');
  }
  get codigoPostal() {
    return this.pessoaForm.get('codigoPostal');
  }
  get endereco() {
    return this.pessoaForm.get('endereco');
  }
  get numero() {
    return this.pessoaForm.get('numero');
  }
  get complemento() {
    return this.pessoaForm.get('complemento');
  }
  get bairro() {
    return this.pessoaForm.get('bairro');
  }
  get estado() {
    return this.pessoaForm.get('estado');
  }
  get cidade() {
    return this.pessoaForm.get('cidade');
  }
  get uf() {
    return this.pessoaForm.get('uf');
  }
  get comercialDdi() {
    return this.pessoaForm.get('comercialDdi');
  }
  get celularDdi() {
    return this.pessoaForm.get('celularDdi');
  }
  get residencialDdi() {
    return this.pessoaForm.get('residencialDdi');
  }
  get deficienciaRadio() {
    return this.pessoaForm.get('deficienciaRadio');
  }
  get raca() {
    return this.pessoaForm.get('raca');
  }
  get deficiencia() {
    return this.pessoaForm.get('deficiencia');
  }
  get termoCadastro() {
    return this.pessoaForm.get('termoCadastro');
  }
  get checkTermoCadastro() {
    return this.pessoaForm.get('checkTermoCadastro');
  }
  get recusaTermoCadastro() {
    return this.pessoaForm.get('recusaTermoCadastro');
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private pessoaService: PessoaService,
    private route: ActivatedRoute,
    private serviceSituacao: SituacaoService,
  ) {

    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '200px',
      toolbar: [
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    }

    this.pessoaForm = this.formBuilder.group({
      nomeCompleto: ['', Validators.required],
      sobrenome: ['', Validators.required],
      tituloEleitor: ['', Validators.required],
      nomeMae: ['', Validators.required],
      nomePai: ['', Validators.required],
      genero: ['', Validators.required],
      dataNascimento: ['', Validators.required],
      celularDdi: ['55', Validators.required],
      celular: ['', Validators.required],
      telefoneComercial: [''],
      comercialDdi: ['55'],
      telefoneResidencial: [''],
      residencialDdi: ['55'],
      emailPrimario: [
        '',
        [Validators.required, Validators.pattern(this.emailPattern)],
      ],
      emailSecundario: ['', Validators.pattern(this.emailPattern)],
      linkedin: [''],
      nacionalidade: ['', Validators.required],
      numeroPassapote: ['', Validators.required],
      paisEmissor: ['', Validators.required],
      tipo: ['', Validators.required],
      cpf: ['', [Validators.required, CpfValidator.validCpf]],
      documento: ['', Validators.required],
      digito: [''],
      dataEmissao: ['', Validators.required],
      ufExpedicao: ['', Validators.required],
      orgaoExpedicao: ['', Validators.required],
      dataEmissaoEstrangeiro: ['', Validators.required],
      dataValidade: ['', Validators.required],
      pais: ['', Validators.required],
      codigoPostal: ['', Validators.required],
      endereco: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      estado: ['', Validators.required],
      cidade: ['', Validators.required],
      uf: ['', Validators.required],
      codigoEtica: [''],
      aceiteTermo: [''],
      deficienciaRadio: [null, Validators.required],
      raca: [null, Validators.required],
      deficiencia: this.formBuilder.array([]),
      termoCadastro: [''],
      checkTermoCadastro: [''],
      recusaTermoCadastro: ['']
    });
  }

  ngOnInit(): void {
    this.load();
    this.obterGenero();
    this.obterRaca();
    this.obterDeficiencias();
    this.obterPais();
    this.obterNacionalidade();
    this.obterOrgaoEmissor();
    this.obterEstados();

    if (!this.isInscricao) this.textoCodigoEtica();
    this.route.paramMap.subscribe(params => {
      if (params.get("editar") === 'true' || this.isInscricao) {
        this.btnVoltar = false
        this.exibirTextoAtualizacao = true;
        if (params.get("renovacao") === 'true') {
          this.renovacao = true;
        }
        this.obterDadosPessoais()

        this.desabilitaSalvar = true;
      } else {

        if (!this.checkedTermoCadastro && !this.recusadoTermoCadastro) {
          this.checkedTermoCadastro = true
          this.recusadoTermoCadastro = false
        }

      }
    });

  }

  load() {
    (sessionStorage.refresh == 'true' || !sessionStorage.refresh) && location.reload();
    sessionStorage.refresh = false;
  }

  selecionado(checked) {
    this.checkedTermoCadastro = checked;
    this.recusadoTermoCadastro = !checked;
  }

  aceiteTermo() {

  }

  mudaDeficiencia(value) {
    if (value === false) {
      this.possuiDeficiencia = false;
    } else {
      this.possuiDeficiencia = true;
    }
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.pessoaForm.get('deficiencia') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  textoCodigoEtica() {
    this.pessoaService
      .obterEditalPrivacidade()
      .subscribe((resp) => {
        this.pessoaForm.controls.codigoEtica.setValue(resp.resultado.descricao);
        this.itens = resp.resultado;
      }, (erro) => {
        Swal.fire(
          'Auto Cadastro',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  textoTermoAceiteCadastro() {
    this.pessoaService
      .obterTermoAceiteCadastro()
      .subscribe((resp) => {
        this.pessoaForm.controls.termoCadastro.setValue(resp.resultado.descricao);
        this.itens = resp.resultado;
      }, (erro) => {
        Swal.fire(
          'Auto Cadastro',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  obterLogradouroPorCep() {
    this.pessoaService
      .obterPorCep(this.pessoaForm.value.codigoPostal.replace(/\D+/g, ''))
      .subscribe((res) => {
        this.endereco.setValue(res.resultado.logradouro);
        this.bairro.setValue(res.resultado.bairro);
        this.cidade.setValue(res.resultado.localidade);
        this.estado.setValue(res.resultado.uf);
        if (
          res.resultado.logradouro !== '' &&
          this.pessoaForm.value.codigoPostal.length >= 8 &&
          res.resultado.logradouro !== null
        ) {
          this.endereco.disable();
          this.bairro.disable();
          this.estado.disable();
          this.cidade.disable();
          this.cepLocalizado = true;
        } else {
          this.endereco.enable();
          this.bairro.enable();
          this.cidade.enable();
          this.pessoaForm.patchValue({
            estado: '',
          });
          this.showSelect = true;
          this.cepLocalizado = false;
        }
      }, error => {
        console.log(error);
        this.endereco.enable();
        this.bairro.enable();
        this.cidade.enable();
        this.pessoaForm.patchValue({
          estado: '',
        });
        this.showSelect = true;
        this.cepLocalizado = false;
      });
  }

  validaCaractere(event) {
    if (event.key && event.key.match(/^(?:[A-Za-zÀ-ÿ ]+)$/)) {
      return true;
    } else {
      return false;
    }
  }

  onSelect(event) {
    this.onRemove(1);
    this.files.push(...event.addedFiles);
  }

  onSelectRg(event) {
    if (event.rejectedFiles.length) {
      this.rgInvalido = true;
      this.nomeRg = null;
      return;
    }
    this.rgInvalido = false;
    this.onRemove(2);
    this.filesRg.push(...event.addedFiles);
    this.nomeRg = this.filesRg[0].name;
  }

  onSelectCpf(event) {
    if (event.rejectedFiles.length) {
      this.cpfInvalido = true;
      this.nomeCpf = null;
      return;
    }
    this.cpfInvalido = false;
    this.onRemove(3);
    this.filesCpf.push(...event.addedFiles);
    this.nomeCpf = this.filesCpf[0].name;
  }

  onRemove(arquivo) {
    switch (arquivo) {
      case 1:
        this.files.splice(0, 1);
        break;
      case 2:
        this.filesRg.splice(0, 1);
        break;
      case 3:
        this.filesCpf.splice(0, 1);
        break;
    }
  }

  obterSituacaoRenovacao(idPessoa) {

    this.serviceSituacao.obterSituacaoRenovacao(idPessoa).subscribe((res) => {
      res.resultado.map((item) => {
        if (item.idTrilhaItem == 31 && item.quantidadeConcluida == item.quantidadeTotal) {
          this.renovacao = false;
        }
      });
    }, (erro) => {
      Swal.fire(
        'Home',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  obterDadosPessoais() {
    let idPessoa = localStorage.getItem('userId');

    this.pessoaService
      .obterPorId(idPessoa)
      .subscribe(res => {
        this.dadosPessoais = res.resultado;
        this.dadosPessoais.dataNascimento = moment(this.dadosPessoais.dataNascimento).format('YYYY-MM-DD');
        this.dadosPessoais.passaporteEmissao = moment(this.dadosPessoais.passaporteEmissao).format('YYYY-MM-DD');
        this.dadosPessoais.passaporteValidade = moment(this.dadosPessoais.passaporteValidade).format('YYYY-MM-DD');
        this.dadosPessoais.rgEmissao = moment(this.dadosPessoais.rgEmissao).format('YYYY-MM-DD');

        this.textoTermoAceiteCadastro();

        if (this.dadosPessoais.aceiteTermoCadastro)
          this.checkedTermoCadastro = true;

        if (this.dadosPessoais.aceiteTermoCadastro !== null && !this.dadosPessoais.aceiteTermoCadastro)
          this.recusadoTermoCadastro = true;

        if (!res.resultado.dddResidencial == null) {
          if (!res.resultado.telefoneResidencial == null) {
            let telefone = res.resultado.dddResidencial + res.resultado.telefoneResidencial;
            this.pessoaForm.controls.telefoneResidencial.setValue(telefone);
          }
        }
        this.isEditar = res.resultado.habilitarAnexosDocumentos;
        this.isEditarSemAnexo = true;
        this.nomeRg = res.resultado.rgNomeArquivo !== null ? res.resultado.rgNomeArquivo : null;
        this.nomeCpf = res.resultado.cpfNomeArquivo !== null ? res.resultado.cpfNomeArquivo : null;
        this.imagem = res.resultado.foto !== '' ? 'data:image/png;base64,' + res.resultado.foto : null;
        this.arquivoRg = res.resultado.rgArquivo;
        this.arquivoCpf = res.resultado.cpfArquivo;
        this.pessoaForm.controls.deficienciaRadio.setValue(res.resultado.pessoaDeficiencia.length ? true : false);
        this.possuiDeficiencia = res.resultado.pessoaDeficiencia.length ? true : false;
        const checkArray: FormArray = this.pessoaForm.get('deficiencia') as FormArray;
        this.deficiencias.forEach(el => {
          res.resultado.pessoaDeficiencia.forEach(ele => {
            if (el.idDeficiencia === ele.idDeficiencia) {
              el.checked = true;
              checkArray.push(new FormControl(String(el.idDeficiencia)));
            }
          })

        })
        if (this.renovacao)
          this.obterSituacaoRenovacao(idPessoa);
      })
  }

  obterGenero() {
    this.pessoaService.obterGeneros().subscribe((res) => {
      this.generos = res.resultado;
    });
  }

  obterRaca() {
    this.pessoaService.obterRacas().subscribe((res) => {
      this.racas = res.resultado;
    });
  }

  obterDeficiencias() {
    this.pessoaService.obterDeficiencias().subscribe((res) => {
      this.deficiencias = res.resultado;
    });
  }


  obterNacionalidade() {
    this.pessoaService.obterNacionalidade().subscribe((res) => {
      this.nacionalidades = res.resultado;

    });
  }

  obterPais() {
    this.pessoaService.obterPais().subscribe((res) => {
      this.paises = res.resultado;
      this.pais.setValue(32);
    });
  }

  obterOrgaoEmissor() {
    this.pessoaService.obterOrgaoEmissor().subscribe((res) => {
      this.orgaosEmissores = res.resultado;
    });
  }

  obterEstados() {
    this.pessoaService.obterEstados().subscribe((res) => {
      this.estados = res.resultado;
    });
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        return resolve((e.target as FileReader).result);
      };

      reader.onerror = (e) => {
        return reject(null);
      };

      if (file == null) {
        return resolve(null);
      }

      reader.readAsDataURL(file);
    });
  }

  async submit(formulario: FormGroup) {

    this.isSubmitted = true;
    let file = null;
    let arrDeficiencia = [];

    if (this.files && (this.imagem !== '' || this.imagem !== null)) {
      file = await this.readFile(this.files[0]);
    }

    if (!this.pessoaForm.controls.deficiencia.value.length && this.possuiDeficiencia) {
      Swal.fire('Dados Pessoais', 'Selecione ao menos uma deficiência.', 'info');
      return;
    }

    if (this.pessoaForm.controls.deficiencia.value.length && this.possuiDeficiencia) {
      this.pessoaForm.controls.deficiencia.value.forEach(el => {
        arrDeficiencia.push({
          idPessoa: 0,
          idDeficiencia: Number(el)
        })
      })
    }

    if (this.aceiteTermoModel !== true && this.desabilitaSalvar === false) {
      Swal.fire(
        'Cadastro',
        `Confirme a Declaração de Privacidade!`,
        'info'
      );
    } else {

      let fileRg = await this.readFile(this.filesRg[0]);
      let fileCpf = await this.readFile(this.filesCpf[0]);


      if (this.isEditar && !this.isEditarSemAnexo) {
        if (this.nomeRg === '' || this.nomeRg === null) {
          this.rgErro = true;

          return;
        } else {
          this.rgErro = false;
          fileRg = await this.readFile(this.filesRg[0]);
          fileCpf = await this.readFile(this.filesCpf[0]);
        }
      }

      let estado = ((this.estado.valid || this.cepLocalizado == true) ? this.estado.value : null);
      let cidade = ((this.cidade.valid || this.cepLocalizado == true) ? this.cidade.value : null);
      let bairro = ((this.bairro.valid || this.cepLocalizado == true) ? this.bairro.value : null);
      let endereco = ((this.endereco.valid || this.cepLocalizado == true) ? this.endereco.value : null);

      if (this.pessoaForm.controls.deficienciaRadio.value === true && (this.pessoaForm.controls.deficiencia.value === '' || this.pessoaForm.controls.deficiencia.value === null || !this.pessoaForm.controls.deficiencia.value)) {
        return;
      }


      if (this.nacionalidade.value != '3') {
        if (this.dataEmissao.value === 'Invalid date') {
          this.validForm.emit(false);
          this.isSubmitted = true;
          return;
        }

        if (
          this.nomeCompleto.valid &&
          this.sobrenome.valid &&
          this.tituloEleitor.valid &&
          this.nomeMae.valid &&
          this.nomePai.valid &&
          this.dataNascimento.valid &&
          this.celular.valid &&
          this.emailPrimario.valid &&
          this.nacionalidade.valid &&
          this.cpf.valid &&
          this.documento.valid &&
          this.dataEmissao.value !== 'Invalid date' &&
          this.ufExpedicao.valid &&
          this.orgaoExpedicao.valid &&
          this.pais.valid &&
          this.codigoPostal.valid &&
          this.numero.valid &&
          estado &&
          cidade &&
          bairro &&
          endereco &&
          this.deficienciaRadio.valid &&
          this.raca.valid
        ) {
          this.salvar({
            aceiteTermoCadastro: this.checkedTermoCadastro,
            conteudoAceiteTermoCadastro: this.pessoaForm.value.termoCadastro,
            nome: this.pessoaForm.value.nomeCompleto,
            sobrenome: this.pessoaForm.value.sobrenome,
            tituloEleitor: this.pessoaForm.value.tituloEleitor,
            nomeMae: this.pessoaForm.value.nomeMae,
            nomePai: this.pessoaForm.value.nomePai,
            idGenero: Number(this.genero.value),
            dataNascimento: this.pessoaForm.value.dataNascimento,
            cpf: this.pessoaForm.value.cpf,
            email: this.pessoaForm.value.emailPrimario,
            ddiCelular: this.celularDdi.value,
            dddCelular: String(this.celular.value).substring(0, 2),
            telefoneCelular: String(this.celular.value).substring(2, 11),
            ddiComercial: this.comercialDdi.value,
            dddComercial: this.telefoneComercial.value.substring(0, 2),
            telefoneComercial: this.telefoneComercial.value.substring(2, 10),
            ddiResidencial: this.residencialDdi.value,
            dddResidencial: this.telefoneResidencial.value.substring(0, 2),
            telefoneResidencial: this.telefoneResidencial.value.substring(2, 10),
            emailSecundario: this.pessoaForm.value.emailSecundario,
            linkedin: this.pessoaForm.value.linkedin,
            idNacionalidade: Number(this.nacionalidade.value),
            rgNumero: this.pessoaForm.value.documento,
            rgDigito: this.pessoaForm.value.digito,
            rgEmissao: this.dataEmissao.value,
            idCorPele: Number(this.pessoaForm.controls.raca.value),
            pessoaDeficiencia: arrDeficiencia,
            rg_IdOrgaoEmissor: Number(this.orgaoExpedicao.value),
            rg_IdEstado: Number(this.ufExpedicao.value),
            foto: file ? String(file).replace(/^data:(.*;base64,)?/, '') : String(this.imagem).replace(/^data:(.*;base64,)?/, ''),
            rgArquivo: fileRg ? String(fileRg).replace(/^data:(.*;base64,)?/, '') : this.arquivoRg,
            rgNomeArquivo: this.isEditar || this.isInscricao ? String(this.nomeRg) : null,
            cpfNomeArquivo: this.isEditar || this.isInscricao ? String(this.nomeCpf) : null,
            cpfArquivo: fileCpf ? String(fileCpf).replace(/^data:(.*;base64,)?/, '') : this.arquivoCpf,
            pessoaEndereco: [
              {
                idPessoa: 0,
                idPais: Number(this.pais.value),
                codigoPostal: this.pessoaForm.value.codigoPostal,
                endereco: endereco,
                numero: this.pessoaForm.value.numero,
                complemento: this.pessoaForm.value.complemento,
                bairro: bairro,
                cidade: cidade,
                estado: estado,
              },
            ],
          });
        } else {

          alert("Preencha todos os campos corretamente")

        }
      } else if (this.nacionalidade.value == '3') {
        if (this.dataEmissaoEstrangeiro.value === 'Invalid date' || this.dataValidade.value === 'Invalid date') {
          this.isSubmitted = true;
          this.validForm.emit(false);
          return;
        }
        if (
          this.nomeCompleto.valid &&
          this.sobrenome.valid &&
          this.tituloEleitor.valid &&
          this.nomePai.valid &&
          this.nomeMae.valid &&
          this.dataNascimento.valid &&
          this.celular.valid &&
          this.emailPrimario.valid &&
          this.nacionalidade.valid &&
          this.numeroPassapote.valid &&
          this.paisEmissor.valid &&
          this.tipo.valid &&
          this.documento.valid &&
          this.dataEmissaoEstrangeiro.value !== 'Invalid date' &&
          this.dataValidade.value !== 'Invalid date' &&
          this.pais.valid &&
          this.codigoPostal.valid &&
          this.numero.valid &&
          estado &&
          cidade &&
          bairro &&
          endereco &&
          this.deficienciaRadio.valid &&
          this.raca.valid
        ) {
          this.salvar({
            aceiteTermoCadastro: this.checkedTermoCadastro,
            conteudoAceiteTermoCadastro: this.pessoaForm.value.termoCadastro,
            nome: this.pessoaForm.value.nomeCompleto,
            sobrenome: this.pessoaForm.value.sobrenome,
            tituloEleitor: this.pessoaForm.value.tituloEleitor,
            nomePai: this.pessoaForm.value.nomePai,
            nomeMae: this.pessoaForm.value.nomeMae,
            idGenero: Number(this.genero.value),
            dataNascimento: this.pessoaForm.value.dataNascimento,
            cpf: this.pessoaForm.value.cpf,
            email: this.pessoaForm.value.emailPrimario,
            ddiCelular: this.celularDdi.value,
            dddCelular: String(this.celular.value).substring(0, 2),
            telefoneCelular: String(this.celular.value).substring(2, 11),
            ddiComercial: this.comercialDdi.value,
            dddComercial: this.telefoneComercial.value.substring(0, 2),
            telefoneComercial: this.telefoneComercial.value.substring(2, 10),
            ddiResidencial: this.residencialDdi.value,
            dddResidencial: this.telefoneResidencial.value.substring(0, 2),
            telefoneResidencial: this.telefoneResidencial.value.substring(2, 10),
            emailSecundario: this.pessoaForm.value.emailSecundario,
            linkedin: this.pessoaForm.value.linkedin,
            idCorPele: Number(this.pessoaForm.controls.raca.value),
            pessoaDeficiencia: arrDeficiencia,
            idNacionalidade: Number(this.nacionalidade.value),
            rgNumero:
              this.pessoaForm.value.documento !== ''
                ? this.pessoaForm.value.documento
                : null,
            passaporteNumero:
              this.pessoaForm.value.numeroPassapote !== ''
                ? this.pessoaForm.value.numeroPassapote
                : null,
            passaporte_IdPais: Number(this.paisEmissor.value),
            passaporteTipo:
              this.pessoaForm.value.tipo !== ''
                ? this.pessoaForm.value.tipo
                : null,
            passaporteEmissao:
              this.pessoaForm.value.dataEmissaoEstrangeiro !== ''
                ? this.pessoaForm.value.dataEmissaoEstrangeiro
                : null,
            passaporteValidade:
              this.pessoaForm.value.dataValidade !== ''
                ? this.pessoaForm.value.dataValidade
                : null,
            foto: file ? String(file).replace(/^data:(.*;base64,)?/, '') : String(this.imagem).replace(/^data:(.*;base64,)?/, ''),
            rgArquivo: fileRg ? String(fileRg).replace(/^data:(.*;base64,)?/, '') : this.arquivoRg,
            rgNomeArquivo: this.isEditar || this.isInscricao ? String(this.nomeRg) : null,
            cpfNomeArquivo: this.isEditar || this.isInscricao ? String(this.nomeCpf) : null,
            cpfArquivo: fileCpf ? String(fileCpf).replace(/^data:(.*;base64,)?/, '') : this.arquivoCpf,
            pessoaEndereco: [
              {
                idPessoa: this.isEditarSemAnexo ? Number(localStorage.getItem('userId')) : 0,
                idPais: Number(this.pais.value),
                codigoPostal:
                  this.pessoaForm.value.codigoPostal !== ''
                    ? this.pessoaForm.value.codigoPostal
                    : null,
                endereco: endereco,
                numero: this.pessoaForm.value.numero,
                complemento: this.pessoaForm.value.complemento,
                bairro: bairro,
                cidade: cidade,
                estado: estado,
              },
            ],
          });
        } else {
          alert("Preencha todos os campos corretamente")

        }
      } else {
        this.validForm.emit(false);
      }
    }
  }

  voltar() {
    this.router.navigate(['/login']);
  }

  salvar(pessoaForm) {

    if (!this.checkedTermoCadastro && !this.recusadoTermoCadastro) {
      Swal.fire(
        'Termo sobre compartilhamento de dados',
        'Necessário selecionar o aceite ou recusa do compartilhamento de dados.',
        'error'
      ).then(() => {
      });

      return;
    }

    if (this.codigoPostal.value.length < 8 && this.pais.value == 32) {
      Swal.fire(
        'CEP inválido!',
        'O CEP deve possuir 8 caracteres.',
        'error'
      ).then(() => { });

      return;
    }

    if (this.isEditarSemAnexo) {
      pessoaForm.idPessoa = Number(localStorage.getItem('userId'));

      if (this.pessoaForm.controls.deficiencia.value.length && this.possuiDeficiencia) {
        pessoaForm.pessoaDeficiencia = [];
        this.pessoaForm.controls.deficiencia.value.forEach(el => {
          pessoaForm.pessoaDeficiencia.push({
            idPessoa: Number(localStorage.getItem('userId')),
            idDeficiencia: Number(el)
          })
        })
      }

      this.pessoaService
        .atualizarPessoa(pessoaForm)
        .subscribe((res) => {
          Swal.fire(
            'Dados Pessoais',
            this.isInscricao ? `Dados Pessoais confirmados!` : `Dados Pessoais atualizados com sucesso!`,
            'success'
          ).then(() => {
            if (this.isInscricao) this.validForm.emit(true);
            else this.router.navigate(['/home']);
          });

        },
          (error) => {
            this.validForm.emit(false);
            Swal.fire(this.isInscricao ? 'Dados Pessoais' : 'Auto Cadastro', error.error.excecao[0].mensagem, 'error');
          }
        );

    } else {
      this.pessoaService
        .criarPessoa(pessoaForm)
        .subscribe((res) => {
          //   Swal.fire(
          //     'Auto Cadastro',
          //     `Auto Cadastro Realizado com sucesso!`,
          //     'success'
          //   ).then(() => {
          //     this.router.navigate(['/login']);
          //   });

          this.childModal.show();

        },
          (error) => {
            Swal.fire('Auto Cadastro', error.error.excecao[0].mensagem, 'error');
          }
        );
    }
  }

  hideChildModal(): void {
    this.childModal.hide();
    this.router.navigate(['/login']);

  }
}
