import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DebitosService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }



  obterPorPessoa(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-debito/obter-debito-por-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  reimprimirBoleto(codigoDebito: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-debito/reimprimir-boleto`,
      {
        headers: this.httpHeaders,
        params: { codigoDebito },
      }
    );
  }
  
  gerarRecibo(codigoDebito: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-debito/gerar-recibo`,
      {
        headers: this.httpHeaders,
        params: { codigoDebito },
      }
    );
  }
  
}
