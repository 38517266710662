<div class="container-principal">
  <div class="row col-md-12">
    <fieldset class="border p-3 my-3">
      <legend class="legendFormulario">Agendas </legend>
      <div class="row"
           *ngIf="exibeAgenda">
        <div class="col-md-5 ml-5">
          <p class="texto--sm">Ralização de {{prospeccao.realizacaoInicio | date: 'dd/MM/yyyy'}} a
            {{prospeccao.realizacaoFim | date: 'dd/MM/yyyy'}} </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right mr-5 mb-3">
          <button class="btn btn-sm btn-primary m-0"
                  id="btnAdicionar"
                  routerLink="adicionar"
                  title="Adicionar">
            Adicionar
          </button>
        </div>
      </div>
      <table class="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Dia</th>
            <th>Hora</th>
            <th>Tipo</th>
            <th>Tema</th>
            <th>Público</th>
            <th>Palestrante</th>
            <th>Backup</th>
            <th>Ouvinte</th>
            <th width="130px">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let agenda of agendas; let i = index">
            <td>{{agenda.data | date: 'dd/MM/yyyy'}}</td>
            <td>{{agenda.data.substring(11, 16)}}</td>
            <td>{{agenda.descricaoTipoAgenda}}</td>
            <td>{{agenda.descricaoTema}}</td>
            <td>{{agenda.descricaoPublico}}</td>
            <td>{{agenda.habilitarPalestrante === true ? 'Sim' : 'Não'}}</td>
            <td>{{agenda.habilitarBackup === true ? 'Sim' : 'Não'}}</td>
            <td>{{agenda.habilitarOuvinte === true ? 'Sim' : 'Não'}}</td>
            <td class="text-center"
                width="120px">
              <div class="row">
                <button class="btn btn-sm"
                        (click)="visualizar(agenda.idEventoAgenda)"
                        title="Visualizar"
                        type="button">
                  <img class="logo"
                       src="../../../../../assets/img/icons/visualizar.svg"
                       style=" height: 15px;
                          position: relative;" />
                </button>
                <button class="btn btn-sm"
                        (click)="editar(agenda.idEventoAgenda)"
                        title="Editar"
                        type="button">
                  <img class="logo"
                       src="../../../../../assets/img/icons/editar.svg"
                       style=" height: 15px;
                  position: relative;" />
                </button>
                <button class="btn btn-sm"
                        (click)="excluir(agenda.idEventoAgenda)"
                        title="Excluir"
                        type="button">
                  <img class="logo"
                       src="../../../../../assets/img/icons/excluir.svg"
                       style=" height: 15px;
                          position: relative;" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</div>