import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DebitosService } from 'src/app/services/debitos/debitos.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-debitos',
  templateUrl: './debitos.component.html',
  styleUrls: ['./debitos.component.scss']
})
export class DebitosComponent implements OnInit {
  files: File[] = [];
  view = false;
  imagem = null;
  usuario = null;
  debito = null;
  certificado = null
  boleto = null;
  novaDataVencimento = null
  public showModalBoletoDataVencimento = false
  public lastDebitClicked = null

  constructor(private router: Router,
    private route: ActivatedRoute, private debitoService: DebitosService) { }

  ngOnInit(): void {
    this.usuario = window.localStorage.getItem('userId');

    this.obterDebitos();
  }

  verificaRegraSeDeveExibitBotaoDePagar(debito) {
    // !debitos.habilitarRecibo && debitos.habilitarCartao && debitos.idDebitoSituacao != 2 && debitos.descricaoSituacao === 'Pendente'
    const currentDebito = this.debito.find(d => d.idDebito === debito.idDebito)

    if(currentDebito.desabilitarPagamento) {
      return false
    }

    if (currentDebito.idDebitoSituacao == 2) {
      return true
    }

    //verifica se ta pendente
    if (currentDebito.idDebitoSituacao == 1) {

      if (!currentDebito.habilitarRecibo && currentDebito.habilitarCartao) {
        return true
      }

      // verifica se ta vencido
      if (currentDebito.dataVencimento) {
        const dataVencimento = new Date(currentDebito.dataVencimento)
        if (new Date() > dataVencimento) {
          return true
        }
      }
    }

    return false
  }

  setNovaDataVencimento(event) {
    this.novaDataVencimento = event.target.value
  }

  obterBoleto(codigoDebito) {
    this.debitoService.reimprimirBoleto(codigoDebito).subscribe(resul => {

      const linkSource = `data:application/octet-stream;base64,${resul.resultado.boleto}`;
      const downloadLink = document.createElement('a');
      const fileName = 'Boleto_' + codigoDebito + '.pdf';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

    }, error => {
      console.log(error);
    }
    )
  }


  obterRecibo(codigoDebito) {
    this.debitoService.gerarRecibo(codigoDebito).subscribe(resul => {

      const linkSource = `data:application/octet-stream;base64,${resul.resultado.boleto}`;
      const downloadLink = document.createElement('a');
      const fileName = 'Recibo_' + codigoDebito + '.pdf';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

    }, error => {
      console.log(error);
    }
    )
  }

  fazerEstorno(idDebito: number) {

    const c = confirm("Deseja solicitar o cancelamento do debito ?")

    if (c) {

      const url = `${environment.backofficeUrl}/estorna-um-pagamento/${idDebito}`
      fetch(url, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("tknCdst")
        }
      }).then(res => {

        if (res.ok) {
          window.location.reload()
        }
      })
    }
  }

  obterDebitos() {
    this.debitoService.obterPorPessoa(this.usuario).subscribe((result) => {
      this.debito = result.resultado.sort((a, b) => {

        return a.idDebito < b.idDebito ? 1 : -1;
      })
      this.certificado = result.certificado
    });
  }

  public voltarParaPendente(debito) {
    const dt = new Date(debito.dataCriacao)
    const now = new Date()

    dt.setDate(dt.getDate() + 59)

    return now > dt
  }


  public verificaSeDataAtualMaiorQueSeteDias(debito) {
    if (debito.dataPagamento) {

      const dt = new Date(debito.dataPagamento)
      const now = new Date()

      dt.setDate(dt.getDate() + 7)

      return now < dt
    }

    return false
  }

  public gerarBoleto(debito) {
    const idDebito = debito.idDebito

    this.lastDebitClicked = debito

    if (!debito.dataVencimento && !this.novaDataVencimento) {
      this.showModalBoletoDataVencimento = true
      return
    }

    let url = `${environment.backofficeUrl}/custom-gerar-boleto/${idDebito}`

    if (this.novaDataVencimento) {
      url += `?novaDataVecimento=${this.novaDataVencimento}`
      this.novaDataVencimento = null
      this.lastDebitClicked = null
    }

    fetch(url, {
      method: "GET",
    }).then(res => {

      if (res.ok) {
        alert("Solicitação de geração de boleto concluída, aguarde a página recarregar.")
        window.location.reload()
      } else {
        res.json().then(json => {
          this.showModalBoletoDataVencimento = false
          alert(json.excecao[0].mensagem)
        })
      }
    })
  }
}
