import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
  topNavs = [
    {
      name: 'Blog',
      subnavs: [
        { name: 'Blog Listing' },
        { name: 'Standard Post' },
        { name: 'Gallery Post' },
        { name: 'Video Post' },
        { name: 'Audio Post' },
      ],
    },
    {
      name: 'Portfolio',
      subnavs: [{ name: 'Project List' }, { name: 'Project Category' }],
    },
    {
      name: 'Shop',
      subnavs: [{ name: 'Shop List' }, { name: 'Single Product' }],
    },
    {
      name: 'bbPress',
      subnavs: [],
    },
    {
      name: 'BuddyPress',
      subnavs: [{ name: 'Members' }, { name: 'Side-Wide Activity' }],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
