import { Component, OnInit } from '@angular/core';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { EducaService } from 'src/app/services/educa-service/educa-service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  id: any;
  menus = [];
  certificado : any;
  certificadoTreina: any;
  baseConhecimento = null;
  resultadoEducaService: any;
  constructor(
    private servicePessoa: PessoaService,
    private educaService: EducaService,
    private inscricaoService: AreaLogadaInscricaoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
  }

  ngOnInit(): void {

    this.servicePessoa.obterBaseConhecimento(this.id).subscribe((resp) => {
      this.baseConhecimento = resp.resultado;
    }, (error) => {
      Swal.fire('Acessos', error.error.excecao[0].mensagem, 'error');
    });

    this.servicePessoa.obterAcessosPessoa(this.id).subscribe((resp) => {
      this.menus = resp.resultado;
    }, (error) => {
      Swal.fire('Acessos', error.error.excecao[0].mensagem, 'error');
    });

    this.servicePessoa.obterCertificadoPessoa(this.id).subscribe((resp) => {
      this.certificado = resp.resultado;
    }, (error) => {
      Swal.fire('Acessos', error.error.excecao[0].mensagem, 'error');
    });

    this.servicePessoa.obterCertificadoPessoaTreina(this.id).subscribe((resp) => {
      this.certificadoTreina = resp.resultado;
    }, (error) => {
      Swal.fire('Acessos', error.error.excecao[0].mensagem, 'error');
    });

  }

  irParaVotacao() {
    this.router.navigate(['/CriarVotacao']);
  }

  limpaDados(item) {

    if(item == 'inscricao')
      this.verificarListaBloqueio();

    localStorage.removeItem('passo1');
    localStorage.removeItem('modulos');
    localStorage.removeItem('itens');
    localStorage.removeItem('edital');
  }

  verificarListaBloqueio()
  {

    this.inscricaoService.obterBloqueiosAtivos(this.id).subscribe((result) => {
      if(result.resultado!=null)
      {
        Swal.fire(
          'Inscrição',
          'Por motivos de bloqueio você não poderá seguir com sua inscrição para o Exame CFP®. Para obter informações entre em contato com nosso time de atendimento.',
          'info'
        );
        this.router.navigate(['/home']);
      }

      return;
    });

  }

  emitirCertificado(){
    this.servicePessoa.obterCertificado(this.id).subscribe((resp)=>{
      if(resp.resultado !== null && resp.resultado.arquivo !== ''){
        const linkSource = `data:application/octet-stream;base64,${resp.resultado.arquivo}`;
        const downloadLink = document.createElement('a');
        const fileName = resp.resultado.nomeArquivo;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        Swal.fire(
          'Área Logada',
          'Não existem certificados para serem baixados.',
          'info'
        );
      }
    },(erro) => {
      Swal.fire(
        'Área Logada',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  emitirCertificadoTreina(){
    this.servicePessoa.obterCertificadoTreina(this.id).subscribe((resp)=>{
      if(resp.resultado !== null && resp.resultado.arquivo !== ''){
        const linkSource = `data:application/octet-stream;base64,${resp.resultado.arquivo}`;
        const downloadLink = document.createElement('a');
        const fileName = resp.resultado.nomeArquivo;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        Swal.fire(
          'Área Logada',
          'Não existem certificados para serem baixados.',
          'info'
        );
      }
    },(erro) => {
      Swal.fire(
        'Área Logada',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  async redirectsPortal() {
    const result = await this.educaService.generatePortalUrl();
    if (result?.error) {
      Swal.fire(
        'Portal de Educação',
        'Não foi possível acessar a sua conta. Contate o suporte Planejar.',
        'error'
      );
      return;
    }

    window.open(result.url, '_self');
  }

}
