import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) { }

  urls = [];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();
    this.urls.push(req.url);
    return next.handle(req).pipe(
      finalize(() => {
        this.urls.splice(this.urls.indexOf(req.url), 1);
        if (!this.urls.length) {
          this.loaderService.hide();
        }
      })
    );
  }
}
