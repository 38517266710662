import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { Modulo } from './classes/modulo';

@Component({
  selector: 'app-somente-exame',
  templateUrl: './somente-exame.component.html',
  styleUrls: ['./somente-exame.component.scss']
})
export class SomenteExameComponent implements OnInit {
  @Input() isSubmitted: boolean;
  @Output() somenteExames = new EventEmitter();

  id: any;
  somenteExameForm: FormGroup;
  isDisabled = true;
  isDisabledCheckbox = true;
  numeroExames: any;
  localExames: any;
  modulos = [];
  modulosSelecionados = [];
  inputTodos = false;
  selecionados = [];
  tipoExame = '';
  valorModulo = 0;
  passo2Itens: any;
  avanca = "false";
  comboTotal = false;
  temValorTotal: boolean = false;
  valorComboNaoAssociado: any;
  quantidadeTotalModulos: any;
  qtdModulos: any;
  modulosAprovados: Array<Modulo> = [];
  cancelarModulosAnteriores = false;
  exibeSelecionarTodos = false;
  exibeMsgTodosModulosAprovados = false;
  exibeMsgTodosModulosInscritos = false;

  constructor(
    private fb: FormBuilder,
    private serviceExpectativa: AreaLogadaInscricaoService
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));

    this.passo2Itens = {
      'modulos': JSON.parse(localStorage.getItem('modulos')),
      'itens': JSON.parse(localStorage.getItem('itens')),
    }
  }

  ngOnInit(): void {
    if (this.passo2Itens) {
      this.init();
      this.examesInit();
      this.dataExameInit();

      this.somenteExameForm.controls.localExame.setValue(this.passo2Itens.itens.localExameSelecionado.locaExameSelecionado);
      this.somenteExameForm.controls.numeroDataExame.setValue(this.passo2Itens.itens.localExameSelecionado.numeroExameSelecionado.idExame);

      this.modulos = this.passo2Itens.modulos;
      this.isDisabledCheckbox = false;
      this.compare();
    }
    else {
      this.init();
      this.exames();
    }
  }

  init() {
    this.obterTodosModulos();

    this.somenteExameForm = this.fb.group({
      localExame: ['', Validators.required],
      numeroDataExame: ['', Validators.required]
    });
  }


  obterTodosModulos() {
    this.serviceExpectativa
      .obterTodosModulos()
      .subscribe((result) => {
        this.quantidadeTotalModulos = result.resultado.length;
      }, (erro) => {
        Swal.fire(
          'Exame',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  dataExame() {
    const userId = parseInt(JSON.parse(localStorage.getItem("user")).codigo_usuario)

    this.serviceExpectativa
      .obterDatasPorCidade(this.somenteExameForm.value.localExame, userId)
      .subscribe((result) => {
        this.numeroExames = result.resultado;
        console.log(this.numeroExames)
        this.isDisabled = false;
        this.isDisabledCheckbox = true;
        this.inputTodos = false;
        this.modulos = [];
        this.valorModulo = 0;
        this.somenteExameForm.controls.numeroDataExame.setValue("");
      }, (erro) => {
        Swal.fire(
          'Exame',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }
  exames() {
    this.serviceExpectativa
      .obterExames()
      .subscribe((result) => {
        this.localExames = result.resultado;
      }, (erro) => {
        Swal.fire(
          'Exame',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  examesInit() {
    this.serviceExpectativa
      .obterExames()
      .subscribe((result) => {
        this.localExames = result.resultado;
      }, (erro) => {
        Swal.fire(
          'Exame',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  dataExameInit() {
    const userId = parseInt(JSON.parse(localStorage.getItem("user")).codigo_usuario)
    this.serviceExpectativa
      .obterDatasPorCidade(this.somenteExameForm.value.localExame, userId)
      .subscribe((result) => {
        this.numeroExames = result.resultado
        this.isDisabled = false;
        this.isDisabledCheckbox = false;
      }, (erro) => {
        Swal.fire(
          'Exame',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  desabilitarCheckTabela() {
    this.isDisabledCheckbox = false;
  }

  modulo() {
    this.serviceExpectativa
      .obterValorPorExame(this.somenteExameForm.value.numeroDataExame.idExame)
      .subscribe(resu => {
        this.valorComboNaoAssociado = resu.resultado.valorComboNaoAssociado;
        this.valorComboNaoAssociado !== null ? this.temValorTotal = true : this.temValorTotal = false;
        this.qtdModulos = resu.resultado.quantidadeModulos;

      }, erro => {
        Swal.fire(
          'Exame',
          erro.error.excecao[0].mensagem,
          'error'
        );
      }
      )

    this.serviceExpectativa
      .obterModulos(this.id)
      .subscribe((result) => {
        this.modulos = result.resultado;
        this.inputTodos = false;
        this.valorModulo = 0;
        this.criarObj();
        this.exibeSelecionarTodos = false;
        this.exibeMsgTodosModulosAprovados = true;
        this.exibeMsgTodosModulosInscritos = true;

        if (this.modulos.length > 0) {
          for (var k = 0; k < this.modulos.length; k++) {
            if (this.modulos[k].situacao.indexOf('(Aprovado)') < 0) {
              this.exibeMsgTodosModulosAprovados = false;
              this.criarObj();
            }
            if (this.modulos[k].situacao.indexOf('(Já inscrito') < 0) {
              this.exibeMsgTodosModulosInscritos = false;
              this.criarObj();
            }
          }
          if (!this.exibeMsgTodosModulosAprovados && !this.exibeMsgTodosModulosInscritos)
            this.exibeSelecionarTodos = true;
        }
      }, (erro) => {
        Swal.fire(
          'Exame',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });

    this.capturarModulosAprovados();

  }

  onSubmit(form: FormGroup) {
  }

  get localExame() {
    return this.somenteExameForm.get('localExame');
  }

  get numeroDataExame() {
    return this.somenteExameForm.get('numeroDataExame');
  }

  changeLocalExame(e) {
    this.localExame.setValue(e.target.value, {
      onlySelf: false,
    })
  }

  validacaoModulosAprovadosAnteriores() {
    console.log(this.selecionados.length > 0, this.modulosAprovados.length > 0, this.modulosAprovados.length, this.quantidadeTotalModulos, this.selecionados.length, this.quantidadeTotalModulos)

    if (this.selecionados.length > 0 && this.modulosAprovados.length > 0 && this.modulosAprovados.length < this.quantidadeTotalModulos && this.selecionados.length < this.quantidadeTotalModulos) {
      for (var i = 0; i < this.selecionados.length; i++) {
        if (this.selecionados[i].situacao.length > 0) {
          for (var k = 0; k < this.modulos.length; k++) {
            if (this.modulos[k].idModulo == this.selecionados[i].idModulo) {
              $('#' + this.modulos[k].idModulo).prop('checked', false);
              this.modulos[k].checked = false;
            }
          }
        }
      }
      this.selecionados = this.modulos.filter((modulo) => {
        if (modulo.checked) {
          return modulo;
        }
      });
    }
    if (this.modulosAprovados.length > 0 && this.modulosAprovados.length < this.selecionados.length && this.selecionados.length == this.quantidadeTotalModulos) {
      var modulos = '';
      this.modulosAprovados.forEach(function (modulo) {
        modulos += modulo.descricao + ', '
      });
      if (modulos.length > 0)
        modulos = modulos.substring(0, modulos.length - 2);

      Swal.fire({
        title: 'Exame',
        text: "Estou ciente que ao prosseguir com a minha inscrição para o exame completo, as aprovações obtidas até momento (módulos(s) " + modulos + ") serão canceladas definitivamente. Deseja continuar?",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelarModulosAnteriores = true;
          this.avanca = 'true';
          this.criarObj();
        }
        if (result.isDismissed) {
          for (var k = 0; k < this.modulos.length; k++) {
            $('#' + this.modulos[k].idModulo).prop('checked', false);
            this.modulos[k].checked = false;
          }
          this.selecionados = this.modulos.filter((modulo) => {
            if (modulo.checked) {
              return modulo;
            }
          });
          $('#selecionaTodos').prop('checked', false);
          this.cancelarModulosAnteriores = false;
          this.avanca = 'false';
          this.criarObj();
        }
      });
    }
    if (this.modulosAprovados.length >= this.selecionados.length && this.modulosAprovados.length == this.quantidadeTotalModulos) {
      this.avanca = 'false';
      this.criarObj();
      Swal.fire(
        'Exame',
        'Consta exame completo já solicitado e aprovado! Não será necessário efetuar nova inscrição',
        'info'
      );

      for (var k = 0; k < this.modulos.length; k++) {
        if (this.modulos[k].idModulo == this.selecionados[i].idModulo) {
          $('#' + this.modulos[k].idModulo).prop('checked', false);
          this.modulos[k].checked = false;
        }
      }
      this.selecionados = this.modulos.filter((modulo) => {
        if (modulo.checked) {
          return modulo;
        }
      });

    }
  }

  retirarSelecaoSituacoesPendenteAprovado() {

    for (var k = 0; k < this.modulos.length; k++) {
      if (this.modulos[k].situacao.length > 0) {
        $('#' + this.modulos[k].idModulo).css('display', 'none');
      }
    }
  }

  selecionarTodos() {
    const opts: any = (document.querySelector('#numeroExames') as any).options
    const ex = opts[opts.selectedIndex].innerText.split("-").pop().trim()

    for (var k = 0; k < this.modulos.length; k++) {
      var checked = $('#selecionaTodos').prop('checked');
      $('#' + this.modulos[k].idModulo).prop('checked', checked);

      this.modulos[k].checked = checked;
    }

    for (const a of this.modulos) {
      const el: any = document.getElementById(a.idModulo)

      console.log(ex)
      if (el && ex === "Digital") {
        el.disabled = true
      }
    }

    this.selecionados = this.modulos.filter((modulo) => {
      if (modulo.checked) {
        return modulo;
      }
    });

    if (this.selecionados.length === this.qtdModulos) {
      this.tipoExame = 'Completo';
    } else {
      this.tipoExame = 'Modular';
    }

    console.log(this.inputTodos)

    if (this.inputTodos) {
      this.valor();
      this.validacaoModulosAprovadosAnteriores();
    }
  }

  compare() {
    this.selecionados = this.modulos.filter((modulo) => {
      if (modulo.checked) {
        return modulo;
      }
    });

    if (this.selecionados.length > 0) {
      if ((this.selecionados.length === this.modulos.length) && (this.selecionados.length !== this.qtdModulos)) {
        this.inputTodos = true;
        this.valor();
        this.validacaoModulosAprovadosAnteriores();
      } else if (this.selecionados.length === this.qtdModulos) {
        this.inputTodos = true;
        this.tipoExame = 'Completo';
        this.valor();
        this.validacaoModulosAprovadosAnteriores();
      }
      else {
        this.inputTodos = false;
        this.tipoExame = 'Modular';
        this.valor();
        this.validacaoModulosAprovadosAnteriores();
      }

    }
    else {
      this.valorModulo = 0;
    }
  }

  capturarModulosAprovados() {
    this.serviceExpectativa
      .obterModulosAprovados(this.id)
      .subscribe((resp) => {
        this.modulosAprovados = resp.resultado;
        console.log(this.modulosAprovados)
        const opts: any = (document.querySelector('#numeroExames') as any).options
        const ex = opts[opts.selectedIndex].innerText.split("-").pop().trim()

        if (ex === 'Digital') {
          setTimeout(() => {

            const btnTodos: any = document.querySelector('#selecionaTodos')
            if(btnTodos) {
              btnTodos.click()
            }
          }, 500)
        }

      }, (erro) => {
        Swal.fire(
          'Exame',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  valor() {
    this.serviceExpectativa
      .obterValorDaInscricao(
        this.somenteExameForm.value.numeroDataExame.idExameCidade,
        this.selecionados.length,
        this.id)
      .subscribe((resp) => {
        this.valorModulo = resp.resultado;
        this.avanca = "true";
        this.criarObj();
      }, (erro) => {
        this.avanca = 'false';
        this.valorModulo = 0;
        this.criarObj();
        Swal.fire(
          'Exame',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  selecionado() {
    this.modulosSelecionados = this.modulos.filter((item) => {
      if (item.checked) {
        return item;
      }
      else {
        return item;
      }
    });
  }

  mudaComboTotal(value) {
    this.comboTotal = value;
    this.criarObj();
  }

  criarObj() {
    this.selecionado();
    this.somenteExames.emit(
      {
        "moduloSelecionados": this.modulosSelecionados,
        "locaExameSelecionado": this.somenteExameForm.value.localExame,
        "numeroExameSelecionado": this.somenteExameForm.value.numeroDataExame.idExameCidade,
        "avanca": this.avanca,
        "comboNaoAssociado": this.comboTotal ? 1 : 0,
        "cancelarModulosAnteriores": this.cancelarModulosAnteriores,
        "modulos": this.modulos,
        "todosJaAprovados": this.exibeMsgTodosModulosAprovados,
        "todosJaInscritos": this.exibeMsgTodosModulosInscritos
      }
    );
  }
}
