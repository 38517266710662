<form name="perfilProfissionalForm"
id="perfilProfissionalForm"
class="mt-3"
[formGroup]="perfilProfissionalForm"
(ngSubmit)="submit()">
    <div class="row">
        <div class="form-group col-md-12">
            <label class="texto--sm"
                for="instrucoes">Instruções</label>
            <div id="instrucoes" class="form-control instrucoes" [innerHTML]="instrucoes"></div>
        </div>
    </div>

    <div class="row mt-1">
        <div class="col-md-3 radioInline">
            Permite exibir o perfil?
        </div>
        <div class="col-md-2 radioInline">
        <input  formControlName="exibirPerfil"
                name="exibirPerfil"
                type="radio"
                [value]="true" />
            <label for="exibirPerfil">&nbsp; Sim</label>
        </div>
        <div class="col-md-2 radioInline">
        <input  formControlName="exibirPerfil"
                name="exibirPerfil"
                type="radio"
                [value]="false" />
            <label for="exibirPerfil">&nbsp; Não</label>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <p>Defina abaixo quais os dados que você deseja exibir de forma pública para consulta no site Planejar.org.br</p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <p class="h3"><strong>Dados de contato</strong></p>
        </div>

        <div class="col-md-3 radioInline mt-2">
            Atendimento
        </div>
        <div class="col-md-2 radioInline">
            <input
                formControlName="atendimento"
                name="atendimento"
                type="radio"
                value="0" />
            <label for="atendimento">&nbsp; Presencial</label>
        </div>
        <div class="col-md-2 radioInline">
            <input
                formControlName="atendimento"
                name="atendimento"
                type="radio"
                value="1" />
            <label for="atendimento">&nbsp; À distância</label>
        </div>
        <div class="col-md-2 radioInline">
            <input
                formControlName="atendimento"
                name="atendimento"
                type="radio"
                value="2" />
            <label for="atendimento">&nbsp; Híbrido</label>
        </div>
    </div>

    <!-- Email -->
    <div class="row">
        <div class="form-group col-md-6">
            <label class="texto--sm"
                   for="email"></label>
            <input class="form-control form-control-sm"
                   formControlName="email"
                   [readonly]="true"
                   id="email"
                   placeholder="Email"
                   name="email"
                   type="text" />
        </div>

        <div class="inputInline">
            <div class="col-md-3 radioInline">
                Exibe essa informação?
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="emailRadio"
                    type="radio"
                    formControlName="emailRadio"
                    [value]="true"
                     />
                <label for="emailRadio">&nbsp; Sim</label>
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="emailRadio"
                    type="radio"
                    formControlName="emailRadio"
                    [value]="false" />
                <label for="emailRadio">&nbsp; Não</label>
            </div>
        </div>
    </div>

    <!-- Celular -->
    <div class="row">
        <div class="form-group col-md-6">
            <label class="texto--sm"
                   for="cel"></label>
            <input class="form-control form-control-sm"
                   formControlName="cel"
                   [readonly]="true"
                   id="cel"
                   placeholder="Telefone Celular"
                   name="cel"
                   type="text" />
        </div>

        <div class="inputInline">
            <div class="col-md-3 radioInline">
                Exibe essa informação?
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="celRadio"
                    type="radio"
                    formControlName="celRadio"
                    [value]="true"
                     />
                <label for="celRadio">&nbsp; Sim</label>
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="celRadio"
                    type="radio"
                    formControlName="celRadio"
                    [value]="false" />
                <label for="celRadio">&nbsp; Não</label>
            </div>
        </div>
    </div>

    <!-- Tel Comercial -->
    <div class="row">
        <div class="form-group col-md-6">
            <label class="texto--sm"
                   for="tel"></label>
            <input class="form-control form-control-sm"
                   formControlName="tel"
                   [readonly]="true"
                   id="tel"
                   placeholder="Telefone Comercial"
                   name="tel"
                   type="text" />
        </div>

        <div class="inputInline">
            <div class="col-md-3 radioInline">
                Exibe essa informação?
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="telRadio"
                    type="radio"
                    formControlName="telRadio"
                    [value]="true"
                     />
                <label for="telRadio">&nbsp; Sim</label>
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="telRadio"
                    type="radio"
                    formControlName="telRadio"
                    [value]="false" />
                <label for="telRadio">&nbsp; Não</label>
            </div>
        </div>
    </div>

    <!-- Linkedin -->
    <div class="row">
        <div class="form-group col-md-6">
            <label class="texto--sm"
                   for="linkedin"></label>
            <input class="form-control form-control-sm"
                   formControlName="linkedin"
                   [readonly]="true"
                   id="linkedin"
                   placeholder="LinkedIn"
                   name="linkedin"
                   type="text" />
        </div>

        <div class="inputInline">
            <div class="col-md-3 radioInline">
                Exibe essa informação?
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="linkedinRadio"
                    type="radio"
                    formControlName="linkedinRadio"
                    [value]="true"
                     />
                <label for="linkedinRadio">&nbsp; Sim</label>
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="linkedinRadio"
                    type="radio"
                    formControlName="linkedinRadio"
                    [value]="false" />
                <label for="linkedinRadio">&nbsp; Não</label>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <fieldset class="border p-3 my-3">
                <div>
                    <p class="h3"><strong>Idiomas</strong></p>
                </div>
                <div class="row">
                    <div class="form-group col-md-3">
                        <label class="texto--sm"
                              for="idioma">Idioma</label>
                        <select class="form-control form-control-sm custom-select custom-select-sm"
                                formControlName="idioma"
                                [class.is-invalid]="
                                idioma.invalid && (idioma.touched || isSubmit)"
                                id="idioma"
                                name="idioma">
                          <option [value]="null"
                                  disabled>Selecione</option>
                          <option *ngFor="let idiom of idiomas"
                                  value="{{idiom.idIdioma}}">
                                    {{ idiom.descricao }}
                          </option>
                        </select>
                        <div *ngIf="idioma.invalid && (idioma.touched || isSubmit)">
                          <span class="erro"
                                *ngIf="idioma.errors.required">Campo obrigatório</span>
                        </div>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="texto--sm"
                            for="nivel">Nível</label>
                        <select class="form-control form-control-sm custom-select custom-select-sm"
                                formControlName="nivel"
                                [class.is-invalid]="
                                nivel.invalid && (nivel.touched || isSubmit)"
                                id="nivel"
                                name="nivel">
                        <option [value]="null"
                                disabled>Selecione</option>
                        <option *ngFor="let nivel of niveis"
                                value="{{nivel.idIdiomaNivel}}">
                                    {{ nivel.descricao }}
                        </option>
                        </select>
                        <div *ngIf="nivel.invalid && (nivel.touched || isSubmit)">
                        <span class="erro"
                                *ngIf="nivel.errors.required">Campo obrigatório</span>
                        </div>
                    </div>

                    <div class="col-md-3 mt-3">
                        <label>&nbsp;</label>
                        <button class="btn btn-sm btn-primary mr-0 ml-2 mt-4 botao"
                                type="button"
                                (click)="incluirIdioma()">Incluir</button>
                    </div>

                </div>
                <table class="table table-bordered table-responsive">
                  <thead>
                    <tr>
                      <th>Idioma</th>
                      <th>Nível</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let idioma of idiomaPessoa; let i = index">
                      <td>{{idioma.descricaoIdioma}}</td>
                      <td>{{idioma.descricaoNivel}}</td>
                      <td class="text-center"
                          width="120px">
                        <button class="btn btn-sm"
                                (click)="excluir(idioma.idPessoaIdioma)"
                                title="Excluir"
                                type="button">
                          <img src="../../../../../assets/img/icons/excluir.svg"
                               style=" height: 21px;
                                    position: relative;" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </fieldset>
        </div>
    </div>


    <div class="row">
        <div class="form-group col-md-12">
            <p class="h3"><strong>Apresentação</strong></p>
            <div id="apresentacao">
                <textarea rows="6"
                          class="textArea"
                          formControlName="apresentacao">

                </textarea>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <p class="h3"><strong>Dados profissionais</strong></p>
        </div>
    </div>

    <!-- Empresa Atual -->
    <div class="row">
        <div class="form-group col-md-6">
            <label class="texto--sm"
                   for="empresaAtual"></label>
            <input class="form-control form-control-sm"
                   formControlName="empresaAtual"
                   [readonly]="true"
                   id="empresaAtual"
                   placeholder="Empresa Atual"
                   name="empresaAtual"
                   type="text" />
        </div>

        <div class="inputInline">
            <div class="col-md-3 radioInline">
                Exibe essa informação?
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="empresaAtualRadio"
                    type="radio"
                    formControlName="empresaAtualRadio"
                    [value]="true"
                     />
                <label for="empresaAtualRadio">&nbsp; Sim</label>
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="empresaAtualRadio"
                    type="radio"
                    formControlName="empresaAtualRadio"
                    [value]="false" />
                <label for="empresaAtualRadio">&nbsp; Não</label>
            </div>
        </div>
    </div>

    <!-- Especialidades -->
    <div class="row">
        <div class="form-group col-md-6">
            <label class="texto--sm"
                   for="especialidades"></label>
            <input class="form-control form-control-sm"
                   formControlName="especialidades"
                   [readonly]="true"
                   id="especialidades"
                   placeholder="Especialidades"
                   name="especialidades"
                   type="text" />
        </div>

        <div class="inputInline">
            <div class="col-md-3 radioInline">
                Exibe essa informação?
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="especialidadesRadio"
                    type="radio"
                    formControlName="especialidadesRadio"
                    [value]="true"
                     />
                <label for="especialidadesRadio">&nbsp; Sim</label>
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="especialidadesRadio"
                    type="radio"
                    formControlName="especialidadesRadio"
                    [value]="false" />
                <label for="especialidadesRadio">&nbsp; Não</label>
            </div>
        </div>
    </div>


    <!-- Certificações -->
    <div class="row">
        <div class="form-group col-md-6">
            <label class="texto--sm"
                   for="certificacoes"></label>
            <input class="form-control form-control-sm"
                   formControlName="certificacoes"
                   [readonly]="true"
                   id="certificacoes"
                   placeholder="Certificações"
                   name="certificacoes"
                   type="text" />
        </div>

        <div class="inputInline">
            <div class="col-md-3 radioInline">
                Exibe essa informação?
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="certificacoesRadio"
                    type="radio"
                    formControlName="certificacoesRadio"
                    [value]="true"
                     />
                <label for="certificacoesRadio">&nbsp; Sim</label>
            </div>
            <div class="col-md-2 radioInline">
                <input
                    name="certificacoesRadio"
                    type="radio"
                    formControlName="certificacoesRadio"
                    [value]="false" />
                <label for="certificacoesRadio">&nbsp; Não</label>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="form-group col-md-12 col-sm-12">
            <input formControlName="responsabilidade"
                   id="responsabilidade"
                   name="responsabilidade"
                   type="checkbox"
                   (change)="verificaChebox()"
                   value="true"
                   class="checkLateral" />
            <label for="responsabilidade">&nbsp; Declaro que li e concordo com o regulamento acima.</label>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12 text-right">
          <button class="btn btn-sm btn-primary mr-0 ml-2"
                  [disabled]="isDisable"
                  id="salvar"
                  (click)="submit()"
                  type="button">
            Salvar
          </button>
        </div>
    </div>

</form>
