import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EducaService {
  currentUserSubject: any;
  token: any;
  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('tknCdst');
    this.currentUserSubject = new BehaviorSubject<any>(
      localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    );
  }

  async generatePortalUrl() {
      const apiUrl = `${environment.backofficeUrl}/login-hsol/get-url-hsol?url=${environment.urlHsolParam}`;
      const authorizationHeader = `${this.token}`;

      const formdata = new FormData()
      formdata.append('idPessoa', this.currentUserSubject._value.codigo_usuario)

      const options = {
        method: 'POST',
        headers: {
          'accept': '*/*',
          'Authorization': authorizationHeader,
        },
        body: formdata
      };

      try {
        const response = await fetch(apiUrl, options);

        if (!response.ok) {
          throw new Error('Erro na chamada de API');
        }

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
}

interface IGetToken {
  success?: string;
  created_at?: string;
  expired_at?: string;
  CHAVE_PLANEJAR?: string;
  msg?: string;
}

interface IGetURL {
  error?: string;
  url?: string;
  expired_at?: string;
  msg?: string;
}
