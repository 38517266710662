import { Component, ViewChild, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AgendaService } from '../../../services/agenda/agenda.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import * as moment from 'moment';


@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements OnInit {
  isSubmitted: boolean;
  agendaForm: FormGroup;

  mode = 'add';
  isEditar: boolean = false;
  btnVoltar = true;
  btnSalvar = true;
  prospeccao = null;
  temas = [];
  publicos = [];
  participantes = [];
  tipos = [];
  idPessoa = null;
  idLocal = null;
  dataInicio: any;
  dataFim: any;
  idEvento = null;
  idAgendaUrl = null;
  idParticipante = null;
  inscricoes = [];

  get tipo() {
    return this.agendaForm.get('tipo');
  }
  get tema() {
    return this.agendaForm.get('tema');
  }
  get publico() {
    return this.agendaForm.get('publico');
  }
  get data() {
    return this.agendaForm.get('data');
  }
  get horario() {
    return this.agendaForm.get('horario');
  }
  get funcao() {
    return this.agendaForm.get('funcao');
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private agendaService: AgendaService,
    private route: ActivatedRoute
  ) {

    this.agendaForm = this.formBuilder.group({
      tipo: ['', Validators.required],
      tema: ['', Validators.required],
      publico: ['', Validators.required],
      data: ['', Validators.required],
      horario: ['', Validators.required],
      funcao: ['', Validators.required],
      observacao: [''],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.idLocal = params.get("id");
    });
    this.idPessoa = localStorage.getItem('userId');

    this.obterProspeccao();
    this.obterPublicos();
    this.obterTema();
    this.obterTipo();

    if (this.router.url.split('/')[3] === 'adicionar') {
      this.mode = 'add';
      this.idLocal = this.router.url.split('/')[2];
    }
    if (this.router.url.split('/')[3] === 'editar') {
      this.mode = 'edit';
      this.idLocal = this.router.url.split('/')[2];
      this.idAgendaUrl = this.router.url.split('/')[4];
      this.obterDadosLocal();
      this.obterInscricoes();
    }
    if (this.router.url.split('/')[3] === 'visualizar') {
      this.mode = 'visualizar';
      this.idLocal = this.router.url.split('/')[2];
      this.idAgendaUrl = this.router.url.split('/')[4];
      this.btnSalvar = false
      this.obterDadosLocal();
      this.obterInscricoes();
    }
  }

  carregaTipo() {
    this.idParticipante = this.agendaForm.value.tipo;
    this.obterTipoParticipante();
  }

  obterTipoParticipante() {
    this.agendaService.obterParticipantes(this.idPessoa, this.idParticipante ).subscribe((result) => {
      this.participantes = result.resultado;
    });
  }

  obterInscricoes() {
    this.agendaService.obterInscricoes(this.idAgendaUrl).subscribe((result) => {
      this.inscricoes = result.resultado;
    });
  }

  obterPublicos() {
    this.agendaService.obterPublico().subscribe((result) => {
      this.publicos = result.resultado;
    });
  }

  obterTema() {
    this.agendaService.obterTemas().subscribe((result) => {
      this.temas = result.resultado;
    });
  }

  obterTipo() {
    this.agendaService.obterAgendas().subscribe((result) => {
      this.tipos = result.resultado;
    });
  }

  obterProspeccao() {
    this.agendaService.obterProspeccao().subscribe((result) => {
      this.prospeccao = result.resultado[0];
      this.idEvento = result.resultado[0].idEvento;
      this.dataInicio = moment(result.resultado[0].realizacaoInicio).format('YYYY-MM-DD')
      this.dataFim = moment(result.resultado[0].realizacaoFim).format('YYYY-MM-DD')
    });
  }

  obterDadosLocal() {
    this.agendaService
      .obterPorId(this.idAgendaUrl)
      .subscribe(res => {
        this.idParticipante = res.resultado.idEventoAgendaTipo;
        this.agendaService.obterParticipantes(this.idPessoa, this.idParticipante ).subscribe((result) => {
          this.participantes = result.resultado;
        });
        this.agendaForm.controls.tipo.setValue(res.resultado.idEventoAgendaTipo);
        this.agendaForm.controls.tema.setValue(res.resultado.idEventoTema);
        this.agendaForm.controls.publico.setValue(res.resultado.idEventoPublico);
        this.agendaForm.controls.data.setValue(moment(res.resultado.data).format('YYYY-MM-DD'));
        this.agendaForm.controls.horario.setValue(res.resultado.data.substring(11, 16));
        this.agendaForm.controls.funcao.setValue(res.resultado.idEventoParticipanteTipo);
        this.agendaForm.controls.observacao.setValue(res.resultado.observacoes);
      })

    if (this.mode === 'visualizar') {
      this.agendaForm.controls.tipo.disable();
      this.agendaForm.controls.tema.disable();
      this.agendaForm.controls.publico.disable();
      this.agendaForm.controls.data.disable();
      this.agendaForm.controls.horario.disable();
      this.agendaForm.controls.funcao.disable();
      this.agendaForm.controls.observacao.disable();
    }
  }

  submit(formulario) {
    this.isSubmitted = true;
    if (this.agendaForm.status === 'VALID') {
      if (this.mode === 'add') {
        this.salvar({
          idEvento: parseInt(this.idEvento),
          idEventoLocal: parseInt(this.idLocal),
          idEventoAgendaTipo: parseInt(this.agendaForm.value.tipo),
          idEventoTema: parseInt(this.agendaForm.value.tema),
          idEventoPublico: parseInt(this.agendaForm.value.publico),
          data: this.agendaForm.value.data +'T'+ this.agendaForm.value.horario,
          idEventoParticipanteTipo: parseInt(this.agendaForm.value.funcao),
          observacoes: this.agendaForm.value.observacao
        });
      }else{
      this.alterar({
        idEventoAgenda: parseInt(this.idAgendaUrl),
        idEvento: parseInt(this.idEvento),
        idEventoLocal: parseInt(this.idLocal),
        idEventoAgendaTipo: parseInt(this.agendaForm.value.tipo),
        idEventoTema: parseInt(this.agendaForm.value.tema),
        idEventoPublico: parseInt(this.agendaForm.value.publico),
        data: this.agendaForm.value.data +'T'+ this.agendaForm.value.horario,
        idEventoParticipanteTipo: parseInt(this.agendaForm.value.funcao),
        observacoes: this.agendaForm.value.observacao
      });
    }
    }
  }

  voltar() {
    this.router.navigate([
      `/agendas/${this.idLocal}`,]);
  }

  salvar(agendaForm) {
    this.agendaService
      .criarAgenda(agendaForm)
      .subscribe((res) => {
        Swal.fire(
          'Agenda',
          `Agendamento cadastrado com sucesso!`,
          'success'
        ).then(() => {
          this.router.navigate([
            `/agendas/${this.idLocal}`,]);
        });
      },
        (error) => {
          Swal.fire('Local', error.error.excecao[0].mensagem, 'error');
        }
      );
  }

  alterar(agendaForm) {
    this.agendaService
      .atualizarAgenda(agendaForm)
      .subscribe((res) => {
        Swal.fire(
          'Agenda',
          `Agendamento editado com sucesso!`,
          'success'
        ).then(() => {
          this.router.navigate([
            `/agendas/${this.idLocal}`,]);
        });
      },
        (error) => {
          Swal.fire('Agenda', error.error.excecao[0].mensagem, 'error');
        }
      );
  }

}
