
<form name="solicitacaoForm"
      id="solicitacaoForm"
      class="mt-3"
      [formGroup]="solicitacaoForm">
      <fieldset class="border p-3 my-3">
        <legend class="legendFormulario">Dados pessoais do Supervisionado</legend>

        <div class="row">
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="nome">Nome</label>
                  <input class="form-control form-control-sm"
                  formControlName="nome"
                  id="nome"
                  name="nome"
                  type="text"
                  [(ngModel)]="pessoa['nome']" disabled />
          </div>
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="localizacao">Localização:</label>
            <input class="form-control form-control-sm"
                  formControlName="localizacao"
                  id="localizacao"
                  name="localizacao"
                  type="text"
                  [(ngModel)]="cidade_uf" disabled />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="email">E-mail</label>
                  <input class="form-control form-control-sm"
                  formControlName="email"
                  id="email"
                  name="email"
                  type="text"
                  [(ngModel)]="pessoa['email']" disabled />
          </div>
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="email">Telefone Celular</label>
                  <input class="form-control form-control-sm"
                  formControlName="telefoneCelular"
                  id="telefoneCelular"
                  name="telefoneCelular"
                  type="text"
                  [(ngModel)]="pessoa['telefoneCelular']" disabled />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="tipoAtendimentoSupervisao">Área de atuação</label>
                  <input class="form-control form-control-sm"
                  formControlName="areaAtuacao"
                  id="tipoAtendimentoSupervisao"
                  name="tipoAtendimentoSupervisao"
                  type="text"
                  [(ngModel)]="cargos['descricaoAreaAtuacao']" disabled />
          </div>
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="tipoAtendimentoSupervisao">Empresa</label>
                  <input class="form-control form-control-sm"
                  formControlName="empresa"
                  id="empresa"
                  name="empresa"
                  type="text"
                  [(ngModel)]="cargos['nomeEmpresa']" disabled />
          </div>
        </div>
        <section *ngif="false">
          <div class="row">
            <div class="form-group col-md-12">
              <label class="texto--sm" for="atribuicaoCargo">Atribuições do Cargo</label>
                <div id="atribuicaoCargo">
                    <textarea rows="3" cols="125" class="textArea" id="atribuicaoCargo" name="atribuicaoCargo" formControlName="atribuicaoCargo" class="largura" disabled></textarea>
                </div>
            </div>
          </div>
        </section>

        <section>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="texto--sm" for="apresentacaoSupervisao">Breve descrição de sua Experiência Profissional e o que você espera de sua Experiência Supervisionada</label>
                <div id="apresentacaoSupervisao">
                    <textarea rows="6" cols="125" class="textArea" id="apresentacaoSupervisao" name="apresentacaoSupervisao" formControlName="apresentacaoSupervisao" class="largura" ></textarea>
                </div>
            </div>
          </div>
        </section>

        <section>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="texto--sm"
                    for="formacao">Selecione as quatro áreas de conhecimento que você pretende obter a prática durante sua Experiência Supervisionada:
                    </label>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12" >
              <div class="col-md-6" *ngFor="let conhecimento of conhecimentos">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input"
                        type="checkbox"
                        id="conhecimento.idAreaAtuacaoConhecimento"
                        name="conhecimento-{{conhecimento.idAreaAtuacaoConhecimento}}"
                        [(ngModel)]="conhecimento.checked"
                        [disabled]="conhecimento.isDisabled"
                        [ngModelOptions]="{standalone: true}"
                        (click)="checkArea(conhecimento.idAreaSupervisao)"
                        >
                  <label class="ml-2">{{conhecimento.descricao}}</label>
                </div>
              </div>
            </div>
          </div>
        </section>

     </fieldset>

     <div class="col-ml-4">
      <div class="row">
        <div class="form-group col-md-12 col-sm-12">
          <label> </label>
          <div formControlName="termoCadastro"
               id="summer"
               name="summer"
               [ngxSummernote]="config"
               [ngxSummernoteDisabled]="true">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 padding-left-14">
          <div class="custom-control custom-checkbox lado-lado">
            <input class="custom-control-input"
                   (change)="selecionado(checkedTermoCadastro)"
                   id="checkTermoCadastro"
                   [(ngModel)]="checkedTermoCadastro"
                   [ngModelOptions]="{standalone: true}"
                   type="checkbox">
                   <label class="ml-4">Li e concordo em compartilhar meus dados com o Profissional selecionado para assumir a condição de Supervisor, em conformidade com as disposições da Lei Geral de Proteção de Dados (LGPD).</label>
          </div>
        </div>
        <!-- <div class="col-md-11 padding-left-14">

        </div> -->
      </div>
    </div>
</form>


<div class="row">
<div class="col-md-12">
 <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" (click)="enviarSolicitacao();">
  Enviar solicitação ao Supervisor
  </button>
  <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" onclick="javascript:history.go(-1);">
    Voltar
  </button>
</div>
</div>

