<footer class="dark-div main-color-2-bg">
  <section id="bottom">
    <div class="section-inner">
      <div class="container">
        <div class="row normal-sidebar">

        </div>
      </div>
    </div>
  </section>
  <div id="bottom-nav">
    <div class="container">
      <div class="text-center line-under">
        <a href="#" class="btn btn-default main-color-2-bg back-to-top">TOP</a>
      </div>
      <div class="row footer-content">
        <div class="copyright col-md-6">
          Planejar - 2023
        </div>
        <nav class="col-md-6 footer-social">
          <ul class="list-inline pull-right social-list">
            <li>
              <a href="https://www.facebook.com/APlanejarBR" class="btn btn-default social-icon" target="_blank">
                <i class="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/planejar---associa%C3%A7%C3%A3o-brasileira-de-planejadores-financeiros/?viewAsMember=true" class="btn btn-default social-icon" target="_blank">
                <i class="fa fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/PlanejarPraVida" class="btn btn-default social-icon" target="_blank">
                <i class="fa fa-youtube"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</footer>
