<div id="main-nav"
     class="dark-div nav-style-1 my-0">
  <nav role="navigation"
       class="navbar navbar-inverse main-color-2-bg">
    <div class="container">
      <div class="navbar-header">
        <a href="https://www.planejar.org.br/"
           target="_self"
           class="logo"
           title="Planejar"><img src="assets/img/logos/header_logo.png"
               class="header-logo"
               alt="Planejar" /></a>
      </div>
      <div class="main-menu hidden-xs affix-top d-flex"
           data-spy="affix"
           data-offset-top="500"
           style="justify-content: flex-end;">
        <div class="d-flex mr-5 align-items-center" style="cursor: pointer;" (click)="abrirMensagens()" *ngIf="currentUser">
          <img src="../../../../../assets/img/icons/envelope-svgrepo-com.svg" width="20px" [ngClass]="{'animar-sino' : countMensagens > 0}"/>
          <div *ngIf="countMensagens > 0" style="width: 10px; height: 10px; border-radius: 50px; background-color: red; position: absolute; margin-left: 18px;" class="animar-sino"></div>
        </div>
        <ul class="nav navbar-nav navbar-right">
          <li *ngFor="let nav of mainNavs"
              class="main-menu-item menu-item-depth-0 menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-has-children parent dropdown">

            <a href="#"
               *ngIf="nav.name == 'Home'"
               class="menu-link teste dropdown-toggle disabled main-menu-link"
               (click)="goHome()"><i class="fa fa-home">

              </i>{{ nav.name }}
              <span class="menu-description">{{ nav.description }}</span></a>
            <a href="#"
               *ngIf="nav.name !== 'Home'"
               class="menu-link teste dropdown-toggle disabled main-menu-link"
               data-toggle="dropdown"><i class="fa fa-home">

              </i>{{ nav.name }}
              <span class="menu-description">{{ nav.description }}</span></a>




            <ul class="dropdown-menu menu-depth-1">
              <li
                  class="sub-menu-item menu-item-depth-1 menu-item menu-item-type-post_type menu-item-object-page menu-item-home">
                <a *ngFor="let subnav of nav.subnavs"
                   href="#"
                   class="menu-link sub-menu-link">{{ subnav.name }}</a>
              </li>
            </ul>
          </li>
          <li *ngIf="currentUser"
              class="main-menu-item menu-item-depth-0 menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-has-children parent dropdown">
            <a href="#"
               class="menu-link dropdown-toggle disabled main-menu-link"
               data-toggle="dropdown"><i class="fa fa-home"></i>{{ currentUser.nomeCompleto }}
              <span class="menu-description">
                {{ currentUser.perfil ? currentUser.perfil[0].descricao : null}}
              </span>
            </a>
            <ul class="dropdown-menu menu-depth-1">
              <li
                  class="sub-menu-item menu-item-depth-1 menu-item menu-item-type-post_type menu-item-object-page menu-item-home">
                <a href="#"
                   class="menu-link sub-menu-link"
                   routerLink="/auto-cadastro/true">Dados Pessoais</a>
              </li>
              <li
                  class="sub-menu-item menu-item-depth-1 menu-item menu-item-type-post_type menu-item-object-page menu-item-home">
                <a href="#"
                   class="menu-link sub-menu-link"
                   (click)="logout()">Sair</a>
              </li>
            </ul>
          </li>
        </ul>




        <a href="#top"
           class="sticky-gototop main-color-1-hover">
          <i class="fa fa-angle-up"></i>
        </a>
      </div>
    </div>
  </nav>
</div>
