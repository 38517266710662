<fieldset class="border p-3 my-3">
  <legend class="legendFormulario">Inscrição</legend>

  <div class="modal-inscricao-component" style="display:none;">
    <h4 id="tituloModalInscricao">Inscricao</h4>
    <div style="height:85%;width:100%;" id="body-inscricao"></div>
    <br>
    <button class="btn-primary" (click)="proximoPasso()">Aceitar</button>
    <button class="btn-danger pull-right" (click)="hideModalInscricaoAceite()">Cancelar</button>
  </div>

  <div class="row">
    <div class="col-12">

      <!-- <div class="alert alert-warning text-center"
           role="alert"
           *ngIf="habilitaEducacao">
        Você não tem cadastro de <a href="#/educacao"
           class="link-incricao">educação</a>.
      </div>

      <div class="alert alert-warning text-center"
           role="alert"
           *ngIf="habilitaExperiencia">
        Você não tem cadastro de <a href="#/experiencia-profissional"
           class="link-incricao">experiência profissional</a>.
      </div> -->
    </div>
    <div class="col-12">
      <!-- WIZARD -->
      <aw-wizard class="arc-wizard" navBarLayout="large-empty-symbols" [disableNavigationBar]="true"
        [defaultStepIndex]="this.currentStep-1" #wizard>
        <div class="row">
          <div class="col-12"></div>
        </div>

        <!-- PASSO 1 -->
        <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Confirmar dados">
          <div>
            <div class="col-sm-12">
              <app-auto-cadastro (validForm)="receberValidForm($event)" [isInscricao]="true" [isEditarSemAnexo]="true"
                *ngIf="currentStep === 1">
              </app-auto-cadastro>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <div>
                <button class="btn btn-sm btn-primary btn-secondary mr-0" (click)="voltar(wizard, 0)" id="voltar"
                  type="button">
                  Voltar
                </button>
                <button class="btn btn-sm btn-primary mr-0 ml-2" (click)="validaPasso(1, wizard)" id="salvar"
                  type="button">
                  Próximo
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <!-- PASSO 2 -->
        <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Dados Profissionais">
          <div class="row ml-4">
            <div class="col-sm-12">
              <app-experiencia-profissional *ngIf="currentStep === 2" [isInscricao]="true" [semArquivo]="true"
                (validForm)="receberValidFormProfissao($event)">
              </app-experiencia-profissional>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right mt-2">
              <div>
                <button class="btn btn-sm btn-primary btn-secondary mr-0" (click)="voltar(wizard, 1)" id="voltar"
                  type="button">
                  Anterior
                </button>
                <button class="btn btn-sm btn-primary mr-0 ml-2" (click)="validaPasso(2, wizard)" id="salvar"
                  type="button">
                  Próximo
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <!-- PASSO 3 -->
        <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Dados Educacionais">
          <div class="row ml-4">
            <div class="col-sm-12">
              <app-educacao (validForm)="receberValidFormEducacao($event)" [isInscricao]="true" [semArquivo]="true"
                *ngIf="currentStep === 3">
              </app-educacao>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right mt-2">
              <div>
                <button class="btn btn-sm btn-primary btn-secondary mr-0" (click)="voltar(wizard, 2)" id="voltar"
                  type="button">
                  Anterior
                </button>
                <button class="btn btn-sm btn-primary mr-0 ml-2" (click)="validaPasso(3, wizard)" id="salvar"
                  type="button">
                  Próximo
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <!-- PASSO 4 -->
        <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Preparação">
          <div class="row ml-4">
            <div class="col-sm-12">
              <preparacao (validForm)="receberValidFormPreparacao($event)" *ngIf="currentStep === 4">
              </preparacao>


            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <div>
                <button class="btn btn-sm btn-primary btn-secondary mr-0" (click)="voltar(wizard, 3)" id="voltar"
                  type="button">
                  Anterior
                </button>
                <button class="btn btn-sm btn-primary mr-0 ml-2" (click)="validaPasso(4, wizard)" id="salvar"
                  type="button">
                  Próximo
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <!-- PASSO 5 -->
        <aw-wizard-step stepTitle="Seleção do exame" [navigationSymbol]="{ symbol: '5' }">
          <div class="row">
            <div class="col-sm-12 ml-4">
              <div>
                <app-somente-exame [isSubmitted]="passoCincoSubmitted" *ngIf="currentStep === 5"
                  (somenteExames)="receberSomenteExame($event)">
                </app-somente-exame>
              </div>
              <!-- <div>
                <app-autorizacao-aceite *ngIf="currentStep === 5"
                  [isSubmitted]="passoCincoSubmitted"
                  (edital)="receberEdital($event)"
                  (manual)="receberManual($event)"
                ></app-autorizacao-aceite>
              </div> -->
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <div>
                <button class="btn btn-sm btn-primary btn-secondary mr-0" id="voltar" (click)="voltar(wizard, 4)"
                  type="button">
                  Anterior
                </button>
                <button class="btn btn-sm btn-primary mr-0 ml-2" (click)="validaPasso(5, wizard)" id="salvar" data-concluir-passo="concluir-passo"
                  type="button">
                  Concluir
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </div>


    <!-- MODAL  -->
    <div aria-labelledby="dialog-child-name" bsModal #childModal="bs-modal"
      [config]="{ backdrop: 'static', keyboard: false}" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 id="dialog-child-name" class="modal-title pull-left">Inscrição </h2>
            <button aria-label="Close" class="close pull-right" (click)="hideChildModal()" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div align="center">
              <h4>Sua inscrição foi cadastrada com sucesso!</h4>
            </div>
            <div align="center">
              <div class="alert alert-danger" role="alert">
                Aguardando realização de pagamento para efetivação
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h5> Resumo da Inscrição</h5>
              </div>
              <div class="col-md-12">
                <span>Tipo de exame: </span><span> {{modalResulte['descricaoTipo']}}</span>
              </div>
              <div class="col-md-12">
                <span>Local:</span><span> {{modalResulte['descricaoCidade']}}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h4> Módulos inscritos:</h4>
              </div>
              <div class="col-md-12" *ngFor="let item of modalResulte['inscricaoModulo']">
                <span>{{item.descricaoModulo}}</span>
              </div>
            </div>
            <div class="row" *ngIf="exibeCombos">
              <div class="col-md-12 mt-2">
                <span>Combos: Associação não Certificada</span>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-sm btn-primary btn-secondary" (click)="abrirPagamento()">Pagar
              agora</button>
            <button type="button" class="btn btn-sm btn-primary " (click)="hideChildModal()">Pagar depois</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>
