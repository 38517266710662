import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

// import { Users } from 'src/models/users';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public estrutura: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(
      localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tkn'),
    });
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public getCurrentUser(): any {
    return this.currentUserSubject.asObservable();
  }

  login(username: string, password: string, manterLogado: boolean) {
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token`,
        {
          username,
          password,
          manterLogado,
          grant_type: 'password',
          semPerfil: true
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((user) => {
          localStorage.setItem('estruturas', user.estruturas);
          localStorage.setItem('userId', user.codigo_usuario);
          localStorage.setItem('tkn', 'Bearer ' + user.access_token);
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  loginHsol(idpessoa: string) {
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token`,
        {
          idpessoa,
          grant_type: 'integ_hsol',
          semPerfil: true
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((user) => {
          localStorage.setItem('estruturas', user.estruturas);
          localStorage.setItem('userId', user.codigo_usuario);
          localStorage.setItem('tkn', 'Bearer ' + user.access_token);
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }


  loginAnonimo() {
    let tokenAnonimo = environment.tokenAnonimo;
    let manterLogado = true;

    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token`,
        {
          tokenAnonimo,
          manterLogado,
          grant_type: 'password'
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((user) => {
          localStorage.setItem('tknCdst', 'Bearer ' + user.access_token);
        })
      );
  }

  recuperarSenha(email: string) {
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token/solicitar-renovacao-senha-portal?email=${email}`,
        {
          headers: new HttpHeaders({}),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }

  recuperarSenhaEmailSecundario(email: string) {
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token/solicitar-renovacao-senha-email-secundario-portal?email=${email}`,
        {
          headers: new HttpHeaders({}),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }

  obterModalTexto() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-texto-abertura`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  recuperarSenhaCelular(celular: string) {
    return this.http
      .post<any>(
        `${
        environment.backofficeUrl
        }/token/solicitar-renovacao-senha-celular-portal?ddd=${celular.substring(
          0,
          2
        )}&numero=${celular.substring(2, 11)}`,
        {
          headers: new HttpHeaders({}),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }

  alterarSenha(novaSenha: any) {
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token/criar-senha-token`,
        novaSenha,
        {
          headers: new HttpHeaders({}),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('tkn');
    localStorage.removeItem('tknEstrutura');
    localStorage.removeItem('estruturas');
    localStorage.removeItem('informacoes');
    localStorage.removeItem('eixos');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }
}
