import { FormControl } from '@angular/forms';

export interface ValidationResult {
    [key: string]: boolean;
}

export class SenhaValidator {

    public static strong(control: FormControl): ValidationResult {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        let hasCaracters = /[#@$%^&*()]/.test(control.value);
        const valid = hasNumber && hasUpper && hasLower && hasCaracters;
        if (!valid) {
            // return what´s not valid
            return { strong: true};
        }
        return null;
    }

    public static number(control: FormControl): ValidationResult {
        let hasNumber = /\d/.test(control.value);
        const valid = hasNumber;
        if (!valid) {
            // return what´s not valid
            return { number: hasNumber};
        }
        return { number: hasNumber};
    }
    public static upper(control: FormControl): ValidationResult {
        let hasUpper = /[A-Z]/.test(control.value);
        const valid = hasUpper;
        if (!valid) {
            // return what´s not valid
            return { upper: hasUpper};
        }
        return { upper: hasUpper};
    }
    public static lower(control: FormControl): ValidationResult {
        let hasLower = /[a-z]/.test(control.value);
        const valid = hasLower;
        if (!valid) {
            // return what´s not valid
            return { lower: hasLower};
        }
        return { lower: hasLower};
    }
    public static caracters(control: FormControl): ValidationResult {
        let hasCaracters = /[#@$%^&*()]/.test(control.value);
        const valid = hasCaracters;
        if (!valid) {
            // return what´s not valid
            return { caracters: hasCaracters};
        }
        return { caracters: hasCaracters};
    }
}