<form name="parecerFinalForm"
      id="parecerFinalForm"
      class="mt-3"
      [formGroup]="parecerFinalForm"
      (ngSubmit)="submit(parecerFinalForm)">



    <fieldset class="border p-3 my-3">
      <div class="container-principal"></div>
      <div class="row col-md-12">
      <legend class="legendFormulario">Parecer final do supervisor </legend>

      <div class="row">
        <div class="form-group col-md-12 col-sm-12">
          <input name="habilitadoRadio"
                type="radio"
                id="habilitado"
                (change)="mudaHabilitado(true)"
                formControlName="habilitadoRadio"
                [value]="true"

                />
          <label for="habilitadoRadio">&nbsp; Habilitado</label>
          <span class="ml-5"></span>
          <input name="habilitadoRadio"
                type="radio"
                (change)="mudaHabilitado(false)"
                formControlName="habilitadoRadio"
                [value]="false"
                />
          <label for="habilitadoRadio">&nbsp; Não Habilitado</label>
          <div *ngIf="habilitadoRadio.invalid && (habilitadoRadio.touched || isSubmitted)">
            <span class="erro"
                  *ngIf="habilitadoRadio.errors.required">Campo obrigatório</span>
          </div>
        </div>
    </div>

    <div class="row">
          <div class="form-group col-md-12">
            <label class="texto--sm" for="parecerFinal">Parecer Final</label>
              <div id="parecerFinal">
                  <textarea rows="6" cols="115" class="textArea" id="parecerFinal" name="parecerFinal" formControlName="parecerFinal" class="largura" ></textarea>
              </div>
          </div>
    </div>
    </div>
    </fieldset>



<div class="col-md-12 text-right">
  <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" onclick="history.go(-1);">
    Voltar
  </button>

  <button *ngIf="!isDesabilitarEdicao"  class="btn btn-sm btn-primary mr-0 ml-2"
          id="salvar"
          (click)="submit(parecerFinalForm)"
          type="submit"
          >
  Salvar
  </button>
</div>
</form>
