<form autocomplete="off"
      [formGroup]="somenteExameForm"
      (ngSubmit)="onSubmit(somenteExameForm)">
  <div class="row mb-4">
    <div class="col-md-6">
      <label>Local Exame</label>
      <select class="form-control"
              id="localExame"
              name="localExame"
              [class.is-invalid]="localExame.invalid && (localExame.touched || isSubmitted)"
              formControlName="localExame"
              (change)="dataExame();">
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let local of localExames;"
                [value]="local.idCidade">{{ local.descricaoCidade }}
        </option>
      </select>
      <div *ngIf="localExame.invalid && (localExame.touched || isSubmitted)">
        <span class="erro"
              *ngIf="localExame.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="col-md-6"
         *ngIf="!isDisabled">
      <label>Data - Número do Exame</label>
      <select (change)="desabilitarCheckTabela(); this.modulo();"
              class="form-control"
              [class.is-invalid]="numeroDataExame.invalid && (numeroDataExame.touched  || isSubmitted)"
              formControlName="numeroDataExame"
              id="numeroExames"
              name="numeroExames">
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let exame of numeroExames;"
                [ngValue]="exame"
                >{{ exame.dataNumero }}
        </option>
      </select>
      <div *ngIf="numeroDataExame.invalid && (numeroDataExame.touched  || isSubmitted)">
        <span class="erro"
              *ngIf="numeroDataExame.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <!-- DATA DISABLED -->
    <div class="col-md-6"
         *ngIf="isDisabled">
      <label>Data - Número do Exame</label>
      <select class="form-control"
              disabled
              formControlName="numeroDataExame"
              id="numeroExames"
              name="numeroExames">
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let exame of numeroExames;"
                [value]="exame.idExameCidade">{{ exame.dataNumero }}
        </option>
      </select>
      <div *ngIf="numeroDataExame.invalid && numeroDataExame.touched">
        <span class="erro"
              *ngIf="numeroDataExame.errors.required">Campo obrigatório</span>
      </div>
    </div>
  </div>
  <div *ngIf="modulos.length > 0">
    <h5>Módulos</h5>
    <div class="row ml-1">
      <div class="col-12 padding-left-14">
        <div class="custom-control custom-checkbox" *ngIf="exibeSelecionarTodos==true">
          <input (change)="selecionarTodos()"
                 class="custom-control-input"
                 [disabled]="isDisabledCheckbox"
                 id="selecionaTodos"
                 name="selecionaTodos"
                 [(ngModel)]="inputTodos"
                 [ngModelOptions]="{standalone: true}"
                 type="checkbox">
          <label class="ml-2">Inscrição Completa</label>
        </div>
      </div>
      <div class="col-12 padding-left-14"
           *ngFor="let modulo of modulos; ">
        <div *ngIf="modulo.situacao.length==0" class="custom-control custom-checkbox">
          <input
                (change)="compare()"
                 class="custom-control-input"
                 [disabled]="isDisabledCheckbox"
                 [id]="modulo.idModulo"
                 [name]="modulo.idModulo"
                 [(ngModel)]="modulo.checked"
                 [ngModelOptions]="{standalone: true}"
                 type="checkbox">
          <label class="ml-2">{{modulo.descricao}}</label>
        </div>
        <div *ngIf="modulo.situacao.length>0" class="custom-control custom-checkbox">
          <input (change)="compare()"
                 class="custom-control-input"
                 [disabled]="isDisabledCheckbox"
                 [id]="modulo.idModulo"
                 [name]="modulo.idModulo"
                 [(ngModel)]="modulo.checked"
                 [ngModelOptions]="{standalone: true}"
                 type="checkbox"
                 style="display: none;">
          <label class="ml-2">{{modulo.descricao}} {{modulo.situacao}}</label>
        </div>
      </div>
    </div>
    <div class="row ml-1 mt-3 mb-3">
      <label>Tipo de Exame: {{tipoExame}}</label>
    </div>

    <div class="row ml-1">
      <label>Valor: {{valorModulo | currency:'BRL':'symbol':'0.2-2'}} </label>

      <div *ngIf="temValorTotal && tipoExame === 'Completo' && quantidadeTotalModulos === modulos.length ">
        <input class="custom-control-input"
              (change)="mudaComboTotal(comboTotal)"
              id="comboTotal"
              [(ngModel)]="comboTotal"
              [ngModelOptions]="{standalone: true}"
              type="checkbox">
            <label class="ml-2" for="comboTotal">Desejo adquirir o Combo Exame Completo + Associação Não Certificado.</label>
        <div>
          <label>Valor total: {{valorComboNaoAssociado | currency:'BRL':'symbol':'0.2-2'}}</label>
        </div>
      </div>
    </div>
  </div>
</form>
