<div class="overlay content"
     *ngIf="isLoading">
  <div class="disposicao">
    <div>
      <img class="logo"
           src="../../../assets/img/logos/logo-planejar-verde.svg" />
    </div>
    <div>
      <span>CARREGANDO...</span>
    </div>
  </div>
</div>