<div bsModal #basicModal="bs-modal" [config]="{ backdrop: 'static', keyboard: false}" class="modal fade" tabindex="-1"
  role="dialog" aria-labelledby="myBasicModalLabel">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <form class="mt-3" [formGroup]="termoProcessoCertificadoForm" id="termoProcessoCertificadoForm"
      name="termoProcessoCertificadoForm" (ngSubmit)="submit(termoProcessoCertificadoForm)">
      <fieldset class="border p-3 my-3">
        <div class="modal-content">
          <div class="modal-header ">
            <h3 id="termoHeader">{{termo != null ? termo.assunto : 'Manual da Certificação'}}</h3>
        </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12 col-sm-12">
                <div
                  formControlName="manual"
                  id="summer"
                  name="summer"
                  [ngxSummernote]="config"
                  [ngxSummernoteDisabled]="true"
                >
                </div>
              </div>
            </div>
            <!-- <div class="row">
            <div class="form-group col-md-12">
              <label class="texto--sm"
                     for="motivo">
                Termo de condições
              </label>
              <textarea class="form-control form-control-sm col-6"
                        disabled
                        id="motivo"
                        name="motivo"
                        rows="5"
                        type="text">{{ textoTermo != null? textoTermo.descricao : '' }}</textarea>
            </div>
          </div> -->
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-md-12 col-sm-12">
              </div>

              <div class="col-md-12 text-right btn-toolbar">
                <button class="btn btn-default" id="Cancelar" title="Cancelar" type="button"
                  (click)="termoNaoAceito()">
                  Cancelar
                </button>
                <button class="btn btn-primary" id="Aceitar" title="Aceitar" type="submit" id="termoManual">
                  Aceitar
                </button>
              </div>
            </div>
          </div>
        </div>

      </fieldset>
    </form>
  </div>
</div>
