import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TransferenciaCancelamentoService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    let token = localStorage.getItem('tknCdst');


    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  transferirIncricao(inscricao: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/transferir-inscricao`,
      inscricao,
      {
        headers: this.httpHeaders,
      }
    );
  }

  estornaDebito(inscricao: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/estornar-debito`,
      inscricao,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterDataExameCidade(codigoCidade: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/obter-data-exame-por-cidade`,
      {
        headers: this.httpHeaders,
        params: {
          codigoCidade: codigoCidade,
        },
      }
    );
  }

  obterCidadeExame(codigoInscricao: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/obter-cidade-exame`,
      {
        headers: this.httpHeaders,
        params: {
          codigoInscricao: codigoInscricao,
        },
      }
    );
  }

  obterInscricoes(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/obter-inscricoes`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa
        },
      }
    );
  }

  obterInscricoesJustificativa(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/obter-inscricoes-justificativa`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa
        },
      }
    );
  }  

  verificarInscricao(inscricao: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/verificar-inscricao`,
      inscricao,
      {
        headers: this.httpHeaders,
      }
    );
  }

  cancelarInscricao(inscricao: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/cancelar-inscricao`,
      inscricao,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterInformativoBoleto() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/obter-informativo-cancelamento-boleto`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterInformativoCartao() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-transferencia-cancelamento/obter-informativo-cancelamento-cartao`,
      {
        headers: this.httpHeaders,
      }
    );
  }

}
