<fieldset class="border p-3 my-3">
  <legend class="legendFormulario">Biblioteca de doscumentos</legend>
  <div class="dashboard">
    <div class="row mt-3" >
      <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 d-flex flex-row wrapper justify-content-center" style="flex-wrap: wrap;">
                <div *ngFor="let doc of documentos">
                  <div (click)="abrirDoc(doc.url)" class="card-doc">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 247.744 247.744" style="enable-background:new 0 0 247.744 247.744;" xml:space="preserve"><g><path style="fill:#fff;" d="M206.89,0H74.824c-9.649,0-17.5,7.851-17.5,17.5v21.005h-16.47c-9.649,0-17.5,7.85-17.5,17.5v174.239c0,9.649,7.851,17.5,17.5,17.5h132.065c9.649,0,17.5-7.851,17.5-17.5V209.24h16.471c9.649,0,17.5-7.851,17.5-17.5V17.5C224.39,7.851,216.54,0,206.89,0z M175.419,230.244c0,1.355-1.145,2.5-2.5,2.5H40.854c-1.355,0-2.5-1.145-2.5-2.5V56.005c0-1.356,1.145-2.5,2.5-2.5h132.065c1.355,0,2.5,1.144,2.5,2.5V230.244z M209.39,191.74c0,1.355-1.145,2.5-2.5,2.5h-16.471V56.005c0-9.65-7.851-17.5-17.5-17.5H72.324V17.5c0-1.355,1.144-2.5,2.5-2.5H206.89c1.355,0,2.5,1.145,2.5,2.5V191.74z"/><path style="fill:#fff;" d="M149.553,77.121H64.22c-4.143,0-7.5,3.357-7.5,7.5c0,4.143,3.357,7.5,7.5,7.5h85.333c4.143,0,7.5-3.357,7.5-7.5C157.053,80.479,153.696,77.121,149.553,77.121z"/><path style="fill:#000002;" d="M149.553,110.898H64.22c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h85.333c4.143,0,7.5-3.357,7.5-7.5S153.696,110.898,149.553,110.898z"/><path style="fill:#fff;" d="M149.553,144.676H64.22c-4.143,0-7.5,3.357-7.5,7.5c0,4.143,3.357,7.5,7.5,7.5h85.333c4.143,0,7.5-3.357,7.5-7.5C157.053,148.033,153.696,144.676,149.553,144.676z"/><path style="fill:#fff;" d="M149.553,178.453H64.22c-4.143,0-7.5,3.357-7.5,7.5c0,4.143,3.357,7.5,7.5,7.5h85.333c4.143,0,7.5-3.357,7.5-7.5C157.053,181.811,153.696,178.453,149.553,178.453z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                    <p>{{doc.descricao}}</p>
                  </div>
                </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-right mr-4">
              <div>
                <button class="btn btn-sm btn-primary btn-secondary ml-2"
                        type="button"
                        routerLink="/inscricao-config/{{idInscricao}}">
                  Voltar
                </button>    
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</fieldset>