import { SituacaoService } from './../../services/situacao/situacao.service';
import { ExameService } from 'src/app/services/exame/exame.service';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as $ from 'jquery';
import * as moment from 'moment';


@Component({
  selector: 'inscricao-config',
  templateUrl: './inscricao-config.component.html',
  styleUrls: ['./inscricao-config.component.scss']
})
export class InscricaoConfigComponent implements OnInit {
  id: any
  certificados = [];
  naoCertificados = [];
  instrucaoModal: ModalDirective;
  itemModal: any;
  inscricao = null;
  idInscricao: any;
  loading: boolean = true;
  pagamento: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private inscricaoService : AreaLogadaInscricaoService
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
    moment.locale('pt-BR');
  }
  
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params.get("idInscricao")) {
        this.idInscricao = params.get("idInscricao")
      }
    });
    this.carregaInscricao();
  }

  carregaInscricao() {
    this.inscricaoService.obterInscricao(this.idInscricao, true).subscribe((result) => {
      this.inscricao = result.resultado;
      this.pagamento = this.inscricao.descricaoPagamento;
      this.inscricao.exame.dataLimiteTransferencia = this.inscricao.exame.dataLimiteTransferencia != null ? moment(this.inscricao.exame.dataLimiteTransferencia).format('DD/MM/yyyy') : null;
      this.inscricao.exame.dataLimiteCancelamento = this.inscricao.exame.dataLimiteCancelamento != null ? moment(this.inscricao.exame.dataLimiteCancelamento).format('DD/MM/yyyy') : null;
      this.validarCards();
      $("#config-cards").show();
    });
  }

  validarCards() {
    this.validarCancelamento();

    if (this.pagamento == 'Pago') {
      this.validarTransferencia();
      this.validarJustificativa();
      this.validarCartaoInfo();
      $("#baseCard").show();
      $("#finCard").show();
      $("#materialCard").show();
    }
  }

  validarJustificativa() {

    var isJustificativa = false;

    const dtFormated = this.formatedDate(this.inscricao.exameCidade.dataExame);
    if (this.inscricao.exameCidade.diasJustificar != null) {
      const dtFormatedCidade = this.formatedDate(this.inscricao.exameCidade.dataExame);
      const dtLimiteJust = moment(dtFormatedCidade);
      const dtLimiteJustMinus = moment(dtFormatedCidade);
      dtLimiteJustMinus.add(-Number(this.inscricao.exameCidade.diasJustificar), 'days');
      dtLimiteJust.add(Number(this.inscricao.exameCidade.diasJustificar), 'days');
      if (dtLimiteJust >= moment() && dtLimiteJustMinus <= moment()) {
        console.log(dtLimiteJust.format('DD/MM/yyyy'))
        this.inscricao.exame.dataLimiteJustificativa = dtLimiteJust.format('DD/MM/yyyy');
        isJustificativa = true;
        $("#justCard").show();
      }
      else {
        $("#justCard").hide();
      }
    }
    else{
      $("#justCard").hide();
    }

    if(!isJustificativa)
    {
    if (this.inscricao.exame.diasJustificar != null) {
      const dtFormated = this.formatedDate(this.inscricao.exame.dataExame);
      const dtLimiteJust = moment(dtFormated);
      const dtLimiteJustMinus = moment(dtFormated);
      dtLimiteJust.add(Number(this.inscricao.exame.diasJustificar), 'days');
      dtLimiteJustMinus.add(-Number(this.inscricao.exame.diasJustificar), 'days');
      if (dtLimiteJust >= moment() && dtLimiteJustMinus <= moment()) {
        $("#justCard").show();
        this.inscricao.exame.dataLimiteJustificativa = dtLimiteJust.format('DD/MM/yyyy');
      }
      else {
        $("#justCard").hide()
      }
    }
    else{
      $("#justCard").hide();
    }       
  }
 }

  validarTransferencia() {
    let dtFormated = this.formatedDate(this.inscricao.exame.dataLimiteTransferencia);

    if (this.inscricao.exameCidade.dataLimiteTransferencia == null) {
      if (this.inscricao.exame.dataLimiteTransferencia == null) {
        $("#transfCard").hide()
      }
      else {
        const dtLimiteTransf = moment(dtFormated);
        if (dtLimiteTransf >= moment()) {
          $("#transfCard").show();
          this.inscricao.exame.dataLimiteTransferencia = dtLimiteTransf.format('DD/MM/yyyy');
        }
        else {
          $("#transfCard").hide()
        }
      }
    }
    else {
      dtFormated = this.formatedDate(this.inscricao.exameCidade.dataLimiteTransferencia);
      const dtLimiteTransf = moment(dtFormated);
      if (dtLimiteTransf >= moment()) {
        $("#transfCard").show();
        this.inscricao.exame.dataLimiteTransferencia = dtLimiteTransf.format('DD/MM/yyyy');
      }
      else {
        $("#transfCard").hide()
      }
    }
  }

  formatedDate(date: any) {
    if (date && date.includes('/')) {
      const partsOfDate = date.split('/');
      if (partsOfDate && partsOfDate.length > 0) {
        date = partsOfDate[2] + '-' + partsOfDate[1] + '-' + partsOfDate[0];
        return date+'T23:59:59Z';
      }
    }

    return date;
  }

  validarCancelamento() {
    let dtFormated = this.formatedDate(this.inscricao.exame.dataLimiteCancelamento);
    if (this.inscricao.exameCidade.dataLimiteCancelamento == null) {
      if (this.inscricao.exame.dataLimiteCancelamento == null) {
        $("#cancelCard").hide();
      }
      else {
        const dtLimiteCancel = moment(dtFormated);
        if (dtLimiteCancel >= moment()) {
          $("#cancelCard").show();
          this.inscricao.exame.dataLimiteCancelamento = dtLimiteCancel.format('DD/MM/yyyy');
        }
        else {
          $("#cancelCard").hide();
        }
      }
    }
    else {
      dtFormated = this.formatedDate(this.inscricao.exameCidade.dataLimiteCancelamento);
      const dtLimiteCancel = moment(dtFormated);
      console.log(dtLimiteCancel >= moment());
      if (dtLimiteCancel >= moment()) {
        $("#cancelCard").show();
        this.inscricao.exame.dataLimiteCancelamento = dtLimiteCancel.format('DD/MM/yyyy');
      }
      else {
        $("#cancelCard").hide()
      }
    }
  }

  validarCartaoInfo() {
    const dtExameLimitCartao = moment(this.inscricao.exame.dataExame);
    dtExameLimitCartao.add(-10, 'days')

    if (dtExameLimitCartao <= moment()) {
      $("#cartaoInfoCard").show();
    }
    else {
      $("#cartaoInfoCard").hide();
    }
  }

  abrirInscricao() {
    this.router.navigate(['/transferencia-cancelamento']);
  }

  abrirTransferencia() {
    this.router.navigate(['/transferencia-inscricao/'+this.idInscricao]);
  }

  abrirCancelamento() {
    this.router.navigate(['/cancelamento-inscricao/'+this.idInscricao]);
  }

  abrirMaterial() {
    this.router.navigate(['/material-estudos/'+this.idInscricao]);
  }

  abrirDocumentos() {
    this.router.navigate(['/documentos/'+this.idInscricao]);
  }

  abrirJustificativa() {
    this.router.navigate(['/justificar-ausencia/'+this.idInscricao]);
  }

  abrirFinanceiro() {
    this.router.navigate(['/debitos/']);
  }

  abrirCartaoInformativo(){
    window.open("https://www.concursosfcc.com.br/concursos/jsp/localDeExame/index.jsp", "_blank");
  }
}
