<div class="page-heading">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-8">
        <h1>{{ title }}</h1>
      </div>
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
</div>
