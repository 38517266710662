<div class="container">
  <div class="col-12">

    <h1>Questionário</h1>

    <ul *ngIf="!iniciado && votacao">
      <li class="pergunta">
        <h3>{{votacao.titulo}}</h3>
        <b>{{votacao.textoApresentacao}}</b>
      </li>
    </ul>
    <div *ngIf="!iniciado" class="row col-12">
      <div class="col-12" align="center"><a (click)="iniciarVotacao()" class="btn btn-link">Iniciar</a></div>
    </div>


    <ul *ngIf="iniciado && !finalizado">
      <li *ngIf="pergunta" class="pergunta">
        <h3>{{pergunta.titulo}}</h3>

        <table class="table">
          <tr *ngFor="let resp of pergunta.respostas">
            <td style="width: 3%;text-align: left;"><input [checked]="resp.checked"
                (change)="setCheckResposta($event, pergunta, resp)" type="checkbox"></td>
            <td style="width: 97%;text-align: left;">{{resp.resposta}}</td>
          </tr>
        </table>
      </li>
    </ul>
  </div>

  <div *ngIf="iniciado && !finalizado" class="row col-12">
    <div class="col-md-1"><a (click)="perguntaAnterior()" class="btn btn-link">Anterior</a>
    </div>
    <div class="col-md-9" align="center"><a class="btn btn-link">{{currentPage +
        1}}/{{votacao.votacaoPerguntas.length}}</a></div>
    <div class="col-md-1">
      <a *ngIf="!carregando" (click)="proximaPergunta()" class="btn btn-link">Próxima</a>
      <a *ngIf="carregando" class="btn btn-link">Aguarde...</a>
    </div>
  </div>

  <ul *ngIf="finalizado && votacao">
    <li class="pergunta">
      <h3>{{votacao.titulo}}</h3>
      <b>{{votacao.textoFinalizacao}}</b>
    </li>
  </ul>
  <div *ngIf="finalizado" class="row col-12">
    <div class="col-12" align="center"><a (click)="voltarParaListaDeVotacao()" class="btn btn-link">Voltar para a listagem</a></div>
  </div>

</div>