import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormaPagamentoService {
  httpHeaders: HttpHeaders;
  cielo: HttpHeaders;

  constructor(private http: HttpClient) {

    let token = localStorage.getItem('tknCdst');


    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });


    this.cielo = new HttpHeaders({
      MerchantId: '1e0c9109-eee4-4de1-a460-ff03af9f6997',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  }

  //Obter dados do débito gerado
  obterDebito(codigoDebito: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-debito/obter-debito-por-id`,
      {
        headers: this.httpHeaders,
        params: { codigoDebito },
      }
    );
  }

  //Obter dados da pessoa logada
  obterDadosPessoa(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-debito/obter-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  //Api cielo retorno do link checkout
  superLinkCielo(items: any) {
    return this.http.post<any>(
      `https://cieloecommerce.cielo.com.br/api/public/v1/orders`,
      items,
      {
        headers: this.cielo,
      }
    );
  }

  //Gerar link cielo
  linkCielo(codigoDebito: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-debito/gerar-pagamento-cielo`,
      {
        headers: this.httpHeaders,
        params: { codigoDebito },
      }
    );
  }

  //Gerar boleto para pagamento
  gerarBoleto(dados: any){
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-debito/gerar-boleto`,
      dados,
      {
        headers: this.httpHeaders,
      }
    );
  }

  //Obter boleto para impressão
  obterBoleto(codigoDebito: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-debito/reimprimir-boleto`,
      {
        headers: this.httpHeaders,
        params: { codigoDebito },
      }
    );
  }

  
}
