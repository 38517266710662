import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2';
import { CpfValidator } from 'src/app/validator/cpf.validator';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TransferenciaCancelamentoService } from 'src/app/services/transferencia-cancelamento/transferencia-cancelamento.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as moment from 'moment';




@Component({
  selector: 'app-transferencia-cancelar-inscricao',
  templateUrl: './transferencia-cancelar-inscricao.component.html',
  styleUrls: ['./transferencia-cancelar-inscricao.component.scss']
})
export class TransferenciaCancelarInscricaoComponent implements OnInit {
  files: File[] = [];
  view = false;
  imagem = null;
  obj = [];
  tipoBancario: any;
  tipoChaves = [];
  cancelarRadio = false;
  tranferirRadio = false;
  chave: any;
  tipoTransferencias = [];
  @ViewChild('estornoModal') estornoModal: ModalDirective;
  @ViewChild('justificativaModal') justificativaModal: ModalDirective;
  tranferenciaForm: FormGroup;
  tipoForm: FormGroup;
  justificativaForm: FormGroup;
  estornoForm: FormGroup;
  isSubmitted: boolean;
  isSubmittedJustificativa: boolean;
  isSubmittedEstorno: boolean;
  id: any;
  transferirHabilitado = false;
  justificativaObjeto = [];

  incricaoSelecionada = [];

  public emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  config: any;
  tipoModal = null;
  info = [];
  modelCheck: boolean = false;

  inscricoes = [];
  botaoConfirmar = true;
  datas = [];
  paras = [];
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private tranferenciaService: TransferenciaCancelamentoService,
    private route: ActivatedRoute
  ) {
    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '200px',
      toolbar: [
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    }
    this.id = JSON.parse(localStorage.getItem('userId'));

  }

  ngOnInit(): void {
    this.init();
    // this.estornoModal.show();
    this.dadosModalEstorno();
  }

  init() {
    this.tranferenciaForm = this.formBuilder.group({
      inscricao: ['', Validators.required],
      numero: ['', Validators.required],
      para: ['', Validators.required],
    });

    this.justificativaForm = this.formBuilder.group({
      justificativaDescricao: ['', Validators.required],
    });
    this.tipoForm = this.formBuilder.group({
      tipo: ['', Validators.required],
    });

    this.estornoForm = this.formBuilder.group({
      codigoEtica: [''],
      aceiteTermo: [''],
      cpf: ['', [Validators.required, CpfValidator.validCpf]],
      codigo: ['', Validators.required],
      banco: ['', Validators.required],
      agencia: ['', Validators.required],
      conta: ['', Validators.required],
      digito: ['', Validators.required],
    });

    this.carregaInscricoes();


  }


  dadosModalEstorno() {
    this.tipoTransferencias = [
      {
        "idTipo": 0,
        "descricao": "PIX",
      },
      {
        "idTipo": 1,
        "descricao": " Dados Bancários",
      },
    ];
    this.dadosTipo();

  }

  dadosTipo() {

    this.tipoChaves = [
      {
        "idTipoBancario": 0,
        "descricao": "Chave aleatória"
      },
      {
        "idTipoBancario": 1,
        "descricao": "Telefone"
      },
      {
        "idTipoBancario": 2,
        "descricao": "E-mail"
      },
      {
        "idTipoBancario": 3,
        "descricao": "CPF"
      },
      {
        "idTipoBancario": 4,
        "descricao": "CNPJ"
      },
    ];
  }

  mudaTermo() {

  }

  camposObrigatoriosTed() {

    this.estornoForm = this.formBuilder.group({
      codigoEtica: [''],
      aceiteTermo: [''],
      cpf: [''],
      codigo: [''],
      banco: ['', Validators.required],
      agencia: ['', Validators.required],
      conta: ['', Validators.required],
      digito: ['', Validators.required],
    });
  }

  camposObrigatorios() {
    this.estornoForm = this.formBuilder.group({
      codigoEtica: [''],
      aceiteTermo: [''],
      cpf: ['', [Validators.required, CpfValidator.validCpf]],
      codigo: ['', Validators.required],
      banco: [''],
      agencia: [''],
      conta: [''],
      digito: [''],
    });


    return;
  }

  submit(value) {
    this.isSubmitted = true;
    if ((value.status == 'VALID') && (this.tranferirRadio || this.cancelarRadio)) {
      if (this.justificativaObjeto.length > 0) {
        let obj = {
          "idInscricao": this.tranferenciaForm.controls['inscricao'].value.idInscricao,
          "idExameCidade": Number(this.tranferenciaForm.controls['numero'].value),
          "justificativa": this.justificativaObjeto[0].justificativa,
          "nomeArquivo": this.justificativaObjeto[0].nomeArquivo,
          "arquivo": this.justificativaObjeto[0].arquivo
        };

        this.tranferenciaService.transferirIncricao(obj).subscribe(() => {
          Swal.fire('Transferir ou Cancelar Inscrição',
            "Inscrição Transferida com sucesso!", 'success').then(() => {
              this.router.navigate(['/home']);
            });


        }, (error) => {
          Swal.fire('Transferir ou Cancelar Inscrição',
            error.error.excecao[0].mensagem, 'error');
        });

      } else {
        let obj =

        {
          "idInscricao": this.tranferenciaForm.controls['inscricao'].value.idInscricao,
          "idExameCidade": Number(this.tranferenciaForm.controls['numero'].value),
          "justificativa": null,
          "nomeArquivo": null,
          "arquivo": null
        };


        this.tranferenciaService.transferirIncricao(obj).subscribe(() => {
          Swal.fire('Transferir ou Cancelar Inscrição',
            "Inscrição Transferida com sucesso!", 'success').then(() => {
              this.router.navigate(['/home']);

            });

        }, (error) => {
          Swal.fire('Transferir ou Cancelar Inscrição',
            error.error.excecao[0].mensagem, 'error');
        });


      }
    }
    else {
      Swal.fire('Transferir ou Cancelar Inscrição',
        "Selecione uma opção", 'error');
    }
  }
  submitJustificativa(value) {
    this.justificativaObjeto = [];

    this.isSubmittedJustificativa = true;
    if (value.status == 'VALID') {
      if (this.files.length > 0) {
        this.justificativaObjeto = [{
          "nomeArquivo": this.obj['first'].file.name,
          "arquivo": this.obj['first'].imageSrc.replace(/^data:(.*base64,)?/, ''),
          'justificativa': this.justificativaForm.controls['justificativaDescricao'].value
        }];
        this.transferirHabilitado = true;

        this.justificativaModal.hide();
        this.onRemove();


      } else {
        Swal.fire(
          'Transferir ou Cancelar Inscrição',
          `Insira ao menos um arquivo.`,
          'warning'
        );
      }

    }

  }

  submitEstorno(value) {
    this.isSubmittedEstorno = true;

    if (this.tipoModal == 1) {
      let obj = {
        "idDebito": this.tranferenciaForm.controls['inscricao'].value.idDebito,
        "estornoBanco": null,
        "estornoAgencia": null,
        "estornoConta": null,
        "estornoContaDigito": null,
        "estornoCpfTitular": this.estornoForm.controls['cpf'].value ? this.estornoForm.controls['cpf'].value : null
      }

      if (this.estornoForm.controls['cpf'].status === 'VALID') {
        this.tranferenciaService.estornaDebito(obj).subscribe((cancel) => {

          if(cancel.sucesso)
          {
            this.tranferenciaService.cancelarInscricao(this.tranferenciaForm.controls['inscricao'].value).subscribe((resp) => {

              if(resp.sucesso)
              {
                Swal.fire('Transferir ou Cancelar Inscrição',
                "Inscrição cancelada com Sucesso! ", 'success').then(() => {
  
                  this.router.navigate(['/home']);
                  this.estornoModal.hide();
                });

              }
              else
              {
                Swal.fire('Transferir ou Cancelar Inscrição', 'Não foi possível efetuar o cancelamento da inscrição. Favor contate o suporte', 'error');              
              }
            }, (error) => {
              Swal.fire('Transferir ou Cancelar Inscrição',
                error.error.excecao[0].mensagem, 'error');
            });
          }
          else
          {
            Swal.fire('Transferir ou Cancelar Inscrição', 'Não foi possível efetuar o cancelamento da inscrição. Favor contate o suporte', 'error');              
          }
          
        },(error) => {
          Swal.fire('Transferir ou Cancelar Inscrição',
            error.error.excecao[0].mensagem, 'error');
        });
      }
    } else {
      let obj = {
        "idDebito": this.tranferenciaForm.controls['inscricao'].value.idDebito,
        "estornoBanco": this.estornoForm.controls['banco'].value,
        "estornoAgencia": this.estornoForm.controls['agencia'].value,
        "estornoConta": this.estornoForm.controls['conta'].value,
        "estornoContaDigito": this.estornoForm.controls['digito'].value,
        "estornoCpfTitular": this.estornoForm.controls['cpf'].value ? this.estornoForm.controls['cpf'].value : null,
      }
      if (this.estornoForm.controls['banco'].status === 'VALID' &&
        this.estornoForm.controls['agencia'].status === 'VALID' &&
        this.estornoForm.controls['digito'].status === 'VALID' &&
        this.estornoForm.controls['conta'].status === 'VALID') {
        this.tranferenciaService.estornaDebito(obj).subscribe((cancel) => {          

          if(cancel.sucesso)
          {
            this.tranferenciaService.cancelarInscricao(this.tranferenciaForm.controls['inscricao'].value).subscribe((resp) => {

              if(resp.success)
              {
                Swal.fire('Transferir ou Cancelar Inscrição',
                "Inscrição cancelada com Sucesso! ", 'success').then(() => {
  
                  this.router.navigate(['/home']);
                  this.estornoModal.hide();
                });
              }
              else
              {
                Swal.fire('Transferir ou Cancelar Inscrição','Não foi possivel efetuar o cancelamento da inscrição. Favor contate o suporte', 'error');
              }

            }, (error) => {
              Swal.fire('Transferir ou Cancelar Inscrição',
                error.error.excecao[0].mensagem, 'error');
            });

          }
          else
          {
            Swal.fire('Transferir ou Cancelar Inscrição','Não foi possivel efetuar o cancelamento da inscrição. Favor contate o suporte', 'error');
          }
        }, (error) => {
          Swal.fire('Transferir ou Cancelar Inscrição',
            error.error.excecao[0].mensagem, 'error');
        });

      }
    }

  }

  cancelar() {
    this.botaoConfirmar = false;
    this.isSubmitted = false;
    this.tranferirRadio = false;

    this.tranferenciaForm = this.formBuilder.group({
      inscricao: ['', Validators.required],
      numero: [''],
      para: [''],
    });


  }

  transferir() {
    this.isSubmitted = false;
    this.botaoConfirmar = true;

    this.cancelarRadio = false;

    this.tranferenciaForm = this.formBuilder.group({
      inscricao: ['', Validators.required],
      numero: ['', Validators.required],
      para: ['', Validators.required],
    });
  }

  carregaInscricoes() {

    this.tranferenciaService.obterInscricoes(this.id).subscribe((res) => {
      res.resultado.map((item) => {
        item.descricaoInscricao = item.numeroExame + ' - ' + item.descricaoCidade + ' - ' + moment(item.dataExame).format('DD/MM/YYYY');
      });
      this.inscricoes = res.resultado;

    }, (error) => {
      Swal.fire('Transferir ou Cancelar Inscrição',
        error.error.excecao[0].mensagem, 'error');
    });
  }

  selecionaIncricao() {
    this.transferirHabilitado = false;
    this.justificativaObjeto = [];
    let obj = this.tranferenciaForm.controls['inscricao'].value;

    if (this.cancelarRadio) {
      if (obj.dataPagamento === null) {
        Swal.fire({
          title: 'Transferir ou Cancelar Inscrição',
          text: "Você deseja cancelar?",
          icon: 'question',
          showCancelButton: true,
          cancelButtonColor: '#3085d6',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.tranferenciaService.cancelarInscricao(this.tranferenciaForm.controls['inscricao'].value).subscribe(() => {
              Swal.fire('Transferir ou Cancelar Inscrição',
                "Inscrição cancelada com Sucesso! ", 'success').then(() => {

                  this.router.navigate(['/home']);
                  this.estornoModal.hide();
                });
            }, (error) => {
              Swal.fire('Transferir ou Cancelar Inscrição',
                error.error.excecao[0].mensagem, 'error');
            });
          }
        });
      }
      else {
        this.estornoModal.show();

        if (obj.habilitaEstornoBoleto === true) {
          this.camposObrigatoriosTed();
          this.tipoModal = 0;
          this.obterInfoBoleto()
        }
        if (obj.habilitaEstornoCartao === true) {
          this.camposObrigatorios();
          this.tipoModal = 1;
          this.obterInfoCartao();
        }
      }
      this.tipoBancario = 10;
    } else {
      if (obj.exameRealizado) {

        Swal.fire({
          title: 'Transferir ou Cancelar Inscrição',
          text: "É necessário justificar, deseja prosseguir?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.isConfirmed) {
            this.tranferenciaService.obterCidadeExame(obj.idInscricao).subscribe((res) => {
              this.justificativaForm = this.formBuilder.group({
                justificativaDescricao: ['', Validators.required],
              });
              this.isSubmittedJustificativa = false;
              this.justificativaModal.show();
              this.paras = res.resultado;
            }, (error) => {
              Swal.fire('Transferir ou Cancelar Inscrição',
                error.error.excecao[0].mensagem, 'error');
            });
          }
          else {
            this.init();
          }
        })

      } else {
        this.transferirHabilitado = true;
        this.tranferenciaService.obterCidadeExame(obj.idInscricao).subscribe((res) => {
          this.paras = res.resultado;

        }, (error) => {
          Swal.fire('Transferir ou Cancelar Inscrição',
            error.error.excecao[0].mensagem, 'error');
        });
      }
    }
  }

  obterInfoCartao() {
    this.tranferenciaService.obterInformativoCartao().subscribe((res) => {
      this.info = res.resultado;
      this.estornoForm.controls['codigoEtica'].setValue(res.resultado.descricao);

    });
  }

  obterInfoBoleto() {
    this.tranferenciaService.obterInformativoBoleto().subscribe((res) => {
      this.info = res.resultado;
      this.estornoForm.controls['codigoEtica'].setValue(res.resultado.descricao);
    });
  }

  selecionePara() {
    this.tranferenciaService.obterDataExameCidade(this.tranferenciaForm.controls['para'].value).subscribe((res) => {
      this.datas = res.resultado;

    }, (error) => {
      Swal.fire('Transferir ou Cancelar Inscrição',
        error.error.excecao[0].mensagem, 'error');
    });

  }

  hideEstorno(): void {
    this.estornoModal.hide();
    this.tranferirRadio = false;
  }

  hideJustificativa(): void {
    this.justificativaModal.hide();
    this.cancelarRadio = false;
  }

  get numero() {
    return this.tranferenciaForm.get('numero');
  }
  get inscricao() {
    return this.tranferenciaForm.get('inscricao');
  }
  get para() {
    return this.tranferenciaForm.get('para');
  }

  get cpf() {
    return this.estornoForm.get('cpf');
  }
  get codigo() {
    return this.estornoForm.get('codigo');
  }
  get banco() {
    return this.estornoForm.get('banco');
  }
  get agencia() {
    return this.estornoForm.get('agencia');
  }
  get conta() {
    return this.estornoForm.get('conta');
  }
  get digito() {
    return this.estornoForm.get('digito');
  }
  //MODAL JUSTIFICATIVA
  get justificativaDescricao() {
    return this.justificativaForm.get('justificativaDescricao');
  }

  onRemove() {
    this.obj = [];
    this.files.splice(0, 1);
    if (this.imagem !== '') {
      this.imagem = '';
    }
  }
  onSelect(event) {
    this.obj = [];
    this.onRemove();
    this.obj = event.source._previewChildren;
    this.files.push(...event.addedFiles);
  }
}
