<div class="pathway col-md-4 col-sm-4 hidden-xs text-right breadcrumb-center">
  <ol
    class="breadcrumbs"
    itemscope=""
    itemtype="http://schema.org/BreadcrumbList"
  >
    <li
      *ngFor="let breadcrumb of breadcrumbItems; let i = $index"
      itemprop="itemListElement"
      itemscope=""
      itemtype="http://schema.org/ListItem"
    >
      <a
        [routerLink]="breadcrumb.url"
        [class.router-link-active]="
          breadcrumb.url === currentUrl || breadcrumb.isAxis
        "
        itemprop="item"
        href="http://university.cactusthemes.com/"
        ><span itemprop="name">{{ breadcrumb.label }}</span></a
      ><meta itemprop="position" content="{{ i + 1 }}" />
    </li>
  </ol>
</div>
