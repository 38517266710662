import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SolicitacaoPlanoFinanceiroService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterSolicitacao(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/plano-financeiro/obter-solicitacao-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterSolicitacaoPorId(codigoSolicitacaoPlanoFinanceiro: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/plano-financeiro/obter-solicitacao`,
      {
        headers: this.httpHeaders,
        params: { codigoSolicitacaoPlanoFinanceiro },
      }
    );
  }

  criar(solicitacao: any) {
    return this.http.post<any>(
      `${environment.backofficeUrlV2}/plano-financeiro/incluir`,
      solicitacao,
      {
        headers: this.httpHeaders
      }
    );
  }

  alterar(solicitacao: any) {
    return this.http.put<any>(
      `${environment.backofficeUrlV2}/plano-financeiro/alterar`,
      solicitacao,
      {
        headers: this.httpHeaders
      }
    );
  }

  incluirAnexo(anexo: any) {
    return this.http.post<any>(
      `${environment.backofficeUrlV2}/plano-financeiro/incluir-anexo`,
      anexo,
      {
        headers: this.httpHeaders
      }
    );
  }

  removerAnexo(codigoSolicitacaoLicencaAnexo: any) {
    return this.http.put<any>(
      `${environment.backofficeUrlV2}/plano-financeiro/remover-anexo`,
      {
        headers: this.httpHeaders,
        params: { codigoSolicitacaoLicencaAnexo }
      }
    );
  }

  cancelarSolicitacao(idSolicitacaoPlanoFinanceiro: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/plano-financeiro/cancelar-solicitacao?idSolicitacaoPlanoFinanceiro=`+idSolicitacaoPlanoFinanceiro,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterNotas(idSolicitacaoPlanoFinanceiro: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/plano-financeiro/obter-notas`,
      {
        headers: this.httpHeaders,
        params: { idSolicitacaoPlanoFinanceiro },
      }
    );
  }

}
