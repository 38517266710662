import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SituacaoService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    let token = localStorage.getItem('tknCdst');


    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }



  obterSituacaoCertificado(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/trilha-situacao/obter-situacao-certificado`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterSituacaoRenovacao(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/trilha-situacao/obter-situacao-renovacao`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterSituacaoExperienciaSupervisionada(codigoPessoa: any, isRelatorios: any, isSupervisao: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/trilha-situacao/obter-situacao-experiencia-supervisionada`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, isRelatorios, isSupervisao },
      }
    );
  } 
  
  obterSituacaoCredenciamentoSupervisor(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/trilha-situacao/obter-situacao-credenciamento-supervisor`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }    

  obterNaoCertificado(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/trilha-situacao/obter-situacao-nao-certificado`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa,
        },
      }
    );
  }

  obterModalTexto() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-texto-abertura`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterCertificadoValidade(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/area-logada-auto-cadastro/obter-certificado-validade`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

}
