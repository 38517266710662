<fieldset class="border p-3 my-3">
  <legend class="legendFormulario">Exames</legend>
  <div class="dashboard">
    <div class="row">
        <div class="col-md-12">
          <Label>Trilha de Aprovação Exame CFP®</Label>
        </div>
      </div>
      <div class="row mt-3 ml-5">
        <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 d-flex flex-row" style="flex-wrap: wrap;">
                  <div class="card-home" (click)="abrirModulos()">
                    Trilha de aprovação
                  </div>

                  <div class="card-home" (click)="abrirHistorico()">
                    Histórico de exames
                  </div>

                  <div class="card-home" (click)="abrirDesempenho()">
                    Desempenho no exame atual
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
</fieldset>
<!-- <div *ngIf="certificado != null">
  <app-progress></app-progress>
</div> -->