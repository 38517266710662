<div class="container">
  <div class="col-12 d-flex justify-content-center mt-4">
    <h1 class="containerLogin_formulario_titulo">
      Cadastrar senha
    </h1>
  </div>
  <ng-container>
    <form
    class="containerLogin_formulario row no-gutters d-flex justify-content-center align-items-center mt-5 mb-5"
    name="alterarSenhaForm"
    [formGroup]="alterarSenhaForm"
    (ngSubmit)="alterarSenha(alterarSenhaForm)"
    >
      <div class="col-md-6 mt-3">
        <!-- SENHA -->
        <div class="form-row mt-2">
          <div class="form-group col-12">
            <label class=""
                  for="senha">Nova senha</label>
            <input class="form-control form-control-sm"
                  [class.is-invalid]="(senha.errors.strong || senha.errors.minlength) && senha.touched"
                  formControlName="senha"
                  (blur)="validaSenha()"
                  id="senha"
                  name="senha"
                  placeholder="Senha"
                  type="password" />

            <div [hidden]="olho">
              <img id="olho"
                  (mouseup)="mostrarNovaSenha()"
                  src="../../../../../assets/img/icons/eyeClose.svg"
                  title="Mostrar senha">
            </div>
            <div [hidden]="!olho">
              <img id="olho"
                  (mouseup)="ocultarNovaSenha()"
                  src="../../../../../assets/img/icons/eyeOpen.svg"
                  title="Ocultar senha">
            </div>

            <div *ngIf="senha.invalid && senha.touched">
              <span class="erro"
                    *ngIf="senha.errors.required">Campo obrigatório</span>
              <span class="erro"
                    *ngIf="(senha.errors.strong || senha.errors.minlength) && senha.value.length > 0">Senha
                não atende as regras abaixo.</span>
            </div>
          </div>
        </div>

        <!-- CONFIRMA SENHA -->
        <div class="form-row ">
          <div class="form-group col-12">
            <label class=""
                  for="confirmarSenha">Confirmar senha</label>
            <input class="form-control form-control-sm"
                  [class.is-invalid]="(confirmarSenha.invalid && confirmarSenha.touched ) || (errorSenhas && confirmarSenha.touched)"
                  formControlName="confirmarSenha"
                  id="confirmarSenha"
                  (blur)="validaSenha()"
                  name="confirmarSenha"
                  placeholder="Confirmar senha"
                  type="password" />
            <div [hidden]="olhoNovo">
              <img id="olho"
                  (mouseup)="mostrarConfirmaSenha()"
                  src="../../../../../assets/img/icons/eyeClose.svg"
                  title="Mostrar senha">
            </div>
            <div [hidden]="!olhoNovo">
              <img id="olho"
                  (mouseup)="ocultarConfirmaSenha()"
                  src="../../../../../assets/img/icons/eyeOpen.svg"
                  title="Ocultar senha">
            </div>
            <div *ngIf="confirmarSenha.invalid && confirmarSenha.touched">
              <span class="erro"
                    *ngIf="confirmarSenha.errors.required">Campo obrigatório</span>
            </div>
            <div *ngIf="confirmarSenha.value.length > 0">
              <span class="erro"
                    *ngIf="errorSenhas && confirmarSenha.touched">senhas não conferem</span>
            </div>
          </div>
        </div>

        <!-- REQUISITOS -->
        <div class="row"
        style="margin: -4px;">
          <div class="col-md-12 mb-2 mt-4">
            <label class="mb-3">Requisitos:</label>
          </div>
          <div class="col-md-12">
            <label> Conter pelo menos 8 caracteres </label>
            <div *ngIf="senha.invalid && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style="left: 190px;
                              width: 12px;
                              height: 12px;
                              position: relative;
                              top: -32px;"
                    *ngIf="senha.errors.minlength || senha.value.length == 0"
                    src="../../../../../assets/img/icons/visualizarOK.svg" />
            </div>
            <div *ngIf="((!senha.errors.minlength) && (senha.value.length != 0)) && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style=" left: 190px ;
                            width: 12px;
                              height: 12px;
                            position: relative;
                            top: -32px;"
                    src="../../../../../assets/img/icons/visualizarO.svg" />
            </div>

          </div>
          <div class="col-md-12">
            <label>Conter letras maiúsculas</label>
            <div *ngIf="senha.invalid && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style="left: 150px;
                              width: 12px;
                              height: 12px;
                              position: relative;
                              top: -32px;"
                    *ngIf="!senha.errors.upper && senha.invalid"
                    src="../../../../../assets/img/icons/visualizarOK.svg" />
            </div>
            <div *ngIf="(senha.errors.upper && senha.errors.upper != null) && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style=" left: 150px;
                              width: 12px;
                              height: 12px;
                            position: relative;
                            top: -32px;"
                    src="../../../../../assets/img/icons/visualizarO.svg" />
            </div>
          </div>
          <div class="col-md-12">
            <label>Conter letras minúsculas</label>
            <div *ngIf="senha.invalid && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style="left: 150px;
                          width: 12px;
                          height: 12px;
                          position: relative;
                          top: -32px;"
                    *ngIf="!senha.errors.lower && senha.invalid"
                    src="../../../../../assets/img/icons/visualizarOK.svg" />
            </div>
            <div *ngIf="(senha.errors.lower && senha.errors.lower != null) && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style=" left: 150px;
                          width: 12px;
                          height: 12px;
                          position: relative;
                          top: -32px;"
                    src="../../../../../assets/img/icons/visualizarO.svg" />
            </div>
          </div>
          <div class="col-md-12">
            <label>Conter pelo menos um número</label>
            <div *ngIf="senha.invalid && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style="left: 185px;
                        width: 12px;
                        height: 12px;
                      position: relative;
                      top: -32px;"
                    *ngIf="!senha.errors.number && senha.invalid"
                    src="../../../../../assets/img/icons/visualizarOK.svg" />
            </div>
            <div *ngIf="(senha.errors.number && senha.errors.number != null) && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style=" left: 185px;
                        width: 12px;
                        height: 12px;
                      position: relative;
                      top: -32px;"
                    src="../../../../../assets/img/icons/visualizarO.svg" />
            </div>
          </div>
          <div class="col-md-12">
            <label>Conter pelo menos um caractere especial (@#$%+=)</label>
            <div *ngIf="senha.invalid && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style="left: 310px;
                              width: 12px;
                              height: 12px;
                            position: relative;
                            top: -32px;"
                    *ngIf="!senha.errors.caracters && senha.invalid"
                    src="../../../../../assets/img/icons/visualizarOK.svg" />
            </div>
            <div *ngIf="(senha.errors.caracters && senha.errors.caracters != null) && senha.touched"
                  style="height:0px">
              <img class="logo"
                    style=" left: 310px;
                          width: 12px;
                              height: 12px;
                        position: relative;
                        top: -32px;"
                    src="../../../../../assets/img/icons/visualizarO.svg" />
            </div>
          </div>
        </div>

        <!-- BOTÕES -->
        <div class="col-12 d-flex justify-content-center mt-4">
          <button
            class="btn btn-sm btn-secondary"
            (click)="cancelar()"
            title="cancelar"
          >
            Cancelar
          </button>
          <button
            class="btn btn-sm btn-primary ml-2"
            type="button"
            (click)="alterarSenha(alterarSenhaForm)"
            title="salvar"      
            [disabled]="senha.errors.strong || errorSenhas"
          >
            Salvar
          </button>
        </div>
      </div>
   
    </form>
  </ng-container>
</div>