import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent implements OnInit {
  currentUser: any;
  countMensagens: number = 0;
  mainNavs = [
    // {
    //   name: 'Home',
    //   description: 'All Variations',

    // },
    // {
    //   name: 'Events',
    //   description: 'Our Activities',
    //   subnavs: [{ name: 'Sub 1' }, { name: 'Sub 2' }, { name: 'Sub 3' }],
    // },
    // {
    //   name: 'Courses',
    //   description: 'Research & Study',
    //   subnavs: [{ name: 'Sub 1' }, { name: 'Sub 2' }, { name: 'Sub 3' }],
    // },
    // {
    //   name: 'Pages',
    //   description: 'Designed Pages',
    //   subnavs: [{ name: 'Sub 1' }, { name: 'Sub 2' }, { name: 'Sub 3' }],
    // },
    // {
    //   name: 'Shortcodes',
    //   description: 'Simple & Useful',
    //   subnavs: [{ name: 'Sub 1' }, { name: 'Sub 2' }, { name: 'Sub 3' }],
    // },
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private mensagemService: MensagemService,
    private router: Router) {}

  ngOnInit(): void {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      this.currentUser = JSON.parse(currentUser.informacoes);
      this.mensagemService.naoLidasCount(currentUser.codigo_usuario).subscribe((res) => {
        this.countMensagens = res.resultado ? Number(res.resultado) : 0;
      })
    } else {
      this.currentUser = null;
    }

    this.authenticationService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = JSON.parse(user.informacoes);
        this.mensagemService.naoLidasCount(user.codigo_usuario).subscribe((res) => {
          this.countMensagens = res.resultado ? Number(res.resultado) : 0;
        })
      } else {
        this.currentUser = null;
      }
    });

  }

  abrirMensagens() {
    this.router.navigate(['/mensagens']);
    this.countMensagens = 0;
    console.log('abrir pagina de mensagens', this.countMensagens);
  }

  logout() {
    this.authenticationService.logout();
  }
  goHome(){
    this.router.navigate(['/home']);

  }
}
