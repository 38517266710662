import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExameService } from 'src/app/services/exame/exame.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exame',
  templateUrl: './exame.component.html',
  styleUrls: ['./exame.component.scss']
})
export class ExameComponent implements OnInit {
  files: File[] = [];
  view = false;
  imagem = null;
  usuario = null;
  exames = null;
  inscricaoModel = 1;

  constructor(private router: Router,
    private route: ActivatedRoute, private exameService : ExameService) { }

  ngOnInit(): void {
    this.usuario = window.localStorage.getItem('userId');    
  }

  abrirModulos() {
    this.router.navigate(['modulos-atual'])
  }

  abrirHistorico() {
    this.router.navigate(['/exames-config/0/exame'])
  }

  abrirDesempenho(){
    this.router.navigate(['/exames-config/1/exame'])
  }

}
