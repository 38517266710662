<form
  name="buscaSupervisorForm"
  id="buscaSupervisorForm"
  [formGroup]="buscaSupervisorForm"
  (ngSubmit)="submit(buscaSupervisorForm)"
>
  <div class="container-principal">
    <div class="mb-3 mt-3 row">
      <label class="texto--sm mr-2" for="nome">Nome</label>
      <input
        class="form-control form-control-sm"
        formControlName="nome"
        id="nome"
        maxlength="100"
        name="nome"
        type="text"
        placeholder="Ex.: Maria"
      />

      <button
        class="btn btn-sm btn-primary mr-0 ml-2"
        id="pesquisarPorNome"
        (click)="submit(buscaSupervisorForm)"
        type="button"
      >
        Pesquisar
      </button>
    </div>
    <div class="row" *ngif="false">
      <div class="form-group">
        <label class="texto--sm" for="formacao"
          >Qual especialidade você procura ?</label
        >
      </div>
    </div>

    <div class="row" *ngif="false">
      <div class="form-group col-md-12">
        <div class="col-md-6" *ngFor="let area of areas">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input"
              type="checkbox"
              id="area.IdAreaSupervisao"
              (click)="checkArea(area.idAreaSupervisao)"
              name="area-{{ area.idAreaSupervisao }}"
            />
            <label class="ml-2">{{ area.descricao }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group">
        <label class="texto--sm" for="formacao"
          >Prefere atendimento presencial ou à distância ?</label
        >
      </div>
      <input
        name="atendimentoRadio"
        type="radio"
        id="atendimento"
        formControlName="atendimentoRadio"
        value="1"
      />
      <label for="atendimentoRadio">&nbsp; Presencial</label>

      <span class="ml-5"></span>
      <input
        name="atendimentoRadio"
        type="radio"
        formControlName="atendimentoRadio"
        value="2"
      />
      <label for="atendimentoRadio">&nbsp; À distância</label>

      <span class="ml-5"></span>
      <input
        name="atendimentoRadio"
        type="radio"
        formControlName="atendimentoRadio"
        value="3"
        [(ngModel)]="selectedValue"
      />
      <label for="atendimentoRadio">&nbsp; Híbrido</label>
    </div>
    <div class="row">
      <div class="form-group">
        <label class="texto--sm" for="localidade"
          >Deseja especificar uma localidade ?</label
        >
      </div>
      <div class="row align-items-center d-flex row">
        <div class="form-group col-md-4">
          <label class="texto--sm" for="ufExpedicao">UF</label>
          <select
            class="form-control form-control-sm custom-select custom-select-sm"
            formControlName="uf"
            id="uf"
            name="uf"
            [(ngModel)]="uf"
            (change)="changeUfObterCidade()"
          >
            <option value="" disabled>Selecione</option>
            <option *ngFor="let estado of estados" [value]="estado.uf">
              {{ estado.uf }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-4">
          <label class="texto--sm" for="municipio">Município</label>
          <select
            class="form-control form-control-sm custom-select custom-select-sm"
            id="municipio"
            name="municipio"
            formControlName="municipio"
            [(ngModel)]="municipio"
          >
            <option [value]="null" disabled>Selecione</option>
            <option
              *ngFor="let municipio of arrMunicipios"
              value="{{ municipio.descricao }}"
            >
              {{ municipio.descricao }}
            </option>
          </select>
        </div>
        </div>
        <div class="row align-items-center d-flex row">

          <div class="col-md-6">
            <button
            class="btn btn-sm btn-primary mr-0 ml-2"
            id="pesquisarPorFiltros"
            (click)="submit(buscaSupervisorForm)"
            type="button"
          >
            Pesquisar
          </button>

          <button
            class="btn btn-sm btn-primary mr-0 ml-2"
            id="pesquisarSemFiltros"
            (click)="buscaSemFiltro(true)"
            type="button"
          >
            Relação Completa de Supervisores
          </button>
        </div>
      </div>
      </div>



  </div>
</form>
<br/>
<div class="row">
<div class="container-principal" *ngIf="exibeGrade">
  <div class="row col-md-12">
    <fieldset class="border p-3 my-3">
      <table class="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Cidade</th>
            <th>UF</th>
            <th>Especialidades</th>
            <th>Perfil</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let supervisor of supervisores; let i = index">
            <td>{{supervisor.nome}}</td>
            <td>{{supervisor.cidade}}</td>
            <td>{{supervisor.uf}}</td>
            <td>{{supervisor.especialidades}}</td>
            <td class="text-center"
                width="60px">
              <div class="row">
                <button
                        class="btn btn-sm"
                        title="visualizar"
                        routerLink="../selecionar-supervisor/{{supervisor.idPessoa}}"
                        type="button">
                  <img class="logo"
                       style=" height: 21px;
                            position: relative;"
                       src="../../../../../assets/img/icons/search_icon_grey.svg" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</div>
</div>
<div *ngIf="nenhumEncontrado">Nenhum registro encontrado.</div>
