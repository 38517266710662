<div class="row" *ngIf="!perfilProfissional">
    <div class="form-group col-md-12">
        <div class="d-flex">
          <div class="custom-control custom-radio">
            <input type="radio"
                   id="pesquisaNome"
                   value="1"
                   class="custom-control-input"
                   (change)="pesquisarFiltro()"
                   name="pesquisar"
                   [(ngModel)]="pesquisaModel" />
            <label class="custom-control-label pt-1 ml-3"
                   for="pesquisaNome">Pesquisa por nome</label>
          </div>
          <div class="custom-control custom-radio ml-3">
            <input type="radio"
                   value="0"
                   id="pesquisaFiltro"
                   class="custom-control-input"
                   name="pesquisar"
                   [(ngModel)]="pesquisaModel"
                   (change)="pesquisarFiltro()" />
            <label class="custom-control-label pt-1 ml-3"
                   for="pesquisaFiltro">Pesquisa por filtro</label>
          </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="!perfilProfissional">
    <div *ngIf="porNome">
        <div class="form-group col-md-4">
            <label class="texto--sm"
                   for="nome"></label>
            <input class="form-control form-control-sm"
                   id="nome"
                   placeholder="Ex: Maria"
                   name="nome"
                   [(ngModel)]="nome"
                   type="text" />
        </div>

        <div class="col-md-3 mt-3">
            <label>&nbsp;</label>
            <button class="btn btn-sm btn-primary mr-0 ml-2 mt-3 botao"
                    type="button"
                    [disabled]="!validaCaptcha"
                    (click)="pesquisarPorNome()">Pesquisar</button>
        </div>

        <div class="col-md-12"
             *ngIf="!validaCaptcha">
            <re-captcha (resolved)="resolved($event)"
                        siteKey="{{ chaveCaptcha }}"
                        size="300"></re-captcha>
        </div>

    </div>

    <div class="col-md-12" *ngIf="porFiltros">
        <p>Qual especialidade você procura?</p>
        <div *ngFor="let especialidade of arrEspecialidades">
        <div class="form-group col-md-6 col-sm-12" >
            <input id="especialidades.idAreaAtuacaoConhecimento"
                   name="especialidade.idAreaAtuacaoConhecimento"
                   type="checkbox"
                   [(ngModel)]="especialidade.checked"
                   (change)="verificaChebox()"
                   class="checkLateral" />
            <label for="especialidades.idAreaAtuacaoConhecimento">&nbsp; {{especialidade.descricao}}.</label>
        </div>
    </div>

        <div class="row">
            <div class="col-md-12">
                <p>Prefere atendimento preferencial ou à distância?</p>
            </div>
            <div class="col-md-2">
                <input
                    [(ngModel)]="atendimento"
                    name="atendimento"
                    type="radio"
                    value="0" />
                <label for="atendimento">&nbsp; Presencial</label>
            </div>
            <div class="col-md-2">
                <input
                    [(ngModel)]="atendimento"
                    name="atendimento"
                    type="radio"
                    value="1" />
                <label for="atendimento">&nbsp; À distância</label>
            </div>
            <div class="col-md-2">
                <input
                    [(ngModel)]="atendimento"
                    name="atendimento"
                    type="radio"
                    value="2" />
                <label for="atendimento">&nbsp; Híbrido</label>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <p>Deseja especificar uma localidade?</p>
            </div>

            <div class="form-group col-md-3">
                <label class="texto--sm"
                      for="idioma">UF</label>
                <select class="form-control form-control-sm custom-select custom-select-sm"
                        [(ngModel)]="uf"
                        (change)="changeUfObterCidade()"
                        id="uf"
                        name="uf">
                  <option [value]="null"
                          disabled>Selecione</option>
                  <option *ngFor="let uf of arrUf"
                          value="{{uf.idEstado}}">
                            {{ uf.descricao }}
                  </option>
                </select>
            </div>

            <div class="form-group col-md-3">
                <label class="texto--sm"
                    for="nivel">Município</label>
                <select class="form-control form-control-sm custom-select custom-select-sm"
                        [(ngModel)]="municipio"
                        id="municipio"
                        name="municipio">
                    <option [value]="null"
                            disabled>Selecione</option>
                    <option *ngFor="let municipio of arrMunicipios"
                            value="{{municipio.idCidade}}">
                                {{ municipio.descricao }}
                    </option>
                </select>

            </div>

        </div>
        <div class="row">
            <div class="col-md-6"
            *ngIf="!validaCaptcha">
                <re-captcha (resolved)="resolved($event)"
                            siteKey="{{ chaveCaptcha }}"
                            size="300"></re-captcha>
            </div>

            <div class="col-md-6 mt-3">
                <label>&nbsp;</label>
                <button class="btn btn-sm btn-primary mr-0 ml-2 mt-3 botao"
                        [disabled]="!validaCaptcha"
                        type="button"
                        (click)="pesquisaPorFiltros()">Pesquisar</button>
            </div>
        </div>

    </div>
</div>


<div class="row mt-4" *ngIf="!perfilProfissional">
    <div class="table-wrapper table-responsive col-md-12">
        <table id="planejadores"
               class="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Cidade</th>
              <th>UF</th>
              <th>Especialidades</th>
              <th class="text-center not-sorting"
                  width="130px">Perfil</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let planejador of arrPlanejadores; let i = index">
              <td>{{planejador.nome}}</td>
              <td>{{planejador.cidade}}</td>
              <td>{{planejador.uf }}</td>
              <td>{{planejador.especialidades }}</td>
              <td class="text-center">
                <button class="btn btn-sm btn-transparent m-0"
                        (click)="verPerfil(planejador.idPessoa)"
                        *ngIf="planejador.permiteExibirPerfil === 1"
                        title="Ver Perfil"
                        type="button">
                    <img class="logo"
                        src="../../../../../assets/img/icons/search_icon.svg"
                        style=" height: 21px;
                           position: relative;" />
                </button>
                <div *ngIf="planejador.permiteExibirPerfil === 0">
                    Privado
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
</div>



<div class="row mt-5" *ngIf="perfilProfissional">
    <div class="col-md-2">
        <img [src]="'data:image/png;base64,' + dadosProfissional.foto" alt="Foto profissional" width="80">
    </div>
    <div class="col-md-5">
        <p>{{ dadosProfissional.pessoaEndereco[0].cidade }} - {{ dadosProfissional.pessoaEndereco[0].estado }}</p>
        <p><strong>{{ dadosProfissional.nome }}</strong></p>
    </div>


    <div class="col-md-6 mt-4">
        <p>Validade: {{ dadosProfissional.validadeCertificado | date: 'dd/MM/yyyy'}}</p>
        <p *ngIf="dadosProfissional.permiteExibirEmail === 1">Email: {{ dadosProfissional.email}}</p>
        <p *ngIf="dadosProfissional.permiteExibirLinkedin === 1">LinkedIn: {{ dadosProfissional.linkedin }}</p>
    </div>

    <div class="col-md-6 mt-4">
        <p *ngIf="dadosProfissional.permiteExibirCelular === 1">Telefone Celular: {{ dadosProfissional.ddiCelular + ' - ' + dadosProfissional.dddCelular + dadosProfissional.telefoneCelular}}</p>
        <p *ngIf="dadosProfissional.permiteExibirTelefoneComercial === 1">Telefone Comercial: {{ dadosProfissional.ddiComercial + ' - ' + dadosProfissional.telefoneComercial}}</p>
        <p>Atendimento: {{dadosProfissional.formaAtendimento }}</p>
    </div>

    <div class="col-md-12">
        <p class="h3"><strong>Apresentação</strong></p>
        <textarea rows="6"
                  readonly
                  class="textArea"
                  [ngModel]="dadosProfissional.apresentacaoPessoal">
        </textarea>
    </div>

    <div class="col-md-6">
        <table class="table table-bordered table-responsive">
            <thead>
              <tr>
                <th>Idioma</th>
                <th>Nível</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let idioma of idiomaPessoa; let i = index">
                <td>{{idioma.descricaoIdioma}}</td>
                <td>{{idioma.descricaoNivel}}</td>
              </tr>
            </tbody>
        </table>
    </div>

    <div class="col-md-6">
        <div class="form-group col-md-12" *ngIf="dadosProfissional.permiteExibirEmpresaAtual === 1">
            <input class="form-control form-control-sm"
                   [ngModel]="dadosProfissional.empresaAtual"
                   [readonly]="true"
                   id="empresaAtual"
                   placeholder="Empresa Atual"
                   name="empresaAtual"
                   type="text" />
        </div>
        <div class="form-group col-md-12" *ngIf="dadosProfissional.permiteExibirEspecialidades === 1">
            <input class="form-control form-control-sm"
                   [ngModel]="dadosProfissional.especialidades"
                   [readonly]="true"
                   id="especialidades"
                   placeholder="Especialidades"
                   name="especialidades"
                   type="text" />
        </div>
        <div class="form-group col-md-12" *ngIf="dadosProfissional.permiteExibirCertificacoes === 1">
            <input class="form-control form-control-sm"
                   [ngModel]="dadosProfissional.certificados"
                   [readonly]="true"
                   id="certificacoes"
                   placeholder="Certificados"
                   name="certificacoes"
                   type="text" />
        </div>
    </div>

    <div class="col-md-12">
        <button (click)="perfilProfissional = false; validaCaptcha = false" class="btnSiteVoltar">
            Voltar
        </button>

    </div>
</div>
