import { Component, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PerguntaService } from 'src/app/services/perguntas/pergunta.service';


@Component({
  selector: 'preparacao',
  templateUrl: './preparacao.component.html',
  styleUrls: ['./preparacao.component.scss'],
})
export class PreparacaoComponent implements OnInit, AfterViewInit, OnDestroy {
  isInscricao: boolean = true;
  @Output() validForm = new EventEmitter();
  isSubmitted: boolean;
  config: any;
  itens = [];
  perguntas: any;

  public ddiMask = [/\d/, /\d/, /\d/, /\d/];
  public emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private perguntaService: PerguntaService,
  ) {

    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '200px',
      toolbar: [
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    }
  }
  ngOnDestroy() {
    document.removeEventListener("salvarPreparacaoExame", null)
  }
  ngAfterViewInit() {
    document.addEventListener("salvarPreparacaoExame", () => {
      this.submit()
    })
  }

  ngOnInit(): void {
    this.obterPerguntas();
  }

  onChange(value, idPergunta) {
    const obj = {
      idPergunta: idPergunta,
      idOpcao: Number(value)
    }
    if (this.itens) {
      const exists = this.itens.filter(i => i.idPergunta === obj.idPergunta);
      if (exists.length > 0) {
        let idx = this.itens.indexOf(exists[0]);
        this.itens.splice(idx, 1);
      }
    }

    this.itens.push(obj);
  }

  obterPerguntas() {
    this.perguntaService.obterPerguntas().subscribe((res) => {
      this.perguntas = res.resultado;
    });
  }

  async submit() {
    this.isSubmitted = true;
    if (this.itens.length === this.perguntas.length) {
      const obj = {
        valid: true,
        respostas: this.itens
      }
      this.validForm.emit(obj)
      this.enviaEventoAposSubmit(obj)
    }
    else {
      const obj = {
        valid: false,
        respostas: null
      }
      this.validForm.emit(obj)
      this.enviaEventoAposSubmit(obj)
    }
  }

  enviaEventoAposSubmit(obj: any) {
    const event = new CustomEvent("evento-apos-salvar-preparacao", { detail: obj })
    document.dispatchEvent(event)
  }
}
