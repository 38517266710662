<form name="enefForm"
      id="enefForm"
      [formGroup]="enefForm"
      (ngSubmit)="submit(enefForm)">
  <div class="container-principal">
    <div class="row col-md-12">
      <fieldset class="border p-3 my-3">
        <legend class="legendFormulario">Consultar Semana ENEF -Inscrição </legend>
        <div class="row">
          <div class="col-md-12">
            <label>Aqui você pode se inscrever como voluntário da semana ENEF.</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>{{headerItens[0].descricao}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Inscrição de {{headerItens[0].inscricaoInicio | date: 'dd/MM/yyyy'}} a {{headerItens[0].inscricaoFim | date: 'dd/MM/yyyy'}}.</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-4">
            <label>Realização de {{headerItens[0].realizacaoInicio | date: 'dd/MM/yyyy'}} a {{headerItens[0].realizacaoFim | date: 'dd/MM/yyyy'}}.</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label class="legendFormulario">Agendas</label>
          </div>
        </div>,
        <div class="row">
            <div class="form-group col-md-3 col-sm-3">
              <label class="texto--sm"
                    for="tipo">Tipo</label>
              <select class="form-control form-control-sm custom-select custom-select-sm"
                      formControlName="tipo"
                      [class.is-invalid]="tipo.invalid && (tipo.touched || isSubmitted)"
                      id="tipo"
                      name="tipo">
                <option value=""
                        disabled>Selecione</option>
                <option *ngFor="let tipo of tipos"
                        [value]="tipo.idEventoTema">
                  {{ tipo.descricao }}
                </option>
              </select>
              <div *ngIf="tipo.invalid && (tipo.touched || isSubmitted)">
                <span class="erro"
                      *ngIf="tipo.errors.required">Campo obrigatório</span>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-3">
              <label class="texto--sm"
                    for="tipo">Assunto</label>
              <select class="form-control form-control-sm custom-select custom-select-sm"
                      formControlName="assunto"
                      [class.is-invalid]="assunto.invalid && (assunto.touched || isSubmitted)"
                      id="assunto"
                      name="assunto">
                <option value=""
                        disabled>Selecione</option>
                <option *ngFor="let assunto of assuntos"
                        [value]="assunto.idEventoAgendaTipo">
                        {{assunto.descricao }}
                </option>
              </select>
              <div *ngIf="assunto.invalid && (assunto.touched || isSubmitted)">
                <span class="erro"
                      *ngIf="assunto.errors.required">Campo obrigatório</span>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-3">
              <label class="texto--sm"
                    for="tipo">Público</label>
              <select class="form-control form-control-sm custom-select custom-select-sm"
                      formControlName="publico"
                      [class.is-invalid]="publico.invalid && (publico.touched || isSubmitted)"
                      id="publico"
                      name="publico">
                <option value=""
                        disabled>Selecione</option>
                <option *ngFor="let publico of publicos"
                        [value]="publico.idEventoPublico">
                  {{ publico.descricao }}
                </option>
              </select>
              <div *ngIf="publico.invalid && (publico.touched || isSubmitted)">
                <span class="erro"
                      *ngIf="publico.errors.required">Campo obrigatório</span>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-3">
              <label class="texto--sm"
                    for="estado">Estado</label>
              <select class="form-control form-control-sm custom-select custom-select-sm"
                      formControlName="estado"
                      [class.is-invalid]="estado.invalid && (estado.touched || isSubmitted)"
                      id="estado"
                      name="estado">
                <option value=""
                        disabled>Selecione</option>
                <option *ngFor="let estado of estados"
                        [value]="estado.idEstado">
                  {{ estado.uf }}
                </option>
              </select>
              <div *ngIf="estado.invalid && (estado.touched || isSubmitted)">
                <span class="erro"
                      *ngIf="estado.errors.required">Campo obrigatório</span>
              </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="form-group col-md-3 col-sm-3">
              <label class="texto--sm"
                    for="cidade">Cidade</label>
              <select class="form-control form-control-sm custom-select custom-select-sm"
                      formControlName="cidade"
                      [class.is-invalid]="cidade.invalid && (cidade.touched || isSubmitted)"
                      id="cidade"
                      name="cidade">
                <option *ngFor="let cidade of cidades"
                        [value]="cidade.idCidade">
                  {{ cidade.descricao }}
                </option>
              </select>
              <div *ngIf="cidade.invalid && (cidade.touched || isSubmitted)">
                <span class="erro"
                      *ngIf="cidade.errors.required">Campo obrigatório</span>
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="texto--sm"
                    for="de">De:</label>
              <input class="form-control form-control-sm"
                    [class.is-invalid]="de.invalid && (de.touched || isSubmitted)"
                    formControlName="de"
                    id="de"
                    maxlength="100"
                    name="de"
                    type="date"/>
              <div *ngIf="
                  de.invalid && (de.touched || isSubmitted)
                ">
                <span class="erro"
                      *ngIf="de.errors.required">Campo obrigatório</span>
              </div>
            </div>
            <div class="form-group col-md-3">
              <label class="texto--sm"
                    for="ate">Até:</label>
              <input class="form-control form-control-sm"
                    [class.is-invalid]="ate.invalid && (ate.touched || isSubmitted)"
                    formControlName="ate"
                    id="ate"
                    maxlength="100"
                    name="ate"
                    type="date"/>
              <div *ngIf="
                  ate.invalid && (ate.touched || isSubmitted)
                ">
                <span class="erro"
                      *ngIf="ate.errors.required">Campo obrigatório</span>
              </div>
            </div>
            <div class="col-md-1 d-flex justify-content-end align-items-center mt-4">
              <button class="btn btn-sm btn-primary mt-3 "
                      id="filtrar"
                      type="submit">
                Filtrar
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4">
              <label class="legendFormulario">Agendas</label>
            </div>
          </div>
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Assunto</th>
              <th>Público</th>
              <th>Local</th>
              <th>Data</th>
              <th>Horário</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let agenda of agendas; let i = index">
              <td>{{agenda.descricaoTipoParticipante}}</td>
              <td>{{agenda.descricaoTema}}</td>
              <td>{{agenda.descricaoPublico}}</td>
              <td>{{agenda.descricaoLocal}}</td>
              <td>{{agenda.data | date: 'dd/MM/yyyy'}}</td>
              <td>{{agenda.data | date: 'hh:mm'}}</td>
              <td class="text-center"
                  width="120px">
                <div class="row">
                  <!-- INFO -->
                  <button *ngIf="!agenda.habilitarRecibo"
                          class="btn btn-sm "
                          title="Informações"
                          (click)="informacao(agenda)"
                          type="button">
                    <img class="logo"
                        style=" height: 21px;
                              position: relative;"
                        src="../../../../../assets/img/icons/info.svg" />
                  </button>
                  <!-- palestrante -->
                  <button *ngIf="!agenda.habilitarRecibo"
                          class="btn btn-sm "
                          [disabled]="!agenda.habilitarPalestrante"
                          title="palestrante"
                          (click)="criar(agenda, 2)"
                          type="button">
                    <img class="logo"
                        style=" height: 21px;
                              position: relative;"
                        src="../../../../../assets/img/icons/microfone.svg" />
                  </button>
                  <!-- backup -->
                  <button *ngIf="!agenda.habilitarRecibo"
                          class="btn btn-sm "
                          [disabled]="!agenda.habilitarBackup"
                          (click)="criar(agenda, 3)"
                          title="Back-up"
                          type="button">
                    <img class="logo"
                        style=" height: 21px;
                              position: relative;"
                        src="../../../../../assets/img/icons/hard-drive.svg" />
                  </button>
                  <!-- backup -->
                  <button *ngIf="!agenda.habilitarRecibo"
                          class="btn btn-sm "
                          title="Ouvinte"
                          (click)="criar(agenda, 4)"
                          [disabled]="!agenda.habilitarOuvinte"
                          type="button">
                    <img class="logo"
                        style=" height: 21px;
                              position: relative;"
                        src="../../../../../assets/img/icons/fones-de-ouvido.svg" />
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col-md-12 mt-4">
            <label class="legendFormulario">Inscrições Realizadas</label>
          </div>
        </div>
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Assunto</th>
              <th>Público</th>
              <th>Local</th>
              <th>Data</th>
              <th>Horário</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let realizadas of agendasRelizadas; let i = index">
              <td>{{realizadas.descricaoTipoAgenda}}</td>
              <td>{{realizadas.descricaoTema }}</td>
              <td>{{realizadas.descricaoPublico}}</td>
              <td>{{realizadas.descricaoLocal}}</td>
              <td>{{realizadas.dataAgenda | date: 'dd/MM/yyyy'}}</td>
              <td>{{realizadas.dataAgenda | date: 'HH:MM'}}</td>
              <td class="text-center"
                  width="120px">
                <div class="row">
                   <!-- INFO -->
                   <button *ngIf="!realizadas.habilitarRecibo"
                   class="btn btn-sm "
                   title="Informações"
                   (click)="informacao(realizadas)"
                   type="button">
             <img class="logo"
                 style=" height: 21px;
                       position: relative;"
                 src="../../../../../assets/img/icons/info.svg" />
           </button>
           <!-- EXCLUIR -->
           <button class="btn btn-sm"
           (click)="excluir(realizadas.idEventoAgendaInscricao)"
           [disabled]="!realizadas.habilitarExcluir"
           title="Excluir"
           type="button">
     <img class="logo"
          src="../../../../../assets/img/icons/excluir.svg"
          style=" height: 15px;
             position: relative;" />
   </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </div>
  </div>
</form>

  <!-- MODAL  -->
  <div aria-labelledby="dialog-child-name"
       bsModal
       #childModal="bs-modal"
       [config]="{ backdrop: 'static', keyboard: false}"
       class="modal  fade"
       tabindex="-1"
       role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 id="dialog-child-name"
              class="modal-title pull-left" style="margin-top: -15px">Informações </h2>
          <button aria-label="Close"
                  class="close pull-right"
                  (click)="hideChildModal()"
                  type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <div class="row">
            <div class="col-md-3">
              <label class="texto--sm">Tipo - {{itensModal[0].descricaoTema}} - {{itensModal[0].descricaoPublico}} </label>
            </div>
            <div class="col-md-3">
              <label class="texto--sm">Data - {{itensModal[0].dataAgenda | date: 'dd/MM/yyyy hh:mm'}} </label>

            </div>
            <div class="col-md-3">
              <label class="texto--sm">Local</label>

            </div>
            <div class="col-md-3">
              <label class="texto--sm"> Endereço</label>
            </div>
          </div> -->
          <div class="row">
            <table class="table table-bordered table-responsive">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Tema</th>
                  <th>Data</th>
                  <th>Endereço</th>
                  <th>Função</th>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th>E-mail</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let realizadas of itensModal; let i = index">
                  <td>{{realizadas.descricaoTipoAgenda }}</td>
                  <td>{{realizadas.descricaoTema }}</td>
                  <td>{{realizadas.dataAgenda | date: 'dd/MM/yyyy hh:mm'}}</td>
                  <td>{{realizadas.descricaoLocal  }}</td>
                  <td>{{realizadas.descricaoTipoParticipante }}</td>
                  <td>{{realizadas.nome}}</td>
                  <td>{{realizadas.ddiCelular}}({{realizadas.dddCelular}}){{realizadas.telefoneCelular | mask: '00000-0000'}}</td>
                  <td>{{realizadas.email}}</td>


                </tr>
              </tbody>
            </table>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="texto--sm">Observações da Agenda</label>
              <textarea class="form-control form-control-sm" id="agenda" name="agenda"
                 [(ngModel)]="itens['descricaoTipoAgenda']"
                 disabled
                     rows="5" cols="33">
          </textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="texto--sm">Observações do Local</label>
              <textarea class="form-control form-control-sm" id="local" name="local"
                         [(ngModel)]="itens['descricaoLocal']"
                         disabled
                         rows="5" cols="33">
          </textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-primary "
                  (click)="hideChildModal()">Fechar</button>
        </div>
      </div>
    </div>
  </div>
