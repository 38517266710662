
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EducacaoService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterFormacao() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-educacao/obter-formacao`,
      {
        headers: this.httpHeaders,
      }
    );
  }


  obterAreaAtuacaoConhecimento() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-educacao/obter-area-atuacao-conhecimento`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterCertificado() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-educacao/obter-certificado`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarEducacao(experiencia: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-educacao/criar-educacao`,
      experiencia,
      {
        headers: this.httpHeaders,
      }
    );
  }

  alterarEducacao(pessoa: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-educacao/alterar-educacao`,
      pessoa,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterEducacao(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-educacao/obter-educacao`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

desativarEducacao(codigoTrilhaPessoaItem: any) {
  return this.http.delete(
    `${environment.backofficeUrl}/area-logada-educacao/desativar-educacao?codigoTrilhaPessoaItem=${codigoTrilhaPessoaItem}`,
    {
      headers: this.httpHeaders,
    }
  );
}

obterPendencia(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-pendencia-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }


}
