import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AgendaService } from 'src/app/services/agenda/agenda.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agendas',
  templateUrl: './agendas.component.html',
  styleUrls: ['./agendas.component.scss']
})
export class AgendasComponent implements OnInit {
  files: File[] = [];
  usuario = null;
  periodos = [];
  teste = '20'
  habilitaCreditos = false;
  prospeccao= null;
  agendas= [];
  idAgenda : any;
  exibeAgenda = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private agendaService: AgendaService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.idAgenda = params.get("id");
    });
    this.usuario = window.localStorage.getItem('userId');
    this.obterProspeccao();
    this.carregaGrid();
  }

  obterProspeccao() {
    this.agendaService.obterProspeccao().subscribe((result) => {
      if(result.resultado.length){
        this.prospeccao = result.resultado[0];
        this.exibeAgenda = true;
      } else{
        this.prospeccao = ''
      }
    });
  }

  carregaGrid() {
    this.agendaService.obterTodos(this.idAgenda).subscribe((result) => {
      this.agendas = result.resultado;
    });
  }

  visualizar(id) {
    this.router.navigate([
      `/agendas/${this.idAgenda}/visualizar/${id}`,]);
  }

  editar(id) {
    this.router.navigate([
      `/agendas/${this.idAgenda}/editar/${id}`,]);
  }

  excluir(id) {
    Swal.fire({
      title: 'Agenda',
      text: 'Tem certeza que deseja excluir essa agenda?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.agendaService.excluir(id).subscribe((resp) => {
          Swal.fire(
            'Agenda',
            'Agenda excluída com sucesso!',
            'success'
          ).then(() => {
            this.carregaGrid();
          });
        }, (erro) => {
          Swal.fire(
            'Local',
            erro.error.excecao[0].mensagem,
            'error'
          );
        });
      }
    });
  }


}
