
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentosService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterPorExame(id: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/arquivo/obter-por-exame?id=${id}`,
      {
        headers: this.httpHeaders,
      }
    );
  }
}
