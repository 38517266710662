import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AreaLogadaEventoInscricaoService } from 'src/app/services/area-logada-evento-inscricao/area-logada-evento-inscricao.service';

@Component({
  selector: 'app-semana-enef',
  templateUrl: './semana-enef.component.html',
  styleUrls: ['./semana-enef.component.scss']
})
export class SemanaEnefComponent implements OnInit {
  @ViewChild('childModal') childModal: ModalDirective;

  isSubmitted: boolean;
  enefForm: FormGroup;

  codigoPessoa: any;
  inscricoes = [];
  tipos = [];
  assuntos = [];
  publicos = [];
  estados = [];
  cidades = [];
  headerItens = [];
  agendas = [];
  agendasRelizadas = [];
  itensModal = [];
  itens = [];

  get tipo() {
    return this.enefForm.get('tipo');
  }
  get assunto() {
    return this.enefForm.get('assunto');
  }
  get publico() {
    return this.enefForm.get('publico');
  }
  get estado() {
    return this.enefForm.get('estado');
  }
  get cidade() {
    return this.enefForm.get('cidade');
  }
  get de() {
    return this.enefForm.get('de');
  }
  get ate() {
    return this.enefForm.get('ate');
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private serviceSemanaEnef: AreaLogadaEventoInscricaoService
  ) {

    this.route.paramMap.subscribe(params => {
      this.codigoPessoa = localStorage.getItem('userId');
    })
  }

  ngOnInit(): void {

    this.enefForm = this.formBuilder.group({
      tipo: ['', Validators.required],
      assunto: ['', Validators.required],
      publico: ['', Validators.required],
      estado: ['', Validators.required],
      cidade: ['', Validators.required],
      de: ['', Validators.required],
      ate: ['', Validators.required],
    });
  this.carregaEventoInscricao();
  this.carregaTipos();
  this.carregaAssuntos();
  this.carregaPublicos();
  this.carregaEstados();
  this.carregaCidades();
  this.selecionaTodos();

  }

  selecionaTodos(){
    this.enefForm.controls['tipo'].setValue(0);
    this.enefForm.controls['cidade'].setValue(0);
    this.enefForm.controls['estado'].setValue(0);
    this.enefForm.controls['assunto'].setValue(0);
    this.enefForm.controls['publico'].setValue(0);
    this.carregarAgendasRealizadas();
  }


  carregaTipos(){
    this.serviceSemanaEnef
    .obterTemas()
    .subscribe((resp)=>{
      this.tipos = resp.resultado;
      this.tipos.push({idEventoTema: 0, descricao: "Todos"});

  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }
  carregaEventoInscricao(){
    this.serviceSemanaEnef
    .obterEventoInscricao()
    .subscribe((resp)=>{
      this.headerItens = resp.resultado;

  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }
  carregaAssuntos(){
    this.serviceSemanaEnef
    .obterTipoAgenda()
    .subscribe((resp)=>{
      this.assuntos = resp.resultado;
      this.assuntos.push({idEventoAgendaTipo: 0, descricao: "Todos"})
  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }
  carregaPublicos(){
    this.serviceSemanaEnef
    .obterPublicos()
    .subscribe((resp)=>{
      this.publicos = resp.resultado;
      this.publicos.push({idEventoPublico: 0, descricao: "Todos"});
  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }
  carregaEstados(){
    this.serviceSemanaEnef
    .obterEstados()
    .subscribe((resp)=>{
      this.estados = resp.resultado;
      this.estados.push({uf: "Todos", idEstado: 0
      });
  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }
  carregaCidades(){
    this.serviceSemanaEnef
    .obterEstados()
    .subscribe((resp)=>{
      this.cidades = resp.resultado;
      this.cidades.push({descricao:"Todos" , idCidade: 0
    });
  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }

  filtrar(){
    this.serviceSemanaEnef
    .obterAgendas(
      this.codigoPessoa,
      this.enefForm.controls['tipo'].value,
      this.enefForm.controls['assunto'].value,
      this.enefForm.controls['publico'].value,
      this.enefForm.controls['estado'].value == 0 ? '' : this.enefForm.controls['estado'].value,
      this.enefForm.controls['cidade'].value == 0 ? '' : this.enefForm.controls['cidade'].value,
      this.enefForm.controls['de'].value,
      this.enefForm.controls['ate'].value,
    )
    .subscribe((resp)=>{
       this.agendas = resp.resultado;
  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  submit(enefForm){
    this.isSubmitted = true;
    if(enefForm.status="VALID"){
      this.filtrar();
      this.carregarAgendasRealizadas();
    }
  }

  carregarAgendasRealizadas(){
    this.serviceSemanaEnef
    .obterAgendaPessoaId(
      this.codigoPessoa,
    )
    .subscribe((resp)=>{
      this.agendasRelizadas = resp.resultado;

  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }

  informacao(agenda){
    this.childModal.show()
    this.itens = agenda;
    this.carregarInscricoes(agenda.idEventoAgenda);
  }

  criar(item, eventoPariticipante){
    let obj = {
      "idEventoAgenda": item.idEventoAgenda,
      "idPessoa": Number(this.codigoPessoa),
      "idEventoParticipanteTipo": eventoPariticipante
    }
    this.serviceSemanaEnef
    .criarNovaInscricao(
      obj,
    )
    .subscribe((resp)=>{
         Swal.fire(
      'Semana ENEF-Inscrição',
      "Cadastrado com sucesso",
      'success'
    ).then((item)=>{
      this.filtrar();
      this.carregarAgendasRealizadas();
    })

  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }

  excluir(id) {
    Swal.fire({
      title: 'Agenda',
      text: "Tem certeza que deseja excluir essa agenda?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.serviceSemanaEnef.desativarInscricao(id).subscribe((resp) => {
          Swal.fire(
            'Semana ENEF-Inscrição',
            'evento excluído com sucesso!!',
            'success'
          ).then(() => {
            this.carregarAgendasRealizadas();
          });
        }, (erro) => {
          Swal.fire(
            'Local',
            erro.error.excecao[0].mensagem,
            'error'
          );
        });
      }
    });
  }

  carregarInscricoes(idEventoAgenda){
    this.serviceSemanaEnef
    .obterInscricoes(
      idEventoAgenda,
    )
    .subscribe((resp)=>{
      this.itensModal = resp.resultado;
  }, (erro) => {
    Swal.fire(
      'Semana ENEF-Inscrição',
      erro.error.excecao[0].mensagem,
      'error'
    );
  });
  }
}
