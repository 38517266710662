<form name="preparacaoForm"
      id="preparacaoForm"
      class="mt-3 ml-3 mb-3"
      (ngSubmit)="submit()">
  <fieldset class="border p-3 my-3">
    <legend class="legendFormulario">Preparação</legend>
    <div class="container-principal">
      <div class="row">
        <div *ngFor="let pergunta of perguntas" class="form-group col-md-4 col-sm-12">
          <label class="texto--sm"
                 [for]="pergunta.idPergunta">{{pergunta.descricao}}</label>
          <select class="form-control form-control-sm custom-select custom-select-sm"
          [id]="pergunta.idPergunta"
          [name]="pergunta.idPergunta"
          [ngModel]=""
          (change)="onChange($event.target.value, pergunta.idPergunta)">
           <option value=""
                   disabled>Selecione</option>
           <option
              *ngFor="let opcao of pergunta.opcoes"
              [value]="opcao.idPerguntaOpcao">
             {{ opcao.opcaoDescricao }}
           </option>
         </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <button class="btn btn-sm btn-primary mr-0 ml-2"
                  id="salvarPreparacaoExame"
                  (click)="submit()"
                  type="button"
                  style="visibility: hidden;">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </fieldset>
</form>
