<form name="localForm"
      id="localForm"
      [formGroup]="localForm"
      (ngSubmit)="submit(localForm)">
  <div class="row">
    <div class="col-md-12">
      <h1 *ngIf="mode ==='add'">
        Cadastrar Local
      </h1>
      <h1 *ngIf="mode ==='edit'">
        Editar Local
      </h1>
      <h1 *ngIf="mode ==='visualizar'">
        Visualizar Local
      </h1>
    </div>
  </div>
  <div class="container-principal mt-3">
  <div class="row">
    <div class="form-group col-md-12 col-sm-12">
      <label class="texto--sm"
             for="nomeLocal">Identificação do Local</label>
        <input class="form-control form-control-sm"
               [class.is-invalid]="nomeLocal.invalid && (nomeLocal.touched || isSubmitted)"
               formControlName="nomeLocal"
               id="nomeLocal"
               maxlength="100"
               name="nomeLocal"
               type="text" />
        <div *ngIf="nomeLocal.invalid && (nomeLocal.touched || isSubmitted)">
          <span class="erro"
                *ngIf="nomeLocal.errors.required">Campo obrigatório</span>
        </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-2 col-sm-12">
      <label class="texto--sm"
             for="codigoPostal">CEP</label>
      <input class="form-control form-control-sm"
             (blur)="obterLogradouroPorCep()"
             [class.is-invalid]="
          codigoPostal.invalid && (codigoPostal.touched || isSubmitted)
        "
             formControlName="codigoPostal"
             id="codigoPostal"
             maxlength="100"
             name="codigoPostal"
             type="text" />
      <div *ngIf="
          codigoPostal.invalid && (codigoPostal.touched || isSubmitted)
        ">
        <span class="erro"
              *ngIf="codigoPostal.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-6 col-sm-12">
      <label class="texto--sm"
             for="endereco">Logradouro</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="
          endereco.invalid && (endereco.touched || isSubmitted)
        "
             formControlName="endereco"
             id="endereco"
             (keydown)="validaCaractere($event)"
             maxlength="100"
             name="endereco"
             type="text" />
      <div *ngIf="endereco.invalid && (endereco.touched || isSubmitted)">
        <span class="erro"
              *ngIf="endereco.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-2 col-sm-12">
      <label class="texto--sm"
             for="numero">Número</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="numero.invalid && (numero.touched || isSubmitted)"
             formControlName="numero"
             id="numero"
             maxlength="100"
             name="numero"
             type="text" />
      <div *ngIf="numero.invalid && (numero.touched || isSubmitted)">
        <span class="erro"
              *ngIf="numero.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-2 col-sm-12">
      <label class="texto--sm"
             for="complemento">Complemento</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="
          complemento.invalid && (complemento.touched || isSubmitted)
        "
             formControlName="complemento"
             id="complemento"
             maxlength="100"
             name="complemento"
             type="text" />
      <div *ngIf="complemento.invalid && (complemento.touched || isSubmitted)">
        <span class="erro"
              *ngIf="complemento.errors.required">Campo obrigatório</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-4 col-sm-12">
      <label class="texto--sm"
             for="bairro">Bairro</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="bairro.invalid && (bairro.touched || isSubmitted)"
             formControlName="bairro"
             id="bairro"
             (keydown)="validaCaractere($event)"
             maxlength="100"
             name="bairro"
             type="text" />
      <div *ngIf="bairro.invalid && (bairro.touched || isSubmitted)">
        <span class="erro"
              *ngIf="bairro.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-4 col-sm-12">
      <label class="texto--sm"
             for="estado">Estado</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="estado.invalid && (estado.touched || isSubmitted)"
             formControlName="estado"
             (keydown)="validaCaractere($event)"
             id="estado"
             maxlength="100"
             name="estado"
             type="text" />
      <div *ngIf="estado.invalid && (estado.touched || isSubmitted)">
        <span class="erro"
              *ngIf="estado.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-4 col-sm-12">
      <label class="texto--sm"
             for="cidade">Cidade</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="cidade.invalid && (cidade.touched || isSubmitted)"
             formControlName="cidade"
             id="cidade"
             (keydown)="validaCaractere($event)"
             maxlength="100"
             name="cidade"
             type="text" />
      <div *ngIf="cidade.invalid && (cidade.touched || isSubmitted)">
        <span class="erro"
              *ngIf="cidade.errors.required">Campo obrigatório</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-4 col-sm-12">
      <label class="texto--sm"
             for="contato">Contato</label>
        <input class="form-control form-control-sm"
               formControlName="contato"
               id="contato"
               maxlength="100"
               name="contato"
               type="text" />
    </div>

    <div class="form-group col-md-4 col-sm-12">
      <label class="texto--sm"
             for="celular">Telefone</label>
        <input class="form-control form-control-sm"
               [class.is-invalid]="celular.invalid && (celular.touched || isSubmitted)"
               formControlName="celular"
               id="celular"
               name="celular"
               mask="(00) 00000-0000"
               type="text" />
        <div *ngIf="celular.invalid && (celular.touched || isSubmitted)">
          <span class="erro"
                *ngIf="celular.errors.required">Campo obrigatório</span>
        </div>
      </div>
      
    <div class="form-group col-md-4 col-sm-12">
      <label class="texto--sm"
             for="email">E-mail </label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="email.invalid && (email.touched || isSubmitted)"
             formControlName="email"
             id="email"
             maxlength="100"
             [(ngModel)]="emailModel"
             name="email"
             type="text" />
      <div *ngIf="
          email.invalid && (email.touched || isSubmitted)
        ">
        <span class="erro"
              *ngIf="email.errors.required">Campo obrigatório</span>
        <span *ngIf="email.errors.pattern"
              class="erro">E-mail Inválido</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-12">
      <label class="texto--sm"
             for="observacao">
        Observação
      </label>
      <textarea class="form-control form-control-sm col-6"
                id="observacao"
                name="observacao"
                formControlName="observacao"
                rows="5"
                type="text"></textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <button class="btn btn-sm btn-primary btn-secondary mr-0"
              id="voltar"
              (click)="voltar()"
              type="button">
        Voltar
      </button>
      <button class="btn btn-sm btn-primary mr-0 ml-2"
              id="salvar"
              *ngIf="btnSalvar"
              type="submit">
        Salvar
      </button>
    </div>
  </div>
</div>
</form>