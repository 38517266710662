import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editar-votacao',
  templateUrl: './editar-votacao.component.html',
  styleUrls: ['./editar-votacao.component.scss']
})
export class EditarVotacaoComponent implements OnInit {
  perguntasVotacao: any[];

  // Variáveis para armazenar temporariamente os dados da pergunta sendo editada
  perguntaEditando: any;
  tituloVotacaoEditando: string;
  descricaoVotacaoEditando: string;
  textoPerguntaEditando: string;
  tipoVotacaoEditando: string;
  respostasEditando: any[];

  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.carregarPerguntasVotacao();
  }

  carregarPerguntasVotacao(): void {
    this.http.get<any[]>('http://localhost:4250/perguntasVotacao').subscribe(
      (data) => {
        this.perguntasVotacao = data;
      },
      (error) => {
        console.error('Erro ao carregar perguntas de votação:', error);
      }
    );
  }

  verVotacao(): void {
    this.router.navigate(['/votacao']);
  }

  editarPergunta(pergunta: any): void {
    // Armazenar temporariamente os dados da pergunta sendo editada
    this.perguntaEditando = pergunta;
    this.tituloVotacaoEditando = pergunta.titulo;
    this.descricaoVotacaoEditando = pergunta.descricao;
    this.textoPerguntaEditando = pergunta.pergunta;
    this.tipoVotacaoEditando = pergunta.tipoVotacao;
    this.respostasEditando = [...pergunta.respostas]; // Criar uma cópia das respostas
  }

  removerPergunta(pergunta: any): void {
    const confirmarRemocao = confirm('Tem certeza de que deseja remover esta pergunta?');
    if (confirmarRemocao) {
      this.http.delete(`http://localhost:4250/perguntasVotacao/${pergunta.id}`).subscribe(
        () => {
          // Remover a pergunta da lista local
          const index = this.perguntasVotacao.indexOf(pergunta);
          if (index !== -1) {
            this.perguntasVotacao.splice(index, 1);
          }
        },
        (error) => {
          console.error('Erro ao remover pergunta:', error);
        },
        () => {
          // Após a remoção, recarregar as perguntas
          this.carregarPerguntasVotacao();
        }
      );
    }
  }

  cancelarEdicaoPergunta(): void {
    // Limpar as variáveis de edição
    this.perguntaEditando = null;
    this.tituloVotacaoEditando = '';
    this.descricaoVotacaoEditando = '';
    this.textoPerguntaEditando = '';
    this.tipoVotacaoEditando = '';
    this.respostasEditando = [];
  }

  salvarEdicaoPergunta(): void {
    // Atualizar os dados da pergunta com as alterações
    this.perguntaEditando.titulo = this.tituloVotacaoEditando;
    this.perguntaEditando.descricao = this.descricaoVotacaoEditando;
    this.perguntaEditando.pergunta = this.textoPerguntaEditando;
    this.perguntaEditando.tipoVotacao = this.tipoVotacaoEditando;
    this.perguntaEditando.respostas = [...this.respostasEditando];

    // Enviar os dados atualizados para o servidor (faça uma requisição HTTP PUT ou PATCH)
    // Após a atualização, recarregar as perguntas
    this.http.put(`http://localhost:4250/perguntasVotacao/${this.perguntaEditando.id}`, this.perguntaEditando).subscribe(
      () => {
        this.carregarPerguntasVotacao();
      },
      (error) => {
        console.error('Erro ao salvar edição da pergunta:', error);
      }
    );

    // Limpar as variáveis de edição
    this.cancelarEdicaoPergunta();
  }

  adicionarResposta(): void {
    // Adicionar uma nova resposta ao formulário de edição
    this.respostasEditando.push({ respostaId: null, resposta: '' });
  }

  removerResposta(index: number): void {
    // Remover uma resposta do formulário de edição
    this.respostasEditando.splice(index, 1);
  }
}
