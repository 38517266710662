import { SituacaoService } from '../../services/situacao/situacao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ExameService } from 'src/app/services/exame/exame.service';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  id: any
  certificados = [];
  naoCertificados = [];
  instrucaoModal: ModalDirective;
  itemModal: any;
  dtValidade: any = null;
  dtValidadeCertificado: any = null;
  isRenovacao: boolean = false;
  trilhasExperiencias: [];
  isCredenciamentoSupervisor = false;
  idPessoaSupervisionada: number = null;
  nomeSupervisionado: string = null;
  dataInicioAtividade: Date = null;
  dataTerminoAtividade: Date = null;
  existeSupervisorAceito: boolean = false;
  existeEtapasEmAndamento: boolean = false;
  dataAceiteSupervisor: any;
  pessoaAtualLogada: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private serviceSituacao: SituacaoService,
    private exameService: ExameService,
    private pessoaService: PessoaService,
    private experienciaSupervisionadaService: ExperienciaSupervisionadaService) {
    this.id = JSON.parse(localStorage.getItem('userId'));
  }



  ngOnInit(): void {

    this.pesquisaPessoa()
    this.route.paramMap.subscribe(params => {
      //renovacao

      if (params.get("renovacao") == 'false') {

        if (params.get("sub-trilhas") == 'true') {

          if (params.get("etapa") == null) {
            this.trilhaExperienciaSupervisionada(false, null);
          }

          if (params.get("etapa") == 'relatorios') {
            this.idPessoaSupervisionada = Number(params.get("idPessoaSupervisionada"));

            if (this.idPessoaSupervisionada !== null && this.idPessoaSupervisionada != 0) {
              console.log(this.idPessoaSupervisionada);
              //this.idPessoa = params.get("idPessoa")
              this.verificarSupervisionadoEValido(this.idPessoaSupervisionada);
            }

            this.trilhaExperienciaSupervisionada(true, this.idPessoaSupervisionada);

            if (this.idPessoaSupervisionada !== null && this.idPessoaSupervisionada !== 0) {
              this.pessoaService.obterPorId(this.idPessoaSupervisionada).subscribe((resp) => {
                this.nomeSupervisionado = resp.resultado.nome;
              });

              this.experienciaSupervisionadaService.obterTrilhaExperienciaPorSupervisionado(this.idPessoaSupervisionada).subscribe((resp) => {
                this.dataInicioAtividade = resp.resultado.dataInicioAtividade;
                this.dataTerminoAtividade = resp.resultado.dataTerminoAtividade;
              });

            }

          }

          if (params.get("etapa") == 'adesao-associacao-nao-certificado') {
            this.trilhaNaoCertificacao();
          }

          if (params.get("etapa") == 'credenciamento-supervisor') {
            this.pessoaService.obterPorId(this.id).subscribe((resp) => {

              if (resp.resultado.dataAceiteSupervisor === null || resp.resultado.dataAceiteSupervisor === undefined) {
                // this.ModalAceite();
              }
              else {
                // this.trilhaCredenciamentoSupervisor();
              }
            });
          }

        }
        else {
          //se nao tiver trilha de certificacao busca trilha de nao certificado
          this.trilhaCertificacao();
        }
      }
      else {
        //tb consulta trilha de certificado caso nao existir trilha de renovacao
        this.trilhaRenovacao();
      }
    });

    this.obterDataValidade();
    this.obterDataValidadeCertificado();

    if (localStorage.getItem('modalInicio') === '1') {
      this.obterModalTexto();
    }
  }

  pesquisaPessoa() {
    const id = JSON.parse(localStorage.getItem('userId'));
    const url = `${environment.backofficeUrl}/pessoa/obter-por-id?id=${id}`
    const user = JSON.parse(localStorage.getItem("user"))

    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.access_token}`
      }
    }).then(res => {

      res.json().then(json => {
        this.pessoaAtualLogada = json.resultado

        console.log(this.pessoaAtualLogada)
        const cpf = "05828846590"
        if (cpf == json.resultado.cpf.replace(/[^a-zA-Z0-9]/g, "") && !this.pessoaAtualLogada?.dataAceiteNotificaoEntrarEmContato) {
          const elem: any = document.querySelector("#progress-modal-center")
          elem.style.display = "block"
        }
      })
    })
  }

  aceiteModalNotificacao() {
    const elem: any = document.querySelector("#progress-modal-center")
    elem.style.display = "none"
    this.pessoaAtualLogada.dataAceiteNotificaoEntrarEmContato = new Date()
    const user = JSON.parse(localStorage.getItem("user"))
    const url = `${environment.backofficeUrl}/pessoa/alterar`

    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.access_token}`
      },
      method: "PUT",
      body: JSON.stringify(this.pessoaAtualLogada)
    }).then(res => {
      res.json().then(json => {

        console.log(json)
      })
    })
  }

  sortDates(a, b) {
    if (!a || !b) return 100000;

    return new Date(b).getTimezoneOffset() - new Date(a).getTimezoneOffset();
  }

  obterDataValidade() {
    this.exameService.obterExame(this.id, 0).subscribe((result) => {
      const exames = result.resultado?.sort((a, b) => this.sortDates(a.dataValidade, b.dataValidade));
      exames?.forEach(ex => {
        if (ex.dataValidade) this.dtValidade = ex.dataValidade;
      })
    });
  }

  obterDataValidadeCertificado() {
    this.serviceSituacao.obterCertificadoValidade(this.id).subscribe((result) => {
      this.dtValidadeCertificado = result.resultado.dataValidadeCertificado;
    });
  }

  obterModalTexto() {
    this.serviceSituacao.obterModalTexto().subscribe(res => {
      this.itemModal = res.resultado;

      if (res.resultado) {
        document.getElementById('btnModal').click();
      }
    })
  }

  excluirModal() {
    localStorage.removeItem('modalInicio');
  }

  trilhaCertificacao() {

    this.isRenovacao = false;

    this.serviceSituacao.obterSituacaoCertificado(this.id).subscribe((res) => {
      res.resultado.map((item) => {
        item.subTitulos = [
          item.percentualConclusao.split('.')[0] +
          '%'
        ];

        item.descricaoTrilhaItem = this.splitTitle(item.descricaoTrilhaItem);
        if (item.percentualConclusao != '100.0') {
          if (item.idTrilhaItem == '13' || item.idTrilhaItem == '14') {
            item.descricaoTrilhaItem.push(item.quantidadeConcluida + ' meses comprovados');
          }
        }

        this.certificados = res.resultado;
      });

      if (this.certificados === null || this.certificados.length === 0)
        this.trilhaNaoCertificacao();

    }, (erro) => {
      Swal.fire(
        'Home',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  trilhaRenovacao() {

    this.isRenovacao = true;

    this.serviceSituacao.obterSituacaoRenovacao(this.id).subscribe((res) => {
      res.resultado.map((item) => {
        item.subTitulos = [
          item.percentualConclusao.split('.')[0] +
          '%'
        ];
        item.descricaoTrilhaItem = this.splitTitle(item.descricaoTrilhaItem);
        this.certificados = res.resultado;
      });
      if (this.certificados.length == 0) {
        this.trilhaCertificacao();
      }
    }, (erro) => {
      Swal.fire(
        'Home',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  splitTitle(title) {
    if (!title) return [title];

    if (title.length < 25) return [title];

    let titleArr = title.split(' ');

    if (titleArr.length == 0) return [title];

    const titleArrLength = titleArr.length;
    if (titleArr.length > 4) {
      let idxConcat = 0;
      titleArr = titleArr.map((t, idx) => {
        if ((idx + 1) <= (titleArrLength - 1)) {
          if (idx == idxConcat && idxConcat !== 0) return 'X';
          idxConcat = idx + 1;
          return t + ' ' + titleArr[idx + 1];
        }
        else {
          if (idx == idxConcat && idxConcat !== 0) return 'X';
          return t
        }
      });
    }

    return titleArr.filter(t => t !== 'X');
  }

  trilhaExperienciaSupervisionada(isRelatorios, idPessoaSupervisionada = null) {

    var isSupervisao = false;

    if (idPessoaSupervisionada === null || idPessoaSupervisionada === '0' || idPessoaSupervisionada === 0) {
      idPessoaSupervisionada = this.id;
      this.verificaExisteEtapasEmAndamento();
      this.verificaExisteSupervisorAceitoSupervisionado();
    }
    else
      isSupervisao = true;

    this.serviceSituacao.obterSituacaoExperienciaSupervisionada(idPessoaSupervisionada, isRelatorios, isSupervisao).subscribe((res) => {
      res.resultado.map((item) => {
        item.subTitulos = [
          item.percentualConclusao.split('.')[0] +
          '%'
        ];
        item.descricaoTrilhaItem = this.splitTitle(item.descricaoTrilhaItem);
        this.trilhasExperiencias = res.resultado;
      });

    }, (erro) => {
      Swal.fire(
        'Experiência Supervisionada',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });

  }

  trilhaNaoCertificacao() {
    this.serviceSituacao.obterNaoCertificado(this.id).subscribe((res) => {
      res.resultado.map((item) => {
        item.subTitulos = [
          item.percentualConclusao.split('.')[0] +
          '%'
        ];
        item.descricaoTrilhaItem = this.splitTitle(item.descricaoTrilhaItem);
        this.naoCertificados = res.resultado;
      });
    }, (erro) => {
      Swal.fire(
        'Home',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  trilhaCredenciamentoSupervisor() {
    this.isCredenciamentoSupervisor = true;
    this.serviceSituacao.obterSituacaoCredenciamentoSupervisor(this.id).subscribe((res) => {
      res.resultado.map((item) => {
        item.subTitulos = [
          item.percentualConclusao.split('.')[0] +
          '%'
        ];
        item.descricaoTrilhaItem = this.splitTitle(item.descricaoTrilhaItem);
        this.trilhasExperiencias = res.resultado;
      });
    }, (erro) => {
      Swal.fire(
        'Home',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  verificaExisteSupervisorAceitoSupervisionado() {

    this.existeSupervisorAceito = false;

    this.experienciaSupervisionadaService.obterSolicitacoesSupervisoresPorSupervisionado(this.id).subscribe((res) => {
      res.resultado.map((item) => {
        if (item.idSituacao === 2)
          this.existeSupervisorAceito = true;
      });
    }, (erro) => {
      Swal.fire(
        'Home',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  verificaExisteEtapasEmAndamento() {

    this.existeEtapasEmAndamento = false;

    this.experienciaSupervisionadaService.obterExperienciasPorSupervisionado(this.id).subscribe((res) => {
      res.resultado.map((item) => {
        if (item.idEtapa > 1 && item.idSituacao > 1)
          this.existeEtapasEmAndamento = true;
      });
    }, (erro) => {
      Swal.fire(
        'Home',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  desistenciaSupervisionado(motivoCancelamento) {
    this.experienciaSupervisionadaService.efetuarDesistenciaExperienciaSupervisionada(this.id, motivoCancelamento).subscribe((result) => {
      if (result.sucesso) {
        //this.obterSolicitacoes(this.aprovados);
        history.go(-1);
      }
    });
  }

  cancelarSupervisionado(motivoCancelamento) {
    this.experienciaSupervisionadaService.cancelarSolicitacaoSupervisionado(this.id, motivoCancelamento).subscribe((result) => {
      if (result.sucesso) {
        //this.obterSolicitacoes(this.aprovados);
        history.go(-1);
      }
    });
  }

  confirmarDesistenciaSupervisionado() {

    Swal.fire({
      title: 'Desistência da Experiência Supervisionada',
      text: "Tem certeza que deseja efetuar a desistência da experiência supervisionada? A desistência não poderá ser desfeita.",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {

        Swal.fire({
          title: "Desistência da Experiência Supervisionada",
          text: "Favor informe o motivo da desistência:",
          input: 'text',
          showCancelButton: true,
          cancelButtonColor: '#3085d6',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          reverseButtons: true
        }).then((result) => {
          if (!result.value) {
            Swal.fire(
              'Desistência da Experiência Supervisionada',
              'Motivo não informado. Não será possível efetuar a desistência.',
              'error'
            );
          }
          else {
            this.desistenciaSupervisionado(result.value);
          }
        });

      }
    });

  }

  confirmarTrocaSupervisor() {

    Swal.fire({
      title: 'Troca de Supervisor',
      text: "Tem certeza que deseja efetuar a troca de supervisor? A troca não poderá ser desfeita.",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {

        Swal.fire({
          title: "Troca de Supervisor",
          text: "Favor informe o motivo da troca de supervisor:",
          input: 'text',
          showCancelButton: true,
          cancelButtonColor: '#3085d6',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          reverseButtons: true
        }).then((result) => {
          if (!result.value) {
            Swal.fire(
              'Troca de Supervisor',
              'Motivo não informado. Não será possível efetuar a desistência.',
              'error'
            );
          }
          else {
            this.cancelarSupervisionado(result.value);
          }
        });

      }
    });

  }

  detalhes(id: number) {
    switch (id) {
      case 11:
        this.router.navigate(['/exames-config/0/progress']);
        break;
      case 12:
        this.router.navigate(['/adesao-codigo-etica']);
        break;
      case 13:
        this.router.navigate(['/experiencia-supervisionada']);
        break;
      case 14:
        this.router.navigate(['/experiencia-profissional']);
        break;
      case 15:
        this.router.navigate(['/educacao']);
        break;
      case 16:
        this.router.navigate(['/auto-cadastro/true']);
        break;
      case 17:
        this.router.navigate(['/solicitacao-plano-financeiro']);
        break;
      case 22:
        this.router.navigate(['/adesao-codigo-etica']);
        break;
      case 26:
        this.router.navigate(['/solicitacao-associacao-nao-certificado']);
        break;
      case 27:
        this.router.navigate(['/auto-cadastro/true']);
        break;
      case 28:
      case 33:
        this.router.navigate(['/debitos']);
        break;
      case 30:
        this.router.navigate(['/creditos/true']);
        break;
      case 31:
        this.router.navigate(['/auto-cadastro/true/true']);
        break;
      case 32:
        this.router.navigate(['/home/false/true']);
        break;
      case 34:
        this.router.navigate(['/home/false/true/adesao-associacao-nao-certificado']);
        break;
      case 35:
        this.router.navigate(['/supervisor-profissional']);
        break;
      case 36:
        if (this.idPessoaSupervisionada !== null && this.idPessoaSupervisionada != 0)
          this.router.navigate(['/acordo-supervisao/' + this.idPessoaSupervisionada]);
        else
          this.router.navigate(['/acordo-supervisao']);
        break;
      case 37:
      case 38:
      case 39:
      case 40:
      case 41:
      case 42:
        if (this.idPessoaSupervisionada !== null && this.idPessoaSupervisionada != 0)
          this.router.navigate(['/relatorio-evidencias/' + (id - 36).toString() + '/' + this.idPessoaSupervisionada]);
        else
          this.router.navigate(['/relatorio-evidencias/' + (id - 36).toString()]);
        break;
      case 46:
        this.router.navigate(['/solicitacao-supervisor/habilitacao']);
        break;
      case 47:
        this.router.navigate(['/supervisao-andamento/false']);
        break;
      case 48:
        this.router.navigate(['/supervisao-andamento/true']);
        break;
      case 99:
        this.router.navigate(['/home/false/true/relatorios']);
        break;
      case 100:
        this.router.navigate(['/lista-solicitacao-supervisao']);
        break;
      case 99999:
        if (this.idPessoaSupervisionada !== null && this.idPessoaSupervisionada != 0)
          this.router.navigate(['/parecer-final-supervisor/' + this.idPessoaSupervisionada]);
        else
          this.router.navigate(['/parecer-final-supervisor']);
        break;
      default:
        console.log("default");
    }
  }

  ModalAceite() {
    Swal.fire({
      title: 'Habilitar seu perfil como Supervisor?',
      text: 'Você deseja iniciar o processo para habilitar seu perfil como Supervisor?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Aceitar',
      denyButtonText: `Cancelar`,
      allowOutsideClick: false,
      icon: 'question',
    }).then((result) => {

      if (result.isConfirmed) {

        this.pessoaService
          .aceiteSupervisor(this.id)
          .subscribe((res) => {
            Swal.fire('Aceite registrado!', '', 'success').then(() => {
              this.trilhaCredenciamentoSupervisor();
            });
          },
            (error) => {
              Swal.fire('Não foi possível registrar, tente novamente mais tarde.', error.error.excecao[0].mensagem, 'error');
              this.router.navigate(['/home'])
            }
          );
      } else if (result.isDenied) {
        this.router.navigate(['/home']);
      }
    })
  }

  verificarSupervisionadoEValido(idPessoa: number) {
    this.experienciaSupervisionadaService.obterExperienciasPorSupervisor(this.id).subscribe((resp) => {
      console.log(resp.resultado);
      console.log(resp.resultado?.some(d => d.idPessoa === idPessoa));
      if (!resp.resultado?.some(d => d.idPessoa === idPessoa))
        this.router.navigate(['/home']);
    });

  }
}
