<div class="container-principal">
  <div class="row col-md-12">
    <fieldset class="border p-3 my-3">
      <legend class="legendFormulario">Exames </legend>
      <div class="row">
        <div class="form-group col-md-6">
        </div>
      </div>
      <div>
        <button class="btn btn-sm btn-primary btn-secondary mb-3"
                id="graficos"
                (click)="setMostrarGraficos(!mostrarGraficos)"
                type="button">
          Mostrar {{mostrarGraficos ? 'tabela' : 'gráficos'}}
        </button>
        
      </div>
      <div *ngIf="mostrarGraficos">
        <div class="d-flex mb-5 graph-wrapper" *ngFor="let modulo of modulos">
          <b>{{modulo[0].descricaoModulo}}</b>
          <div style="display: flex; flex-wrap: wrap;">
            <div *ngFor="let resultado of modulo" class="mt-2">
              <span>100%</span>
              <div class="graph-background" [title]="resultado.descricaoSituacao">
                <div [ngStyle]="getStyles(resultado)"> 
                  <span style="color: white;"><span style="font-size: 16px;">{{resultado.resultado}}%</span> acertos</span>             
                </div>
              </div>
              <span>{{resultado.numeroExame}}° - {{resultado.descricaoCidade}}</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-bordered table-responsive" *ngIf="!mostrarGraficos">
        <thead>
          <tr>
            <th>Módulos</th>
            <th>N° Exame</th>
            <th>Formato</th>
            <th>Data da Prova</th>
            <th>Local</th>
            <th>Situação</th>
            <th>Resultado</th>
            <th>Validade</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let exame of exames; let i = index">
            <td>{{exame.descricaoModulo}}</td>
            <td>{{exame.numeroExame}}</td>
            <td>{{exame.descricaoTipoInscricao}}</td>
            <td>{{exame.dataExame | date: 'dd/MM/yyyy'}}</td>
            <td>{{exame.descricaoCidade}}</td>
            <td>{{exame.descricaoSituacao}}</td>
            <td>{{exame.resultado}}</td>
            <td>{{exame.dataValidade | date: 'dd/MM/yyyy'}}</td>
          </tr>
        </tbody>
      </table>
      <p *ngIf="inscricaoModel == 1"><a href="https://www.concursosfcc.com.br/concursos/plane122/index.html" target="_blank" class="gabaritoLink">Gabarito preliminar 40°</a> - disponivel em 01/05/2022 a partir das 17h</p>
      <p *ngIf="inscricaoModel == 1"><a href="https://www.concursosfcc.com.br/concursos/plane222/index.html" target="_blank" class="gabaritoLink">Gabarito preliminar 41°</a> - disponivel em 21/08/2022 a partir das 20h</p>
      <p *ngIf="inscricaoModel == 1"><a href="https://www.concursosfcc.com.br/concursos/plane322/index.html" target="_blank" class="gabaritoLink">Gabarito preliminar 42°</a> - disponivel em 06/08/2022 a partir das 20h</p>
      <p *ngIf="inscricaoModel == 1"><a href="https://www.concursosfcc.com.br/concursos/plane122/index.html" target="_blank" class="gabaritoLink">Gabarito oficial 40°</a> - disponivel em 26/05/2022 a partir das 17h</p>
      <p *ngIf="inscricaoModel == 1"><a href="https://www.concursosfcc.com.br/concursos/plane222/index.html" target="_blank" class="gabaritoLink">Gabarito oficial 41°</a> - disponivel em 16/09/2022 a partir das 17h</p>
      <p *ngIf="inscricaoModel == 1"><a href="https://www.concursosfcc.com.br/concursos/plane322/index.html" target="_blank" class="gabaritoLink">Gabarito oficial 42°</a> - disponivel em 02/12/2022 a partir das 17h</p>
      <div>
        <button class="btn btn-sm btn-primary btn-secondary mr-0"
                id="voltar"
                (click)="voltar()"
                type="button">
          Voltar
        </button>
      </div>
    </fieldset>

  </div>


</div>
