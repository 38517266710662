<fieldset class="border p-3 my-3">
  <legend class="legendFormulario">Inscrição</legend>
  <div class="dashboard">
    <div class="row">
        <div class="col-md-12">
          <Label>Inscrição Exame CFP®: {{inscricao.exame.descricaoExame}} - {{inscricao.descricaoCidade}}</Label>
        </div>
      </div>
      <div class="row mt-3" >
        <div class="col-md-12">
            <div class="row" id="config-cards" style="display: none;">
              <div class="col-md-12 d-flex flex-row" style="flex-wrap: wrap; padding-left: 60px;">
                  <div class="card-config" (click)="abrirFinanceiro()" style="display:none" id="finCard">
                    Status do Pagamento
                  </div>
                  <div class="card-config" (click)="abrirTransferencia()" id="transfCard" style="display:none">
                    Transferencia de exame
                    <span style="font-size: 10px;">Prazo máximo {{inscricao.exame.dataLimiteTransferencia}}</span>
                  </div>
                  <div class="card-config" (click)="abrirCancelamento()" id="cancelCard">
                    Cancelamento de inscrição
                    <span style="font-size: 10px;">Prazo máximo {{inscricao.exame.dataLimiteCancelamento}}</span>
                  </div>
                  <div class="card-config" (click)="abrirMaterial()" style="display:none" id="materialCard">
                    Material de estudo
                  </div>
                  <div class="card-config" (click)="abrirCartaoInformativo()" id="cartaoInfoCard" style="display:none">
                    Cartão informativo
                  </div>
                  <div class="card-config" (click)="abrirDocumentos()" style="display:none" id="baseCard">
                    Biblioteca de documentos
                  </div>
                  <div class="card-config" (click)="abrirJustificativa()" id="justCard" style="display:none">
                    Envio de atestado
                    <span style="font-size: 10px;">Prazo máximo {{inscricao.exame.dataLimiteJustificativa}}</span>
                  </div>
              </div>
            </div>
        </div>
      </div>
  </div>
</fieldset>
