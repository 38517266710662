import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import { DocumentosService } from 'src/app/services/documentos/documentos.service';


@Component({
  selector: 'documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {
  id: any
  idInscricao: any;
  mockDocs: any = [
    {url:'https://planejar.org.br/wp-content/uploads/2022/02/Cronogramas-Exame-40_41_42_2022.pdf', descricao: 'Cronograma'},
    {url:'https://planejar.org.br/wp-content/uploads/2022/02/Edital-Planejar-Exames-2022-1.pdf', descricao: 'Edital'},
    {url:'https://planejar.org.br/wp-content/uploads/2022/02/Manual-da-Certificacao_2022.pdf', descricao: 'Manual de Certificação CFP®'},
    {url:'https://planejar.org.br/wp-content/uploads/2022/02/Guia-de-Orientacao-ao-Candidato.pdf', descricao: 'Guia de orientação ao candidato'},
    {url:'https://planejar.org.br/wp-content/uploads/2021/11/39-Calculadoras-permitidas-Exame-CFP-Planejar.pdf', descricao: 'Calculadoras permitidas'},
    {url:'https://planejar.org.br/wp-content/uploads/2021/03/Programa_Detalhado_37_Exame.pdf', descricao: 'Programa detalhado'},
    {url:'https://planejar.org.br/wp-content/uploads/2021/11/Atualizacao-Formulas-37_38_39.pdf', descricao: 'Lista de fórmulas'},
    {url:'https://planejar.org.br/wp-content/uploads/2021/03/Orientacoes_Estudo.pdf', descricao: 'Orientações de estudo'},
    {url:'https://planejar.org.br/wp-content/uploads/2021/04/Bibliografia-Referencia-2021.pdf', descricao: 'Bibliográfia referência'},
  ]; 
  idExame: any;
  documentos: any;

  constructor(
    private route: ActivatedRoute,
    private documentosService: DocumentosService,
    private inscricaoService: AreaLogadaInscricaoService
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
  }

  abrirDoc(url) {
    window.open(url, '_blank');
  }
  
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params.get("idInscricao")) {
        this.idInscricao = params.get("idInscricao")
      }
    });
    this.carregarDocumentos();
  }

  carregarDocumentos() {
    this.inscricaoService.obterInscricao(this.idInscricao, true)
    .subscribe((result) => {
      const resposta = result.resultado;
      this.idExame = resposta.exame.idExame;

      if (!this.idExame) this.documentos = this.mockDocs;
  
      this.documentosService.obterPorExame(this.idExame)
      .subscribe((result) => {
        if (result.resultado.length > 0) this.documentos = result.resultado;
        else this.documentos = this.mockDocs;
      });
    });

  }
}
