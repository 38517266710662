import { SituacaoService } from '../../services/situacao/situacao.service';
import { ExameService } from 'src/app/services/exame/exame.service';
import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MensagemService } from 'src/app/services/mensagem/mensagem.service';
import * as $ from 'jquery';
import * as moment from 'moment';


@Component({
  selector: 'app-mensagens',
  templateUrl: './mensagens.component.html',
  styleUrls: ['./mensagens.component.scss']
})
export class MensagensComponent implements OnInit {
  id: any
  certificados = [];
  naoCertificados = [];
  instrucaoModal: ModalDirective;
  itemModal: any;
  exames = null;
  certificado: any = null;
  config: any;
  selectedId: any;
  mensagens: any = [];
  mensagemAtual: any;

  constructor(
    private router: Router,
    private inscricaoService : AreaLogadaInscricaoService,
    private pessoaService : PessoaService,
    private mensagemService: MensagemService,
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
  }

  ngOnInit(): void {
    this.carregaMensagens();
    // this.pessoaService.obterCertificadoPessoa(this.id).subscribe((result) => {
    //   this.certificado = result.resultado;
    // });
    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '340px',
      toolbar: [
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    };

  }

  lerMensagem(id: any) {
    this.mensagemAtual = this.mensagens.filter(m => m.idMensagemInterna == id)[0].textoDescricao;
    const mensagemDt = this.mensagens.filter(m => m.idMensagemInterna == id)[0].dataCadastro;
    const mensagemGeral = this.mensagens.filter(m => m.idMensagemInterna == id)[0].mensagemGeral;
    this.selectedId = id;
    $('.note-editable').html('<b>Mensagem enviada em ' + moment(mensagemDt).format('DD/MM/yyyy') + '</b><br />' + this.mensagemAtual);

    this.mensagemService.lerMensagem(id, mensagemGeral).subscribe((res) => {
      console.log(res);
      this.carregaMensagens();
    })
  }

  carregaMensagens(){
    this.mensagemService.obterPorPessoa(this.id).subscribe((res) => {
      this.mensagens = res.resultado;
    })
  }

  abrirInscricao() {
    this.router.navigate(['/inscricao']);
  }

  abrirInscricaoConfig(exame: any) {
    localStorage.setItem('exameSelecionado', exame.idExame.toString());
    this.router.navigate(['/inscricao-config/'+exame.idInscricao]);

    /*if (exame.descricaoPagamento == 'Pendente' || exame.descricaoPagamento == 'Aguardando Confirmação') {
      Swal.fire('Detalhes da inscrição', 'Só será possivel acessar todos os detalhes da inscrição após o pagamento ser confirmado', 'warning');
    }*/
  }
}
