import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as $ from 'jquery';
import * as moment from 'moment';
@Component({
  selector: 'app-supervisor-profissional',
  templateUrl: './supervisor-profissional.component.html',
  styleUrls: ['./supervisor-profissional.component.scss'],
})
export class SupervisorProfissionalComponent implements OnInit {
  buscaSupervisorForm: FormGroup;
  areas: any;
  atendimentoRadio: any;
  checkAreas: any[];
  uf: any;
  estados: any;
  arrMunicipios: any;
  municipio: any;
  supervisores: any[];
  exibeGrade: boolean;
  nenhumEncontrado: boolean;
  selectedValue: string = '3';

  async submit(formulario: FormGroup) {
    var nome = formulario.value.nome;
    var estado = formulario.value.uf;
    var cidade = formulario.value.municipio;
    var tipoAtendimento = formulario.value.atendimentoRadio;
    console.log(estado);
    this.obterSupervisorFiltro(
      nome,
      tipoAtendimento,
      this.checkAreas,
      estado,
      cidade
    );
  }

  async buscaSemFiltro(status: boolean){
    this.obterSupervisorSemFiltro();
  }

  ngOnInit(): void {
    this.obterEstados();
   // this.obterAreasConhecimento();

    this.buscaSupervisorForm = this.formBuilder.group({
      atendimentoRadio: [],
      nome: [],
      uf: [],
      municipio: [],
    });
  }


  checkArea(id) {
    if (this.checkAreas?.some((c) => c == id) ?? false) {
      var index = this.checkAreas.indexOf(id);
      this.checkAreas.splice(index, 1);
      return;
    }

    if (!this.checkAreas) {
      this.checkAreas = [id];
      return;
    }

    this.checkAreas.push(id);
    console.log(this.checkAreas);
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private pessoaService: PessoaService,
    private experienciaSupervisionadaService: ExperienciaSupervisionadaService,
    private route: ActivatedRoute
  ) {
    this.buscaSupervisorForm = this.formBuilder.group({
      nome: [''],
    });
  }

  obterEstados() {
    this.pessoaService.obterEstados().subscribe((res) => {
      this.estados = res.resultado;
    });
  }

  changeUfObterCidade() {
    this.municipio = '';
    this.experienciaSupervisionadaService
      .obterCidades(this.uf)
      .subscribe((res) => {
        this.arrMunicipios = res.resultado;
      });
  }

  obterAreasConhecimento() {
    this.pessoaService
      .obterAreasSupervisao()
      .subscribe((res) => {
        this.areas = res.resultado;
      });
  }

  obterSupervisorFiltro(
    nome: any,
    tipoAtendimento: any,
    areas: any[],
    idEstado: any,
    idCidade: any
  ) {
    this.experienciaSupervisionadaService
      .obterSupervisorFiltro(nome, tipoAtendimento, areas, idEstado, idCidade)
      .subscribe((res) => {
        this.supervisores = res.resultado;

        if (this.supervisores.length > 0) {
          this.nenhumEncontrado = false;
          this.exibeGrade = true;
        } else {
          this.exibeGrade = false;
          this.nenhumEncontrado = true;
        }
      });
  }

  obterSupervisorSemFiltro() {
    this.experienciaSupervisionadaService
      .obterSupervisorSemFiltro()
      .subscribe((res) => {
        this.supervisores = res.resultado;

        if (this.supervisores.length > 0) {
          this.nenhumEncontrado = false;
          this.exibeGrade = true;
        } else {
          this.exibeGrade = false;
          this.nenhumEncontrado = true;
        }
      });
  }
}
