import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreditosService } from 'src/app/services/creditos/creditos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-creditos',
  templateUrl: './creditos.component.html',
  styleUrls: ['./creditos.component.scss']
})
export class CreditosComponent implements OnInit {
  files: File[] = [];
  view = false;
  imagem = null;
  usuario = null;
  vigencias = null;
  boleto = null;
  isSubmitted = false;
  creditoForm: FormGroup;
  periodos = [];
  teste = '20'
  habilitaCreditos = false;
  creditos: any;
  renovacao: boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private creditosService : CreditosService,
    private formBuilder: FormBuilder) {

      this.creditoForm = this.formBuilder.group({
        periodo: ['', Validators.required],
      });
     }

  ngOnInit(): void {

    this.usuario = window.localStorage.getItem('userId');
    this.obterVigencia();

  }

    obterVigencia() {
      this.creditosService.obterVigencia(this.usuario).subscribe((result) => {
        this.periodos = result.resultado;
        this.route.paramMap.subscribe(params => {
          //renovacao
          if (params.get("renovacao") == 'true' && this.periodos.length > 0)
          {
            this.renovacao = true;
            this.creditoForm.get('periodo').setValue(this.periodos[this.periodos.length-1].idPessoaCertificadoVigencia);

            this.efetuarConsulta();
          }
          else
          {
            this.renovacao = false;
          }
        });
      });
    }

    submit(form){
      this.isSubmitted = true;
      if(this.creditoForm.status === 'VALID'){
        this.efetuarConsulta();
      }
  }

  efetuarConsulta(){
    this.habilitaCreditos = true;

    this.creditosService.obterVigenciaPorPessoa(this.creditoForm.controls.periodo.value, this.usuario).subscribe((result) => {
      this.vigencias = result.resultado;
    });
    this.creditosService.obterCreditos(this.creditoForm.controls.periodo.value).subscribe((result) => {
      this.creditos = result.resultado;
    });
  }

  visualizar(credito){
    this.router.navigate([
      `/creditos/visualizar/${credito}`,]);
  }

  editar(credito){
    this.router.navigate([
      `/creditos/editar/${credito}`,]);
  }

  excluir(credito){
    this.creditosService.excluir(credito).subscribe((resp)=>{
        Swal.fire(
          'Créditos',
          'Crédito excluído com sucesso!',
          'success'
        ).then(() => {
          this.submit(1);
        });
    },(erro)=>{
      Swal.fire(
        'Créditos',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

    get periodo() {
      return this.creditoForm.get('periodo');
    }

}
