<fieldset class="border p-3 my-3">
  <legend class="legendFormulario">Exames</legend>
  <div class="dashboard">
    <div class="row">
      <div class="col-md-12">
        <Label>Trilha de Aprovação Exame CFP®</Label>
        <!-- <p *ngIf="exames[0] != null">Inscrito no {{exames[0].descricaoExame}} - {{exames[0].descricaoTipoInscricao}}</p> -->
      </div>
    </div>
    <div class="row mt-3 ml-5">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex flex-row" style="flex-wrap: wrap;">
            <div [ngStyle]="getStyles(exame.descricaoSituacao)" class="card-home" *ngFor="let exame of exames">
              <span>{{exame.descricaoModulo}}</span>
              <span><b>{{exame.descricaoSituacao}}<span
                    *ngIf="exame.numeroExame != null">{{exame.numeroExame}}°</span></b></span>
              <span *ngIf="exame.dataValidade != null">Válido até {{exame.dataValidade | date: 'dd/MM/yyyy'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <button class="btn btn-sm btn-primary btn-secondary mr-0" id="voltar" (click)="voltar()" type="button">
        Voltar
      </button>
    </div>
  </div>
</fieldset>
<!-- <div *ngIf="certificado != null">
  <app-progress></app-progress>
</div> -->
