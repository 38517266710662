
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeclaracaoPrivacidadeService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterEditalManual() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-texto-instrucoes-creditos`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterEditalPrivacidade() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-declaracao-privacidade`,
      {
        headers: this.httpHeaders
      }
    );
  }

}
