<fieldset class="border p-3 my-3" *ngIf="acessoPlanoFinanceiro===true">
  <legend class="legendFormulario">Solicitação de Plano Financeiro </legend>
  <div class="row d-flex justify-content-center" *ngIf="solicitacao != null && ((solicitacao.idSituacaoResultado === 3 || solicitacao.idSituacaoResultado === 4) && (solicitacao.idSituacaoCuradoria === 1 || solicitacao.idSituacaoCuradoria === 3 ))">
    <div class="dropMobile text-right align-items-center col-md-4 col-sm-4"
        [ngClass]="{ 'mt-4': view }">
          <div class="text-right align-items-center dropCustom col-sm-12 mt-3">
            <div class="w-100">
              <div [accept]="'application/pdf'"
                   (change)="onSelect($event)"
                   class="custom-dropzone col-sm-12"
                   #drop
                   [disabled]="view"
                   [maxFileSize]="20971520"
                   [multiple]="false"
                   ngx-dropzone>
                <ngx-dropzone-label>
                  <div>
                    <img class="img-adicionada"
                         [src]="imagem" />
                    <p *ngIf="(!view && imagem == null) || imagem == ''">
                      Arraste o(s) seu(s) arquivo(s) ou clique aqui para selecionar
                    </p>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-image-preview class="previa-img"
                                            *ngFor="let f of files"
                                            [file]="f"
                                            ngProjectAs="ngx-dropzone-preview"
                                            [removable]="true">
                  <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                </ngx-dropzone-image-preview>
              </div>
              <div class="text-center">
                <button class="btn btn-sm btn-danger mt-1 btnRemove"
                        (click)="onRemove()"
                        id="remove"
                        [disabled]="view"
                        *ngIf="files.length > 0"
                        title="Remover Imagem">
                  Remover Arquivo
                </button>
              </div>
              <p class="mt-1 text-primary text-center col-md-12 col-sm-12"
                 *ngIf="
                  (files.length === 0 && imagem == '') ||
                  (imagem == null && !view)
                ">
                Formato do arquivo PDF.
              </p>
            </div>
          </div>
        </div>
      </div>

  <div class="row" *ngIf="solicitacao != null && ((solicitacao.idSituacaoResultado === 3 || solicitacao.idSituacaoResultado === 4) && (solicitacao.idSituacaoCuradoria === 1 || solicitacao.idSituacaoCuradoria === 3 ))">
    <div class="form-group col-md-12 col-sm-12">
      <label for="descricao">Observação:</label>
      <input class="form-control form-control-sm" id="observacao" height="300" multiple maxlength="1000" name="observacao" type="text" [ngModel]="observacao" />
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-right d-flex" style="justify-content: flex-end;">
      <div class="wrapper2 cancelar-solicitacao" *ngIf="solicitacao != null && ((solicitacao.idSituacaoResultado === 3 || solicitacao.idSituacaoResultado === 4) && (solicitacao.idSituacaoCuradoria === 1 || solicitacao.idSituacaoCuradoria === 3 ))">
        <button class="btn btn-sm btn-danger mr-0 ml-2" (click)="cancelarSolicitacao()" id="cancelar" type="button">
          Cancelar
        </button>
      </div>
      <div class="wrapper2">
        <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" routerLink="/home">
          Voltar
        </button>
      </div>
      <div class="wrapper2" *ngIf="solicitacao != null && ((solicitacao.idSituacaoResultado === 3 || solicitacao.idSituacaoResultado === 4) && (solicitacao.idSituacaoCuradoria === 1 || solicitacao.idSituacaoCuradoria === 3 ))">
        <button class="btn btn-sm btn-primary mr-0 ml-2" (click)="cadastrarCuradoria()" id="salvar" type="button">
          Enviar
        </button>
      </div>
      <div class="wrapper2 ver-notas" *ngIf="solicitacao != null && (solicitacao.idSituacaoCuradoria === 4 || solicitacao.idSituacaoCuradoria === 5 || solicitacao.idSituacaoCuradoria === 6)">
        <button class="btn btn-sm btn-warning mr-0 ml-2" (click)="verNotas()" id="verNotas" type="button">
          Ver Avaliação
        </button>
      </div>
    </div>
  </div>

  <div class="row cadastro-inscricao-prova" *ngIf="(solicitacao == null || solicitacao.idSituacaoResultado === 1 || solicitacao.idSituacaoResultado === 5 || solicitacao.idSituacaoResultado === 6 || solicitacao.idSituacaoCuradoria >=6)">
    <a (click)="cadastrarInscricaoProva()">Clique aqui para efetuar sua inscrição no curso</a>
  </div>

  <div class="row justificativa-solicitacao" *ngIf="checarPodeJustificar()">
    <a routerLink="/justificar-plano-financeiro/{{solicitacao.idSolicitacaoPlanoFinanceiro}}">Clique aqui para efetuar uma justificativa para esta solicitação</a>
  </div>

  <div class="row" *ngIf="solicitacao != null">
    <div>
      <p *ngIf="solicitacao != null && ((solicitacao.idSituacaoResultado === 3 || solicitacao.idSituacaoResultado === 4) && (solicitacao.idSituacaoCuradoria === 1 || solicitacao.idSituacaoCuradoria === 3 ))"><b>Observação:</b> {{solicitacao.observacao}}</p>
      <p><b>Situação Curso: </b>
        <span *ngIf="solicitacao.idSituacaoResultado === 2"><a class="debitos-link" routerLink="/debitos">{{solicitacao.situacaoResultado}}</a></span>
        <span *ngIf="solicitacao.idSituacaoResultado !== 2">{{solicitacao.situacaoResultado}}</span>
      </p>
      <p><b>Situação Avaliação do Plano Financeiro: </b>
        <span *ngIf="solicitacao.idSituacaoCuradoria === 2"><a class="debitos-link" routerLink="/debitos">{{solicitacao.situacaoCuradoria}}</a></span>
        <span *ngIf="solicitacao.idSituacaoCuradoria !== 2">{{solicitacao.situacaoCuradoria}}</span>
      </p>
      <div *ngIf="solicitacao.motivo != null && solicitacao.motivo.length > 0">
        <p><b>Parecer do Avaliador:</b> {{solicitacao.motivo}}</p>
      </div>
      <div *ngIf="solicitacao.dataEntradaResultado != null && solicitacao.idSituacaoResultado>2">
        <p><b>Data Início Etapa Prova:</b> {{solicitacao.dataEntradaResultado | date: 'dd/MM/yyyy' }}</p>
        <p><b>Prazo Conclusão Prova:</b> {{solicitacao.dataLimiteResultado | date: 'dd/MM/yyyy' }}</p>
      </div>
      <div *ngIf="solicitacao.dataEntradaCuradoria != null && solicitacao.idSituacaoCuradoria>2">
        <p><b>Data Envio Arquivo:</b> {{solicitacao.dataEntradaCuradoria | date: 'dd/MM/yyyy' }}</p>
        <p><b>Prazo Análise Arquivo:</b> {{solicitacao.dataLimiteCuradoria | date: 'dd/MM/yyyy' }}</p>
      </div>
      <div *ngIf="solicitacao.diasProrrogacao != null">
        <p><b>Dias Prorrogados:</b> {{solicitacao.diasProrrogacao}}</p>
      </div>
    </div>
  </div>
</fieldset>

<!-- MODAL -->
<div bsModal #childModal="bs-modal" [config]="{ backdrop: 'static', keyboard: false}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Avaliação</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideChildModal();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          Para os itens listados abaixo, o avaliador atribuiu uma pontuação de 0 a 10. Sendo:<br/>
          9 a 10: Muito Bom<br/>
          7 a 8,9: Bom<br/>
          Abaixo de 6,9: Regular                                 
      </div>
      <div class="modal-body">
        <table id="tabelaNotas" class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th class="tema">Tema</th>
              <th>Avaliação</th>
            </tr>
          </thead>
          <tbody>          
            <tr *ngFor="let item of notas; let i = index">
              <td>{{item.descricao}}</td>
              <td class="nota">{{item.nota}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td class="nota">{{obterNotaTotal() | number: '1.0-2'}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="modal-body">
        O Total corresponderá a média dos 14 itens listados e ao resultado obtido na entrega de seu Plano Financeiro ( maior que 9 a 10: Muito Bom, > maior que 7 a 8,9: Bom e Abaixo de 6,9: Regular).        
      </div>      
      <div class="modal-footer">
        <div class="col-12 text-right">
          <button class="btn btn-sm btn-secondary mr-0" type="button" (click)="hideChildModal();">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
