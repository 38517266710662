import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdesaoCodigoEticaService } from 'src/app/services/adesao-codigo-etica/adesao-codigo-etica.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-adesao-codigo-etica',
  templateUrl: './adesao-codigo-etica.component.html',
  styleUrls: ['./adesao-codigo-etica.component.scss']
})
export class AdesaoCodigoEticaComponent implements OnInit {
  aderir = false;
  monstrarLabel = [];
  config: any;
  adesaoCodigoEticaForm: FormGroup;
  itens = [];
  id: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private serviceAdesaoCodigoEtica: AdesaoCodigoEticaService
    ) {
      this.id = localStorage.getItem('userId');
      this.init();
      this.config = {
        placeholder: '',
        tabsize: 2,
        height: '200px',
        toolbar: [
        ],
        lang: "pt-BR",
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
      }
    }

  ngOnInit(): void {
    this.textoCodigoEtica();
  }

  textoCodigoEtica(){
    this.serviceAdesaoCodigoEtica
      .obterEditalManual()
      .subscribe((resp)=>{
      this.adesaoCodigoEticaForm.controls.codigoEtica.setValue(resp.resultado.descricao);
       this.itens = resp.resultado;
       this.obterDataAdesao();
    }, (erro) => {
      Swal.fire(
        'Adesão ao Código de Ética',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  init(){
    this.adesaoCodigoEticaForm = this.formBuilder.group({
      codigoEtica:[''],
    });
  }

  submit(adesaoCodigoEticaForm){
    this.serviceAdesaoCodigoEtica
      .aceitarCodigoEtica(
      Number(this.id)
      ).subscribe((resp)=>{
        Swal.fire(
          'Adesão ao Código de Ética',
           'Adesão efetuada com sucesso!',
          'success'
        );
        this.aderir = false;
      this.textoCodigoEtica();

    }, (erro) => {
      Swal.fire(
        'Adesão ao Código de Ética',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  obterDataAdesao(){
    this.serviceAdesaoCodigoEtica.obterCodigoEticaPessoa(
      Number(this.id)
    ).subscribe((resp)=>{
      if(resp.resultado == null || resp.resultado.idTrilhaItemSituacao === 1){
        this.aderir = true;
       }
        this.monstrarLabel = resp.resultado;

      },(erro) => {
      Swal.fire(
        'Adesão ao Código de Ética',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
   }

   voltar(){
    this.router.navigate(['/home/false/true/adesao-associacao-nao-certificado']);
   }

}
