import { Component, ViewChild, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalService } from '../../../services/local/local.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import * as moment from 'moment';


@Component({
  selector: 'app-local',
  templateUrl: './local.component.html',
  styleUrls: ['./local.component.scss'],
})
export class LocalComponent implements OnInit {
  isSubmitted: boolean;
  localForm: FormGroup;

  cepLocalizado: boolean;
  emailModel = null;
  mode = 'add';
  isEditar: boolean = false;
  btnVoltar = true;
  btnSalvar = true;
  itens = [];
  idPessoa = null;
  idLocal = null;

  public emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  get nomeLocal() {
    return this.localForm.get('nomeLocal');
  }
  get celular() {
    return this.localForm.get('celular');
  }
  get email() {
    return this.localForm.get('email');
  }
  get codigoPostal() {
    return this.localForm.get('codigoPostal');
  }
  get endereco() {
    return this.localForm.get('endereco');
  }
  get numero() {
    return this.localForm.get('numero');
  }
  get complemento() {
    return this.localForm.get('complemento');
  }
  get bairro() {
    return this.localForm.get('bairro');
  }
  get estado() {
    return this.localForm.get('estado');
  }
  get cidade() {
    return this.localForm.get('cidade');
  }
  get observacao() {
    return this.localForm.get('observacao');
  }


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private localService: LocalService,
    private route: ActivatedRoute
  ) {

    this.localForm = this.formBuilder.group({
      nomeLocal: ['', Validators.required],
      codigoPostal: ['', Validators.required],
      endereco: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      estado: ['', Validators.required],
      cidade: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      celular: ['', Validators.required],
      contato: [''],
      observacao: [''],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.idLocal = params.get("id");
    });
    this.idPessoa = localStorage.getItem('userId');


    if (this.router.url.split('/')[2] === 'adicionar') {
      this.mode = 'add';
    }
    if (this.router.url.split('/')[2] === 'editar') {
      this.mode = 'edit';
      this.obterDadosLocal();
    }
    if (this.router.url.split('/')[2] === 'visualizar') {
      this.mode = 'visualizar';
      this.btnSalvar = false
      this.obterDadosLocal();
    }
  }


  obterLogradouroPorCep() {
    this.localService
      .obterPorCep(this.localForm.value.codigoPostal.replace(/\D+/g, ''))
      .subscribe((res) => {
        this.endereco.setValue(res.resultado.logradouro);
        this.bairro.setValue(res.resultado.bairro);
        this.cidade.setValue(res.resultado.localidade);
        this.estado.setValue(res.resultado.uf);
        if (
          res.resultado.logradouro !== '' &&
          this.localForm.value.codigoPostal.length >= 8
        ) {
          this.endereco.disable();
          this.bairro.disable();
          this.estado.disable();
          this.cidade.disable();
          this.cepLocalizado = true;
        } else {
          this.endereco.enable();
          this.bairro.enable();
          this.estado.enable();
          this.cidade.enable();
          this.cepLocalizado = false;
        }
      });
  }

  validaCaractere(event) {
    if (event.key && event.key.match(/^(?:[A-Za-zÀ-ÿ ]+)$/)) {
      return true;
    } else {
      return false;
    }
  }


  obterDadosLocal() {
    this.localService
      .obterPorId(this.idLocal)
      .subscribe( res => {
        this.localForm.controls.nomeLocal.setValue(res.resultado.descricao);
        this.localForm.controls.codigoPostal.setValue(res.resultado.codigoPostal);
        this.localForm.controls.endereco.setValue(res.resultado.endereco);
        this.localForm.controls.numero.setValue(res.resultado.numero);
        this.localForm.controls.complemento.setValue(res.resultado.complemento);
        this.localForm.controls.bairro.setValue(res.resultado.bairro);
        this.localForm.controls.cidade.setValue(res.resultado.cidade);
        this.localForm.controls.estado.setValue(res.resultado.estado);
        this.localForm.controls.contato.setValue(res.resultado.contato);
        this.localForm.controls.celular.setValue(res.resultado.dddContato + res.resultado.telefoneContato);
        this.localForm.controls.email.setValue(res.resultado.email);
        this.localForm.controls.observacao.setValue(res.resultado.observacoes);
      })

      if(this.mode === 'visualizar'){
        this.localForm.controls.nomeLocal.disable();
        this.localForm.controls.codigoPostal.disable();
        this.localForm.controls.endereco.disable();
        this.localForm.controls.numero.disable();
        this.localForm.controls.complemento.disable();
        this.localForm.controls.bairro.disable();
        this.localForm.controls.cidade.disable();
        this.localForm.controls.estado.disable();
        this.localForm.controls.contato.disable();
        this.localForm.controls.celular.disable();
        this.localForm.controls.email.disable();
        this.localForm.controls.observacao.disable();
      }
  }

  submit(formulario) {
    this.isSubmitted = true;
    let file = null;

    let estado = ((this.estado.valid || this.cepLocalizado == true) ? this.estado.value : null);
    let cidade = ((this.cidade.valid || this.cepLocalizado == true) ? this.cidade.value : null);
    let bairro = ((this.bairro.valid || this.cepLocalizado == true) ? this.bairro.value : null);
    let endereco = ((this.endereco.valid || this.cepLocalizado == true) ? this.endereco.value : null);

    if (this.localForm.status === 'VALID') {
      if(this.mode === 'add'){
      this.salvar({
        idPessoa: parseInt(this.idPessoa),
        descricao: this.localForm.value.nomeLocal,
        codigoPostal: this.localForm.value.codigoPostal,
        endereco: endereco,
        numero: this.localForm.value.numero,
        complemento: this.localForm.value.complemento,
        bairro: bairro,
        cidade: cidade,
        estado: estado,
        contato: this.localForm.value.contato,
        ddiContato: '55',
        dddContato: this.localForm.value.celular.substring(0, 2),
        telefoneContato: this.localForm.value.celular.substring(2, 11),
        email: this.localForm.value.email,
        observacoes: this.localForm.value.observacao,
      });
    }else{
    this.alterar({
      idEventoLocal: parseInt(this.idLocal),
      idPessoa: parseInt(this.idPessoa),
      descricao: this.localForm.value.nomeLocal,
      codigoPostal: this.localForm.value.codigoPostal,
      endereco: endereco,
      numero: this.localForm.value.numero,
      complemento: this.localForm.value.complemento,
      bairro: bairro,
      cidade: cidade,
      estado: estado,
      contato: this.localForm.value.contato,
      ddiContato: '55',
      dddContato: this.localForm.value.celular.substring(0, 2),
      telefoneContato: this.localForm.value.celular.substring(2, 11),
      email: this.localForm.value.email,
      observacoes: this.localForm.value.observacao,
    });
  }
}
  }

  voltar() {
    this.router.navigate(['/locais']);
  }

  salvar(localForm) {
    this.localService
      .criarLocal(localForm)
      .subscribe((res) => {
          Swal.fire(
            'Local',
            `Local cadastrado com sucesso!`,
            'success'
          ).then(() => {
            this.router.navigate(['/locais']);
          });
      },
        (error) => {
          Swal.fire('Local', error.error.excecao[0].mensagem, 'error');
        }
      );
  }

  alterar(localForm) {
    this.localService
      .atualizarLocal(localForm)
      .subscribe((res) => {
          Swal.fire(
            'Local',
            `Local editado com sucesso!`,
            'success'
          ).then(() => {
            this.router.navigate(['/locais']);
          });
      },
        (error) => {
          Swal.fire('Local', error.error.excecao[0].mensagem, 'error');
        }
      );
  }

}
