
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExperienciaProfissionalService {
  httpHeaders: HttpHeaders;S

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterEmpresa() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-nao-supervisionada/obter-empresa`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterAreaAtuacao() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-nao-supervisionada/obter-area-atuacao`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterCargo() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-nao-supervisionada/obter-cargo`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterPlataforma() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-nao-supervisionada/obter-plataforma`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarExperienciaNaoSupervisionada(experiencia: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-nao-supervisionada/criar-experiencia`,
      experiencia,
      {
        headers: this.httpHeaders,
      }
    );
  }

  alterarExperiencia(pessoa: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-nao-supervisionada/alterar-experiencia`,
      pessoa,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterExperiencia(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-nao-supervisionada/obter-experiencia`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

desativarExperiencia(codigoTrilhaPessoaItem: any) {
  return this.http.delete(
    `${environment.backofficeUrl}/area-logada-nao-supervisionada/desativar-experiencia?codigoTrilhaPessoaItem=${codigoTrilhaPessoaItem}`,
    {
      headers: this.httpHeaders,
    }
  );
}

obterPendencia(codigoPessoa: any) {
  return this.http.get<any>(
    `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-pendencia-pessoa`,
    {
      headers: this.httpHeaders,
      params: { codigoPessoa },
    }
  );
}
}
