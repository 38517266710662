import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SolicitacaoLicencaService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterSolicitacao(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/licenca/obter-solicitacao-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  criar(solicitacao: any) {
    return this.http.post<any>(
      `${environment.backofficeUrlV2}/licenca/incluir`,
      solicitacao,
      {
        headers: this.httpHeaders
      }
    );
  }  

  alterar(solicitacao: any) {
    return this.http.put<any>(
      `${environment.backofficeUrlV2}/licenca/alterar`,
      solicitacao,
      {
        headers: this.httpHeaders
      }
    );
  }

  incluirAnexo(anexo: any) {
    return this.http.post<any>(
      `${environment.backofficeUrlV2}/licenca/incluir-anexo`,
      anexo,
      {
        headers: this.httpHeaders
      }
    );
  }  

  removerAnexo(codigoSolicitacaoLicencaAnexo: any) {
    return this.http.put<any>(
      `${environment.backofficeUrlV2}/licenca/remover-anexo`,      
      {
        headers: this.httpHeaders,
        params: { codigoSolicitacaoLicencaAnexo }
      }
    );
  }  

}
