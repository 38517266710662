<div class="row mt-3"
     *ngIf="menus.length">
  <div id="nav_menu-13"
       class="col-md-12 widget widget_nav_menu">
    <div class="widget-inner">
      <h2 class="widget-title maincolor2">Menu</h2>
      <div class="menu-main-navigation-container">
        <ul id="menu-main-navigation-1"
            class="menu">
          <li id="menu-item-1268"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-has-children">
            <div>
              <a routerLink="/home"> Início</a>
            </div>
            <div>
              <a
                target="_self"
                (click)="redirectsPortal()"
              >
                  Portal de Educação
              </a>
            </div>
            <!-- <div>
              <a
                target="_self"
                (click)="irParaVotacao()"
              >
                  Votação
              </a>
            </div> -->
            <div>
              <a (click)="emitirCertificado()"
                 *ngIf="certificado && certificado.idCertificadoSituacao==1"
                 target="_blank"> Meu Certificado</a>
            </div>
            <div>
              <a (click)="emitirCertificadoTreina()"
                 *ngIf="certificadoTreina!=null && certificadoTreina.idSituacaoResultado==4 && (certificadoTreina.idSituacaoCuradoria==4 || certificadoTreina.idSituacaoCuradoria==5)"
                 target="_blank"> Meu Certificado Treina</a>
            </div>
            <div *ngFor="let menu of menus">
              <a routerLink="/{{menu.key}}"
                 (click)="limpaDados(menu.key)"> {{menu.value}}</a>
            </div>
            <div>
              <a routerLink="/tutoriais"> Tutoriais</a>
            </div>
        </ul>
      </div>
    </div>
  </div>
</div>
