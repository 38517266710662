import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalService } from 'src/app/services/local/local.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-locais',
  templateUrl: './locais.component.html',
  styleUrls: ['./locais.component.scss']
})
export class LocaisComponent implements OnInit {
  files: File[] = [];
  usuario = null;
  periodos = [];
  teste = '20'
  habilitaCreditos = false;
  prospeccao= null;
  locais= [];
  exibeProspeccao = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private localService: LocalService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.usuario = window.localStorage.getItem('userId');
    this.obterProspeccao();
    this.carregaGrid();
  }

  obterProspeccao() {
    this.localService.obterProspeccao().subscribe((result) => {
      if(result.resultado.length){
        this.prospeccao = result.resultado[0];
        this.exibeProspeccao = true;
      } else{
        this.prospeccao = '';
      }
    });
  }

  carregaGrid() {
    this.localService.obterTodos().subscribe((result) => {
      this.locais = result.resultado;
    });
  }

  agenda(local) {
    this.router.navigate([
      `/agendas/${local}`,]);
  }

  visualizar(local) {
    this.router.navigate([
      `/locais/visualizar/${local}`,]);
  }

  editar(local) {
    this.router.navigate([
      `/locais/editar/${local}`,]);
  }

  excluir(local) {
    Swal.fire({
      title: 'Local',
      text: "Confirma o descarte desse local? Essa ação não pode ser desfeita!",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.localService.excluir(local).subscribe((resp) => {
          Swal.fire(
            'Local',
            'Local excluído com sucesso!',
            'success'
          ).then(() => {
            this.carregaGrid();
          });
        }, (erro) => {
          Swal.fire(
            'Local',
            erro.error.excecao[0].mensagem,
            'error'
          );
        });
      }
    });
  }


}
