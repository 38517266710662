<!-- <div class="container"> -->
  <fieldset class="p-3 my-3">
    <legend class="legendFormulario">Experiência Supervisionada </legend>
  </fieldset>
<div class="row">
  <div class="border">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label class="texto--sm"
               for="nomeSupervisor">
          Nome do Supervisor: {{supervisor.supervisorNome}}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label class="texto--sm"
               for="dataInicio">
          Data de Início: {{supervisor.inicio | date: 'dd/MM/yyyy'}}
        </label>
      </div>
      <div class="col-md-6 col-sm-12">
        <label class="texto--sm"
               for="dataTermino">
          Data de Término: {{supervisor.fim | date: 'dd/MM/yyyy'}}
        </label>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <table class="table table-wrapper table-responsive mt-4">
    <thead>
      <tr>
        <th class="text-center">Relatório</th>
        <th class="text-center">Previsto</th>
        <th class="text-center">Entregue</th>
        <th class="text-center">Situação</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let experiencia of experiencias; let i = index">
        <td class="text-center">{{experiencia.codigo}}</td>
        <td class="text-center">{{experiencia.previsao | date: 'dd/MM/yyyy'}}</td>
        <td class="text-center">{{experiencia.entrega | date: 'dd/MM/yyyy'}}</td>
        <td class="text-center">{{experiencia.descricaoTrilhaItemSituacao}}</td>
        <td class="text-center">
          <button class="btn btn-sm btn-transparent m-0"
                  (click)="abreModalMotivo(experiencia)"
                  title="Informação"
                  type="button">
            <i class="fa fa-info-circle"></i>
          </button>
          <button class="btn btn-sm btn-transparent m-0"
                  (click)="abreModalArquivo(experiencia)"
                  title="Anexo"
                  type="button">
            <i class="fa fa-paperclip"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- </div> -->

<!--  Modal Motivo -->
<div bsModal
     #childModalMotivo="bs-modal"
     [config]="{ backdrop: 'static', keyboard: false}"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-dialog-centered"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"
            id="modalInfTitle">Informação</h5>
        <button type="button"
                class="close"
                (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-md-12">
            <label class="texto--sm"
                   for="motivo">
              Motivo
            </label>
            <textarea class="form-control form-control-sm col-6"
                      disabled
                      id="motivo"
                      name="motivo"
                      [(ngModel)]="motivoModel"
                      rows="5"
                      type="text"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal
     #childModalAnexoArquivo="bs-modal"
     [config]="{ backdrop: 'static', keyboard: false}"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-dialog-centered"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"
            id="exampleModalTitle">Anexar Relatório</h5>
        <button type="button"
                class="close"
                (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-1 col-sm-12 mt-3">
            <label>Arquivo </label>
          </div>
          <div class="col-md-3 col-sm-12 ml-3 arquivo">
              <div class="input-group mb-3">
                  <div [accept]="
        'image/jpeg,image/png,application/pdf,image/x-eps'
      "
                       (change)="onSelect($event)"
                       class="custom-dropzone-anexos"
                       #drop
                       [maxFileSize]="5000000"
                       [multiple]="false"
                       ngx-dropzone>
                      <ngx-dropzone-label class="label-arquivo">
                          Escolher arquivo
                      </ngx-dropzone-label>
                  </div>
              </div>
          </div>
          <div class="col-md-7 col-sm-12 mt-3">
              <div class="text-primary"
                   *ngIf="nome == null">
                  Nenhum arquivo selecionado
              </div>
              <div *ngIf="rgInvalido" class="erro">
                <span>
                  Tamanho do arquivo maior que 5mb.
                </span>
              </div>
              <ngx-dropzone-label class="text-primary"
                                  *ngIf="nome !==null">
                  {{ nome }}
              </ngx-dropzone-label>
          </div>
      </div>
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-sm btn-secondary"
                data-dismiss="modal"
                (click)="hideChildModal()">Cancelar</button>
        <button type="button"
                class="btn btn-sm btn-primary"
                (click)="salvaArquivo()">Salvar</button>
      </div>
    </div>
  </div>
</div>
