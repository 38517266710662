import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

export class PerfilProfissionalService {
    httpHeaders: HttpHeaders;
  
    constructor(private http: HttpClient) {
      let token = localStorage.getItem('tknCdst');
  
      this.httpHeaders = new HttpHeaders({
        Authorization: localStorage.getItem('tknCdst'),
      });
    }
  
    obterIdiomas(codigoPessoa: any) {
        return this.http.get<any>(
          `${environment.backofficeUrl}/perfil-profissional/obter-idiomas`,
          {
            headers: this.httpHeaders,
            params: { codigoPessoa },
          }
        );
    }

    obterNiveis(codigoPessoa: any) {
      return this.http.get<any>(
        `${environment.backofficeUrl}/perfil-profissional/obter-nivel-idioma`,
        {
          headers: this.httpHeaders,
          params: { codigoPessoa },
        }
      );
  }

    obterDadosPessoais(codigoPessoa: any) {
        return this.http.get<any>(
          `${environment.backofficeUrl}/perfil-profissional/obter-dados-pessoa`,
          {
            headers: this.httpHeaders,
            params: { codigoPessoa },
          }
        );
    }

    obterIdiomasPorPessoa(codigoPessoa: any) {
        return this.http.get<any>(
          `${environment.backofficeUrl}/perfil-profissional/obter-idiomas-pessoa`,
          {
            headers: this.httpHeaders,
            params: { codigoPessoa },
          }
        );
    }

    obterInstrucao() {
        return this.http.get<any>(
          `${environment.backofficeUrl}/perfil-profissional/obter-instrucao-perfil-publico`,
          {
            headers: this.httpHeaders,
          }
        );
    }

    criarIdioma(codigoPessoa:any, idioma: any, nivel: any,) {
        return this.http.post<any>(
          `${environment.backofficeUrl}/perfil-profissional/criar-idioma`,
          {
            idPessoa: codigoPessoa,
            idIdioma: idioma,
            idIdiomaNivel: nivel
          },
          {
            headers: this.httpHeaders,
          }
        );
    }

    salvarPerfilProfissional(pessoa: any) {
    return this.http.put<any>(
        `${environment.backofficeUrl}/perfil-profissional/alterar`,
        pessoa,
        {
        headers: this.httpHeaders,
        }
    );
    }

    excluirIdioma(codigoPessoa: any) {
    return this.http.delete(
        `${environment.backofficeUrl}/perfil-profissional/desativar-idioma?codigoPessoaIdioma=${codigoPessoa}`,
        {
        headers: this.httpHeaders,
        }
    );
    }

}