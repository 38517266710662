import { Component, ViewChild, OnInit } from '@angular/core';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-experiencia-supervisionada',
  templateUrl: './experiencia-supervisionada.component.html',
  styleUrls: ['./experiencia-supervisionada.component.scss']
})
export class ExperienciaSupervisionadaComponent implements OnInit {
  @ViewChild('childModalMotivo') childModalMotivo: ModalDirective;
  @ViewChild('childModalAnexoArquivo') childModalAnexoArquivo: ModalDirective;
  files: File[] = [];
  view = false;
  imagem = null;
  nome = null;
  rgInvalido: boolean = false;
  idPessoa = null;
  experiencias = null;
  supervisor = null;
  motivoModel = null;
  idTrilhaPessoaItem: any;

  constructor(private serviceExperienciaSupervisionada: ExperienciaSupervisionadaService) { }

  ngOnInit(): void {
    this.idPessoa = JSON.parse(localStorage.getItem('userId'));
    this.carregaExperiencia();
    this.carregaSupervisor();
  }

  carregaExperiencia() {
    this.serviceExperienciaSupervisionada.obterExperiencia(this.idPessoa).subscribe((resp) => {
      this.experiencias = resp.resultado;
      this.nome = resp.resultado.nomeArquivo;
    }, (erro) => {
      Swal.fire(
        'Experiência Profissional',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  carregaSupervisor() {
    this.serviceExperienciaSupervisionada.obterSupervisor(this.idPessoa).subscribe((resp) => {
      this.supervisor = resp.resultado;
    }, (erro) => {
      Swal.fire(
        'Experiência Profissional',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  abreModalMotivo(experiencia) {
    this.childModalMotivo.show();
    this.motivoModel = experiencia.justificativaReprovacao;
  }

  abreModalArquivo(experiencia) {
    this.childModalAnexoArquivo.show();
    this.nome = experiencia.nomeArquivo;
    this.idTrilhaPessoaItem = experiencia.idTrilhaPessoaItem;
  }

  hideChildModal(): void {
    this.childModalMotivo.hide();
    this.childModalAnexoArquivo.hide();
  }

  onSelect(event) {
    if (event.rejectedFiles.length) {
      this.rgInvalido = true;
      this.nome = null;
      return;
    }
    this.rgInvalido = false;
    this.onRemove();
    this.files.push(...event.addedFiles);
    this.nome = this.files[0].name;

  }

  onRemove() {

    this.files.splice(0, 1);
    if (this.imagem !== '') {
      this.imagem = '';
    }
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        return resolve((e.target as FileReader).result);
      };

      reader.onerror = (e) => {
        return reject(null);
      };

      if (file == null) {
        return resolve(null);
      }

      reader.readAsDataURL(file);
    });
  }

  async salvaArquivo() {
    let file = null;
    if (this.files.length > 0) {
      if (this.files) {
        file = await this.readFile(this.files[0]);
      }
      this.submit({
        idTrilhaPessoaItem: this.idTrilhaPessoaItem,
        idPessoa: this.idPessoa,
        nomeArquivo: this.files[0].name,
        arquivo: file ? String(file).replace(/^data:(.*;base64,)?/, '') : null,
      });
    }
    else {
      if (this.nome === null) {
        Swal.fire(
          'Experiência Supervisionada',
          'Arquivo Obrigatório!',
          'info'
        );
      }
      else {
        this.hideChildModal();
      }
    }
  }

  submit(obj) {
    this.serviceExperienciaSupervisionada.alterarExperiencia(obj).subscribe((resp) => {
      Swal.fire(
        'Experiência Supervisionada',
        'Arquivo salvo com sucesso!',
        'success'
      );
      this.hideChildModal();

    }, (erro) => {
      Swal.fire(
        'Experiência Supervisionada',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

}
