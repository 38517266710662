<form
  name="pessoaForm"
  id="pessoaForm"
  class="mt-3"
  [ngClass]="{ 'ml-3': isInscricao, 'mb-3': isInscricao }"
  [formGroup]="pessoaForm"
  (ngSubmit)="submit(pessoaForm)"
>
  <fieldset class="border p-3 my-3">
    <legend *ngIf="isInscricao" class="legendFormulario">
      Confirmar dados
    </legend>
    <legend *ngIf="!isInscricao" class="legendFormulario">Auto cadastro</legend>
    <div class="container-principal">
      <div class="row">
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="nomeCompleto">Nome</label>
          <input class="form-control form-control-sm"
            [class.is-invalid]="nomeCompleto.invalid && (nomeCompleto.touched || isSubmitted)"
            formControlName="nomeCompleto"
            id="nomeCompleto"
            maxlength="100"
            (keydown)="validaCaractere($event)"
            name="nomeCompleto"
            [ngModel]="dadosPessoais ? dadosPessoais.nome : ''"
            type="text"/>
          <div *ngIf="nomeCompleto.invalid && (nomeCompleto.touched || isSubmitted)">
            <span class="erro" *ngIf="nomeCompleto.errors.required">Campo obrigatório</span>
          </div>
        </div>

        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="sobrenome">Sobrenome</label>
          <input class="form-control form-control-sm"
            [class.is-invalid]="sobrenome.invalid && (sobrenome.touched || isSubmitted)"
            formControlName="sobrenome"
            id="sobrenome"
            maxlength="100"
            (keydown)="validaCaractere($event)"
            name="sobrenome"
            [ngModel]="dadosPessoais ? dadosPessoais.sobrenome : ''"
            type="text"/>
          <div *ngIf="sobrenome.invalid && (sobrenome.touched || isSubmitted)">
            <span class="erro" *ngIf="sobrenome.errors.required">Campo obrigatório</span>
          </div>
        </div>

        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="raca">Raça/Cor</label>
          <select
            class="form-control form-control-sm custom-select custom-select-sm"
            formControlName="raca"
            [class.is-invalid]="raca.invalid && (raca.touched || isSubmitted)"
            id="raca"
            [ngModel]="dadosPessoais ? dadosPessoais.idCorPele : null"
            name="raca"
          >
            <option [value]="null" disabled>Selecione</option>
            <option *ngFor="let rac of racas" [value]="rac.idCorPele">
              {{ rac.descricao }}
            </option>
          </select>
          <div *ngIf="raca.invalid && (raca.touched || isSubmitted)">
            <span class="erro" *ngIf="raca.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="tituloEleitor">Titulo de Eleitor</label>
          <input class="form-control form-control-sm"
            [class.is-invalid]="tituloEleitor.invalid && (tituloEleitor.touched || isSubmitted)"
            formControlName="tituloEleitor"
            id="tituloEleitor"
            maxlength="100"
            name="tituloEleitor"
            [ngModel]="dadosPessoais ? dadosPessoais.tituloEleitor : ''"
            type="text"/>
          <div *ngIf="tituloEleitor.invalid && (tituloEleitor.touched || isSubmitted)">
            <span class="erro" *ngIf="tituloEleitor.errors.required">Campo obrigatório</span>
          </div>
        </div>

        <div class="form-group col-md-4 col-sm-4">
          <label class="texto--sm" for="genero">Gênero</label>
          <select
            class="form-control form-control-sm custom-select custom-select-sm"
            formControlName="genero"
            [class.is-invalid]="
              genero.invalid && (genero.touched || isSubmitted)
            "
            id="genero"
            [ngModel]="dadosPessoais ? dadosPessoais.idGenero : ''"
            name="genero"
          >
            <option value="" disabled>Selecione</option>
            <option *ngFor="let sexo of generos" [value]="sexo.idGenero">
              {{ sexo.descricao }}
            </option>
          </select>
          <div *ngIf="genero.invalid && (genero.touched || isSubmitted)">
            <span class="erro" *ngIf="genero.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="dataNascimento"
            >Data de Nascimento</label
          >
          <input
            class="form-control form-control-sm"
            [class.is-invalid]="
              dataNascimento.invalid && (dataNascimento.touched || isSubmitted)
            "
            formControlName="dataNascimento"
            id="dataNascimento"
            maxlength="100"
            name="dataNascimento"
            [ngModel]="dadosPessoais ? dadosPessoais.dataNascimento : ''"
            type="date"
          />
          <div
            *ngIf="
              dataNascimento.invalid && (dataNascimento.touched || isSubmitted)
            "
          >
            <span class="erro" *ngIf="dataNascimento.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="nomeMae">Nome da mãe</label>
          <input class="form-control form-control-sm"
            [class.is-invalid]="nomeMae.invalid && (nomeMae.touched || isSubmitted)"
            formControlName="nomeMae"
            id="nomeMae"
            maxlength="100"
            name="nomeMae"
            [ngModel]="dadosPessoais ? dadosPessoais.nomeMae : ''"
            type="text"/>
          <div *ngIf="nomeMae.invalid && (nomeMae.touched || isSubmitted)">
            <span class="erro" *ngIf="nomeMae.errors.required">Campo obrigatório</span>
          </div>
        </div>


        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="nomePai">Nome do Pai</label>
          <input class="form-control form-control-sm"
            [class.is-invalid]="nomePai.invalid && (nomePai.touched || isSubmitted)"
            formControlName="nomePai"
            id="nomePai"
            maxlength="100"
            name="nomePai"
            [ngModel]="dadosPessoais ? dadosPessoais.nomePai : ''"
            type="text"/>
          <div *ngIf="nomePai.invalid && (nomePai.touched || isSubmitted)">
            <span class="erro" *ngIf="nomePai.errors.required">Campo obrigatório</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="celular">Telefone Celular</label>
          <div class="d-flex flex-row">
            <div class="col-md-4 pl-0 col-mob-grupo">
              <input
                type="text"
                class="form-control form-control-sm grupo-input"
                id="celularDdi"
                name="celularDdi"
                [ngModel]="
                  renovacao
                    ? ''
                    : dadosPessoais && dadosPessoais.ddiCelular
                    ? dadosPessoais.ddiCelular
                    : '55'
                "
                formControlName="celularDdi"
                [class.is-invalid]="
                  celularDdi.invalid && (celularDdi.touched || isSubmitted)
                "
                mask="00"
              />
              <div
                *ngIf="
                  celularDdi.invalid && (celularDdi.touched || isSubmitted)
                "
              >
                <span class="erro" *ngIf="celularDdi.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
            <div class="col-md-8 pl-0 pr-0 w-100">
              <input
                class="form-control form-control-sm"
                [class.is-invalid]="
                  celular.invalid && (celular.touched || isSubmitted)
                "
                formControlName="celular"
                id="celular"
                maxlength="100"
                name="celular"
                mask="(00) 00000-0000"
                [ngModel]="
                  renovacao
                    ? ''
                    : dadosPessoais
                    ? dadosPessoais.dddCelular + dadosPessoais.telefoneCelular
                    : ''
                "
                type="text"
              />
              <div *ngIf="celular.invalid && (celular.touched || isSubmitted)">
                <span class="erro" *ngIf="celular.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="telefoneComercial"
            >Telefone Comercial</label
          >
          <div class="d-flex flex-row">
            <div class="col-md-4 pl-0 col-mob-grupo">
              <input
                type="text"
                class="form-control form-control-sm grupo-input"
                id="comercialDdi"
                name="comercialDdi"
                [ngModel]="
                  renovacao
                    ? ''
                    : dadosPessoais
                    ? dadosPessoais.ddiComercial
                    : '55'
                "
                formControlName="comercialDdi"
                mask="00"
              />
            </div>
            <div class="col-md-8 pl-0 pr-0 w-100">
              <input
                class="form-control form-control-sm"
                formControlName="telefoneComercial"
                id="telefoneComercial"
                maxlength="100"
                name="telefoneComercial"
                [ngModel]="
                  renovacao
                    ? ''
                    : dadosPessoais &&
                      dadosPessoais.dddComercial &&
                        dadosPessoais.telefoneComercial
                    ? dadosPessoais.dddComercial +
                      dadosPessoais.telefoneComercial
                    : ''
                "
                mask="(00) 0000-0000"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="telefoneResidencial"
            >Telefone Residencial</label
          >
          <div class="d-flex flex-row">
            <div class="col-md-4 pl-0 col-mob-grupo">
              <input
                type="text"
                class="form-control form-control-sm grupo-input"
                id="residencialDdi"
                name="residencialDdi"
                [ngModel]="
                  renovacao
                    ? ''
                    : dadosPessoais
                    ? dadosPessoais.ddiResidencial
                    : '55'
                "
                formControlName="residencialDdi"
                mask="00"
              />
            </div>
            <div class="col-md-8 pl-0 pr-0 w-100">
              <input
                class="form-control form-control-sm"
                formControlName="telefoneResidencial"
                id="telefoneResidencial"
                maxlength="100"
                name="telefoneResidencial"
                [ngModel]="
                  renovacao
                    ? ''
                    : dadosPessoais &&
                      dadosPessoais.dddResidencial &&
                        dadosPessoais.telefoneResidencial
                    ? dadosPessoais.dddResidencial +
                      dadosPessoais.telefoneResidencial
                    : ''
                "
                mask="(00) 0000-0000"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="emailPrimario">E-mail Primário</label>
          <input
            class="form-control form-control-sm"
            [class.is-invalid]="
              emailPrimario.invalid && (emailPrimario.touched || isSubmitted)
            "
            formControlName="emailPrimario"
            id="emailPrimario"
            maxlength="100"
            [(ngModel)]="
              renovacao
                ? ''
                : dadosPessoais
                ? dadosPessoais.email
                : emailPrimarioModel
            "
            name="emailPrimario"
            type="text"
          />
          <div
            *ngIf="
              emailPrimario.invalid && (emailPrimario.touched || isSubmitted)
            "
          >
            <span class="erro" *ngIf="emailPrimario.errors.required"
              >Campo obrigatório</span
            >
            <span *ngIf="emailPrimario.errors.pattern" class="erro"
              >E-mail Inválido</span
            >
          </div>
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="emailSecundario"
            >E-mail Secundário</label
          >
          <input
            class="form-control form-control-sm"
            formControlName="emailSecundario"
            [class.is-invalid]="
              emailSecundario.invalid &&
              (emailSecundario.touched || emailSecundario.value)
            "
            id="emailSecundario"
            maxlength="100"
            name="emailSecundario"
            [ngModel]="
              renovacao
                ? ''
                : dadosPessoais
                ? dadosPessoais.emailSecundario
                : ''
            "
            type="text"
          />
          <div
            *ngIf="
              emailSecundario.invalid &&
              (emailSecundario.touched || emailSecundario.value)
            "
          >
            <span *ngIf="emailSecundario.errors.pattern" class="erro"
              >E-mail Inválido</span
            >
          </div>
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="linkedin">Linkedin</label>
          <input
            class="form-control form-control-sm"
            formControlName="linkedin"
            id="linkedin"
            maxlength="255"
            name="linkedin"
            [ngModel]="dadosPessoais ? dadosPessoais.linkedin : ''"
            type="text"
          />
        </div>
      </div>
    </div>
    <div class="container-principal">
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div class="form-group col-md-4 col-sm-4">
              <label class="texto--sm" for="nacionalidade">Nacionalidade</label>
              <select
                class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="nacionalidade"
                [class.is-invalid]="
                  nacionalidade.invalid &&
                  (nacionalidade.touched || isSubmitted)
                "
                id="nacionalidade"
                [ngModel]="dadosPessoais ? dadosPessoais.idNacionalidade : ''"
                name="nacionalidade"
              >
                <option value="" disabled>Selecione</option>
                <option
                  *ngFor="let nacionalidade of nacionalidades"
                  [value]="nacionalidade.idNacionalidade"
                >
                  {{ nacionalidade.descricao }}
                </option>
              </select>
              <div
                *ngIf="
                  nacionalidade.invalid &&
                  (nacionalidade.touched || isSubmitted)
                "
              >
                <span class="erro" *ngIf="nacionalidade.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
            <div
              class="form-group col-md-3 col-sm-4"
              *ngIf="nacionalidade.value != '3'"
            >
              <label class="texto--sm" for="cpf">CPF</label>
              <input
                class="form-control form-control-sm"
                [class.is-invalid]="
                  nacionalidade.value != '3' &&
                  cpf.invalid &&
                  (cpf.touched || isSubmitted)
                "
                formControlName="cpf"
                id="cpf"
                maxlength="100"
                name="cpf"
                [ngModel]="dadosPessoais ? dadosPessoais.cpf : ''"
                mask="000.000.000-00"
                type="text"
              />
              <div
                *ngIf="
                  nacionalidade.value != '3' &&
                  cpf.invalid &&
                  (cpf.touched || isSubmitted)
                "
              >
                <span class="erro" *ngIf="cpf.errors.required"
                  >Campo obrigatório</span
                >
                <span class="erro" *ngIf="cpf.errors.validCpf"
                  >CPF inválido</span
                >
              </div>
              <div
                *ngIf="
                  nacionalidade.value != '3' &&
                  cpf.invalid &&
                  cpf.value.length > 11
                "
              >
                <span class="erro">CPF Inválido</span>
              </div>
            </div>

            <div class="form-group col-md-3 col-sm-4">
              <label
                class="texto--sm"
                for="documento"
                *ngIf="nacionalidade.value != '3'"
                >RG</label
              >
              <label
                class="texto--sm"
                for="documento"
                *ngIf="nacionalidade.value == '3'"
                >RNE</label
              >
              <input
                class="form-control form-control-sm"
                [class.is-invalid]="
                  documento.invalid && (documento.touched || isSubmitted)
                "
                formControlName="documento"
                id="documento"
                maxlength="100"
                name="documento"
                [ngModel]="dadosPessoais ? dadosPessoais.rgNumero : ''"
                type="text"
              />
              <div
                *ngIf="documento.invalid && (documento.touched || isSubmitted)"
              >
                <span class="erro" *ngIf="documento.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>

            <div
              class="form-group col-md-2 col-sm-4"
              *ngIf="nacionalidade.value != '3'"
            >
              <label class="texto--sm" for="digito">Dígito</label>
              <input
                class="form-control form-control-sm"
                formControlName="digito"
                id="digito"
                maxlength="1"
                name="digito"
                [ngModel]="dadosPessoais ? dadosPessoais.rgDigito : ''"
                type="text"
              />
              <div
                *ngIf="
                  nacionalidade.value != '3' &&
                  digito.invalid &&
                  (digito.touched || isSubmitted)
                "
              ></div>
            </div>
            <div
              class="form-group col-md-5 col-sm-12"
              *ngIf="nacionalidade.value == '3'"
            >
              <label class="texto--sm" for="numeroPassapote"
                >Número Passaporte</label
              >
              <input
                class="form-control form-control-sm"
                [class.is-invalid]="
                  nacionalidade.value == '3' &&
                  numeroPassapote.invalid &&
                  (numeroPassapote.touched || isSubmitted)
                "
                formControlName="numeroPassapote"
                id="numeroPassapote"
                maxlength="100"
                name="numeroPassapote"
                [ngModel]="dadosPessoais ? dadosPessoais.passaporteNumero : ''"
                type="text"
              />
              <div
                *ngIf="
                  numeroPassapote.invalid &&
                  (numeroPassapote.touched || isSubmitted)
                "
              >
                <span
                  class="erro"
                  *ngIf="
                    nacionalidade.value == '3' &&
                    numeroPassapote.errors.required
                  "
                  >Campo obrigatório</span
                >
              </div>
            </div>

            <div
              class="form-group col-md-3 col-sm-12"
              *ngIf="nacionalidade.value == '3'"
            >
              <label class="texto--sm" for="paisEmissor">País Emissor</label>
              <select
                class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="paisEmissor"
                [class.is-invalid]="
                  nacionalidade.value == '3' &&
                  paisEmissor.invalid &&
                  (paisEmissor.touched || isSubmitted)
                "
                id="paisEmissor"
                [ngModel]="dadosPessoais ? dadosPessoais.passaporte_IdPais : ''"
                name="paisEmissor"
              >
                <option value="" disabled>Selecione</option>
                <option
                  *ngFor="let paisEmissor of paises"
                  [value]="paisEmissor.idPais"
                >
                  {{ paisEmissor.descricao }}
                </option>
              </select>
              <div
                *ngIf="
                  nacionalidade.value == '3' &&
                  paisEmissor.invalid &&
                  (paisEmissor.touched || isSubmitted)
                "
              >
                <span class="erro" *ngIf="paisEmissor.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
            <div
              class="form-group col-md-3 col-sm-12"
              *ngIf="nacionalidade.value == '3'"
            >
              <label class="texto--sm" for="tipo">Tipo</label>
              <input
                class="form-control form-control-sm"
                [class.is-invalid]="
                  nacionalidade.value == '3' &&
                  tipo.invalid &&
                  (tipo.touched || isSubmitted)
                "
                formControlName="tipo"
                id="tipo"
                maxlength="2"
                name="tipo"
                [ngModel]="dadosPessoais ? dadosPessoais.passaporteTipo : ''"
                type="text"
              />
              <div
                *ngIf="
                  nacionalidade.value == '3' &&
                  tipo.invalid &&
                  (tipo.touched || isSubmitted)
                "
              >
                <span class="erro" *ngIf="tipo.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
            <div
              class="form-group col-md-3 col-sm-12"
              *ngIf="nacionalidade.value == '3'"
            >
              <label class="texto--sm" for="dataEmissaoEstrangeiro"
                >Data de Emissão</label
              >
              <input
                class="form-control form-control-sm"
                [class.is-invalid]="
                  (nacionalidade.value == '3' &&
                    isSubmitted &&
                      dataEmissaoEstrangeiro.value == 'Invalid date') ||
                  (dataEmissaoEstrangeiro.invalid &&
                    (dataEmissaoEstrangeiro.touched || isSubmitted))
                "
                formControlName="dataEmissaoEstrangeiro"
                id="dataEmissaoEstrangeiro"
                maxlength="100"
                name="dataEmissaoEstrangeiro"
                [ngModel]="dadosPessoais ? dadosPessoais.passaporteEmissao : ''"
                type="date"
              />
              <div
                *ngIf="
                  (nacionalidade.value == '3' &&
                    isSubmitted &&
                      dataEmissaoEstrangeiro.value == 'Invalid date') ||
                  (dataEmissaoEstrangeiro.invalid &&
                    (dataEmissaoEstrangeiro.touched || isSubmitted))
                "
              >
                <span
                  class="erro"
                  *ngIf="
                    (nacionalidade.value == '3' &&
                      isSubmitted &&
                        dataEmissaoEstrangeiro.value == 'Invalid date') ||
                    dataEmissaoEstrangeiro.errors.required
                  "
                  >Campo obrigatório</span
                >
              </div>
            </div>
            <div
              class="form-group col-md-3 col-sm-12"
              *ngIf="nacionalidade.value == '3'"
            >
              <label class="texto--sm" for="dataValidade"
                >Data de Validade</label
              >
              <input
                class="form-control form-control-sm"
                [class.is-invalid]="
                  (nacionalidade.value == '3' &&
                    isSubmitted && dataValidade.value == 'Invalid date') ||
                  (dataValidade.invalid &&
                    (dataValidade.touched || isSubmitted))
                "
                formControlName="dataValidade"
                id="dataValidade"
                maxlength="100"
                name="dataValidade"
                [ngModel]="
                  dadosPessoais ? dadosPessoais.passaporteValidade : ''
                "
                type="date"
              />
              <div
                *ngIf="
                  (nacionalidade.value == '3' &&
                    isSubmitted && dataValidade.value == 'Invalid date') ||
                  (dataValidade.invalid &&
                    (dataValidade.touched || isSubmitted))
                "
              >
                <span
                  class="erro"
                  *ngIf="
                    (isSubmitted && dataValidade.value == 'Invalid date') ||
                    dataValidade.errors.required
                  "
                  >Campo obrigatório</span
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="form-group col-md-4 col-sm-12"
              *ngIf="nacionalidade.value != '3'"
            >
              <label class="texto--sm" for="dataEmissao">Data de Emissão</label>
              <input
                class="form-control form-control-sm"
                [class.is-invalid]="
                  (nacionalidade.value != '3' &&
                    isSubmitted && dataEmissao.value == 'Invalid date') ||
                  (pessoaForm.controls.dataEmissao.invalid &&
                    (pessoaForm.controls.dataEmissao.touched || isSubmitted))
                "
                formControlName="dataEmissao"
                id="dataEmissao"
                name="dataEmissao"
                [ngModel]="dadosPessoais ? dadosPessoais.rgEmissao : ''"
                type="date"
              />
              <div
                *ngIf="
                  (nacionalidade.value != '3' &&
                    isSubmitted && dataEmissao.value == 'Invalid date') ||
                  (dataEmissao.invalid && (dataEmissao.touched || isSubmitted))
                "
              >
                <span
                  class="erro"
                  *ngIf="
                    (isSubmitted && dataEmissao.value == 'Invalid date') ||
                    dataEmissao.errors.required
                  "
                  >Campo obrigatório</span
                >
              </div>
            </div>
            <div
              class="form-group col-md-3 col-sm-4"
              *ngIf="nacionalidade.value != '3'"
            >
              <label class="texto--sm" for="ufExpedicao">UF de Expedição</label>
              <select
                class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="ufExpedicao"
                [class.is-invalid]="
                  nacionalidade.value != '3' &&
                  ufExpedicao.invalid &&
                  (ufExpedicao.touched || isSubmitted)
                "
                id="ufExpedicao"
                [ngModel]="dadosPessoais ? dadosPessoais.rg_IdEstado : ''"
                name="ufExpedicao"
              >
                <option value="" disabled>Selecione</option>
                <option
                  *ngFor="let ufExpedicao of estados"
                  [value]="ufExpedicao.idEstado"
                >
                  {{ ufExpedicao.uf }}
                </option>
              </select>
              <div
                *ngIf="
                  nacionalidade.value != '3' &&
                  ufExpedicao.invalid &&
                  (ufExpedicao.touched || isSubmitted)
                "
              >
                <span class="erro" *ngIf="ufExpedicao.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
            <div
              class="form-group col-md-5 col-sm-4"
              *ngIf="nacionalidade.value != '3'"
            >
              <label class="texto--sm" for="orgaoExpedicao"
                >Órgão de Expedição</label
              >
              <select
                class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="orgaoExpedicao"
                [class.is-invalid]="
                  nacionalidade.value != '3' &&
                  nacionalidade.value != '3' &&
                  orgaoExpedicao.invalid &&
                  (orgaoExpedicao.touched || isSubmitted)
                "
                id="orgaoExpedicao"
                [ngModel]="dadosPessoais ? dadosPessoais.rg_IdOrgaoEmissor : ''"
                name="orgaoExpedicao"
              >
                <option value="" disabled>Selecione</option>
                <option
                  *ngFor="let orgaoExpedicao of orgaosEmissores"
                  [value]="orgaoExpedicao.idOrgaoEmissor"
                >
                  {{ orgaoExpedicao.descricao }}
                </option>
              </select>
              <div
                *ngIf="
                  nacionalidade.value != '3' &&
                  orgaoExpedicao.invalid &&
                  (orgaoExpedicao.touched || isSubmitted)
                "
              >
                <span class="erro" *ngIf="orgaoExpedicao.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-8 col-sm-4">
              <label class="texto--sm" for="pais">País</label>
              <select
                class="form-control form-control-sm custom-select custom-select-sm"
                formControlName="pais"
                [class.is-invalid]="
                  pais.invalid && (pais.touched || isSubmitted)
                "
                id="pais"
                [ngModel]="
                  dadosPessoais && dadosPessoais.pessoaEndereco.length
                    ? dadosPessoais.pessoaEndereco[0].idPais
                    : ''
                "
                name="pais"
              >
                <option *ngFor="let pais of paises" [value]="pais.idPais">
                  {{ pais.descricao }}
                </option>
              </select>
              <div *ngIf="pais.invalid && (pais.touched || isSubmitted)">
                <span class="erro" *ngIf="pais.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>

            <div class="form-group col-md-4 col-sm-12">
              <label class="texto--sm" for="codigoPostal">Código Postal</label>
              <input
                class="form-control form-control-sm"
                (blur)="obterLogradouroPorCep()"
                [class.is-invalid]="
                  codigoPostal.invalid && (codigoPostal.touched || isSubmitted)
                "
                formControlName="codigoPostal"
                id="codigoPostal"
                maxlength="100"
                name="codigoPostal"
                [ngModel]="
                  dadosPessoais && dadosPessoais.pessoaEndereco.length
                    ? dadosPessoais.pessoaEndereco[0].codigoPostal
                    : ''
                "
                type="text"
              />
              <div
                *ngIf="
                  codigoPostal.invalid && (codigoPostal.touched || isSubmitted)
                "
              >
                <span class="erro" *ngIf="codigoPostal.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="dropMobile text-right align-items-center col-md-3 col-sm-4"
          [ngClass]="{ 'mt-4': view }"
        >
          <div class="text-right align-items-center dropCustom col-sm-12 mt-3">
            <div class="w-100">
              <div
                [accept]="'image/png'"
                (change)="onSelect($event)"
                class="custom-dropzone col-sm-12"
                #drop
                [disabled]="view"
                [maxFileSize]="2097152"
                [multiple]="false"
                ngx-dropzone
              >
                <ngx-dropzone-label>
                  <div>
                    <img class="img-adicionada" [src]="imagem" width="100" />
                    <p *ngIf="imagem === null || imagem == ''">
                      Arraste a sua foto ou clique aqui para selecionar
                    </p>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-image-preview
                  class="previa-img"
                  *ngFor="let f of files"
                  [file]="f"
                  ngProjectAs="ngx-dropzone-preview"
                  [removable]="false"
                >
                </ngx-dropzone-image-preview>
              </div>
              <div class="text-center">
                <button
                  class="btn btn-sm btn-danger mt-1 btnRemove"
                  (click)="onRemove(1)"
                  id="remove"
                  [disabled]="view"
                  *ngIf="files.length > 0"
                  title="Remover Imagem"
                >
                  Remover Imagem
                </button>
              </div>
              <p
                class="mt-1 text-primary text-center col-md-12 col-sm-12"
                *ngIf="
                  (files.length === 0 && imagem == '') ||
                  (imagem == null && !view)
                "
              >
                Formatos do arquivo PNG, com tamanho máximo de 2MB.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-principal">
      <div class="row">
        <div class="form-group col-md-6 col-sm-12">
          <label class="texto--sm" for="endereco">Endereço</label>
          <input
            class="form-control form-control-sm"
            [class.is-invalid]="
              endereco.invalid && (endereco.touched || isSubmitted)
            "
            formControlName="endereco"
            id="endereco"
            (keydown)="validaCaractere($event)"
            maxlength="100"
            name="endereco"
            [ngModel]="
              dadosPessoais && dadosPessoais.pessoaEndereco.length
                ? dadosPessoais.pessoaEndereco[0].endereco
                : ''
            "
            type="text"
          />
          <div *ngIf="endereco.invalid && (endereco.touched || isSubmitted)">
            <span class="erro" *ngIf="endereco.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>
        <div class="form-group col-md-2 col-sm-12">
          <label class="texto--sm" for="numero">Número</label>
          <input
            class="form-control form-control-sm"
            [class.is-invalid]="
              numero.invalid && (numero.touched || isSubmitted)
            "
            formControlName="numero"
            id="numero"
            maxlength="100"
            name="numero"
            [ngModel]="
              dadosPessoais && dadosPessoais.pessoaEndereco.length
                ? dadosPessoais.pessoaEndereco[0].numero
                : ''
            "
            type="text"
          />
          <div *ngIf="numero.invalid && (numero.touched || isSubmitted)">
            <span class="erro" *ngIf="numero.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="complemento">Complemento</label>
          <input
            class="form-control form-control-sm"
            [class.is-invalid]="
              complemento.invalid && (complemento.touched || isSubmitted)
            "
            formControlName="complemento"
            id="complemento"
            maxlength="15"
            name="complemento"
            [ngModel]="
              dadosPessoais && dadosPessoais.pessoaEndereco.length
                ? dadosPessoais.pessoaEndereco[0].complemento
                : ''
            "
            type="text"
          />
          <div
            *ngIf="complemento.invalid && (complemento.touched || isSubmitted)"
          >
            <span class="erro" *ngIf="complemento.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="bairro">Bairro</label>
          <input
            class="form-control form-control-sm"
            [class.is-invalid]="
              bairro.invalid && (bairro.touched || isSubmitted)
            "
            formControlName="bairro"
            id="bairro"
            (keydown)="validaCaractere($event)"
            maxlength="100"
            name="bairro"
            [ngModel]="
              dadosPessoais && dadosPessoais.pessoaEndereco.length
                ? dadosPessoais.pessoaEndereco[0].bairro
                : ''
            "
            type="text"
          />
          <div *ngIf="bairro.invalid && (bairro.touched || isSubmitted)">
            <span class="erro" *ngIf="bairro.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="estado">Estado</label>
          <select
            class="form-control form-control-sm"
            [class.is-invalid]="estado.invalid && (estado.touched || isSubmitted)"
            formControlName="estado"
            [ngModel]="dadosPessoais && dadosPessoais.pessoaEndereco.length ? dadosPessoais.pessoaEndereco[0].estado: ''"
            id="estado"
            name="estado">
            <option value="" selected>Selecione</option>
            <option value="AC">AC</option>
            <option value="AL">AL</option>
            <option value="AP">AP</option>
            <option value="AM">AM</option>
            <option value="BA">BA</option>
            <option value="CE">CE</option>
            <option value="DF">DF</option>
            <option value="ES">ES</option>
            <option value="GO">GO</option>
            <option value="MA">MA</option>
            <option value="MS">MS</option>
            <option value="MT">MT</option>
            <option value="MG">MG</option>
            <option value="PA">PA</option>
            <option value="PB">PB</option>
            <option value="PR">PR</option>
            <option value="PE">PE</option>
            <option value="PI">PI</option>
            <option value="RJ">RJ</option>
            <option value="RN">RN</option>
            <option value="RS">RS</option>
            <option value="RO">RO</option>
            <option value="RR">RR</option>
            <option value="SC">SC</option>
            <option value="SP">SP</option>
            <option value="SE">SE</option>
            <option value="TO">TO</option>
          </select>
          <div *ngIf="estado.invalid && (estado.touched || isSubmitted)">
            <span class="erro" *ngIf="estado.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>

        <div class="form-group col-md-4 col-sm-12">
          <label class="texto--sm" for="cidade">Cidade</label>
          <input
            class="form-control form-control-sm"
            [class.is-invalid]="
              cidade.invalid && (cidade.touched || isSubmitted)
            "
            formControlName="cidade"
            id="cidade"
            (keydown)="validaCaractere($event)"
            maxlength="100"
            name="cidade"
            [ngModel]="dadosPessoais && dadosPessoais.pessoaEndereco.length ? dadosPessoais.pessoaEndereco[0].cidade : ''"
            type="text"
          />
          <div *ngIf="cidade.invalid && (cidade.touched || isSubmitted)">
            <span class="erro" *ngIf="cidade.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12 col-sm-12">
          <label class="texto--sm mr-5" for="deficiencia"
            >Portador de alguma necessidade especial?</label
          >
          <input
            name="deficienciaRadio"
            type="radio"
            id="deficiencia"
            (change)="mudaDeficiencia(true)"
            formControlName="deficienciaRadio"
            [value]="true"
          />
          <label for="deficienciaRadio">&nbsp; Sim</label>
          <span class="ml-5"></span>
          <input
            name="deficienciaRadio"
            type="radio"
            (change)="mudaDeficiencia(false)"
            formControlName="deficienciaRadio"
            [value]="false"
          />
          <label for="deficienciaRadio">&nbsp; Não</label>
          <div
            *ngIf="
              deficienciaRadio.invalid &&
              (deficienciaRadio.touched || isSubmitted)
            "
          >
            <span class="erro" *ngIf="deficienciaRadio.errors.required"
              >Campo obrigatório</span
            >
          </div>
        </div>

        <div class="col-md-12" *ngIf="possuiDeficiencia">
          <div class="col-md-12 mb-4">
            <label>Qual(is)?</label>
          </div>
          <div
            class="form-group col-md-6 col-sm-12"
            *ngFor="let deficiencia of deficiencias"
          >
            <input
              id="deficiencia"
              name="deficiencia.idDeficiencia"
              type="checkbox"
              [checked]="deficiencia.checked"
              (change)="onCheckboxChange($event)"
              [value]="deficiencia.idDeficiencia"
              class="checkLateral"
            />
            <label for="deficiencia.idDeficiencia"
              >&nbsp; {{ deficiencia.descricao }}.</label
            >
          </div>
        </div>
      </div>

      <div class="col-ml-4">
        <div class="row">
          <div class="form-group col-md-12 col-sm-12">
            <label> </label>
            <div
              formControlName="termoCadastro"
              id="summer"
              name="summer"
              [ngxSummernote]="config"
              [ngxSummernoteDisabled]="true"
            ></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 padding-left-14">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                (change)="selecionado(checkedTermoCadastro)"
                id="checkTermoCadastro"
                [(ngModel)]="checkedTermoCadastro"
                [ngModelOptions]="{ standalone: true }"
                type="checkbox"
              />
              <label class="ml-2"
                >Li e concordo com os termos sobre o compartilhamento dos meus
                dados</label
              >
            </div>
          </div>
          <div class="col-md-6 padding-left-14">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                (change)="selecionado(!recusadoTermoCadastro)"
                id="recusaTermoCadastro"
                [(ngModel)]="recusadoTermoCadastro"
                [ngModelOptions]="{ standalone: true }"
                type="checkbox"
              />
              <label class="ml-2"
                >Li e não concordo com os termos sobre o compartilhamento dos
                meus dados</label
              >
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!desabilitaSalvar">
        <div class="row">
          <div class="form-group col-md-12 col-sm-12">
            <label>{{ itens["descricaoTipo"] }}</label>
            <div
              formControlName="codigoEtica"
              id="summer"
              name="summer"
              [ngxSummernote]="config"
              [ngxSummernoteDisabled]="true"
            ></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 check-centralizado">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                (change)="aceiteTermo()"
                type="checkbox"
                formControlName="aceiteTermo"
                id="aceiteTermo"
                name="aceiteTermo"
                [(ngModel)]="aceiteTermoModel"
              />
              <label class="ml-2"
                >Li e aceito a declaração de privacidade.</label
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card-body" *ngIf="isEditar && !isInscricao">
        <h4 class="card-title"><strong>Anexar Arquivo (RG, CNH)</strong></h4>
        <!--<p class="text-primary">Obrigatório envio apenas após aprovação no Exame CFP®.</p>-->
        <div class="row mt-5">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-1 col-sm-12 mt-3">
                <label>RG/CNH: </label>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="input-group mb-3">
                  <div
                    [accept]="
                      'image/jpeg,image/png,application/pdf,image/x-eps'
                    "
                    (change)="onSelectRg($event)"
                    class="custom-dropzone-anexos"
                    #drop
                    [maxFileSize]="5000000"
                    [multiple]="false"
                    ngx-dropzone
                  >
                    <ngx-dropzone-label class="label-arquivo">
                      Escolher arquivo
                    </ngx-dropzone-label>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-sm-12 mt-3">
                <div class="text-primary" *ngIf="nomeRg == null">
                  Nenhum arquivo selecionado
                </div>
                <div *ngIf="rgInvalido" class="erro">
                  <span> Tamanho do arquivo maior que 5mb. </span>
                </div>
                <ngx-dropzone-label
                  class="text-primary"
                  *ngIf="nomeRg !== null"
                >
                  {{ nomeRg }}
                </ngx-dropzone-label>
              </div>
            </div>
            <div class="mb-3">
              <span
                class="arquivos-permitidos text-primary"
                [class.erro]="rgErro"
              >
                (Obrigatório envio de RG ou CNH)
              </span>
            </div>
          </div>
        </div>

        <!--<div class="row mt-4">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-1 col-sm-12 mt-3">
                <label>CPF: </label>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="input-group mb-3">
                  <div [accept]="
              'image/jpeg,image/png,application/pdf,image/x-eps'
            "
                       (change)="onSelectCpf($event)"
                       class="custom-dropzone-anexos"
                       #drop
                       [maxFileSize]="5000000"
                       [multiple]="false"
                       ngx-dropzone>
                    <ngx-dropzone-label class="label-arquivo">
                      Escolher arquivo
                    </ngx-dropzone-label>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-sm-12 mt-3">
                <div class="text-primary"
                     *ngIf="nomeCpf == null">
                  Nenhum arquivo selecionado
                </div>
                <div *ngIf="cpfInvalido"
                     class="erro">
                  <span>
                    Tamanho do arquivo maior que 5mb.
                  </span>
                </div>
                <ngx-dropzone-label class="text-primary"
                                    *ngIf="nomeCpf !== null">
                  {{ nomeCpf }}
                </ngx-dropzone-label>
              </div>
            </div>
            <div class="mb-3">
              <span class="arquivos-permitidos text-primary">
                (Não obrigatório se RG ou CNH já possuir CPF)
              </span>
            </div>
          </div>
        </div>-->

        <div class="row mt-4">
          <div class="col-md-12 col-sm-12">
            <h4>
              Situação:
              <span
                [ngStyle]="{
                  color:
                    dadosPessoais.descricaoTrilhaItemSituacao == 'Aprovado'
                      ? '#78C000'
                      : dadosPessoais.descricaoTrilhaItemSituacao ==
                        'Análise Pendente'
                      ? '#dbcb25'
                      : 'red'
                }"
                style="font-weight: bolder"
                >{{ dadosPessoais.situacaoTrilha }}</span
              >
            </h4>
          </div>

          <div class="col-md-12 col-sm-12">
            <p>Motivo: {{ dadosPessoais.justificativaTrilha }}</p>
          </div>
        </div>
      </div>
      <div class="row mt-5" *ngIf="!isEditar && !isInscricao">
        <h4 class="card-title"><strong>Anexar Arquivo (RG, CNH)</strong></h4>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-1 col-sm-12 mt-3">
              <label>RG/CNH: </label>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="input-group mb-3">
                <div
                  [accept]="'image/jpeg,image/png,application/pdf,image/x-eps'"
                  (change)="onSelectRg($event)"
                  class="custom-dropzone-anexos"
                  #drop
                  [maxFileSize]="5000000"
                  [multiple]="false"
                  ngx-dropzone
                >
                  <ngx-dropzone-label class="label-arquivo">
                    Escolher arquivo
                  </ngx-dropzone-label>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-sm-12 mt-3">
              <div class="text-primary" *ngIf="nomeRg == null">
                Nenhum arquivo selecionado
              </div>
              <div *ngIf="rgInvalido" class="erro">
                <span> Tamanho do arquivo maior que 5mb. </span>
              </div>
              <ngx-dropzone-label class="text-primary" *ngIf="nomeRg !== null">
                {{ nomeRg }}
              </ngx-dropzone-label>
            </div>
          </div>
          <div class="mb-3">
            <span
              class="arquivos-permitidos text-primary"
              [class.erro]="rgErro"
            >
              (Obrigatório envio de RG ou CNH)
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-5" *ngIf="isInscricao">
        <h4 class="card-title"><strong>Anexar Arquivo (RG, CNH)</strong></h4>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-1 col-sm-12 mt-3">
              <label>RG/CNH: </label>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="input-group mb-3">
                <div
                  [accept]="'image/jpeg,image/png,application/pdf,image/x-eps'"
                  (change)="onSelectRg($event)"
                  class="custom-dropzone-anexos"
                  #drop
                  [maxFileSize]="5000000"
                  [multiple]="false"
                  ngx-dropzone
                >
                  <ngx-dropzone-label class="label-arquivo">
                    Escolher arquivo
                  </ngx-dropzone-label>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-sm-12 mt-3">
              <div class="text-primary" *ngIf="nomeRg == null">
                Nenhum arquivo selecionado
              </div>
              <div *ngIf="rgInvalido" class="erro">
                <span> Tamanho do arquivo maior que 5mb. </span>
              </div>
              <ngx-dropzone-label class="text-primary" *ngIf="nomeRg !== null">
                {{ nomeRg }}
              </ngx-dropzone-label>
            </div>
          </div>
          <div class="mb-3">
            <span
              class="arquivos-permitidos text-primary"
              [class.erro]="rgErro"
            >
              (Obrigatório envio de RG ou CNH)
            </span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <button
            class="btn btn-sm btn-primary btn-secondary mr-0"
            id="voltar"
            (click)="voltar()"
            *ngIf="btnVoltar"
            type="button"
          >
            Voltar
          </button>
          <button
            class="btn btn-sm btn-primary mr-0 ml-2"
            [disabled]="!aceiteTermoModel && !desabilitaSalvar"
            id="salvarAutoCadastro"
            (click)="submit(pessoaForm)"
            [ngStyle]="{ visibility: isInscricao ? 'hidden' : 'visible' }"
            type="button"
          >
            {{
              exibirTextoAtualizacao
                ? "Você confirma a atualização dos seus dados?"
                : "Salvar"
            }}
          </button>
        </div>
      </div>
    </div>
  </fieldset>
</form>

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Validação de e-mail e Cadastro de Senha</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Obrigado por criar uma conta conosco! Antes de continuar, precisamos
          validar seu e-mail e criar a sua senha de acesso. Por favor acesse
          {{ emailPrimarioModel }} e verifique sua caixa de entrada, SPAM ou
          lixo eletrônico.
        </p>
      </div>
      <div class="modal-footer">
        <div class="col-12 text-right">
          <button
            class="btn btn-sm btn-secondary mr-0"
            type="button"
            data-dismiss="modal"
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL  -->
<div
  bsModal
  #childModal="bs-modal"
  [config]="{ backdrop: 'static', keyboard: false }"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Validação de e-mail e Cadastro de Senha</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Obrigado por criar uma conta conosco! Antes de continuar, precisamos
          validar seu e-mail e criar a sua senha de acesso. Por favor acesse
          {{ emailPrimarioModel }} e verifique sua caixa de entrada, SPAM ou
          lixo eletrônico.
        </p>
      </div>
      <div class="modal-footer">
        <div class="col-12 text-right">
          <button
            class="btn btn-sm btn-secondary mr-0"
            type="button"
            (click)="hideChildModal()"
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
