<form autocomplete="off"
      [formGroup]="editalForm"
      id="editalForm"
      name="editalForm">
  <div class="ml-4">
    <div class="row">
      <div class="form-group col-md-12 col-sm-12">
        <label> {{editals.descricaoTipo}}</label>
        <div formControlName="manual"
             id="summer"
             name="summer"
             [ngxSummernote]="config"
             [ngxSummernoteDisabled]="true">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 padding-left-14">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input"
                 (change)="selecionado(checked)"
                 id="customCheck1"
                 [(ngModel)]="checked"
                 [ngModelOptions]="{standalone: true}"
                 type="checkbox">
          <label class="ml-2">Li e concordo com os termos do {{editals.descricaoTipo}}</label>
        </div>
      </div>
    </div>
  </div>
</form>
<hr>
<form autocomplete="off"
      [formGroup]="editalForm"
      id="editalForm"
      name="editalForm">
  <div class="ml-4">
    <div class="row">
      <div class="form-group col-md-12 col-sm-12">
        <label> {{manuals.descricaoTipo}}</label>
        <div formControlName="editalExame"
             id="summer"
             name="summer"
             [ngxSummernote]="config"
             [ngxSummernoteDisabled]="true">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 padding-left-14">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input"
                 (change)="selecionadoManual(checkedManual)"
                 id="customCheck2"
                 [(ngModel)]="checkedManual"
                 [ngModelOptions]="{standalone: true}"
                 type="checkbox">
          <label class="ml-2">Li e concordo com os termos do {{manuals.descricaoTipo}}</label>
        </div>
      </div>
    </div>
  </div>
</form>