import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SolicitacaoPlanoFinanceiroService } from 'src/app/services/plano-financeiro/solicitacao-plano-financeiro.service';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-solicitacao-plano-financeiro',
  templateUrl: './solicitacao-plano-financeiro.component.html',
  styleUrls: ['./solicitacao-plano-financeiro.component.scss']
})
export class SolicitacaoPlanoFinanceiroComponent implements OnInit {
  @ViewChild('childModal') childModal: ModalDirective;
  files: File[] = [];
  anexos = [];
  view = false;
  imagem = null;
  obj = [];
  id: any;
  observacao: any;
  periodo: any;
  solicitacao: any = null;
  acessoPlanoFinanceiro = true;
  alterar = false;
  dataAtual = moment();
  notas: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public planoFinanceiroService: SolicitacaoPlanoFinanceiroService,
    public pessoaService: PessoaService
  ) {
    this.id = JSON.parse(localStorage.getItem('userId'));
  }

  obterSolicitacao() {
    this.planoFinanceiroService.obterSolicitacao(this.id)
      .subscribe(
        (resp) => {
          this.solicitacao = resp.resultado;
        },
        (erro) => {
          console.log('erro ao buscar solicitacao ' + erro)
        }
      );

  }

  possuiAcessoPlanoFinanceiro() {
    this.pessoaService.possuiAcessoPlanoFinanceiro(this.id)
      .subscribe(
        (resp) => {
          this.acessoPlanoFinanceiro = resp.resultado;
          if(!resp.resultado)
            this.router.navigate(['/home']);
        },
        (erro) => {
          console.log('erro ao buscar acesso ' + erro)
        }
      );

  }

  ngOnInit(): void {
    this.obterSolicitacao();
    //this.possuiAcessoPlanoFinanceiro();
  }

  onSelect(event) {
    this.obj = [];
    this.onRemove();
    this.obj = event.source._previewChildren;
    this.files.push(...event.addedFiles);
  }

  onRemove() {
    this.obj = [];
    this.files.splice(0, 1);
    if (this.imagem !== '') {
      this.imagem = '';
    }
  }

  efetivar(etapa) {

    var etapaResultado = false;
    var etapaCuradoria = false;

    if (etapa == 1)
      etapaResultado = true;

    if (etapa == 2)
      etapaCuradoria = true;

    this.observacao = $('#observacao').val();

    if (etapa == 2 && this.files.length === 0) {
      Swal.fire(
        'Solicitação de Plano Financeiro',
        `Insira ao menos um arquivo.`,
        'warning'
      );
      return
    }

    if (this.solicitacao != null)
    {

      if (etapa == 2)
      {
        this.obj.forEach(o => {

          let arquivo = {
            "nomeArquivo": o.file.name,
            "anexo": o.imageSrc.replace(/^data:(.*base64,)?/, '')
          };

          this.anexos.push(arquivo);

        });
      }

      if (this.alterar) {

        let request = {
          "idSolicitacaoPlanoFinanceiro": this.solicitacao.idSolicitacaoPlanoFinanceiro,
          "idPessoa": this.id,
          "observacao": this.observacao,
          "anexos": this.anexos,
          "etapaResultado": etapaResultado,
          "etapaCuradoria": etapaCuradoria
        };

        this.planoFinanceiroService.alterar(request)
          .subscribe((resp) => {
            if (!resp.sucesso) {
              Swal.fire(
                'Solicitação de Plano Financeiro',
                `Erro ao efetuar alteração de solicitação de plano financeiro. Favor contate o suporte.`,
                'error'
              ).then(() => {
                this.onRemove();
              });
              return;
            }
            this.obterSolicitacao();
            Swal.fire(
              'Solicitação de Plano Financeiro',
              `Solicitação de Plano Financeiro alterada com Sucesso. `,
              'success'
            ).then(() => {
              this.anexos = [];
              this.onRemove();
            });
          }, (erro) => {
            Swal.fire(
              'Solicitação de Plano Financeiro',
              erro.error.excecao[0].mensagem,
              'error'
            );
          });
      }
      else {

        let request = {
          "idPessoa": this.id,
          "observacao": this.observacao,
          "anexos": this.anexos,
          "etapaResultado": etapaResultado,
          "etapaCuradoria": etapaCuradoria
        };

        this.planoFinanceiroService.criar(request)
          .subscribe((resp) => {
            if (!resp.sucesso) {
              Swal.fire(
                'Solicitação de Plano Financeiro',
                `Erro ao efetuar cadastro de solicitação de Plano Financeiro. Favor contate o suporte`,
                'error'
              ).then(() => {
                this.anexos = [];
                this.onRemove();
              });
              return;
            }
            this.obterSolicitacao();
            Swal.fire(
              'Solicitação de Plano Financeiro',
              `Solicitação de Plano Financeiro cadastrada com Sucesso. `,
              'success'
            ).then(() => {
              this.anexos = [];
              this.onRemove();
            })
          }, (erro) => {
            Swal.fire(
              'Solicitação de Plano Financeiro',
              erro.error.excecao[0].mensagem,
              'error'
            );
          });
      }
    } else {

      if (etapa == 2)
      {
        this.obj.forEach(o => {

          let arquivo = {
            "nomeArquivo": o.file.name,
            "anexo": o.imageSrc.replace(/^data:(.*base64,)?/, '')
          };

          this.anexos.push(arquivo);

        });
      }

      let request = {
        "idPessoa": this.id,
        "observacao": this.observacao,
        "anexos": this.anexos,
        "etapaResultado": etapaResultado,
        "etapaCuradoria": etapaCuradoria
      };

      this.planoFinanceiroService.criar(request)
        .subscribe((resp) => {
          if (!resp.sucesso) {
            Swal.fire(
              'Solicitação de Plano Financeiro',
              `Erro ao efetuar cadastro de solicitação de Plano Financeiro. Favor contate o suporte`,
              'error'
            ).then(() => {
              this.onRemove();
            });
            return;
          }
          this.obterSolicitacao();
          Swal.fire(
            'Solicitação de Plano Financeiro',
            `Solicitação de Plano Financeiro cadastrada com Sucesso. `,
            'success'
          ).then(() => {
            this.anexos = [];
            this.onRemove();
          });
        }, (erro) => {
          Swal.fire(
            'Solicitação de Plano Financeiro',
            erro.error.excecao[0].mensagem,
            'error'
          );
        });

    }

  }

  cadastrarCuradoria() {

    if (this.solicitacao != null)
    {

      if (this.solicitacao.idSituacaoCuradoria == 2 || this.solicitacao.idSituacaoResultado == 2)
      {
        Swal.fire({
          title: 'Solicitação de Plano Financeiro',
          text: "A solicitação do Plano Financeiro será continuada após pagamento do débito",
          icon: 'warning'
        }).then((result) => {
        });

        return;
      }

      if (this.solicitacao.idSituacaoCuradoria > 2 && this.solicitacao.idSituacaoCuradoria < 4 && this.solicitacao.idSituacaoResultado <= 4)
      {
        Swal.fire({
          title: 'Solicitação de Plano Financeiro',
          text: "Já foi enviada os arquivos para análise do plano financeiro anteriormente. Deseja reenviar novamente?",
          icon: 'question',
          showCancelButton: true,
          cancelButtonColor: '#3085d6',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
              this.alterar = true;
              this.efetivar(2);
            }
          if (result.isDismissed) {
            return;
          }
        });
      }
      else if ((this.solicitacao.idSituacaoCuradoria == 4 || this.solicitacao.idSituacaoCuradoria == 5) && this.solicitacao.idSituacaoResultado <= 4) {
        Swal.fire(
          'Solicitação de Plano Financeiro',
          `A análise do arquivo do Plano Financeiro já consta como aprovada. Não será mais necessário novo envio.`,
          'warning'
        );
        return;
      }
      else if (this.solicitacao.idSituacaoCuradoria == 1 && this.solicitacao.idSituacaoResultado <= 4)
      {
        this.alterar = true;
        this.efetivar(2);
      }
      else {
        // Regular ou Cancelado
        this.alterar = false;
        this.efetivar(2);
      }
    }
    else {
      this.alterar = false;
      this.efetivar(2);
    }
  }

  cadastrarInscricaoProva() {

    if (this.solicitacao != null)
    {

      if (this.solicitacao.idSituacaoCuradoria == 2 || this.solicitacao.idSituacaoResultado == 2)
      {
        Swal.fire({
          title: 'Solicitação de Plano Financeiro',
          text: "A solicitação do Plano Financeiro será continuada após pagamento do débito",
          icon: 'warning'
        }).then((result) => {
        });

        return;
      }

      if (this.solicitacao.idSituacaoResultado > 2 && this.solicitacao.idSituacaoResultado < 4)
      {
        if (this.solicitacao.idSituacaoCuradoria <= 5)
        {
          this.alterar = true;
          this.efetivar(1);
        }
        else {
          this.alterar = false;
          this.efetivar(1);
        }
      }
      else if ((this.solicitacao.idSituacaoCuradoria == 4 || this.solicitacao.idSituacaoCuradoria == 5) && this.solicitacao.idSituacaoResultado <= 4) {
        Swal.fire(
          'Solicitação de Plano Financeiro',
          `A análise do arquivo do Plano Financeiro já consta como aprovada. Não será mais necessário novo envio.`,
          'warning'
        );
        return;
      }
      else if (this.solicitacao.idSituacaoResultado == 1 && this.solicitacao.idSituacaoCuradoria < 4)
      {
        this.alterar = true;
        this.efetivar(1);
      }
      else
      {
        //reprovado ou cancelado
        this.alterar = false;
        this.efetivar(1);
      }
    }
    else {

      Swal.fire({
        title: 'Confirmação de inscrição do Plano Financeiro',
        text: "Atenção, você deseja realizar sua inscrição para o Plano Financeiro neste momento? Mediante sua confirmação, você terá o prazo de 90 dias para realização de todas as etapas do curso.",
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.alterar = false;
          this.efetivar(1);
        }
      });
    }
  }

  cancelarSolicitacao() {
    Swal.fire({
      title: 'Cancelamento do Plano Financeiro',
      text: "Ao solicitar o cancelamento, você deverá pagar a taxa de inscrição para realizar o Plano Financeiro novamente. Confirma sua solicitação?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {

        this.planoFinanceiroService.cancelarSolicitacao(this.solicitacao.idSolicitacaoPlanoFinanceiro)
          .subscribe((resp) => {
            if (!resp.sucesso) {
              Swal.fire(
                'Solicitação de Plano Financeiro',
                `Erro ao efetuar cancelamento de solicitação de plano financeiro. Favor contate o suporte.`,
                'error'
              ).then(() => {
                this.onRemove();
              });
              return;
            }
            this.obterSolicitacao();
            Swal.fire(
              'Solicitação de Plano Financeiro',
              `Solicitação de Plano Financeiro cancelada com Sucesso. `,
              'success'
            ).then(() => {
              this.onRemove();
            });
          }, (erro) => {
            Swal.fire(
              'Solicitação de Plano Financeiro',
              erro.error.excecao[0].mensagem,
              'error'
            );
          });
      }
      if (result.isDismissed) {
        return;
      }
    });
  }

  checarPodeJustificar() {
    return this.solicitacao != null && this.solicitacao.idSituacaoAtendimento !== 3 && (moment(this.solicitacao.dataLimiteResultado).isBefore(this.dataAtual) || moment(this.solicitacao.dataLimiteCuradoria).isBefore(this.dataAtual))
  }

  verNotas() {
    this.planoFinanceiroService
      .obterNotas(this.solicitacao.idSolicitacaoPlanoFinanceiro)
      .subscribe((result) => {
        this.notas = result.resultado;
        this.childModal.show();
      }, (erro) => {
        Swal.fire(
          'Solicitação de Plano Financeiro',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  obterNotaTotal() {
    let notaTotal = 0
    let notaTotalLength = 0
    if (Array.isArray(this.notas) && this.notas.length > 0) {
      this.notas.map(item => {
        if (item.nota != null) {
          notaTotal += item.nota
          notaTotalLength++
        }
      })
      notaTotal = notaTotal / notaTotalLength
    }
    return notaTotal
  }

}
