import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CreditoService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    let token = localStorage.getItem('tknCdst');


    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterTextoCreditos() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-texto-instrucoes-creditos`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterPorId(id: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-por-id`,
      {
        headers: this.httpHeaders,
        params: { id },
      }
    );
  }

  atualizarCredito(credito: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-credito/alterar`,
      credito,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarCredito(credito: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-credito/criar`,
      credito,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarSemTopicos(credito: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-credito/criar-sem-topicos`,
      credito,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarComTopicos(credito: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-credito/criar-com-topicos`,
      credito,
      {
        headers: this.httpHeaders,
      }
    );
  }
  criarTopicosIsolado(credito: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-credito/criar-topico-isolado`,
      credito,
      {
        headers: this.httpHeaders,
      }
    );
  }

  alterarCredito(credito: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-credito/salvar-dados-adicionais`,
      credito,
      {
        headers: this.httpHeaders,
      }
    );
  }

  alterar(credito: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-credito/salvar-dados-adicionais`,
      credito,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterTipos() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-tipos`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterEmpresas(codigoAtividadeTipoCategoria: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-empresas`,
      {
        headers: this.httpHeaders,
        params: {
          codigoAtividadeTipoCategoria,
         },
      }
    );
  }

  obterPorTipoCategoria(codigoAtividadeTipo: any,codigoAtividadeCategoria: any, liberado: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-por-tipo-categoria`,
      {
        headers: this.httpHeaders,
        params: {
          codigoAtividadeTipo,
          codigoAtividadeCategoria,
          liberado
         },
      }
    );
  }

  obterPorTipoCategoriaEmpresa(codigoAtividadeTipo: any,codigoAtividadeCategoria: any, codigoEmpresa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-por-tipo-categoria-empresa`,
      {
        headers: this.httpHeaders,
        params: {
          codigoAtividadeTipo,
          codigoAtividadeCategoria,
          codigoEmpresa
         },
      }
    );
  }

  obterCategorias(codigoAtividadeTipo: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-categorias`,
      {
        headers: this.httpHeaders,
        params: {
          codigoAtividadeTipo,
         },
      }
    );
  }

  obterTopicosAtividade(codigoAtividade: any, codigoPessoaCertificadoCredito: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-topicos-atividade`,
      {
        headers: this.httpHeaders,
        params: {
          codigoAtividade,
          codigoPessoaCertificadoCredito


         },
      }
    );
  }
  obterUltimaVigencia(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-ultima-vigencia-pessoa`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa,


         },
      }
    );
  }
  obterVigencia(codigoPessoaCertificadoVigencia: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-creditos-por-vigencias`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoaCertificadoVigencia,


         },
      }
    );
  }
  obterAtividade(codigoPessoaCertificadoCredito: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-atividade`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoaCertificadoCredito,


         },
      }
    );
  }
  obterTopicos(codigoPessoaCertificadoCredito: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-topicos-atividade-credito`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoaCertificadoCredito,


         },
      }
    );
  }

  excluirTopico(id: any) {
    return this.http.delete(
      `${environment.backofficeUrl}/area-logada-credito/desativar-topico-isolado?id=${id}`,
      {
        headers: this.httpHeaders,
      }
    );
  }
}
