import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Resposta } from 'src/app/shared/interfaces/resposta-interface';
import Swal from 'sweetalert2';

type VotacaoPerguntaResposta = {
  checked: boolean | null
  resposta: string
  peso: string
  idVotacaoPergunta: number
  idVotacaoPerguntaResposta: number
}

type VotacaoAnexos = {
  idVotacao: number
  idVotacaoAnexos: number
  caminho: string
  tipo: string
}

type VotacaoPerguntas = {
  idVotacaoPergunta: number
  titulo: string
  permitirVotoNulo: boolean
  multiplaEscolha: boolean
  idVotacao: number
  respostas: VotacaoPerguntaResposta[]
}

type Votacao = {
  enviarEmailParaParticipanteComDiasDeAntecedencia: boolean
  enviarEmailParaParticipanteNoFinalDaVotacao: boolean
  filtrarParticipantesDaVotacao: boolean,
  fim: Date
  inicio: Date
  titulo: string,
  idVotacao: number
  votacaoAnexos: VotacaoAnexos[]
  votacaoPerguntas: VotacaoPerguntas[]
  textoApresentacao: string
  textoFinalizacao: string
}

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html',
  styleUrls: ['./questionario.component.scss'],
})
export class QuestionarioComponent implements OnInit {
  myGroup: FormGroup;

  public currentPage = 0
  public pergunta: any
  public iniciado: boolean = false
  public finalizado: boolean = false
  public idPessoa: number = 0
  public carregando: boolean = false
  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient) { }
  private idVotacao: number
  public votacao: Votacao = {
    titulo: "",
    enviarEmailParaParticipanteComDiasDeAntecedencia: false,
    enviarEmailParaParticipanteNoFinalDaVotacao: false,
    filtrarParticipantesDaVotacao: false,
    fim: new Date(),
    inicio: new Date(),
    idVotacao: 0,
    votacaoAnexos: [],
    votacaoPerguntas: [],
    textoApresentacao: "",
    textoFinalizacao: ""
  }

  ngOnInit(): void {
    this.idVotacao = this.route.snapshot.params['votacaoId'];
    this.idPessoa = Number(localStorage.getItem('userId'))
    this.obterVotacao(this.idVotacao)
  }

  iniciarVotacao() {
    this.iniciado = true
    this.carregando = false
    this.currentPage = 0
    this.pergunta = this.votacao.votacaoPerguntas[this.currentPage]
  }

  public voltarParaListaDeVotacao() {
    this.router.navigate(['/CriarVotacao']);
  }

  public perguntaAnterior() {
    this.currentPage -= 1

    if (this.currentPage < 0) {
      this.iniciado = false
      return
    }

    this.pergunta = this.votacao.votacaoPerguntas[this.currentPage]
  }

  public verificaSePodeAnularAPergunta() {
    if (!this.pergunta.permitirVotoNulo) {
      for (const resp of this.pergunta.respostas) {
        if (resp.checked) {
          return
        }
      }

      throw alert("Não é permitido voto nulo")
    }
  }

  enviar() {
    this.carregando = true
    let payload: any[] = []

    for (const pergunta of this.votacao.votacaoPerguntas) {
      for (const resposta of pergunta.respostas) {

        if (!resposta.checked) continue

        payload.push({
          idPessoa: this.idPessoa,
          idVotacaoPerguntaResposta: resposta.idVotacaoPerguntaResposta
        })
      }
    }

    fetch(environment.backofficeUrlV2 + "/votacao/registrar-votacao-do-usuario", {
      method: 'POST',
      body: JSON.stringify({
        respostas: payload
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('tkn')
      }
    }).then(res => {

      if (res.ok) {
        res.json().then(() => {
          this.finalizado = true
        })
      } else {

        alert("Falhou ao enviar respostas")
      }

      this.carregando = false
    })
  }

  public proximaPergunta() {
    this.verificaSePodeAnularAPergunta()

    if (this.currentPage + 1 >= this.votacao.votacaoPerguntas.length) {

      let html = ""
      for (const pergunta of this.votacao.votacaoPerguntas) {
        if (pergunta.respostas.filter(a => a.checked).length <= 0) {
          continue
        }

        html += `<h4>${pergunta.titulo}</h4>`

        for (const resposta of pergunta.respostas) {
          if (resposta.checked) {
            html += `<b>${resposta.resposta}</b><br>`
          }
        }

      }

      Swal.fire(
        {
          title: "Confirmar votação",
          inputLabel: "Confirme as respostas selecionadas",
          html,
          showCancelButton: true
        }

      ).then(e => {

        if (e.isConfirmed) {
          this.enviar()
        }
      })

      return
    }

    this.currentPage += 1
    this.pergunta = this.votacao.votacaoPerguntas[this.currentPage]
  }

  setCheckResposta(event: any, pergunta: VotacaoPerguntas, resposta: VotacaoPerguntaResposta) {
    if (!pergunta.multiplaEscolha) {
      for (const r of pergunta.respostas) {
        r.checked = false
      }
    }

    resposta.checked = event.target.checked
  }

  obterVotacao(idVotacao: number) {
    fetch(environment.backofficeUrlV2 + "/votacao/obter/" + idVotacao, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      res.json().then(json => {
        const v = json.resultado

        this.votacao = v
      })
    })
  }
}
