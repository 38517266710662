
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class AdesaoCodigoEticaService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  aceitarCodigoEtica(codigoPessoa: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/aceitar-codigo-etica?codigoPessoa=${codigoPessoa}`,
      codigoPessoa,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterEditalManual() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-texto-codigo-etica`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterCodigoEticaPessoa(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-inscricao/obter-codigo-etica-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

}
