<div class="container">
  <!-- LOGIN -->
  <form *ngIf="!esqueciSenha"
        class="containerLogin_formulario row no-gutters d-flex justify-content-center align-items-center mt-5 mb-5"
        name="loginForm"
        [formGroup]="loginForm"
        (ngSubmit)="submit(loginForm)">
    <div class="col-md-4 col-sm-6">
      <div class="card m-3 px-3">
        <div class="card-body">
          <div class="form-row">
            <div class="col-12 d-flex justify-content-center mt-3">
              <img class="logoCliente"
                   src="../../../../../assets/img/logos/logo-planejar.svg" />
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <h1 class="containerLogin_formulario_titulo">Seja bem-vindo</h1>
            </div>
            <div></div>
            <div class="form-group col-12 mt-4">
              <label class="sr-only"
                     for="login">Login</label>
              <input #loginInput
                     autofocus
                     class="form-control form-control-sm"
                     [class.is-invalid]="login.invalid && login.touched"
                     formControlName="login"
                     id="login"
                     name="login"
                     placeholder="CPF ou e-mail"
                     type="text" />
              <div *ngIf="login.invalid && login.touched">
                <span class="erro"
                      *ngIf="login.errors.required">Campo obrigatório</span>
              </div>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="form-group col-12">
              <label class="sr-only"
                     for="senha">Senha</label>
              <input class="form-control form-control-sm"
                     [class.is-invalid]="senha.invalid && senha.touched"
                     formControlName="senha"
                     id="senha"
                     name="senha"
                     placeholder="Senha"
                     type="password" />
              <div *ngIf="senha.invalid && senha.touched">
                <span class="erro"
                      *ngIf="senha.errors.required">Campo obrigatório</span>
                <span class="erro"
                      *ngIf="senha.errors.minlength">A senha deve conter ao menos 6 caracteres.</span>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-around mt-2">
            <label
                   class="check-task custom-control custom-checkbox d-flex justify-content-center mb-0 cursor-pointer col-md-6">
              <input type="checkbox"
                     class="custom-control-input mr-2"
                     formControlName="manterConectado" />
              <span class="custom-control-label pt-1"></span> Manter-me
              conectado</label><a class="containerLogin_formulario_link ml-auto col-md-6"
               (click)="esqueciSenha = true">
              Esqueceu a senha?
            </a>
          </div>
          <div class="form-row mt-4">
            <div class="form-group col-12">
              <button class="btn btn-block btn-primary"
                      title="Acessar"
                      type="submit"
                      id="btnAcessar">
                Acessar
              </button>
            </div>
          </div>
          <div class="row">





            <div class="col-md-6 ml-auto sem-conta"
                 style="padding-left: 5%;">
              <div class="info1">

                <a id="info2"
                   href="https://planejar.org.br/wp-content/uploads/2021/04/Tutorial_primeiro_acesso_area_logada_novo.docx">

                  <img class="info3"
                       src="../../../../../assets/img/icons/info.svg">

                </a>

              </div>Está com problemas para recuperar sua senha ou ainda não possui conta?
            </div>
            <div class="col-md-6">
              <a routerLink="/auto-cadastro/false"
                 class="btn btn-block btn-primary"
                 title="Acessar"
                 id="btnAcessar">
                Cadastre-se
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- ESQUECI MINHA SENHA -->
  <ng-container *ngIf="esqueciSenha">
    <form class="containerLogin_formulario row no-gutters d-flex justify-content-center align-items-center mt-5 mb-5"
          name="esqueciSenhaForm"
          [formGroup]="esqueciSenhaForm"
          (ngSubmit)="recuperarSenha(esqueciSenhaForm)">
      <div class="col-md-5 col-sm-6">
        <div class="card m-3 px-3">
          <div class="card-body">
            <div class="form-row text-center">
              <div class="col-12 d-flex justify-content-center mt-3">
                <img class="logoCliente"
                     src="../../../../../assets/img/logos/logo-planejar.svg" />
              </div>
              <div class="col-12 d-flex justify-content-center mt-4">
                <h1 class="containerLogin_formulario_titulo">
                  Recuperar senha
                </h1>
              </div>
              <p>
                Onde você gostaria de receber o link para recuperar a senha?
              </p>
              <div class="form-group col-12 mt-4">
                <input autofocus
                       class="form-control form-control-sm"
                       [class.is-invalid]="
                    (emailPrimario.invalid && emailPrimario.touched) ||
                    esqueciSenhaMsg.type == 'error'
                  "
                       formControlName="emailPrimario"
                       id="emailPrimario"
                       name="emailPrimario"
                       (keyup)="mudaCamposRecuperacao()"
                       placeholder="E-mail primário cadastrado (digite e-mail)"
                       type="email" />
                <div *ngIf="emailPrimario.invalid && emailPrimario.touched">
                  <span class="erro"
                        *ngIf="emailPrimario.errors.required">Campo obrigatório</span>
                  <span class="erro"
                        *ngIf="emailPrimario.errors.pattern">E-mail inválido</span>
                </div>
                <div *ngIf="
                    emailPrimario.invalid == false ||
                    emailPrimario.touched == false
                  ">
                  <span class="erro"
                        *ngIf="esqueciSenhaMsg.type == 'error'">Usuário não encontrado</span>
                </div>
              </div>
              ou
              <div class="form-group col-12 mt-4">
                <input autofocus
                       class="form-control form-control-sm"
                       [class.is-invalid]="
                    (emailSecundario.invalid && emailSecundario.touched) ||
                    esqueciSenhaMsg.type == 'error'
                  "
                       formControlName="emailSecundario"
                       id="emailSecundario"
                       name="emailSecundario"
                       (keyup)="mudaCamposRecuperacao()"
                       placeholder="E-mail secundário cadastrado (digite e-mail)"
                       type="email" />
                <div *ngIf="emailSecundario.invalid && emailSecundario.touched">
                  <span class="erro"
                        *ngIf="emailSecundario.errors.required">Campo obrigatório</span>
                  <span class="erro"
                        *ngIf="emailSecundario.errors.pattern">E-mail inválido</span>
                </div>
                <div *ngIf="
                    emailSecundario.invalid == false ||
                    emailSecundario.touched == false
                  ">
                  <span class="erro"
                        *ngIf="esqueciSenhaMsg.type == 'error'">Usuário não encontrado</span>
                </div>
              </div>
            
              <!-- <div class="form-group col-12 mt-4">
                <input autofocus
                       class="form-control form-control-sm"
                       [class.is-invalid]="
                    (celular.invalid && celular.touched) ||
                    esqueciSenhaMsg.type == 'error'
                  "
                       formControlName="celular"
                       id="celular"
                       name="celular"
                       (keyup)="mudaCamposRecuperacao()"
                       mask="(00) 00000-0000"
                       placeholder="Seu n° cadastrado para SMS"
                       type="text" />
                <div *ngIf="celular.invalid && celular.touched">
                  <span class="erro"
                        *ngIf="celular.errors.required">Campo obrigatório</span>
                  <span class="erro"
                        *ngIf="celular.errors.minlength">Celular inválido</span>
                </div>
                <div *ngIf="celular.invalid == false || celular.touched == false">
                  <span class="erro"
                        *ngIf="esqueciSenhaMsg.type == 'error'">Usuário não encontrado</span>
                </div>
              </div> -->
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <button class="btn btn-sm btn-secondary"
                      (click)="esqueciSenha = false; limpaEsqueciSenha()"
                      title="voltar">
                Voltar
              </button>
              <button class="btn btn-sm btn-primary ml-2"
                      type="button"
                      (click)="recuperarSenha(esqueciSenhaForm)"
                      title="recuperar">
                Recuperar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>