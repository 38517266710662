import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  widgets = [
    {
      icon: 'cube',
      title: 'Research',
      desc: 'Atero voluptatum ptatum eos et accusamus et iusto',
    },
    {
      icon: 'leaf',
      title: 'Engage',
      desc: 'At vero eos et accusamus et iusto odio dignissimos ducimus',
    },
    {
      icon: 'thumbs-up',
      title: 'Commitment',
      desc: 'Ptatum eos et accusamus et iusto odio dignissimos ducimus',
    },
    {
      icon: 'rocket',
      title: 'Innovation',
      desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
    },
  ];

  mainNavs = [
    {
      name: 'Home',
      description: 'All Variations',
      subnavs: [{ name: 'Sub 1' }, { name: 'Sub 2' }, { name: 'Sub 3' }],
    },
    {
      name: 'Events',
      description: 'Our Activities',
      subnavs: [{ name: 'Sub 1' }, { name: 'Sub 2' }, { name: 'Sub 3' }],
    },
    {
      name: 'Courses',
      description: 'Research & Study',
      subnavs: [{ name: 'Sub 1' }, { name: 'Sub 2' }, { name: 'Sub 3' }],
    },
    {
      name: 'Pages',
      description: 'Designed Pages',
      subnavs: [{ name: 'Sub 1' }, { name: 'Sub 2' }, { name: 'Sub 3' }],
    },
    {
      name: 'Shortcodes',
      description: 'Simple & Useful',
      subnavs: [{ name: 'Sub 1' }, { name: 'Sub 2' }, { name: 'Sub 3' }],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
