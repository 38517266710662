
<form name="solicitacaoForm"
      id="solicitacaoForm"
      class="mt-3"
      [formGroup]="solicitacaoForm">
      <fieldset class="border p-3 my-3">
        <legend class="legendFormulario">Dados Pessoais</legend>

        <div class="row">
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="nome">Nome</label>
                  <input class="form-control form-control-sm"
                  formControlName="nome"
                  id="nome"
                  name="nome"
                  type="text"
                  [(ngModel)]="pessoa['nome']" disabled />
          </div>
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="localizacao">Localização:</label>
            <input class="form-control form-control-sm"
                  formControlName="localizacao"
                  id="localizacao"
                  name="localizacao"
                  type="text"
                  [(ngModel)]="cidade_uf" disabled />
          </div>
        </div>

        <div class="row" *ngif="false">
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="email">E-mail</label>
                  <input class="form-control form-control-sm"
                  formControlName="email"
                  id="email"
                  name="email"
                  type="text"
                  [(ngModel)]="pessoa['email']" disabled />
          </div>
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="email">Telefone Celular</label>
                  <input class="form-control form-control-sm"
                  formControlName="telefoneCelular"
                  id="telefoneCelular"
                  name="telefoneCelular"
                  type="text"
                  [(ngModel)]="telefoneCelular" disabled />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="tipoAtendimentoSupervisao">Tipo Atendimento</label>
                  <input class="form-control form-control-sm"
                  formControlName="tipoAtendimentoSupervisao"
                  id="tipoAtendimentoSupervisao"
                  name="tipoAtendimentoSupervisao"
                  type="text"
                  [(ngModel)]="descricaoTipoAtendimentoSupervisao" disabled />
          </div>
          <div class="form-group col-md-6">
            <label class="texto--sm"
                  for="tipoAtendimentoSupervisao">Data da Certificação</label>
                  <input class="form-control form-control-sm"
                  formControlName="dataCertificado"
                  id="dataCertificado"
                  name="dataCertificado"
                  type="text"
                  [(ngModel)]="dataCertificacao" disabled />
          </div>
        </div>
        <section>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="texto--sm" for="apresentacaoSupervisao">Apresentação</label>
                <div id="apresentacaoSupervisao">
                    <textarea rows="6" cols="125" class="textArea" id="apresentacaoSupervisao" name="apresentacaoSupervisao" formControlName="apresentacaoSupervisao" class="largura" disabled></textarea>
                </div>
            </div>

            <div class="form-group col-md-12">
              <label class="texto--sm" for="especialidades">Especialidades</label>
                <div id="especialidades">
                    <textarea rows="3" cols="125" class="textArea" id="especialidades" name="especialidades" formControlName="especialidades" class="largura" disabled></textarea>               </div>
            </div>
          </div>
        </section>
     </fieldset>
</form>
<div class="row">
<div class="col-md-12">
 <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" (click)="DefinirSupervisor()" >
  Quero enviar uma solicitação de Supervisão para este Profissional
  </button>
  <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button" onclick="javascript:history.go(-1);">
    Voltar
  </button>
</div>
</div>


