import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EducacaoService } from 'src/app/services/educacao/educacao.service';
import { ExperienciaProfissionalService } from 'src/app/services/experiencia-profissional/experiencia-profissional.service';

import Swal from 'sweetalert2';
import * as moment from 'moment';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';

@Component({
  selector: 'app-solicitacao-supervisor',
  templateUrl: './solicitacao-supervisor.component.html',
  styleUrls: ['./solicitacao-supervisor.component.scss']
})
export class SolicitacaoSupervisorComponent implements OnInit {
  id: any;
  solicitacaoForm: FormGroup;
  imagem = null;
  conhecimentos = [];
  educacoes =[];

  idPessoaSupervisionada: number;
  codigoSolicitacaoSupervisor: number;
  pessoa: [];
  endereco: [];
  experiencias = [];
  cargos = [];
  cidade_uf : string;
  descricaoTipoAtendimentoSupervisao: string;

  habilitarSupervisor: boolean = false;
  isPerfilSupervisor : boolean = false;

  areasSelecionadas: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private serviceEducacao : EducacaoService,
    private experienciaProfissionalService : ExperienciaProfissionalService,
    private pessoaService : PessoaService,
    private experienciaSupervisionadaService : ExperienciaSupervisionadaService)
    {
      this.id =  JSON.parse(localStorage.getItem('userId'));
    }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {

      if (params.get("idPessoa") === 'habilitacao')
        this.habilitarSupervisor = true;

      this.init();

      if(this.habilitarSupervisor)
      {
        this.verificarCredenciavel(this.id);
        this.obterPessoa(this.id);
        this.carregaEducacao(this.id);
        this.carregaExperiencias(this.id);
      }
      else
      {
        this.idPessoaSupervisionada = Number(params.get("idPessoa"));

        this.verificarSupervisorValido(this.idPessoaSupervisionada);
        this.obterPessoa(this.idPessoaSupervisionada);
        this.carregaEducacao(this.idPessoaSupervisionada);
        this.carregaExperiencias(this.idPessoaSupervisionada);

      }

      this.carregaCargos();
      this.carregaAreaAtuacaoConhecimento();

      });
  }

  selecionarArea(id) {

    if (this.areasSelecionadas?.some((c) => c == id) ?? false) {
      var index = this.areasSelecionadas.indexOf(id);
      this.areasSelecionadas.splice(index, 1);
      return;
    }
    if (!this.areasSelecionadas) {
      this.areasSelecionadas = [id];
      return;
    }

    this.areasSelecionadas.push(id);
  }

  verificarCredenciavel(idPessoa)
  {
    this.pessoaService.obterAcessosPessoa(idPessoa)
      .subscribe((resp)=>{
        if(!resp.resultado?.some(d=>d.key == 'home/false/true/credenciamento-supervisor'))
          this.router.navigate(['/home']);
      });
  }

  salvar()
  {


    if(!this.isPerfilSupervisor)
    {
      Swal.fire(
        'Solicitação de Supervisor',
        'Opção de habilitar perfil supervisor desabilitado!',
        'error'
        ).then(() => {
        });

        return;
    }

    if(this.apresentacaoSupervisionado.value === '' || this.apresentacaoSupervisionado.value === null || this.apresentacaoSupervisionado.value === undefined)
    {
      Swal.fire(
        'Solicitação de Supervisor',
        'Apresentação não preenchida!',
        'error'
        ).then(() => {
        });

        return;
    }

    if(this.tipoAtendimentoSupervisao.value === '' || this.tipoAtendimentoSupervisao.value === null || this.tipoAtendimentoSupervisao.value === undefined)
    {
      Swal.fire(
        'Solicitação de Supervisor',
        'Tipo de Atendimento não informado!',
        'error'
        ).then(() => {
        });

        return;
    }

    if(this.areasSelecionadas === undefined || this.areasSelecionadas === null || this.areasSelecionadas.length === 0)
    {
      Swal.fire(
        'Solicitação de Supervisor',
        'Áreas de conhecimento/atuação não selecionados!',
        'error'
        ).then(() => {
        });

        return;
    }

    Swal.fire({
      title: 'Habilitar Perfil como Supervisor da Experiência Supervisionada',
      text: "Confirma a habilitação desta funcionalidade?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {

          this.experienciaSupervisionadaService
          .habilitarSupervisor(this.id, this.isPerfilSupervisor, this.apresentacaoSupervisionado.value, this.tipoAtendimentoSupervisao.value, this.areasSelecionadas)
            .subscribe((resp)=>{
              Swal.fire(
                'Solicitação de Supervisor',
                'Solicitação de Supervisor salvo com sucesso!',
                'success'
                ).then(() => {
                  history.go(-1);
                });

      }, (erro) => {
        Swal.fire(
          'Relatório',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
      }
    });

  }

  aprovar()
  {

    Swal.fire({
      title: 'Aprovação da Solicitação de Supervisão',
      text: "Confirma a aprovação desta solicitação?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.experienciaSupervisionadaService
        .alterarSituacaoSolicitacao(this.idPessoaSupervisionada, this.id, 2, null, null)
        .subscribe((resp)=>{
          Swal.fire(
            'Solicitação de Supervisor',
            'Solicitação de Supervisor aprovado com sucesso!',
            'success'
          ).then(() => {
            history.go(-1);
          });
      }, (erro) => {
        Swal.fire(
          'Relatório',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
      }
    });

  }

  recusar()
  {

    Swal.fire({
      title: 'Recusa da Solicitação de Supervisão',
      text: "Confirma a recusa desta solicitação?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.experienciaSupervisionadaService
        .alterarSituacaoSolicitacao(this.idPessoaSupervisionada, this.id, 3, null, null)
        .subscribe((resp)=>{
          Swal.fire(
            'Solicitação de Supervisor',
            'Solicitação de Supervisor recusado com sucesso!',
            'success'
          ).then(() => {
            history.go(-1);
          });
      }, (erro) => {
        Swal.fire(
          'Relatório',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
      }

    });

  }

  obterPessoa(idPessoa)
  {
    this.pessoaService.obterPorId(idPessoa).subscribe((resp)=>{
      this.pessoa = resp.resultado;
      this.endereco = resp.resultado.pessoaEndereco[0];
      this.cidade_uf = this. endereco['cidade'] + '/' + this.endereco['estado'];
      if(!this.habilitarSupervisor)
        this.descricaoTipoAtendimentoSupervisao = (resp.resultado.tipoAtendimentoSupervisao === null ? 'Não informado' : resp.resultado.tipoAtendimentoSupervisao === 1 ? 'Presencial' : resp.resultado.tipoAtendimentoSupervisao === 2 ? 'À distância' : 'Híbrido');
      else
        this.tipoAtendimentoSupervisao.setValue(resp.resultado.tipoAtendimentoSupervisao);

      if(!this.habilitarSupervisor)
        this.apresentacaoSupervisionado.setValue(this.pessoa['apresentacaoSupervisionado']);
      else
        this.apresentacaoSupervisionado.setValue(this.pessoa['apresentacaoSupervisao']);

      if(this.pessoa['dataCertificacao'] !== null)
        this.pessoa['dataCertificacao'] = moment(this.pessoa['dataCertificacao']).format('DD/MM/YYYY');

      if(this.pessoa['perfilSupervisor'] !== null)
        this.isPerfilSupervisor = this.pessoa['perfilSupervisor'];
    });
  }

  carregaCargos() {
    this.experienciaProfissionalService.obterCargo().subscribe((resp) => {
      this.cargos = resp.resultado;

    }, (erro) => {
      Swal.fire(
        'Experiência Profissional ',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  carregaExperiencias(idPessoa) {
    this.experienciaProfissionalService.obterExperiencia(idPessoa).subscribe((resp) => {
      this.experiencias = resp.resultado;
      this.experiencias.forEach(el => {

        if (el.descricaoTrilhaItemSituacao == 'Reprovado' || el.descricaoTrilhaItemSituacao == 'Aprovado')
          el.habilitaEditarExcluir = false;

        if(el.fim == undefined)
          el.fim = 'Atual';
        else
          el.fim =  moment(el.fim).format('DD/MM/YYYY');

        this.cargos.forEach(ele => {
          if (el.idCargo === ele.idCargo) {
            el.descricaoCargo = ele.descricao;
          }
        })
      })

    }, (erro) => {
      Swal.fire(
        'Experiência Profissional',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  get nome() {
    return this.solicitacaoForm.get('nome');
  }
  get localizacao() {
    return this.solicitacaoForm.get('localizacao');
  }
  get email() {
    return this.solicitacaoForm.get('email');
  }
  get telefoneCelular() {
    return this.solicitacaoForm.get('telefoneCelular');
  }
  get tipoAtendimentoSupervisao() {
    return this.solicitacaoForm.get('tipoAtendimentoSupervisao');
  }
  get apresentacaoSupervisionado() {
    return this.solicitacaoForm.get('apresentacaoSupervisionado');
  }
  get dataCertificacao() {
    return this.solicitacaoForm.get('dataCertificacao');
  }
  get perfilSupervisor() {
    return this.solicitacaoForm.get('perfilSupervisor');
  }



  init(){
      this.solicitacaoForm = this.formBuilder.group({
      nome:[''],
      localizacao:[''],
      email:[''],
      tipoAtendimentoSupervisao:[''],
      telefoneCelular:[''],
      apresentacaoSupervisionado:[{value: '', disabled: !this.habilitarSupervisor }],
      dataCertificacao: [''],
      perfilSupervisor: ['']
    });
  }

  carregaAreaAtuacaoConhecimento(){
    this.pessoaService.obterAreas().subscribe((resp)=>{
         resp.resultado.map(item=>{
            item.checked = false;
      });
      this.conhecimentos = resp.resultado;
      this.preencherAreasSelecionadas();
    },(erro)=>{
      Swal.fire(
        'Educação ',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }

  verificarSupervisorValido(idPessoa) {
    this.experienciaSupervisionadaService
          .obterSolicitacoesSupervisoresPorSupervisor(this.id)
            .subscribe((resp) => {
              if(!resp.resultado?.some(d=>d.idPessoa === idPessoa))
                this.router.navigate(['/home']);
              else
              {
                this.codigoSolicitacaoSupervisor = resp.resultado.filter(d=> d.idPessoa === idPessoa)[0].idSolicitacaoSupervisor;
              }
            });
  }

  carregaEducacao(idPessoa){
    this.serviceEducacao.obterEducacao(Number(idPessoa)).subscribe((resp)=>{
      this.educacoes = resp.resultado;
      this.educacoes.map(ed => {
        ed.habilitaEditarExcluir = true;

        if (ed.descricaoTrilhaItemSituacao == 'Reprovado' || ed.descricaoTrilhaItemSituacao == 'Aprovado'){
          ed.habilitaEditarExcluir = false;
        }
      });
    },(erro)=>{
      Swal.fire(
        'Educação',
        erro.error.excecao[0].mensagem,
        'error'
      );
    });
  }


  preencherAreasSelecionadas(){

    let selecionados = [];
console.log(this.habilitarSupervisor);
    if(this.habilitarSupervisor)
    {
      this.pessoaService
          .obterAreasPorPessoa(this.id)
            .subscribe((resp)=>{

                selecionados = resp.resultado;

                this.conhecimentos.forEach(item =>{
                  item.checked = false;

                  selecionados.forEach(el =>{

                    if(item.idAreaSupervisao === el.idAreaSupervisao){

                      item.checked = true;
                      this.selecionarArea(item.idAreaSupervisao);
                    }
                  })

                  item.isDisabled = false;
                  if(item.idAreaSupervisao === 1){
                    item.checked = true;
                    item.isDisabled = true;;
                    this.selecionarArea(item.idAreaSupervisao);
                    console.log(item);
                  }
                });



            })
    }
    else
    {
      this.experienciaSupervisionadaService
          .obterSolicitacaoSupervisor(this.codigoSolicitacaoSupervisor)
            .subscribe((resp)=>{
              selecionados = resp.resultado.areas;

              this.conhecimentos.forEach(item =>{
                item.checked = false;
                selecionados.forEach(el =>{
                  if(item.idAreaSupervisao === el.idAreaSupervisao){
                    item.checked = true;
                  }
                })
              });
            })
    }

    //  this.conhecimentos.forEach(c => {
    //             c.isDisabled = false;
    //             if(c.idAreaSupervisao === 1){
    //               c.checked = true;
    //               c.isDisabled = true;
    //               this.selecionarArea(c.idAreaSupervisao);
    //               console.log(c);
    //             }
    //           })

  }

}
