<mat-card class="button-container">
  <button mat-raised-button color="primary" (click)="verVotacao()">
    Ver Votações
  </button>
</mat-card>

<div class="container">
  <div>
    <h3>Editar/Remover Votações</h3>
    <ul>
      <li class="pergunta" *ngFor="let pergunta of perguntasVotacao">
        {{ pergunta.pergunta }}
        <button mat-icon-button (click)="editarPergunta(pergunta)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="removerPergunta(pergunta)">
          <mat-icon>delete</mat-icon>
        </button>
      </li>
    </ul>
  </div>

  <div class="editar-pergunta" *ngIf="perguntaEditando">
    <h3>Editar Pergunta</h3>
    <form>
      <mat-form-field>
        <input matInput [(ngModel)]="tituloVotacaoEditando" name="tituloVotacao" placeholder="Título da votação" />
      </mat-form-field>

      <mat-form-field>
        <textarea matInput [(ngModel)]="descricaoVotacaoEditando" name="descricaoVotacao" placeholder="Descrição da votação"></textarea>
      </mat-form-field>

      <h4>Pergunta</h4>
      <mat-form-field>
        <input matInput [(ngModel)]="textoPerguntaEditando" name="textoPergunta" placeholder="Texto da pergunta" />
      </mat-form-field>

      <h4>Tipo de Votação</h4>
      <mat-radio-group [(ngModel)]="tipoVotacaoEditando" name="tipoVotacao">
        <mat-radio-button value="multipla">Múltipla Escolha</mat-radio-button>
        <mat-radio-button value="unico">Voto Único</mat-radio-button>
      </mat-radio-group>
  
      <h4>Respostas</h4>
      <div *ngFor="let resposta of respostasEditando; let i = index">
        <mat-form-field>
          <input matInput [(ngModel)]="respostasEditando[i].resposta" name="resposta{{ i }}" placeholder="Resposta" />
        </mat-form-field>
        <!-- Exibir checkboxes apenas se for múltipla escolha -->
        <div *ngIf="tipoVotacaoEditando === 'multipla'">
          <mat-checkbox [(ngModel)]="respostasEditando[i].selecionada" name="checkbox{{ i }}">Selecionada</mat-checkbox>
        </div>
        <button mat-icon-button (click)="removerResposta(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <div class="button-container">
        <button mat-raised-button color="primary" (click)="adicionarResposta()">Adicionar Resposta</button>
        <button mat-raised-button color="primary" (click)="salvarEdicaoPergunta()">Salvar</button>
        <button mat-raised-button color="warn" (click)="cancelarEdicaoPergunta()">Cancelar</button>
      </div>
    </form>
  </div>
</div>
