import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DebitosService } from 'src/app/services/debitos/debitos.service';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';

@Component({
  selector: 'app-lista-solicitacao-supervisao',
  templateUrl: './lista-solicitacao-supervisao.component.html',
  styleUrls: ['./lista-solicitacao-supervisao.component.scss']
})
export class ListaSolicitacaoSupervisaoComponent implements OnInit {
  view = false;
  imagem = null;
  id = null;
  solicitacoes = null;

  constructor(private router: Router,
    private route: ActivatedRoute, private experienciaSupervisionadaService: ExperienciaSupervisionadaService) { }

  ngOnInit(): void {
    this.id = window.localStorage.getItem('userId');

    this.obterSolicitacoes();
  }

  obterSolicitacoes() {
    this.experienciaSupervisionadaService.obterSolicitacoesSupervisoresPorSupervisionado(this.id).subscribe((result) => {
      this.solicitacoes = result.resultado;
    });
  }

}
