import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PessoaService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    let token = localStorage.getItem('tknCdst');


    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterBaseConhecimento(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-acesso-colaborativa`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa,
        },
      }
    );
  }

  obterEditalManual() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-credito/obter-texto-instrucoes-creditos`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterEditalPrivacidade() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-declaracao-privacidade`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterTermoAceiteCadastro() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-termo-aceite-cadastro`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterTermoSolicitacaoSupervisor() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-termo-solicitacao-supervisor`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterTodos() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-todos`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterPorId(id: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-por-id`,
      {
        headers: this.httpHeaders,
        params: { id },
      }
    );
  }

  atualizarPessoa(pessoa: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/alterar`,
      pessoa,
      {
        headers: this.httpHeaders,
      }
    );
  }

  excluir(id: any) {
    return this.http.delete(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/desativar-id?id=${id}`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterGeneros() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-generos`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterEstados() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-estado`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterPais() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-pais`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterNacionalidade() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-nacionalidade`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterOrgaoEmissor() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-orgao-emissor`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterEstadoCivil() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-estado-civil`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterRacas() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-cor-pele`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterDeficiencias() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-deficiencias`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarPessoa(pessoa: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/criar`,
      pessoa,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterPorPalavra(palavraChave: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-palavra-chave`,
      {
        headers: this.httpHeaders,
        params: {
          palavraChave: palavraChave,
        },
      }
    );
  }

  obterPorCep(cep: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-endereco-cep`,
      {
        headers: this.httpHeaders,
        params: {
          cep: cep,
        },
      }
    );
  }

  obterAcessosPessoa(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/area-logada-auto-cadastro/obter-acessos-pessoa`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa,
        },
      }
    );
  }

  possuiAcessoPlanoFinanceiro(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/area-logada-auto-cadastro/possui-acesso-plano-financeiro`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa,
        },
      }
    );
  }

  obterCertificado(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-certificado`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa,
        },
      }
    );
  }

  obterCertificadoTreina(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-certificado-treina`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa,
        },
      }
    );
  }

  obterCertificadoPessoa(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-certificado-pessoa`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa,
        },
      }
    );
  }

  obterCertificadoPessoaTreina(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-certificado-pessoa-treina`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa,
        },
      }
    );
  }

  obterAreas()
  {
    return this.http.get<any>(
      `${environment.backofficeUrl}/pessoa/obter-areas-supervisao`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterAreasPorPessoa(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/pessoa/obter-areas-supervisao-pessoa`,
      {
        headers: this.httpHeaders,
        params: {
          codigoPessoa: codigoPessoa
        },
      }
    );
  }
  obterAreasSupervisao(){
    return this.http.get<any>(
      `${environment.backofficeUrl}/pessoa/obter-areas-supervisao`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  aceiteSupervisor(id: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/pessoa/aceite-supervisor?codigoPessoa=${id}`,
      id,
      {
        headers: this.httpHeaders,
      }
    );
  }

}
