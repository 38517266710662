import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, pluck, shareReplay } from 'rxjs/operators';
import {
  GravarComentariosModel,
  GravarRespostasModel,
  PerguntasRespostasModel,
} from 'src/app/pages/relatorio-evidencias/models/perguntas-respostas.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExperienciaSupervisionadaService {
  httpHeaders: HttpHeaders;
  S;

  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  criarExperienciaSupervisionada(experiencia: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-supervisionada/criar-experiencia`,
      experiencia,
      {
        headers: this.httpHeaders,
      }
    );
  }

  alterarExperiencia(pessoa: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-supervisionada/alterar-experiencia`,
      pessoa,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterExperiencia(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-supervisionada/obter-experiencia`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterSupervisor(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-supervisionada/obter-supervisor`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterTrilhaExperienciaPorSupervisionado(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-supervisionada/obter-experiencia-por-supervisionado`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterExperienciasPorSupervisor(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-experiencias-por-supervisor`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterExperienciasPorSupervisionado(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-experiencias-por-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  //lista somente registros de idetapa = supervisor
  obterSolicitacoesSupervisoresPorSupervisionado(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-solicitacoes-supervisor-por-supervisionado`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  //lista somente registros de idetapa = supervisor
  obterSolicitacoesSupervisoresPorSupervisor(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-solicitacoes-supervisor-por-supervisor`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterAcordoSupervisaoPorSupervisionado(codigoPessoa: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-acordo-supervisao-por-pessoa`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa },
      }
    );
  }

  obterRelatorioPorSupervisionadoPeriodo(
    codigoPessoa: any,
    codigoPeriodo: any
  ) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-relatorio-por-pessoa-periodo`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, codigoPeriodo },
      }
    );
  }

  /*obterRelatorioPorSupervisorPeriodo(codigoPessoa: any, codigoPeriodo: any)
  {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-relatorio-por-supervisor-periodo`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, codigoPeriodo },
      }
    );
  }*/

  obterAnexos(codigoExperienciaSupervisionada: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-anexos-relatorio`,
      {
        headers: this.httpHeaders,
        params: { codigoExperienciaSupervisionada },
      }
    );
  }

  obterArquivo(codigoExperienciaSupervisionadaAnexo: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/arquivo`,
      {
        responseType: 'arraybuffer' as 'json',
        headers: this.httpHeaders,
        params: { codigoExperienciaSupervisionadaAnexo },
      }
    );
  }

  obterArquivos(codigoExperienciaSupervisionada: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/arquivos`,
      {
        responseType: 'arraybuffer' as 'json',
        headers: this.httpHeaders,
        params: { codigoExperienciaSupervisionada },
      }
    );
  }

  obterSolicitacaoSupervisor(codigoSolicitacaoSupervisor: any) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-solicitacao-supervisor`,
      {
        headers: this.httpHeaders,
        params: { codigoSolicitacaoSupervisor },
      }
    );
  }

  obterTextoAcordoSupervisao(codigoPessoa, tipoPerfil) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-texto-acordo-supervisao`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, tipoPerfil },
      }
    );
  }

  obterTextoRelatorio(codigoPessoa, tipoPerfil, codigoPeriodo) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-texto-relatorio`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, tipoPerfil, codigoPeriodo },
      }
    );
  }

  aceitarTextoAcordoSupervisao(codigoPessoa, tipoPerfil) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/aceitar-texto-acordo-supervisao`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, tipoPerfil },
      }
    );
  }

  aceitarTextoRelatorio(codigoPessoa, tipoPerfil, codigoPeriodo) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/aceitar-texto-relatorio`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, tipoPerfil, codigoPeriodo },
      }
    );
  }

  obterAceiteAcordoSupervisao(codigoPessoa, tipoPerfil) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-aceite-acordo-supervisao`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, tipoPerfil },
      }
    );
  }

  obterAceiteRelatorio(codigoPessoa, tipoPerfil, codigoPeriodo) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-aceite-relatorio`,
      {
        headers: this.httpHeaders,
        params: { codigoPessoa, tipoPerfil, codigoPeriodo },
      }
    );
  }

  removerAnexo(codigoExperienciaSupervisionadaAnexo) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/remover-anexo`,
      {
        headers: this.httpHeaders,
        params: { codigoExperienciaSupervisionadaAnexo },
      }
    );
  }

  alterarSituacao(codigoExperienciaSupervisionada, codigoSituacao) {
    let request = {
      idExperienciaSupervisionada: codigoExperienciaSupervisionada,
      idSituacao: codigoSituacao,
      isRelatorio: false,
    };

    return this.http.put<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/alterar-situacao-experiencia-supervisionada`,
      request,
      {
        headers: this.httpHeaders,
      }
    );
  }

  alterarSituacaoSolicitacao(
    codigoPessoa: number,
    codigoPessoaSupervisor: number,
    codigoSituacao: number,
    apresentacaoSupervisionado: string,
    areas: number[]
  ) {
    let request = {
      idPessoa: codigoPessoa,
      idPessoaSupervisor: codigoPessoaSupervisor,
      idSituacao: codigoSituacao,
      apresentacaoSupervisionado: apresentacaoSupervisionado,
      areas: areas,
    };

    return this.http.put<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/alterar-situacao-solicitacao-supervisor`,
      request,
      {
        headers: this.httpHeaders,
      }
    );
  }

  importarArquivo(request) {
    return this.http.post<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/incluir-anexo`,
      request,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterCidades(estado: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-inscricao/obter-cidades-por-uf`,
      {
        headers: this.httpHeaders,
        params: { estado },
      }
    );
  }

  obterSupervisorFiltro(
    nome: any,
    tipoAtendimento: any,
    areas: any[],
    uf: any,
    cidade: any
  ) {
    let params = {
      nome: nome,
      tipoAtendimento: tipoAtendimento,
      areas: areas,
      estado: uf,
      cidade: cidade,
    };
    return this.http.post<any>(
      `${environment.backofficeUrl}/pessoa/obter-supervisores-filtro`,
      params,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterSupervisorSemFiltro() {
    return this.http.post<any>(
      `${environment.backofficeUrl}/pessoa/obter-supervisores-sem-filtro`,
      {},
      {
        headers: this.httpHeaders,
      }
    );
  }

  habilitarSupervisor(
    codigoPessoa: number,
    ativar: boolean,
    apresentacaoSupervisao: string,
    tipoAtendimentoSupervisao: number,
    areas: number[]
  ) {
    let request = {
      idPessoa: codigoPessoa,
      ativar: ativar,
      apresentacaoSupervisao: apresentacaoSupervisao,
      tipoAtendimento: tipoAtendimentoSupervisao,
      areas: areas,
    };

    return this.http.put<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/habilitar-perfil-supervisor`,
      request,
      {
        headers: this.httpHeaders,
      }
    );
  }

  cancelarSolicitacaoSupervisor(
    codigoSolicitacaoSupervisor: number,
    motivoCancelamento: string
  ) {
    let request = {
      codigoSolicitacaoSupervisor: codigoSolicitacaoSupervisor,
      motivoCancelamento: motivoCancelamento,
    };

    return this.http.put<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/cancelar-solicitacao-supervisor`,
      request,
      {
        headers: this.httpHeaders,
      }
    );
  }

  cancelarSolicitacaoSupervisionado(
    codigoPessoa: number,
    motivoCancelamento: string
  ) {
    let request = {
      codigoSolicitacaoSupervisor: codigoPessoa,
      motivoCancelamento: motivoCancelamento,
    };

    return this.http.put<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/cancelar-solicitacao-supervisionado`,
      request,
      {
        headers: this.httpHeaders,
      }
    );
  }

  efetuarDesistenciaExperienciaSupervisionada(
    codigoPessoa: number,
    motivoCancelamento: string
  ) {
    let request = {
      codigoSolicitacaoSupervisor: codigoPessoa,
      motivoCancelamento: motivoCancelamento,
    };

    return this.http.put<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/efetuar-desistencia-supervisionado`,
      request,
      {
        headers: this.httpHeaders,
      }
    );
  }

  registrarParecerFinalSupervisor(
    idpessoa,
    parecerFinalDescricao,
    parecerFinalStatus
  ) {
    let request = {
      idPessoa: idpessoa,
      comentarioParecerFinal: parecerFinalDescricao,
      statusParecerFinal: parecerFinalStatus,
    };

    return this.http.post<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/parecer-final-supervisor`,
      request,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterParecerFinalSupervisor(idpessoa) {
    return this.http.get<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-parecer-final-supervisor`,
      {
        headers: this.httpHeaders,
        params: { idpessoa },
      }
    );
  }

  obterPerguntasRespostas(
    codigo: number
  ): Observable<PerguntasRespostasModel[]> {
    const params = new HttpParams().set(
      'codigoExperienciaSupervisionada',
      codigo.toString()
    );
    return this.http
      .get<PerguntasRespostasModel[]>(
        `${environment.backofficeUrlV2}/experiencia-supervisionada/obter-perguntas-opcoes`,
        { params, headers: this.httpHeaders }
      )
      .pipe(pluck('resultado'));
  }

  postGravarRespostas(respostas: GravarRespostasModel[]) {
    return this.http.post(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/gravar-respostas`,
      respostas,
      { headers: this.httpHeaders }
    );
  }

  postGravarComentarios(comentarios: GravarComentariosModel[]) {
    return this.http.post(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/gravar-comentarios`,
      comentarios,
      { headers: this.httpHeaders }
    );
  }

  registrarAceiteRelatorio(idExperienciaSupervisionada: number, isStaffPlanejar: boolean) {
    const requestBody = {
      idExperienciaSupervisionada: idExperienciaSupervisionada,
      isStaffPlanejar: isStaffPlanejar
    };

    return this.http.post<any>(
      `${environment.backofficeUrlV2}/experiencia-supervisionada/gravar-aceite-relatorio`,
      requestBody,
      { headers: this.httpHeaders }
    );
  }
}
