
<div class="row ml-1">
    <div class="col-md-12">
      <div *ngFor="let resposta of respostas; let i = index">
        <div class="col-md-12">
          <label>{{respostas[i].descricao}}?</label>
        </div>
        <div class="col-12 padding-left-14"  *ngFor="let resposta of respostas[i].expectativa">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input "
                    type="checkbox"
                    [name]="resposta.id"
                    id="{{resposta.idExpectativa}}"
                    [(ngModel)]="resposta.checked"
								(change)="selecionado()" >
              <label class="ml-2">{{resposta.descricao}}</label>
            </div>
        </div>
      </div>
   </div>
 </div>
