import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-criar-votacao',
  templateUrl: './criar-votacao.component.html',
  styleUrls: ['./criar-votacao.component.scss']
})
export class CriarVotacaoComponent implements OnInit {
  formTituloDescricao: FormGroup;
  formPerguntaRespostas: FormGroup;
  temVotoNulo: boolean = false;

  constructor(private router: Router, private formBuilder: FormBuilder, private http: HttpClient) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formTituloDescricao = this.formBuilder.group({
      titulo: ['', Validators.required],
      descricao: ['', Validators.required]
    });

    this.formPerguntaRespostas = this.formBuilder.group({
      pergunta: ['', Validators.required],
      tipoVotacao: ['unico', Validators.required],
      adicionarVotoNulo: [false],
      respostas: this.formBuilder.array([this.initResposta()])
    });
  }

  enviarParaAPI() {
    const payload = {
      temVotoNulo: this.temVotoNulo
    };

    this.http.post<any>('http://localhost:4250/perguntasVotacao', payload).subscribe(response => {
      console.log(response);
    });
  }

  initResposta(): FormGroup {
    return this.formBuilder.group({
      resposta: ['', Validators.required],
      selecionada: [false]
    });
  }

  get respostas(): FormArray {
    return this.formPerguntaRespostas.get('respostas') as FormArray;
  }

  addResposta(): void {
    this.respostas.push(this.initResposta());
  }

  removeResposta(index: number): void {
    this.respostas.removeAt(index);
  }

  cancelar(): void {
    this.router.navigate(['/votacao']);
  }

  adicionarPergunta(): void {
    if (this.formTituloDescricao.valid && this.formPerguntaRespostas.valid) {
      const formData = {
        ...this.formTituloDescricao.value,
        ...this.formPerguntaRespostas.value
      };
      this.http.post('http://localhost:4250/perguntasVotacao', formData).subscribe(
        response => {
          console.log('Pergunta adicionada com sucesso:', response);
          alert('Votação Adicionada com Sucesso')
          this.initForm();
          this.router.navigate(['/votacao']);
        },
        error => {
          console.error('Erro ao adicionar pergunta:', error);
        }
      );
    }

  }

}
