<form name="creditoForm"
      id="creditoForm"
      class=""
      [formGroup]="creditoForm"
      (ngSubmit)="submit(creditoForm)">
  <div class="row"
       *ngIf="mode ==='add'">
    <div class="col-md-12">
      <h1>
        Cadastrar Crédito
      </h1>
    </div>
    <div class="col-md-12">
      <label>Período: </label><span> {{datas['inicio']}} a {{datas['fim']}}</span>
    </div>
  </div>
  <div class="row"
       *ngIf="mode ==='edit'">
    <div class="col-md-12">
      <h1>
        Alterar Crédito
      </h1>
    </div>
    <div class="col-md-12">
      <label>Período: </label><span> {{datas['inicio']}} a {{datas['fim']}}</span>
    </div>
  </div>
  <div class="row"
       *ngIf="mode ==='visualizar'">
    <div class="col-md-12">
      <h1>
        Visualizar Crédito
      </h1>
    </div>
    <div class="col-md-12">
      <label>Período: </label><span> {{datas['inicio']}} a {{datas['fim']}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right">
      <div>
        <button class="btn btn-sm btn-primary mr-0 ml-2 botaoModal"
                id="instrucoes"
                (click)="abrirModal()"
                type="button">
          Instruções
        </button>
      </div>
    </div>
  </div>



  <div class="row mt-4">
    <div class="form-group col-md-6"
         *ngIf="mode =='add'">
      <label class="texto--sm"
             for="tipo">Tipo</label>
      <select class="form-control form-control-sm custom-select custom-select-sm"
              formControlName="tipo"
              [class.is-invalid]="
                tipo.invalid && (tipo.touched || isSubmitted)"
              id="tipo"
              (change)="carregaCategoria();"
              name="tipo">
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let tipo of tipos"
                [ngValue]="tipo"
                [selected]=" tipoSelecionado && tipo.idAtividadeTipo == tipoSelecionado ? true : null">
          {{ tipo.descricao }}
        </option>
      </select>
      <div *ngIf="tipo.invalid && (tipo.touched || isSubmitted)">
        <span class="erro"
              *ngIf="tipo.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-6"
         *ngIf="mode!=='add'">
      <label class="texto--sm"
             for="tipoTexte">Tipo</label>
      <input class="form-control form-control-sm"
             formControlName="tipoTexte"
             id="tipoTexte"
             name="tipoTexte"
             type="text" />
    </div>


    <div class="form-group col-md-6"
         *ngIf="mode=='add'">
      <label class="texto--sm"
             for="categoria">Categoria</label>
      <select class="form-control form-control-sm custom-select custom-select-sm"
              formControlName="categoria"
              [class.is-invalid]="
                categoria.invalid && (categoria.touched || isSubmitted)"
              id="categoria"
              (change)="carregaAtividadesEmpresas();"
              name="categoria">
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let categoria of categorias"
                [ngValue]="categoria"
                [selected]=" categoriaSelecionado && categoria.idAtividadeCategoria == categoriaSelecionado ? true : null">
          {{ categoria.descricaoCategoria }}
        </option>
      </select>
      <div *ngIf="categoria.invalid && (categoria.touched || isSubmitted)">
        <span class="erro"
              *ngIf="categoria.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-6"
         *ngIf="mode!=='add'">
      <label class="texto--sm"
             for="categoriaTexte">Categoria</label>
      <input class="form-control form-control-sm"
             formControlName="categoriaTexte"
             id="categoriaTexte"
             name="categoriaTexte"
             type="text" />
    </div>

  </div>
  <div class="row">
    <!-- INSTUTUICAO COMBO -->
    <div class="form-group col-md-4"
         *ngIf="!livre && mode=='add'">
      <label class="texto--sm"
             for="instituicao">Instituição</label>
      <select class="form-control form-control-sm custom-select custom-select-sm"
              formControlName="instituicao"
              [class.is-invalid]="
                instituicao.invalid && (instituicao.touched || isSubmitted)"
              id="instituicao"
              (change)="carregarAtividades();"
              name="instituicao">
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let instituicao of instituicoes"
                value="{{instituicao.idEmpresa}}">
          {{ instituicao.nome }}
        </option>
      </select>
      <div *ngIf="instituicao.invalid && (instituicao.touched || isSubmitted)">
        <span class="erro"
              *ngIf="instituicao.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <!-- INSTUTUICAO TEXTO -->
    <div class="form-group col-md-4"
         *ngIf="livre && mode =='add'">
      <label class="texto--sm"
             for="instituicaoTexte">Instituição</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="
              instituicaoTexte.invalid && (instituicaoTexte.touched || isSubmitted)"
             formControlName="instituicaoTexte"
             id="instituicaoTexte"
             name="instituicaoTexte"
             type="text" />
      <div *ngIf="instituicaoTexte.invalid && (instituicaoTexte.touched || isSubmitted)">
        <span class="erro"
              *ngIf="instituicaoTexte.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-4"
         *ngIf="mode =='edit' || mode =='visualizar'">
      <label class="texto--sm"
             for="instituicaoTexte">Instituição</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="
              instituicaoTexte.invalid && (instituicaoTexte.touched || isSubmitted)"
             formControlName="instituicaoTexte"
             id="instituicaoTexte"
             name="instituicaoTexte"
             type="text" />
      <div *ngIf="instituicaoTexte.invalid && (instituicaoTexte.touched || isSubmitted)">
        <span class="erro"
              *ngIf="instituicaoTexte.errors.required">Campo obrigatório</span>
      </div>
    </div>




    <div class="form-group col-md-4"
         *ngIf="mode=='add'">
      <label class="texto--sm"
             for="atividade">Atividade</label>
      <select class="form-control form-control-sm custom-select custom-select-sm"
              formControlName="atividade"
              [class.is-invalid]="
                atividade.invalid && (atividade.touched || isSubmitted)"
              id="atividade"
              (change)="selecionarAtividade()"
              name="atividade">
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let atividade of atividades"
                [ngValue]="atividade"
                [selected]=" atividadeSelecionado && atividade.idAtividade == atividadeSelecionado ? true : null">
          {{ atividade.titulo }}
        </option>
      </select>
      <div *ngIf="atividade.invalid && (atividade.touched || isSubmitted)">
        <span class="erro"
              *ngIf="atividade.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-4"
         *ngIf="mode!=='add'">
      <label class="texto--sm"
             for="tipoTexte">Atividade</label>
      <input class="form-control form-control-sm"
             formControlName="atividadeTexte"
             id="atividadeTexte"
             name="atividadeTexte"
             type="text" />
    </div>


    <div class="form-group col-md-4">
      <label class="texto--sm"
             for="atividadeComplemento">Descrição da Atividade</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="
             atividadeComplemento.invalid && (atividadeComplemento.touched || isSubmitted)"
             formControlName="atividadeComplemento"
             id="atividadeComplemento"
             name="atividadeComplemento"
             type="text" />
      <div *ngIf="atividadeComplemento.invalid && (atividadeComplemento.touched || isSubmitted)">
        <span class="erro"
              *ngIf="atividadeComplemento.errors.required">Campo obrigatório</span>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="form-group col-md-3">
      <label class="texto--sm"
             for="data">Data</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="data.invalid && (data.touched || isSubmitted)"
             formControlName="data"
             id="data"
             maxlength="100"
             [min]="dataInicio"
             [max]="dataFim"
             name="data"
             type="date" />
      <div *ngIf="
            data.invalid && (data.touched || isSubmitted)
          ">
        <span class="erro"
              *ngIf="data.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-3">
      <label class="texto--sm"
             for="cargaHoraria">Carga Horária</label>
      <input class="form-control form-control-sm"
             (blur)="soma()"
             [class.is-invalid]="
            cargaHoraria.invalid && (cargaHoraria.touched || isSubmitted)
          "
             formControlName="cargaHoraria"
             id="cargaHoraria"
             maxlength="100"
             name="cargaHoraria"
             type="number" />
      <div *ngIf="
            cargaHoraria.invalid && (cargaHoraria.touched || isSubmitted)
          ">
        <span class="erro"
              *ngIf="cargaHoraria.errors.required">Campo obrigatório</span>
        <span class="erro"
              *ngIf="cargaHoraria.errors.min">Valor deve ser maior que 0.</span>
      </div>
    </div>
    <div class="form-group col-md-3">
      <label class="texto--sm"
             for="creditos">Créditos</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="
            creditos.invalid && (creditos.touched || isSubmitted)
          "
             formControlName="creditos"
             id="creditos"
             maxlength="100"
             name="creditos"
             type="text" />
      <div *ngIf="
            creditos.invalid && (creditos.touched || isSubmitted)
          ">
        <span class="erro"
              *ngIf="creditos.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="form-group col-md-3">
      <label class="texto--sm"
             for="creditos">Limite</label>
      <input class="form-control form-control-sm"
             [class.is-invalid]="
            creditos.invalid && (creditos.touched || isSubmitted)
          "
             formControlName="limite"
             id="limite"
             name="limite"
             type="text" />
      <div *ngIf="
            creditos.invalid && (creditos.touched || isSubmitted)
          ">
        <span class="erro"
              *ngIf="creditos.errors.required">Campo obrigatório</span>
      </div>
    </div>

  </div>

  <div class="row"
       *ngIf="!livre">
    <div class="col-12 text-right">
      <div *ngIf="!desabilitarButtons">
        <button class="btn btn-sm btn-primary mr-4 ml-2"
                id="aplicar"
                [disabled]="desabilitarButtons"
                type="submit">
          Aplicar
        </button>
      </div>
    </div>
  </div>


  <div class="row mt-5"
       *ngIf="livre">
    <div class="form-group col-md-12">
      <h4> Tópicos incluídos na atividade</h4>
    </div>
  </div>

  <!-- Topicos incluidos na atividade -->
  <div class="row "
       *ngIf="livre">
    <div class="form-group col-md-6">
      <label class="texto--sm"
             for="topico">Tópico</label>
      <select class="form-control form-control-sm custom-select custom-select-sm"
              formControlName="topico"
              [class.is-invalid]="
                topico.invalid && (topico.touched || isSubmitted)"
              id="topico"
              name="topico">
        <option value=""
                disabled>Selecione</option>
        <option *ngFor="let topico of topicos"
                value="{{topico.idAtividadeTopico}}">
          {{topico.descricaoTopico }}
        </option>
      </select>
      <div *ngIf="topico.invalid && (topico.touched || isSubmitted)">
        <span class="erro"
              *ngIf="topico.errors.required">Campo obrigatório</span>
      </div>
    </div>
    <div class="col-md-3 ">
      <label>&nbsp;</label>
      <button class="btn btn-sm btn-primary mr-0 ml-2 mt-4 botao"
              type="button"
              (click)="mode == 'add' ? submit(creditoForm) : incluirTopico(creditoForm)"
              [disabled]="mode =='visualizar' ? desabilitarButtons: false">Incluir</button>
    </div>
  </div>

  <div class="row  mt-3 mr-0 ml-0 margem-tabela"
       *ngIf="topicosAdicionados.length > 0">
    <table class="table table-bordered table-responsive">
      <thead>
        <tr>
          <th>Tópicos</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let topico of topicosAdicionados; let i = index">
          <td>{{topico.descricaoTopico}}</td>
          <td class="text-center"
              width="120px">
            <div class="row">
              <button class="btn btn-sm"
                      title="Excluir"
                      [disabled]="!livre || mode =='visualizar' ? desabilitarButtons: false"
                      (click)="excluir(topico.idPessoaCertificadoCreditoTopico)"
                      type="button">
                <img class="logo"
                     src="../../../../../assets/img/icons/excluir.svg"
                     style=" height: 21px;
                  position: relative;" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row"
       *ngIf="mode =='edit' || mode =='visualizar' ">
    <div class="form-group col-md-12">
      <label class="texto--sm"
             for="observacao">Observações</label>
      <textarea class="form-control form-control-sm"
                [class.is-invalid]="
               observacao.invalid && (observacao.touched || isSubmitted)
          "
                formControlName="observacao"
                id="observacao"
                name="observacao"
                rows="6"
                type="text">
                </textarea>
      <div *ngIf="
            observacao.invalid && (observacao.touched || isSubmitted)
          ">
        <span class="erro"
              *ngIf="observacao.errors.required">Campo obrigatório</span>
      </div>
    </div>
  </div>

  <!-- //INPUT IMAGEM -->

  <div class="card-body"
       *ngIf="mode =='edit' || mode =='visualizar'">
    <h4 class="card-title"><strong>Tipo de Documento</strong></h4>
    <!-- <p>Obrigatório envio apenas após aprovação no Exame CFP.</p> -->
    <div class="row mt-5">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-3 col-sm-12">
            <div class="input-group mb-3">
              <div [accept]="'image/jpg, image/png, application/pdf, application/vnd.ms-word,.zip, .rar'"
                   (change)="onSelect($event)"
                   [disabled]="desabilitarUpload"
                   class="custom-dropzone-anexos"
                   #drop
                   [maxFileSize]="1000000"
                   [multiple]="false"
                   ngx-dropzone>
                <ngx-dropzone-label class="label-arquivo">
                  Escolher arquivo
                </ngx-dropzone-label>
              </div>
            </div>
          </div>
          <div class="col-md-9 col-sm-12 mt-2">
            <div class="text-primary mt-1"
                 *ngIf="nomeArq == null">
              Nenhum arquivo selecionado
            </div>
            <div class="text-primary"
                 *ngIf="nomeArq !==null">
              {{ nomeArq }}

              <button class="ml-2 btn btn-danger"
                      (click)="onRemove()"
                      [disabled]="desabilitarUpload"
                      id="remove"
                      *ngIf="files.length > 0 || nomeArq !== null "
                      title="Remover arquivos">
                Remover Arquivo
              </button>
            </div>
          </div>
        </div>
        <p class="mt-1 text-primary"
           *ngIf="
                  (files.length === 0 && nomeArq == '') ||
                  (nomeArq == null )
                ">
          Formatos do arquivo PNG, JPG, DOC e ZIP com tamanho máximo de 1MB.
        </p>
      </div>
    </div>
  </div>

  <div class="row mt-4 ml-1"
       *ngIf="mode =='edit' || mode =='visualizar'">
    <div class="form-group col-md-12 col-sm-12">
      <input formControlName="responsabilidade"
             id="responsabilidade"
             name="responsabilidade"
             type="checkbox"
             (change)="verificaChebox()"
             value="true"
             class="checkLateral" />

      <label class="texto--sm ml-2">
        Assumo inteira responsabilidade pelas informações lançadas, estando sujeito à fiscalização por parte da
        Planejar, que poderá solicitar esclarecimentos sobre realização, presença e confirmação da pertinência dos
        créditos pleiteados. Concordo em prestar esclarecimentos adicionais, caso solicitado. Eventuais desvios serão
        levados para apreciação dos órgãos da Planejar.
      </label>
      <div *ngIf="
      responsabilidade.invalid && (responsabilidade.touched || isSubmitted)
    ">
        <span class="erro"
              *ngIf="responsabilidade.errors.required">Campo obrigatório</span>
      </div>
    </div>
  </div>
  <div class="row mt-2 ml-1"
       *ngIf="livre && (mode =='edit' || mode =='visualizar')">
    <div class="form-group col-md-12 col-sm-12">
      <input formControlName="ciente"
             id="ciente"
             name="ciente"
             type="checkbox"
             (change)="verificaChebox()"
             value="true"
             class="checkLateral" />

      <label class="texto--sm ml-2">
        Estou ciente de que:<br>
        1) o Crédito Livre está sujeito a auditoria da Planejar <br>

        2) devo manter as evidências de minha participação nesta atividade por pelo menos 02 anos.
      </label>
      <div *ngIf="
      ciente.invalid && (ciente.touched || isSubmitted)
    ">
        <span class="erro"
              *ngIf="ciente.errors.required">Campo obrigatório</span>
      </div>
    </div>
  </div>


  <div class="row mt-4"
       *ngIf="mode =='edit' || mode =='visualizar'">
    <div class="col-md-12 col-sm-12">
      <h4><strong>Situação: </strong>{{motivo}}</h4>
    </div>

    <div class="col-md-12 col-sm-12">
      <p>Motivo: <span>{{justificativaReprovacao}}</span></p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-right">
      <div>
        <!-- <button class="btn btn-sm btn-primary btn-secondary mr-0"
                (click)="voltar()"
                id="voltar"
                type="button">
          Voltar
        </button> -->
        <button class="btn btn-sm btn-primary mr-0 ml-2"
                *ngIf="mode =='edit'"
                id="salvar"
                [disabled]="botaoSalvar"
                (click)="salvar()"
                type="button">
          Salvar
        </button>
      </div>
    </div>
  </div>
</form>



<form name="instrucoesForm"
      id="instrucoesForm"
      class="mt-3"
      [formGroup]="instrucoesForm">
  <div aria-labelledby="dialog-child-name"
       bsModal
       #instrucaoModal="bs-modal"
       [config]="{ backdrop: 'static', keyboard: false}"
       class="modal fade"
       tabindex="-1"
       role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header-padding">
          <h2 id="dialog-child-name"
              class="modal-title pull-left">Instruções</h2>
          <button aria-label="Close"
                  class="close pull-right"
                  (click)="hideInstrucoes()"
                  type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <div class="row">
      <div class="form-group col-md-12">
        <label class="texto--sm"
               for="instrucao">Instruções para lançamento de créditos</label>
        <textarea class="form-control form-control-sm"
               [class.is-invalid]="
               instrucao.invalid && (instrucao.touched || isSubmitted)
          "
               formControlName="instrucao"
               id="instrucao"
               name="instrucao"
               rows="6"
               type="text" >
                </textarea>
        <div *ngIf="
            instrucao.invalid && (instrucao.touched || isSubmitted)
          ">
          <span class="erro"
                *ngIf="instrucao.errors.required">Campo obrigatório</span>
        </div>
      </div> -->

          <div class="row">
            <div class="form-group col-md-12 col-sm-12">
              <label>{{itemModal}}</label>
              <div formControlName="codigoEtica"
                   id="summer"
                   name="summer"
                   formControlName="instrucao"
                   [ngxSummernote]="config"
                   [ngxSummernoteDisabled]="true">
              </div>
            </div>
          </div>
          <!-- </div> -->



        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-sm btn-primary btn-secondary"
                  (click)="hideInstrucoes()">voltar</button>
        </div>
      </div>
    </div>
  </div>
</form>