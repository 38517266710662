import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SolicitacaoLicencaService } from 'src/app/services/licenca/solicitacao-licenca.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-solicitacao-licenca',
  templateUrl: './solicitacao-licenca.component.html',
  styleUrls: ['./solicitacao-licenca.component.scss']
})
export class SolicitacaoLicencaComponent implements OnInit {

  files: File[] = [];
  anexos = [];
  view = false;
  imagem = null;
  obj = [];
  id: any;
  observacao: any;
  periodo: any;
  solicitacao: any = null;
  alterar = false;

  constructor(
    private route : ActivatedRoute,
    private router: Router,
    public licencaService: SolicitacaoLicencaService
  ) {
    this.id =  JSON.parse(localStorage.getItem('userId'));
  }

  obterSolicitacao()
  {
    this.licencaService.obterSolicitacao(this.id)
      .subscribe(
        (resp)=>{
          this.solicitacao = resp.resultado;
        },
        (erro)=>{
          console.log('erro ao buscar solicitacao ' + erro)
        }
      );

  }

  ngOnInit(): void {
    this.obterSolicitacao();
  }

  onSelect(event) {
    this.obj = [];
    this.onRemove();
    this.obj =  event.source._previewChildren;
    this.files.push(...event.addedFiles);
  }

  onRemove() {
    this.obj = [];
    this.files.splice(0, 1);
    if (this.imagem !== '') {
      this.imagem = '';
    }
  }

  efetivar()
  {

    this.periodo = $('#periodo').val();
    this.observacao = $('#observacao').val();

    if (this.solicitacao != null) 
    {

      if (this.files.length > 0) 
      {

        this.obj.forEach(o => {
          
          let arquivo =  {
            "nomeArquivo": o.file.name,
            "anexo": o.imageSrc.replace(/^data:(.*base64,)?/,'')
          };        
          
          this.anexos.push(arquivo);

        });

        if(this.alterar)
        {

          let request =  {
            "idSolicitacaoLicenca": this.solicitacao.idSolicitacaoLicenca,
            "idPessoa": this.id,
            "periodo": this.periodo,
            "observacao": this.observacao,
            "anexos": this.anexos
          };
  
          this.licencaService.alterar(request)
          .subscribe((resp)=>{
            if(!resp.sucesso)
            {
              Swal.fire(
                'Solicitação de Licença',
                `Erro ao efetuar alteração de solicitação de licença. Favor contate o suporte.`,
                'error'
              ).then(() => {
                this.onRemove();          
              });              
              return;
            }
            this.obterSolicitacao();
            Swal.fire(
              'Solicitação de Licença',
              `Solicitação de Licença alterada com Sucesso. `,
              'success'
            ).then(() => {
              this.anexos = [];
              this.onRemove();          
            });
          },(erro)=>{
            Swal.fire(
              'Solicitação de Licença',
              erro.error.excecao[0].mensagem,
              'error'
            );
          });
        }
        else
        {

          let request =  {
            "idPessoa": this.id,
            "periodo": this.periodo,
            "observacao": this.observacao,
            "anexos": this.anexos
          };
  
          this.licencaService.criar(request)
          .subscribe((resp)=>{
            if(!resp.sucesso)
            {
                Swal.fire(
                  'Solicitação de Licença',
                  `Erro ao efetuar cadastro de solicitação de licença. Favor contate o suporte`,
                  'error'
                ).then(() => {
                  this.anexos = [];
                  this.onRemove();          
                });
                return;  
            }
            this.obterSolicitacao();
            Swal.fire(
              'Solicitação de Licença',
              `Solicitação de Licença cadastrada com Sucesso. `,
              'success'
            ).then(() => {
              this.anexos = [];
              this.onRemove();          
            })
          },(erro)=>{
            Swal.fire(
              'Solicitação de Licença',
              erro.error.excecao[0].mensagem,
              'error'
            );
          });          
        }

      } else {
        Swal.fire(
          'Solicitação de Licença',
          `Insira ao menos um arquivo.`,
          'warning'
        );
      }

    }
    else
    {

      this.obj.forEach(o => {
          
        let arquivo =  {
          "nomeArquivo": o.file.name,
          "anexo": o.imageSrc.replace(/^data:(.*base64,)?/,'')
        };        
        
        this.anexos.push(arquivo);

      });

      let request =  {
        "idPessoa": this.id,
        "periodo": this.periodo,
        "observacao": this.observacao,
        "anexos": this.anexos
      };      

      this.licencaService.criar(request)
      .subscribe((resp)=>{
        if(!resp.sucesso)
        {
            Swal.fire(
              'Solicitação de Licença',
              `Erro ao efetuar cadastro de solicitação de licença. Favor contate o suporte`,
              'error'
            ).then(() => {
              this.onRemove();          
            });
            return;  
        }        
        this.obterSolicitacao();
        Swal.fire(
          'Solicitação de Licença',
          `Solicitação de Licença cadastrada com Sucesso. `,
          'success'
        ).then(() => {
          this.anexos = [];
          this.onRemove();          
        });
      },(erro)=>{
        Swal.fire(
          'Solicitação de Licença',
          erro.error.excecao[0].mensagem,
          'error'
        );
      });
      
    }    

  }

  enviar() {

    if (this.solicitacao != null) 
    {

      if(this.solicitacao.aprovado == null)
      {
        Swal.fire({
          title: 'Solicitação de Licença',
          text: "Já foi enviada uma solicitação de licença anteriormente. Deseja reenviar novamente?",
          icon: 'question',
          showCancelButton: true,
          cancelButtonColor: '#3085d6',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) 
          {
            this.alterar = true;
            this.efetivar();
          }
          if(result.isDismissed)
          {
            this.alterar = false;
            return;
          }
        });       
      }
      else
      {
        if(this.solicitacao.aprovado == true)
        {
          Swal.fire(
            'Solicitação de Licença',
            `A solicitação de licença já consta como aprovada. Não será mais necessário o envio.`,
            'warning'
          );
          return;
        }
        else
        {
          this.alterar = false;
          this.efetivar();          
        }
      }
    }
    else
    {
      this.alterar = false;
      this.efetivar();
    }
  }
}
