import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-supervisao-andamento',
  templateUrl: './supervisao-andamento.component.html',
  styleUrls: ['./supervisao-andamento.component.scss']
})
export class SupervisaoAndamentoComponent implements OnInit {
  view = false;
  imagem = null;
  id = null;
  solicitacoes = null;
  aprovados: boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute, private experienciaSupervisionadaService: ExperienciaSupervisionadaService) { }

  ngOnInit(): void {
    this.id = window.localStorage.getItem('userId');

    this.route.paramMap.subscribe(params => {    
        if (params.get("aprovados") === 'true')
          this.aprovados = true;

          this.obterSolicitacoes(this.aprovados);    
    });    
    
  }

  obterSolicitacoes(aprovados) {
    this.experienciaSupervisionadaService.obterSolicitacoesSupervisoresPorSupervisor(this.id).subscribe((result) => {
      this.solicitacoes = result.resultado;
      if(aprovados)
        this.solicitacoes = this.solicitacoes.filter(d=>d.situacao !== 'Em Análise' && d.situacao !== 'Recusado');
      //else
      //  this.solicitacoes = this.solicitacoes.filter(d=>d.situacao === 'Em Análise' || d.situacao === 'Recusa');
    });
  }

  cancelarSupervisor(codigoSolicitacaoSupervisor, motivoCancelamento)
  {
    this.experienciaSupervisionadaService.cancelarSolicitacaoSupervisor(codigoSolicitacaoSupervisor, motivoCancelamento).subscribe((result) => {
      if(result.sucesso)
      {
        //this.obterSolicitacoes(this.aprovados);
        history.go(-1);
      }
    });
  }

  confirmarCancelamentoSupervisor(codigoSolicitacaoSupervisor) {

    Swal.fire({
      title: 'Cancelamento de Supervisão',
      text: "Tem certeza que deseja efetuar o cancelamento de supervisão para este supervisionado? O cancelamento não poderá ser desfeito",
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) 
      {
      
        Swal.fire({
          title: "Cancelamento de Supervisão",
          text: "Favor informe o motivo do cancelamento:",
          input: 'text',
          showCancelButton: true,
          cancelButtonColor: '#3085d6',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          reverseButtons: true    
          }).then((result) => {
              if (!result.value) {
                  Swal.fire(
                    'Cancelamento de Supervisão',
                    'Motivo não informado. Não será possível efetuar o cancelamento.',
                    'error'
                  );                        
              }
              else
              {
                this.cancelarSupervisor(codigoSolicitacaoSupervisor, result.value);
              }
          });
            
      }
    });    

  }

}
