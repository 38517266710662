import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class RedirecionaService {
  httpHeaders: HttpHeaders;
  private currentUserSubject: BehaviorSubject<any>;
  constructor(private http: HttpClient) {
    let token = localStorage.getItem('tknCdst');

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  validarAcesso(token: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/login-hsol/validar-token`,
      token,
      {
        headers: this.httpHeaders,
      }
    );
  }

  validarAcessoExterno(token: any) {
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/login-hsol/validar-token`,
      JSON.stringify(token)
        ,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((user) => {
          localStorage.setItem('estruturas', user.estruturas);
          localStorage.setItem('userId', user.codigo_usuario);
          localStorage.setItem('tkn', 'Bearer ' + user.access_token);
         // this.currentUserSubject.next(user);
          return user;
        })
      );
  }
}
