import { AreaLogadaInscricaoService } from 'src/app/services/area-logada-inscricao/area-logada-inscricao.service';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PessoaService } from 'src/app/services/pessoa/pessoa.service';
import { ExperienciaSupervisionadaService } from 'src/app/services/experiencia-supervisionada/experiencia-supervisionada.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as $ from 'jquery';
import * as moment from 'moment';
@Component({
  selector: 'app-selecionar-supervisor',
  templateUrl: './selecionar-supervisor.component.html',
  styleUrls: ['./selecionar-supervisor.component.scss'],
})
export class SelecionarSupervisorComponent implements OnInit {
  id: any;
  solicitacaoForm: FormGroup;
  imagem = null;
  conhecimentos = [];
  educacoes = [];

  idPessoa: number;
  codigoSolicitacaoSupervisor: number;
  pessoa: [];
  endereco: [];
  experiencias = [];
  cargos = [];
  cidade_uf: string;
  descricaoTipoAtendimentoSupervisao: string;
  dataCertificacao: any;
  telefoneCelular: any;
  ngOnInit(): void {
    this.init();

    this.route.paramMap.subscribe((params) => {
      if (params.get('idPessoa') !== null)
        this.idPessoa = Number(params.get('idPessoa'));
      this.obterPessoa(this.idPessoa);

      this.carregaAreaAtuacaoConhecimento();
    });
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private pessoaService: PessoaService,
    private experienciaSupervisionadaService: ExperienciaSupervisionadaService,
    private route: ActivatedRoute
  ) {
    this.solicitacaoForm = this.formBuilder.group({});
  }

  init() {
    this.solicitacaoForm = this.formBuilder.group({
      nome: [''],
      localizacao: [''],
      email: [''],
      tipoAtendimentoSupervisao: [''],
      telefoneCelular: [''],
      apresentacaoSupervisao: [''],
      dataCertificado: [''],
      especialidades: [''],
    });
  }

  get nome() {
    return this.solicitacaoForm.get('nome');
  }
  get localizacao() {
    return this.solicitacaoForm.get('localizacao');
  }
  get email() {
    return this.solicitacaoForm.get('email');
  }
  get NumeroTelefoneCelular() {
    return this.solicitacaoForm.get('telefoneCelular');
  }
  get tipoAtendimentoSupervisao() {
    return this.solicitacaoForm.get('tipoAtendimentoSupervisao');
  }
  get apresentacaoSupervisao() {
    return this.solicitacaoForm.get('apresentacaoSupervisao');
  }
  get dataCertificado() {
    return this.solicitacaoForm.get('dataCertificado');
  }
  get especialidades() {
    return this.solicitacaoForm.get('especialidades');
  }

  obterPessoa(idPessoa) {
    this.pessoaService.obterPorId(idPessoa).subscribe((resp) => {
      this.pessoa = resp.resultado;
      this.endereco = resp.resultado.pessoaEndereco[0];
      this.cidade_uf = this.endereco['cidade'] + '/' + this.endereco['estado'];
      this.descricaoTipoAtendimentoSupervisao =
        resp.resultado.tipoAtendimentoSupervisao === null
          ? 'Não informado'
          : resp.resultado.tipoAtendimentoSupervisao === 1
          ? 'Presencial'
          : resp.resultado.tipoAtendimentoSupervisao === 2
          ? 'À distância'
          : 'Híbrido';
      this.apresentacaoSupervisao.setValue(
        this.pessoa['apresentacaoSupervisao']
      );
      this.dataCertificacao = moment(resp.resultado.dataCertificacao).format(
        'DD/MM/YYYY'
      );
      this.especialidades.setValue(this.pessoa['especialidades']);
      this.telefoneCelular =
        this.pessoa['dddCelular'] + this.pessoa['telefoneCelular'];
    });
  }

  carregaAreaAtuacaoConhecimento() {
    this.pessoaService.obterAreas().subscribe(
      (resp) => {
        resp.resultado.map((item) => {
          item.checked = false;
        });
        this.conhecimentos = resp.resultado;
        this.preencherAreasSelecionadas();
      },
      (erro) => {
        Swal.fire('Educação ', erro.error.excecao[0].mensagem, 'error');
      }
    );
  }

  preencherAreasSelecionadas() {
    let selecionados = [];
    this.experienciaSupervisionadaService
      .obterSolicitacaoSupervisor(this.codigoSolicitacaoSupervisor)
      .subscribe((resp) => {
        resp.resultado.areas.map((itens) => {
          selecionados.push(itens);
        });
      });
    this.conhecimentos.map((item) => {
      item.checked = false;
      selecionados.map((el) => {
        if (item.idAreaSupervisao === el.idAreaSupervisao) {
          item.checked = true;
        }
      });
    });
  }

  DefinirSupervisor() {
    this.router.navigateByUrl('/definir-supervisor/' + this.idPessoa);
  }
}
