<fieldset class="border p-3 my-3" *ngIf="certificado == null">
  <div class="dashboard">
    <div class="row">
        <div class="col-md-12">
          <Label>Mensagens</Label>
        </div>
      </div>
      <div class="row mt-3 ml-5">
        <div class="col-md-12">
            <div class="row" *ngIf="mensagens">
              <div class="col-md-12 d-flex flex-row">
                  <div class="mensagemItemWrapper">
                    <div *ngFor="let mensagem of mensagens">
                      <div (click)="lerMensagem(mensagem.idMensagemInterna)" [id]="mensagem.idMensagemInterna" class="mensagemItem" [ngClass]="{'mensagemAtiva': selectedId == mensagem.idMensagemInterna}">
                        <span [ngStyle]="{'color': mensagem.visualizada == false ? 'red' : 'black'}">{{mensagem.assunto}}</span>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%">
                    <div
                      id="msg"
                      name="msg"
                      [ngxSummernote]="config"
                      [ngxSummernoteDisabled]="true"
                      [ngxSummernoteView]="mensagemAtual"
                    >
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
</fieldset>
<div *ngIf="certificado != null">
  <app-progress></app-progress>
</div>