import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    let token = localStorage.getItem('tknCdst');


    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknCdst'),
    });
  }

  obterTodos() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-local/obter-todos-ativos`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterProspeccao() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-local/obter-evento-prospeccao`,
      {
        headers: this.httpHeaders
      }
    );
  }

  obterPorId(id: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-evento-local/obter-por-id`,
      {
        headers: this.httpHeaders,
        params: { id },
      }
    );
  }

  atualizarLocal(local: any) {
    return this.http.put<any>(
      `${environment.backofficeUrl}/area-logada-evento-local/alterar`,
      local,
      {
        headers: this.httpHeaders,
      }
    );
  }

  excluir(id: any) {
    return this.http.delete(
      `${environment.backofficeUrl}/area-logada-evento-local/desativar-id?id=${id}`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  criarLocal(local: any) {
    return this.http.post<any>(
      `${environment.backofficeUrl}/area-logada-evento-local/criar`,
      local,
      {
        headers: this.httpHeaders,
      }
    );
  }

  obterPorCep(cep: any) {
    return this.http.get<any>(
      `${environment.backofficeUrl}/area-logada-auto-cadastro/obter-endereco-cep`,
      {
        headers: this.httpHeaders,
        params: {
          cep: cep,
        },
      }
    );
  }

}
