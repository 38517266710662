<fieldset class="border p-3 my-3">
  <legend class="legendFormulario">Material de estudo</legend>
  <div class="dashboard">
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex flex-row wrapper">
            <div>
              <p>
                A Planejar em parceria com a Practa e a Editora Sirius (responsável pelo site Estante do Investidor),
                disponibiliza o Material Oficial de Estudos para o Exame CFP®.
              </p>

              <p class="mt-5"><b>LIVROS DIGITAIS:</b></p>

              <p class="mt-5">
                A coleção completa estará disponível pelo custo de R$ 80,00 (o acesso ao conteúdo estará disponível por
                24
                meses).
              </p>

              <p class="mt-5">
                Para verificar sua compra, basta selecionar a opção "ASSINATURA DOS LIVROS DIGITAIS" no site
                estantedoinvestidor.com.br e inserir o código <b>{{cupom}}</b> no campo "código do cupom" e clique em
                "Aplicar cupom",
                preenchendo os demais dados da ficha, para finalizar a compra. Vale ressaltar que o desconto é válido
                somente
                para uma compra por cupom ou CPF por Exame CFP®.
              </p>

              <p class="mt-5">
                Após a confirmação de sua compra, você receberá um e-mail contendo as instruções de como efetuar seu
                acesso por
                meio do site <a href="https://www.digital.estantedoinvestidor.com.br"
                  target="_blank">digital.estantedoinvestidor.com.br</a>. Podendo voltar a acessá-lo por este canal,
                sempre que
                necessário.
              </p>

              <p class="mt-5">
                Em caso de dúvidas sobre inscrição no exame, entre em contato conosco: <a
                  href="mailto:planejado@planejar.org.br">planejado@planejar.org.br</a>
              </p>

              <p class="mt-5">
                Atenciosamente, <br>
                Equipe Planejar
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 text-right mr-4">
            <div>
              <button class="btn btn-sm btn-primary btn-secondary ml-2" type="button"
                routerLink="/inscricao-config/{{idInscricao}}">
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>
